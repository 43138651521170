<template>
  <div>
    <BaseWindowList
      :headers="headers"
      :itemsBaseWindowList="desserts"
      title="Gestion de Guias"
      titleAddMethod="Agregar Nuevo Guia"
      :activarEdicion="true"
      :specialEditFunction="true"
      :editarSpecial="specialEditItem"
      :activarBorrar="true"
      :specialEliminarFunction="true"
      :eliminarSpecial="specialEliminarItem"
      :currentPage="currentPage"
      :totalPages="totalPages"
      :loading="loading"
      v-on:editar-row="editItem"
      v-on:open-agregar-listado="openForm"
      v-on:click-gotopage="siguientePagina"
      v-on:eliminar-row="deleteItem"
      itemStore="searchGuias"
      :actBtnExcel="activeAdvanceSearch"
      v-on:reset-info="resetInfo"
      v-on:info-excel="infoExcel"
    >

      <template v-slot:item.fecha="{ item }">
        <tr class="d-flex justify-end">
          <td>
            {{ item.fecha.substring(0,10) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.precio_base="{ item }">
        <tr class="d-flex justify-end">
          <td
            class="text-center text-caption blue-grey--text text--darken-1 "
          >
            {{ convertToMoney(item.precio_base) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.descuento="{ item }">
        <tr class="d-flex justify-end">
          <td
            class="text-center text-caption blue-grey--text text--darken-1 "
          >
            {{ convertToMoney(item.descuento) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.iva="{ item }">
        <tr class="d-flex justify-end">
          <td
            class="text-center text-caption blue-grey--text text--darken-1 "
          >
            {{ convertToMoney(item.iva) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.tipo_identificacion="{ item }">
        <tr class="d-flex justify-end">
          <td
            class="text-center text-caption blue-grey--text text--darken-1 "
          >
            {{ itemTextTipoIdentifi(item.tipo_identificacion) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.estado_guia="{ item }">
        <tr class="d-flex justify-end">
          <td
            class="text-center text-caption blue-grey--text text--darken-1 "
          >
            {{ itemTextEstado(item.estado_guia) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.transportista="{ item }">
        <tr class="d-flex justify-center">
          <td
            class="text-center text-caption blue-grey--text text--darken-1 "
          >
            {{ itemTextTransportista(item.transportista) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.destino_tonelada="{ item }">
        <tr class="d-flex justify-center">
          <td
            class="text-center text-caption blue-grey--text text--darken-1 "
          >
            {{ itemTextTonelada(item.destino_tonelada) }}
          </td>
        </tr>
      </template>

    </BaseWindowList>

    <!-- MAIN DIALOG -->
    <v-form ref="editedItem">
      <v-dialog v-model="dialog" max-width="800px" persistent>
        <v-card rounded="xl" :loading="loading" :disabled="loading">
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
               
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    dense
                    v-model="editedItem.numero"
                    label="Número"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                    <v-menu
                      v-model="menuPickers.fecha"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          v-model="editedItem.fecha"
                          label="Fecha"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.fecha"
                        @input="menuPickers.fecha = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      v-model="editedItem.sede"
                      :items="sedes"
                      item-text="nombre"
                      item-value="id"
                      label="Sedes"
                      clearable
                      dense
                    >
                    </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <BaseAutoComp
                    fieldName="destino_tonelada"
                    :key="keyAutoDestino"
                    textoBusqueda="Destino y/o codigo tonelada"
                    label="Buscar Destino..."
                    :itemText="['numero','destino']"
                    itemValue="id"
                    :advanceSearchFields="{
                      codigo_destino: null,
                      destino: null
                    }"
                    rutaItemAuto="transjuma/DestinoTonelada/"
                    :procesoCompleto="procesoCompleto"
                    :modoEdicion="checkThemodeEdicion"
                    :itemEditar="selectedItemEditar"
                    v-on:change-auto="selectedDestino"
                  >

                  </BaseAutoComp>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      v-model="editedItem.transportista"
                      :items="transportistas"
                      item-text="nombre"
                      item-value="id"
                      label="Transportistas"
                      clearable
                      dense
                    >
                    </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                   :key="keyPrecio" 
                    disabled
                    v-model="editedItem.precio_base"
                    label="Precio"
                    dense
                  ></v-text-field>
                </v-col>


                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    disabled
                    v-model="editedItem.descuento"
                    label="Descuento"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    disabled
                    v-model="editedItem.iva"
                    label="IVA"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                    <v-menu
                      v-model="menuPickers.fecha_pago"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      dense
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.fecha_pago"
                          label="Fecha de Pago"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.fecha_pago"
                        @input="menuPickers.fecha_pago = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="blue darken-1" text @click="close"
              >Cancel</v-btn
            >
            <v-btn small color="blue darken-1" text @click="save(mode)"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <!-- DIALOG DELETE -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >¿Está seguro de borrar este destino y tonelada?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete"
            >Cancel</v-btn
          >
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CUADRO DIALOGO BUSQUEDA AVANZADA -->
    <BusquedaAvanzada
      nameItemStore="searchGuias"
      v-on:advance-search="onAdvanceSearch"
    ></BusquedaAvanzada>
  </div>
</template>

<script>
import { axiosC } from "../api/axiosbd"
import BaseWindowList from "@/components/BaseWindowList"
import { paginationMethods } from "../mixins/pagination"
import BaseAutoComp from '../components/BaseAutoComplet.vue'
import BusquedaAvanzada from "../components/BusquedaAvanzada.vue"
import {utils, writeFile}from 'xlsx'

export default {
  components: {
    BaseWindowList, BaseAutoComp, BusquedaAvanzada
  },
  mixins: [paginationMethods],
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        { text: 'ID', value: 'id'},
        { text: 'Número', value: 'numero', width: '140' },
        { text: 'Estado', value: 'estado_guia', align: 'center' },
        { text: 'Fecha', value: 'fecha', width: '80' },
        // { text: 'Sede', value: 'sede' },
        { text: 'Cod. Asign', value: 'ca', align: 'center' },
        { text: 'Cod. Toneladas', value: 'destino_tonelada', align: 'center' },
        // { text: 'Destino', value: 'destino_tonelada', align: 'center', width: '140' },
        { text: 'Transportista', value: 'transportista', width: '260',  align: 'center' },
        { text: 'Precio', value: 'precio_base', align: 'end' },
        { text: 'Descuento', value: 'descuento', align: 'end' },
        { text: 'IVA', value: 'iva', align: 'end' },
        // { text: 'Fecha proforma', value: 'fecha_proforma' },
        { text: 'Lt proforma', value: 'lote_proforma', align: 'center' },
        // { text: 'Comentario', value: 'comentario' },
        // { text: 'Fecha de factura', value: 'fecha_factura' },
        // { text: 'No. de factura', value: 'no_factura' },
        // { text: 'Referencia de pago', value: 'ref_pago' },
        // { text: 'No. de orden de compra', value: 'no_orden_compra' },
        // { text: 'Fecha de pago', value: 'fecha_pago' },
        // { text: 'Fecha de pago recibido', value: 'fecha_pago_recibido' },
        { text: "Actions", value: "actions", sortable: false }
      ],
      desserts: [],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {},
      deletedItem: {},
      defaultItem: {},
      tipos: [
        { id: "F", text: "FIJO" },
        { id: "V", text: "VARIABLE" },
      ],
      mode: "POST",

      //
      menuPickers: {
        fecha: false,
        fecha_proforma: false,
        fecha_fcatura: false,
        fecha_pago: false,
        fecha_pago_recibido: false
      },
      keyPrecio: 0,
      keyDescuento: 0,
      keyIva: 0,
      keyAutoDestino: 0,
      sedes: [
        {id: 1, nombre: 'Pavas'}
      ],
      transportistas: [],
      estados: [],
      destinos: [],
      procesoCompleto: false,
      selectedItemEditar: null,
      themodeEdicion: false,
      activeAdvanceSearch: false,
      queryAdvanceSearch: {}
    };
  },
  methods: {
    infoExcel(item){
      console.log(item)
      //debe ir al back y traer todas las guias de la opcion filtrada, con mejor detalle
      // ahora mismo solo esta trayendo 20
      // mejor hacer el excel en el back y que venga listo
      const fileName = `Info guias filtrada.xlsx`
      var ws = utils.json_to_sheet(item)
      var wb = utils.book_new()
      utils.book_append_sheet(wb, ws, 'test')
      writeFile(wb, fileName)
    },
    resetInfo(){
      this.getDataGuias()
    },
    onAdvanceSearch(item){
      console.log(item)
      const page = 1;
      const size = 20;
      this.nameItemStore = "desserts"
      this.queryAdvanceSearch = {}
      this.queryAdvanceSearch.pagination = true

      let valid = true

      if (item.fecha1 && item.fecha2 && item.rango){
        this.queryAdvanceSearch.fecha_bw = [item.fecha1, item.fecha2]
      }
      if (item.fecha1 && !item.rango) {
        this.queryAdvanceSearch.fecha = item.fecha1
      }
      if (item.fecha1 && !item.fecha2 && item.rango ) {
        this.queryAdvanceSearch.fecha_gt = item.fecha1
      }
      if (!item.fecha1 && item.fecha2 && item.rango ) {
        this.queryAdvanceSearch.fecha_lt = item.fecha2
      }
      if (item.transportista && item.transportista.length > 0){
        this.queryAdvanceSearch.transportista_id = item.transportista
      }
      if (item.id) {
        this.queryAdvanceSearch.id = item.id
      }
      this.activeAdvanceSearch = true
      this.currentPage = 1
      console.log(this.queryAdvanceSearch)
        this.getDataGeneral(
          "GET",
          `transjuma/Guia/`,
          this.nameItemStore,
          "loading",
          {},
          true, //paginacion
          false, // opcion para que devuelva algo
          false, //modo normal activado, vienen todos los resultados
          null, // parametros query
          false, //unico registro
          this.queryAdvanceSearch
        )

    },
    selectedDestino(selected){
      console.warn(selected)
      let item  = selected.itemSelected
      
      this.editedItem.destino_tonelada = item ? item.id : null

      // this.selectedDestiny = this.destinos_toneladas.find((dest) => dest.id == item)
      // console.log(this.selectedDestiny)
      if (item){
        this.keyPrecio +=1
        this.keyDescuento +=1
        this.keyIva +=1
        let precio_base = parseFloat(item.neto_pagar)
        let descuento = parseFloat(item.neto_pagar) * -0.1
        let iva = (precio_base + descuento) * 0.13

        this.editedItem.iva = this.$convertToMoney(iva)
        this.editedItem.precio_base = this.$convertToMoney(precio_base)
        this.editedItem.descuento = this.$convertToMoney(descuento)

      }
      
    },
    itemTextTipoIdentifi(item){
      if (item){
        const text = this.tipos.find(y => y.id == item)
        return text ? text.nombre : ''
      }
    },
    itemTextTransportista(item){
      if (item){
        const text = this.transportistas.find(y => y.id == item)
        return text ? text.nombre : ''
      }
    },
    itemTextEstado(item){
      if (item){
        const text = this.estados.find(y => y.id == item)
        return text ? text.nombre : ''
      }
    },
    itemTextTonelada(item){

      if (item){
        const toneladas = this.desserts.map(item=> item.destino_tonelada)
        const text = toneladas.find(y => y.id == item.id)

        return text ? `${text.numero} ${text.destino}` : ''
      }
    },
    siguientePagina(item) {
      let query = {}
      if (this.activeAdvanceSearch){
        query = {...this.queryAdvanceSearch}
      } else {
        query =  {
          estado_guia_id : [1,2,3],
          pagination: true
        }
      }

      this.gotoPage(item, this.$route.meta.backRoute, query);
    },
    openForm() {
      this.dialog = true;
    },

    convertToMoney(x) {
      // Verificar si el valor es nulo, indefinido o no es un número
      if (x === undefined || x === null || isNaN(Number(x))) {
        return "0.00";
      }
      // Convertir a número y luego aplicar toFixed(2)
      x = Number(x).toFixed(2);

      // Aplicar formato de separador de miles
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    buildNumber() {
      // // console.log(this.editedItem.tonelada)
      // let tonelada =
      //   this.editedItem.tonelada > 0 ? this.editedItem.tonelada : 0;
      // let codigo_destino =
      //   this.editedItem.codigo_destino.trim() != ""
      //     ? this.editedItem.codigo_destino
      //     : "";
      // this.editedItem.numero =
      //   this.editedItem.tonelada > 0
      //     ? parseInt(tonelada) + codigo_destino
      //     : codigo_destino;
    },
    clearSelects() {
      for (let field in this.defaultItem) {
        if (this.defaultItem[field].value) this.defaultItem[field].value = null;
        if (this.defaultItem[field].values) this.defaultItem[field].values = [];
      }
    },
    close() {
      this.dialog = false;
      this.mode = "POST";
      this.editedItem = {}
      this.search =  null
      this.procesoCompleto = true
      this.keyAutoDestino++
      this.themodeEdicion = false
    },
    closeDelete() {
      this.dialogDelete = false;

      this.$nextTick(() => {
        this.clearSelects();
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    specialEliminarItem(item){
      if (item.estado_guia == 1){
        this.deleteItem(item)
      } else {
        this.$message({
            message: 'La guia seleccionada no se encuentra en estado flete.',
            type: 'error',
            showClose: true,
            duration: 0
          })
      }
    },
    deleteItem(item) {
      this.deletedItem = { ...item };
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.loading = true;
      await this.$store.dispatch("AuthModule/verifyToken");

      axiosC({
        method: "DELETE",
        url: `transjuma/Guia/${this.deletedItem.id}/`,
        headers: {
          Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
        },
      })
        .then(() => {
          this.getDataGuias()
          this.closeDelete();
        })
        .catch((err) => {
          this.loading = false;
          alert(err.response.data.message);
        });
    },
    async save(method) {
      this.loading = true;
      this.procesoCompleto = false
      await this.$store.dispatch("AuthModule/verifyToken");

      let body = {};

      body = { ...this.editedItem }

      body.company = 1;
      body.created_by =
        method == "POST"
          ? this.$store.state.AuthModule.refu
          : this.editedItem.created_by;
      body.updated_by =
        method == "PUT" ? this.$store.state.AuthModule.refu : null

      //del propio componente
      if(method=='POST'){
        body.precio_base = Number(this.editedItem.precio_base.replace(/,/g, ''))
        body.iva = Number(this.editedItem.iva.replace(/,/g, ''))
        body.descuento = Number(this.editedItem.descuento.replace(/,/g, ''))
        body.estado_guia = 1
      }

      if(method=='PUT'){
        body.precio_base = Number(this.editedItem.precio_base.replace(/,/g, ''))
        body.iva = Number(this.editedItem.iva.replace(/,/g, ''))
        body.descuento = Number(this.editedItem.descuento.replace(/,/g, ''))
        if (typeof body.destino_tonelada === 'object' && body.destino_tonelada !== null){
          body.destino_tonelada = body.destino_tonelada.id
        }
      }
      // console.log(body);

      axiosC({
        method: method,
        url: `transjuma/Guia/${method == "PUT" ? body.id + "/" : ""}`,
        data: body,
        headers: {
          Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
        },
      })
        .then(() => {
          this.close();
          this.getDataGuias()
        })
        .catch((err) =>  {
          this.loading = false
          alert(err.response.data.message)
        })
    },
    specialEditItem(item){
      if (item.estado_guia == 1){
        this.editItem(item)
      } else {
        this.$message({
            message: 'La guia seleccionada no se encuentra en estado flete.',
            type: 'error',
            showClose: true,
            duration: 0
          })
      }
    },
    editItem(item) {
      this.keyAutoDestino++
      this.mode = "PUT";

      this.$nextTick(()=>{
        this.themodeEdicion = true
        this.selectedItemEditar =  item.destino_tonelada //se envia al componente auto
      })



      this.editedItem = { ...item };
      this.editedItem.fecha = this.editedItem.fecha.substring(0,10)
      this.editedItem.precio_base = this.convertToMoney(this.editedItem.precio_base)
      this.editedItem.iva = this.convertToMoney(this.editedItem.iva)
      this.editedItem.descuento = this.convertToMoney(this.editedItem.descuento)

      this.procesoCompleto = false
      this.dialog = true;
      return item

    },
    getDataGuias(){
      this.currentPage = 1
      this.activeAdvanceSearch = false
      this.queryAdvanceSearch = {}
      const page = 1;
      const size = 20;
      this.nameItemStore = "desserts";

      const query =  {
        estado_guia_id : [1,2,3],
        pagination: true
      }
      this.getDataGeneral(
        "GET",
        `transjuma/Guia/`,
        this.nameItemStore,
        "loading",
        {},
        true, //paginacion
        false, // opcion para que devuelva algo
        false, //modo normal activado, vienen todos los resultados
        null, // parametros query
        false, //unico registro
        query
      )
    }
  },
  computed: {
    checkThemodeEdicion(){
      return this.themodeEdicion
    },
    checkMode(){
      return this.mode == 'PUT' ? true : false
    },
    formTitle() {
      if (this.editedIndex === -1) {
        return `Nuevo destino y tonelada`;
      } else {
        return `Edición de destino y tonelada`;
      }
    },
  },
  created() {
    this.getDataGuias()
    this.getDataGeneral(
      "GET",
      `transjuma/TransportistaList`, //cuando son rutas de listas completas sin el /
      'transportistas',
      "",
      {},
      false, //paginacion
      false, // opcion para que devuelva algo
      true //modo normal activado, vienen todos los resultados
    );
    this.getDataGeneral(
      "GET",
      `transjuma/EstadoGuia/`,
      'estados',
      "",
      {},
      false, //paginacion
      false, // opcion para que devuelva algo
      true //modo normal activado, vienen todos los resultados
    );
  },
};
</script>