import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import VueJwtDecode from 'vue-jwt-decode'
import { axiosC } from '../api/axiosbd'
import Store from '../store/index'
import Home from '../views/Home.vue'
import Destinos_toneladas from '../views/Destinos_toneladas.vue'
import Guias from '../views/Guias.vue'
import Historico_guias from '../views/Historico_guias.vue'
import Ajustes from '../views/Ajustes.vue'
import Historico_ajustes from '../views/Historico_ajustes.vue'
import Transportistas from '../views/Transportistas.vue'
import Deducciones from '../views/Deducciones.vue'
import Asignaciones from '../views/Asignaciones.vue'
import Proformas from '../views/Proformas.vue'
import Permissions from '../views/Permissions.vue'
import Asignacion from '../views/Asignacion.vue'
import AsignacionGlobal from '../views/AsignacionGlobal.vue'
import Proforma from '../views/Proforma.vue' // se coloca directamente para que momentaneamente funcione el beforerouteleave
import MigracionGuias from '../views/MigracionGuias.vue'
import MigracionAjustes from '../views/MigracionAjustes.vue'
import MigracionDeducciones from '../views/MigracionDeducciones.vue'
import MigracionOrdenesCompra from '../views/MigracionOrdenesCompra.vue'
import Factura from '../views/Factura.vue'
import Reporte_guias from '../views/Reporte_guias.vue'
import FacturaList from '../views/FacturaList.vue'
import OrdenesCompraList from '../views/OrdenesCompraList.vue'
import InfoGuias from '../views/InfoGuias.vue'
import DestinosToneladasHistorico from '../views/DestinosToneladasHistorico.vue'

import PorPagar from '../views/PorPagar.vue'
import PorCobrar from '../views/PorCobrar.vue'

import ProvProgramaciones from '../views/ProvProgramaciones.vue'
import ProgramacionPagos from '../views/ProvProgramacionPagos.vue'
import ProcesoDePago from '../views/ProvProcesoDePago.vue'

//CONFIGURACION
import Configuracion from '../views/Configuracion.vue'
import Sedes from '../views/configuracion/Sedes.vue'
import Tipo_facturar from '../views/configuracion/Tipo_facturar.vue'
import Tipo_identificaciones from '../views/configuracion/Tipo_identificaciones.vue'
import Tipo_deducciones from '../views/configuracion/Tipo_deducciones.vue'
import Estado_guias from '../views/configuracion/Estado_guias.vue'
import Tipo_ajustes from '../views/configuracion/Tipo_ajustes.vue'
import Esatdo_asignaciones from '../views/configuracion/Esatdo_asignaciones.vue'
import User from '../views/configuracion/User.vue'
import Banco from '../views/configuracion/Banco.vue'
import Metodo_pagos from '../views/configuracion/Metodo_pagos.vue'
import Estado_pagos from '../views/configuracion/Estado_pagos.vue'

Vue.use(VueRouter)
    
const routes = [
  { path: '/login', name: 'Login', component: Login },
    { path: '/', name: 'inicio', component: Home },
	{ path: '/home', name: 'Home', component: Home },
	{ path: '/destinos_toneladas', name: 'Destinos_toneladas', component: Destinos_toneladas, meta:{backRoute: '/transjuma/DestinoTonelada'}},
	{ path: '/guias', name: 'Guias', component: Guias, meta:{backRoute: 'transjuma/Guia'} },
    { path: '/historico_guias', name: 'Historico_guias', component: Historico_guias },
	{ path: '/ajuste_detalles', name: 'Ajustes', component: Ajustes, meta:{backRoute: 'transjuma/AjusteDetalle/'}},
    { path: '/historico_ajustes', name: 'Historico_ajustes', component: Historico_ajustes },
	{ path: '/transportistas', name: 'Transportistas', component: Transportistas, meta:{backRoute: 'transjuma/Transportista/'} },
	{ path: '/deducciones', name: 'Deducciones', component: Deducciones },
	{ path: '/asignacion_detalles', name: 'Asignaciones', component: Asignaciones },
	{ path: '/proforma_detalles', name: 'Proformas', component: Proformas },
	{ path: '/sedes', name: 'Sedes', component: Sedes },
	{ path: '/tipo_facturar', name: 'Tipo_facturar', component: Tipo_facturar },
	{ path: '/tipo_identificaciones', name: 'Tipo_identificaciones', component: Tipo_identificaciones },
	{ path: '/tipo_deducciones', name: 'Tipo_deducciones', component: Tipo_deducciones },
	{ path: '/estado_guias', name: 'Estado_guias', component: Estado_guias },
	{ path: '/tipo_ajustes', name: 'Tipo_ajustes', component: Tipo_ajustes },
	{ path: '/estado_asignaciones', name: 'Esatdo_asignaciones', component: Esatdo_asignaciones },
	{ path: '/user', name: 'User', component: User },
	{ path: '/permissions', name: 'Permissions', component: Permissions },
	{ path: '/Asignacion', name: 'Asignacion', component: Asignacion },
	{ path: '/AsignacionGlobal', name: 'AsignacionGlobal', component: AsignacionGlobal },
	{ path: '/Proforma', name: 'Proforma', component: Proforma },
	{ path: '/MigracionGuias', name: 'MigracionGuias', component: MigracionGuias },
	{ path: '/MigracionAjustes', name: 'MigracionAjustes', component: MigracionAjustes },
	{ path: '/MigracionDeducciones', name: 'MigracionDeducciones', component: MigracionDeducciones },
  { path: '/banco', name: 'Banco', component: Banco },
  { path: '/metodo_pagos', name: 'Metodo_pagos', component: Metodo_pagos },
  { path: '/estado_pagos', name: 'Estado_pagos', component: Estado_pagos },
  { path: '/OrdenesCompra', name: 'OrdenesCompra', component: MigracionOrdenesCompra },
  { path: '/Factura', name: 'Factura', component: Factura },
  
  { path: '/prov_programaciones', name: 'ProvProgramaciones', component: ProvProgramaciones },
  { path: '/ProgramacionPagos', name: 'ProgramacionPagos', component: ProgramacionPagos },
  { path: '/ProcesoDePago', name: 'ProcesoDePago', component: ProcesoDePago },
  
  { path: '/Reporte_guias', name: 'Reporte_guias', component: Reporte_guias },
  { path: '/FacturaList', name: 'FacturaList', component: FacturaList },
  { path: '/OrdenesCompraList', name: 'OrdenesCompraList', component: OrdenesCompraList },
  { path: '/InfoGuias', name: 'InfoGuias', component: InfoGuias },
  { path: '/Configuracion', name: 'Configuracion', component: Configuracion },
  { path: '/PorPagar', name: 'PorPagar', component: PorPagar },
  { path: '/PorCobrar', name: 'PorCobrar', component: PorCobrar },
  { path: '/DestinosToneladasHistorico', name: 'DestinosToneladasHistorico', component: DestinosToneladasHistorico, meta:{backRoute: `transjuma/DestinoToneladaHistorico/`} },
]
    
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {

    const res = await Store.dispatch('AuthModule/verifyToken')
    if (to.name !== 'Login') {
      if (res.state) {
        console.log('NO ES LOGIN Y ES VALIDO')
        Store.state.menuOption = true
        next()
      } else {
        Store.state.menuOption = false
        console.log(res.message+" r34")
        // router.history.push('/')
        router.history.push({name: 'Login'})
      }
    } else {
      if (res.state) {
        console.log('LOGIN Y ES VALIDO')
        router.history.push({name: 'Home'})
        Store.state.menuOption = true
      } else {
        Store.state.menuOption = false
        console.log('no hay res', res)
        // router.history.push({name: 'LoginView'})
        next()
      }
    }
  })

export default router