<template>
  <v-container fluid class="pt-1">
    <form ref="createForm">
      <v-card elevation="0" :loading="loading" :disabled="loading">
        <v-card-title class="pt-1">
          <h5>Proceso de pago</h5>
        </v-card-title>
        <v-card-text>

            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left pl-3">
                      Nombre
                    </th>
                    <th class="text-left">
                      Valor a Pagar
                    </th>
                    <th class="text-left">
                      Metodo de Pago
                    </th>
                    <th class="text-left">
                      No Trans. General
                    </th>
                    <th class="text-left">
                      No Trans. Detalle
                    </th>
                    <th class="text-left">
                      Banco
                    </th>
                  
                  </tr>
                </thead>
                <tbody >
                  <tr
                    v-for="item in totales_transportistas"
                    :key="item.transportistaId"
                    class="tr-alucardo"
                    heigth="25px"
                  >
                    
                    <td>
                      <v-text-field 
                        flat
                        height="27"
                        solo
                        dense
                        disabled 
                        :value="item.nombre" class="nombre-width text-caption mt-1"></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        flat 
                        height="27"
                        solo
                        dense 
                        disabled
                        :value="convertToMoney(item.total)" class="text-caption mt-1"></v-text-field>
                    </td>
                    <td>
                      <v-autocomplete class="text-caption mt-1"
                        v-model="createForm[item.transportistaId].metodo_pago"
                        auto-select-first
                        item-value="id"
                        dense
                        :items="metodo_pagos"
                        outlined
                        :item-text="getItemTextMetodoPago"
                        required
                        :rules="reglasCreateForm"                
                      ></v-autocomplete>
                      <!-- <v-text-field
                        v-model="item.metodo_pago"
                        flat 
                        height="27"
                        solo
                        dense 
                        disabled
                        :value="item.metodo_pago" class="text-caption mt-1"></v-text-field> -->
                    </td>
                    <td>
                      <v-text-field 
                        v-model="createForm[item.transportistaId].no_transaccion_general"
                        flat
                        height="27"
                        solo
                        dense
                        outlined
                        required
                        :rules="reglasCreateForm" 
                        :value="item.transaccionG" class="text-caption mt-1"></v-text-field>
                    </td>
                    <td>
                      <v-text-field 
                        v-model="createForm[item.transportistaId].no_transaccion_detallado"
                        flat
                        height="27"
                        solo
                        dense
                        outlined
                        required
                        :rules="reglasCreateForm" 
                        :value="item.transaccionD" class="text-caption mt-1"></v-text-field>
                    </td>
                    <td>
                      <v-autocomplete class="text-caption mt-1"
                        v-model="createForm[item.transportistaId].banco"
                        auto-select-first
                        item-value="id"
                        dense
                        outlined
                        :items="banco"
                        :item-text="getItemTextBanco"
                        required
                        :rules="reglasCreateForm"                
                      ></v-autocomplete>
                    </td>
                    
                    <!-- <td>
                      <input type="text"  :value="item.total" class="alucardo">
                    </td>
                    <td>{{ item.metodo_pago }}</td>
                    <td>{{ item.transaccionG }}</td>
                    <td>{{ item.transaccionD }}</td>
                    <td>{{ item.banco }}</td> -->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

        </v-card-text>
      </v-card>
    </form>
    <v-container fluid class="d-flex justify-end">
      <v-btn x-small rounded class="mx-2" color="primary" @click="$router.push({name: 'ProvProgramaciones'})">
        Cancelar
      </v-btn>
      <v-btn x-small rounded class="mx-2" color="primary" @click="terminarPago()">
        Terminar Pago
      </v-btn>
    </v-container>
  </v-container>
</template>

<script>
import { axiosC } from "../api/axiosbd"
import { paginationMethods } from "../mixins/pagination"

export default {
  mixins:[paginationMethods],
  data() {
    return {
      cabeceraProgramacion: {},
      programacion:[],
      registros: [],
      totales_transportistas: [],
      banco: [],
      metodo_pagos: [],
      reglasCreateForm: [(v) => !!v || "Este Campo es requerido"],
      createForm: {},
      recordsFinales: [],
      updateStateGuias: {
        guias: [],
        ajustes: [],
      },
      loading: false
    }
  },
  computed:{
    updateForm(){
      for (let [key, value] of Object.entries(this.createForm)) {
        // //console.log(key, value);
        for (let transp of this.totales_transportistas){
          // //console.log(transp)
          if (transp.transportistaId == key){
            transp.transacciong = value.no_transaccion_general
            transp.transacciond = value.no_transaccion_detallado
            transp.banco = value.banco
            transp.metodo_pago = value.metodo_pago
          }
        }
      }
    }
  },
  methods:{
    convertToMoney(x) {
      return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    getItemTextMetodoPago(item) {
      return `${item.id} - ${item.nombre}`.toUpperCase()
    },
    getItemTextBanco(item) {
      return `${item.id} - ${item.nombre}`.toUpperCase()
    }, 
    async terminarPago(){
      this.loading = true
      this.updateForm
      //console.log('terminar pagos')
      //console.log(this.totales_transportistas)

      const body ={
        cabecera: this.$store.state.cabeceraProgramacion,
        datos_pagos: this.totales_transportistas
      }

      await this.$store.dispatch("AuthModule/verifyToken")

      axiosC({
        method: "POST",
        url: `transjuma/PagoProveedoresCabecera/TerminarPago/`,
        headers: {
          Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
        },
        data: body
      })
      .then(()=>{
        this.loading = false
        this.$router.push({name: 'ProvProgramaciones'})
      })
      .catch((err)=>{
        this.loading = false
        //console.log(err)
      })

    },
    async getData() {
      await this.$store.dispatch("AuthModule/verifyToken")

      const dataPago = await axiosC({
        method: "GET",
        url: `transjuma/PagoProveedoresCabecera/ProcesoPagoList/${this.$store.state.cabeceraProgramacion}`,
        headers: {
          Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
        },
      })
      this.totales_transportistas = [...dataPago.data]
      //console.log(this.totales_transportistas)
      for (let registro of this.totales_transportistas) {
        this.createForm[`${registro.transportistaId}`] ={metodo_pago: 1, banco: null, no_transaccion_general: null, no_transaccion_detallado: null}
      }

    },

  },
  async created(){
    this.getData()
    this.banco = await this.getDataGeneral(
        "GET",
        `transjuma/Banco/`,
        '',
        "loading",
        {},
        false, //paginacion
        true, // opcion para que devuelva algo
        true, //modo normal activado, vienen todos los resultados
        null, // parametros query
        false, //unico registro
        {}
      )
    this.metodo_pagos = await this.getDataGeneral(
        "GET",
        `transjuma/MetodoPago/`,
        '',
        "loading",
        {},
        false, //paginacion
        true, // opcion para que devuelva algo
        true, //modo normal activado, vienen todos los resultados
        null, // parametros query
        false, //unico registro
        {}
      )
  }
}
</script>

<style >

.nombre-width.text-caption.mt-1{
  width: 300px;
}

.alucardo{
  min-height: 30px;
  width: 100%;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

}
.v-input__control{
  min-height: 16px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details{
  display: none;
}
</style>
