<template>
  <div>
    <v-card :loading="loading" :disabled="loading" class="rounded-xl" elevation="1">
      <v-card-title class="d-flex justify-space-between">
        <p class="mb-0">Gestión de Facturas</p>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :loading="isLoading"
          :disable="isLoading"
          :headers="headers"
          :items="facturas"
          item-key="id"
          class="elevation-0 rounded-xl px-2"
          :search="search"
          :items-per-page="20"
          sort-by="factura"
          sort-desc=""
          hide-default-footer
        >
        <template
          v-slot:header="{ props: { headers } }"
        >
          <thead>
            <tr>
              <th :colspan="headers.length">
                <div class="d-flex">
                <v-radio-group
                  v-model="pendientes"
                  row
                  class="mt-0"
                  dense
                >
                  <v-radio
                    label="Pendientes"
                    value="pendientes"
                  ></v-radio>
                  <v-radio
                    label="Todas las facturas"
                    value="todas"
                  ></v-radio>
                </v-radio-group>
                <!-- <h3 class="mx-4 pt-1" v-if="facturas">Total de regitros: {{ facturas.length }}</h3> -->
                <!-- <v-checkbox
                  class="mt-0"
                  dense
                  v-model="activarSelectMultiple"
                  :label="`Activar Seleccion Multiple`"
                  hide-details
                ></v-checkbox> -->
                <v-col   class="d-flex justify-end">

                  <v-tooltip top>
                    <template v-slot:activator="{on, attrs}">
                      <v-btn
                        fab
                        v-bind="attrs"
                        v-on="on"
                        rounded
                        @click="refreshData"
                        color="success"
                        x-small
                        class="mr-3"
                        >
                        <v-icon dark> mdi-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>Actualizar Datos</span>
                  </v-tooltip>
                    <v-btn small rounded color="info" @click="openSearch">Filtrado</v-btn>
                </v-col>
                </div>
              </th>
            </tr>
          </thead>
        </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              small
              icon
              @click="seeDetails(item)"
            >
              <v-icon color="green" small>
                mdi-file-excel
              </v-icon>
            </v-btn>
          </template>

          <template v-slot:item.precio_base="{ item }">
            <td class="text-end"> {{$convertToMoney(item.precio_base)}}</td>
          </template>
          <template v-slot:item.fecha_factura="{ item }">
            <td class="text-center"> {{ item.fecha_factura ? item.fecha_factura.substring(0,10) : '' }}</td>
          </template>

          <template v-slot:item.iva="{ item }">
            <td class="text-end"> {{$convertToMoney(item.iva)}}</td>
          </template>

          <template v-slot:item.total="{ item }">
            <td class="text-end"> {{$convertToMoney(item.total)}}</td>
          </template>

        </v-data-table>
        <v-pagination
          v-model="currentPage"
          :length="totalPages"
          total-visible="8"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="siguientePagina"
        ></v-pagination>
      </v-card-text>
    </v-card>
      


    <v-dialog v-model="downgradeDialog" max-width="600px">
      <v-card :loading="loading" :disabled="loading">
        <v-card-title class="text-h5">Por favor elegir una de las siguientes opciones:</v-card-title>
        <v-card-text>
          <p color="primary">La opcion <strong>Flete</strong> no elimina la <strong>guia/ajuste</strong>, pero si quita de proformas y asignaciones la <strong>guia/ajuste</strong> seleccionada.</p>
          <v-radio-group
            v-model="radios"
            mandatory
          >
            <v-radio
              label="Orden"
              value="orden"
            ></v-radio>
            <v-radio
              label="Proforma"
              value="proforma"
            ></v-radio>
            <v-radio
              label="Flete"
              value="flete"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="close">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="postDowngrade">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDetails" width="990px">
      <v-card :loading="isLoadingDetail" :disabled="isLoadingDetail" rounded="xl">
          <v-card-title class="text-h6">Detalle de orden compa no. {{selectedOrden}}</v-card-title>

          <v-card-text>
            <v-data-table
              :loading="isLoadingDetail"
              :headers="headersDetails"
              :items="facturasDetails"
              :search="searchDetail"
              class="rounded-xl px-2"
              :items-per-page="20"
            >
              <template v-slot:top>
                <v-toolbar flat height="45">
                  <v-toolbar-title>{{ `Lista de facturas` }}</v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-text-field
                      v-model="searchDetail"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                  ></v-text-field>

                </v-toolbar>
              </template>

              <template v-slot:item.precio_base="{ item }">
                <td class="text-end"> {{$convertToMoney(item.precio_base)}}</td>
              </template>

              <template v-slot:item.iva="{ item }">
                <td class="text-end"> {{$convertToMoney(item.iva)}}</td>
              </template>

              <template v-slot:item.total="{ item }">
                <td class="text-end"> {{$convertToMoney(item.total)}}</td>
              </template>

              <template v-slot:item.action="{ item }">
                <v-btn
                  v-if="item.id_estado == 5"
                  small
                  icon
                  @click="downgrade(item)"
                >
                  <v-icon color="red" small>
                    mdi-transfer-down
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
      </v-card>
    </v-dialog>

    <!-- CUADRO DIALOGO BUSQUEDA AVANZADA -->
    <BusquedaAvanzada
      nameItemStore="searchFacturas"
      v-on:advance-search="onAdvanceSearch"
      :activateGuiaTransportista="false"
      @reset-refresh="refreshData"
    ></BusquedaAvanzada>
  </div>
</template>

<script>
import { axiosC } from "../api/axiosbd"
import { paginationMethods } from "../mixins/pagination";
import BusquedaAvanzada from "../components/BusquedaAvanzada.vue";


export default {
  mixins: [paginationMethods],
  components:{
    BusquedaAvanzada
  },
  data() {
    return {
      pendientes: 'pendientes',
      totalRegistros: 0,
      radios: null,
      downgradeDialog: false,
      facturas: [],
      facturasDetails:[],
      search: '',
      searchDetail: '',
      headersDetails: [
        {text: 'Origen', value: 'origen', align: 'center'},
        {text: 'Numero', value: 'numero', align: 'center'},
        {text: 'Fecha', value: 'fecha', align: 'center'},
        {text: 'Destino', value: 'destino'},
        {text: 'Valor', value: 'precio_base', align: 'end'},
        {text: 'Iva', value: 'iva', align: 'end'},
        {text: 'Total', value: 'total', align: 'end'},
        {text: 'OC', value: 'no_factura', align: 'center'},
        {text: 'Lote PR', value: 'proforma_cabecera_id', align: 'center'},
        {text: 'Orden de Compra', value: 'no_orden_compra', align: 'center'},
        {text: 'Accion', value: 'action', align: 'center'},
      ],
      headers: [
        {text: 'Factura', value: 'no_factura', align: 'start'},
        {text: 'Fecha', value: 'fecha_factura', align: 'center'},
        {text: 'Precio Base', value: 'precio_base', align: 'end'},
        {text: 'iva', value: 'iva', align: 'end'},
        {text: 'Total', value: 'total', align: 'end'},
        {text: 'Acciones', value: 'actions', align: 'center'},
      ],
      boldChange: false,
      isLoading: false,
      loading: false,
      dialogDetails: false,
      selectedOrden: null,
      isLoadingDetail: false
    }
  },
  methods: {
    downgrade(item){
      this.downgradeDialog = true
      console.log(item)
      this.selectedFactura = item
    },
    async postDowngrade(){
      
      console.log(this.radios)
    
      let url = `transjuma/downgrade`
      let body = {guia: this.selectedFactura.id, type: this.radios}
      let method = 'POST'
    
      this.loading = true
      await this.$store.dispatch('AuthModule/verifyToken')

      axiosC({
        method: method,
        url:url,
        headers: { Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}` },
        data: body ? body : null
      })
      .then((result) => {
        console.log(result)
        this.close()
        this.loading = false
        this.getDataRecords()
      }).catch((err) => {
        this.loading = false
        console.log(err)
      });
    },
    close(){
      this.downgradeDialog = false
    },
    classChange(){
      if (this.boldChange){
        this.boldChange = false
      } else {
        this.boldChange = true
      }
    },
    async seeDetails(item){
      this.dialogDetails = true
      this.selectedOrden = item.no_factura
      console.log(item)

      const params = {
        no_factura: item.no_factura
      }

      this.isLoadingDetail = true
      await this.$store.dispatch('AuthModule/verifyToken')
      axiosC({
        method: "GET",
        url: `transjuma/FacturasListDetail`,
        headers: {
          Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
        },
        params: params
      })
      .then(res => {
        console.log(res.data)
        this.facturasDetails = res.data
        this.isLoadingDetail = false
        })
      .catch(err => {
        console.log(err)
        alert('Hubo un error al cargar los datos, si el error persiste consulte al administrador')
        this.isLoadingDetail = false
      })

    },
    siguientePagina(item) {
      this.queryAdvanceSearch = {id_estado_int: 5}
      // this.queryAdvanceSearch.list = true
      this.queryAdvanceSearch.onAdvanceSearch = true

      let axiosParamas = {}
      // if (modo == 'todas'){
      //   axiosParamas = {id_estado_int: 6}
      // }

      // if(this.activeAdvanceSearch){
        //console.log('ACTIVADO SEARH ADVANCE')
        if (this.pendientes=='todas')  this.queryAdvanceSearch.id_estado_int = this.pendientes
        if (this.pendientes=='pendientes')  this.queryAdvanceSearch.id_estado_int = 5
        axiosParamas = {...this.queryAdvanceSearch}
      // }
      this.gotoPage(item, `transjuma/FacturasList`, axiosParamas, 'isLoading')
    },
    getDataRecords(modo = 'pendientes') {
      //console.log('MODE', modo)
      const page = 1;
      const size = 20;
      this.nameItemStore = "facturas"
      this.queryAdvanceSearch = {id_estado_int: 5}
      // this.queryAdvanceSearch.list = true
      this.queryAdvanceSearch.onAdvanceSearch = true

      let axiosParamas = {}
      // if (modo == 'todas'){
      //   axiosParamas = {id_estado_int: 6}
      // }

      // if(this.activeAdvanceSearch){
        //console.log('ACTIVADO SEARH ADVANCE')
        if (modo=='todas')  this.queryAdvanceSearch.id_estado_int = modo
        if (modo=='pendientes')  this.queryAdvanceSearch.id_estado_int = 5
        axiosParamas = {...this.queryAdvanceSearch}
      // }

      this.getDataGeneral(
        "GET",
        `transjuma/FacturasList`,
        this.nameItemStore,
        "isLoading",
        {},
        true, false,false, null, false, axiosParamas
      );
    },
    openSearch(){
      this.$store.state.searchFacturas = true
    },
    onAdvanceSearch(item){
      //console.log(item, this.pendientes)
      const page = 1;
      const size = 20;
      this.nameItemStore = "facturas"
      this.queryAdvanceSearch = {}
      // this.queryAdvanceSearch.list = true
      this.queryAdvanceSearch.onAdvanceSearch = true

      let valid = true

      if (item.fecha1 && item.fecha2 && item.rango){
        this.queryAdvanceSearch.fecha_bw = [item.fecha1, item.fecha2]
      }
      if (item.fecha1 && !item.rango) {
        this.queryAdvanceSearch.fecha_e = item.fecha1
      }
      if (item.fecha1 && !item.fecha2 && item.rango ) {
        this.queryAdvanceSearch.fecha_gt = item.fecha1
      }
      if (!item.fecha1 && item.fecha2 && item.rango ) {
        this.queryAdvanceSearch.fecha_lt = item.fecha2
      }
      // if (item.transportista && item.transportista.length > 0){
      //   this.queryAdvanceSearch.transportista_id = item.transportista
      // }
      // if (item.numero_guia) {
      //   this.queryAdvanceSearch.guia = item.numero_guia
      // }

      // if (item.descripcion_detallada) {
      //   this.queryAdvanceSearch.descripcion_detallada = item.descripcion_detallada
      // }

      if (item.no_factura){
        this.queryAdvanceSearch.no_factura = item.no_factura
      }

      if (this.pendientes == 'pendientes'){
        this.queryAdvanceSearch.id_estado_int = 5
      } else {
        this.queryAdvanceSearch.id_estado_int = null
      }

      this.activeAdvanceSearch = true
      this.currentPage = 1

      //console.log(this.queryAdvanceSearch)
      // this.getDataGeneral(
      //   'GET',  `transjuma/Deduccion/`, "desserts", "loading", {},
      //   true, false,false, null, false, this.queryAdvanceSearch)
      this.getDataGeneral(
        "GET",
        `transjuma/FacturasList`,
        this.nameItemStore,
        "isLoading",
        {},
        true, false,false, null, false, this.queryAdvanceSearch
      );

    },
    refreshData(){
      this.getDataRecords()
    }
  },
  watch:{
    pendientes(val){
      //console.log(val)
      this.getDataRecords(val)
    }
  },
  
  created(){
    // this.getData()
    this.nameItemStore = 'facturas'
    this.getDataRecords()
    // this.getDataGeneral(
    //   "GET",
    //   `transjuma/FacturasList`,
    //   this.nameItemStore,
    //   "isLoading",
    //   {},
    //   true,
    //   true
    // );
  }
}
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  height: 25px;
}
.custom-fila{
  min-height: 25px !important;
}
.v-list-item{
  min-height: 0;
}

</style>>

