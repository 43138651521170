<template>
  <v-row justify="center">
    <v-col cols="12" class="py-1">
      <v-card class="card-principal" flat :loading="isloading" :disabled="isloading">
        <v-card-subtitle class="pa-1 mb-2">
          <strong v-if="tipoModulo == 'AG' ">Asignacion nueva</strong>
          <strong v-if="tipoModulo == 'PF' ">Crear Proformas</strong>
        </v-card-subtitle>
        <v-form class="px-1 py-2" ref="createForm" v-if="formulario" :disabled="disableForm">
          <v-row align="center">
            <v-col cols="12"  md="2">
              <v-menu
                v-model="menuPicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="pa-0"
                    v-model="createForm.fecha"
                    label="Fecha"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                    :rules="reglasCreateForm"
                    @focus="setCodigoEstadoGuiaEnviada()"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="createForm.fecha"
                  @input="menuPicker = false"
                >
                  <!-- <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuPicker = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuPicker.save(createForm.fecha)"
                  >
                    OK
                  </v-btn> -->
                </v-date-picker>
              </v-menu>
            </v-col>

              <v-col cols="12" md="3"  v-if="tipoModulo == 'AG' && !AsignacionGlobal">
                <v-autocomplete
                  v-model="createForm.transportista"
                  item-value="id"
                  dense
                  :items="transportistas"
                  :item-text="getItemTextTransportista"
                  label="Transportistas"
                  required
                  :rules="reglasCreateForm"
                  @change="haveEmail(createForm.transportista)"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="3" v-if="tipoModulo == 'AG' && AsignacionGlobal">
                <v-switch
                  v-model="allOption"
                  :label="`Incluir Todo`"
                ></v-switch>
              </v-col>

              <v-col cols="12"  md="2" v-if="tipoModulo == 'AG'">
                <v-autocomplete
                  v-model="createForm.estado_asignacion"
                  item-value="id"
                  dense
                  disabled
                  :items="estado_asignaciones"
                  :item-text="getItemTextAsignaciones"
                  label="Estado Asignaciones"
                  required
                  :rules="reglasCreateForm"
                ></v-autocomplete>
              </v-col>


              <v-col cols="12" md="4" v-if="tipoModulo == 'PF'">
                <v-text-field
                  v-model="createForm.nombre"
                  label="Nombre"
                  dense
                  :rules="reglasCreateForm"
                ></v-text-field>
              </v-col>

            <v-col cols="12" md="1" class="py-1">
              <v-checkbox
                label="Estado"
                input-value="createForm.estado"
                required
                :rules="reglasCreateForm"
              ></v-checkbox>
            </v-col>
            <v-col cols="12"  md="2" >
              <v-btn
                color="primary"
                small
                @click="saveCabecera(createForm)"
                :disabled="botonContinuar"
                >Continuar</v-btn
              >
            </v-col>
            <v-col cols="12"  md="1">
              <v-btn
                color="warning"
                small
                @click="accionCancelar"
                >Cancelar</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-col>

    <!-- CUADRO DE MANEJO DE REGISTROS, TRABLA DE TRANSFERENCIAS -->
    <v-col cols="12">
      <v-card 
        elevation="0" 
        :loading="isloadingAsignacion" 
        :disabled="isloadingAsignacion ? isloadingAsignacion : disableCardContent" class="card-content">
        <el-transfer
          style="text-align: left display: inline-block"
          v-model="registrosAsignar"
          filterable
          :props="{
            key: 'key',
            guia: 'guia',
            fecha: 'fecha',
            destino: 'destino',
            descripcion: 'descripcion',
            precioBase: 'precioBase',
            descuento: 'descuento',
            iva: 'iva',
            tipo: 'tipo'
          }"
          :titles="['Escoger opciones', 'Asignaciones a enviar']"
          :format="{
            noChecked: '${total}',
            hasChecked: '${checked}/${total}',
          }"
          @change="handleChange"
          :data="registros"
        >
          <span slot-scope="{ option }"
            >{{ `${option.tipo}${option.guia} --${option.label}` }}</span
          >
          <!-- <el-button class="transfer-footer" slot="left-footer" size="small"
              >Operation</el-button
            > -->
          <div class="transfer-footer" slot="right-footer">
            <v-row class="align-center">
              <v-col class="d-flex flex-column pr-1 pl-4">
                <div class="d-flex justify-space-between px-2">
                  <span class="texto-footer"> Ttl Base: </span>
                  <span class="texto-footer">{{
                    `¢${sumatorias.totalBase ? sumatorias.totalBase: 0.00}`
                  }}</span>
                </div>

                <div class="d-flex justify-space-between red--text px-2">
                  <span class="texto-footer"> Ttl Desc: </span>
                  <span class="texto-footer">{{
                    `¢${sumatorias.totalDescuento ? sumatorias.totalDescuento: 0.00}`
                  }}</span>
                </div>
              </v-col>

              <v-col class="d-flex flex-column px-1">
                <div class="d-flex justify-space-between">
                  <span class="texto-footer"> Ttl Iva: </span>
                  <span class="texto-footer">{{
                    `¢${sumatorias.totalIva ? sumatorias.totalIva: 0.00}`
                  }}</span>
                </div>
                <div class="d-flex justify-space-between">
                  <span class="texto-footer">
                    <strong>Asignacion:</strong>
                  </span>
                  <span class="texto-footer"
                    ><strong> {{ `¢${sumatorias.total ? sumatorias.total: 0.00}` }}</strong>
                  </span>
                </div>
              </v-col>

              <div v-if="tipoModulo == 'AG'">
                <v-col cols="2" class="d-flex justify-center" v-if="!allOption && createForm.transportista">
                  <v-btn fab color="primary" x-small @click="saveAsignacion"
                    ><v-icon light>mdi-email-fast-outline</v-icon></v-btn
                  >
                </v-col>

                <v-col cols="2" class="d-flex justify-center" v-else>
                  <v-btn fab color="primary" x-small @click="previewAsignacion"
                    ><v-icon light>find_in_page</v-icon></v-btn
                  >
                </v-col>
              </div>

                <v-col cols="2" class="d-flex justify-center" v-if="tipoModulo == 'PF'">
                  <v-btn fab color="primary" x-small @click="CrearAsignacionMany"
                    ><v-icon light>mdi-lightbulb-cfl</v-icon></v-btn
                  >
                </v-col>

            </v-row>
          </div>
        </el-transfer>
      </v-card>
    </v-col>

    <!-- CUADRRO DE PREVISUALIZACION DE ASIGNACIONES POR TRANSPORTISTA -->
    <v-row justify="center" v-if="previewProgramacion">
      <v-dialog
        v-model="previewProgramacion"
        scrollable
        max-width="900px"
        max-heigth="600px"
      >
        <v-card :loading="isloadingAsignacion" :disabled="isloadingAsignacion">
          <v-card-title>Previsualizacion de Asignaciones</v-card-title>
          <v-row dense class="py-1 px-6">
            <v-col cols="12" md="4"  class=" py-0 text-left">
              <span class="font-weight-bold"> Transportista </span>
            </v-col>

            <v-col cols="12" md="2" class=" py-0 text-right">
              <span class="font-weight-bold pr-3"> Precio </span>
            </v-col>

            <v-col cols="12" md="2" class=" py-0 text-right">
              <span class="font-weight-bold pr-3"> Descuento </span>
            </v-col>

            <v-col cols="12" md="2" class=" py-0 text-right">
              <span class="font-weight-bold pr-3"> Iva </span>
            </v-col>

            <v-col cols="12" md="2" class=" py-0 text-right">
              <span class="font-weight-bold pr-3"> Total </span>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-text style="height: 400px;">

            <div class="d-flex justify-space-between py-1" v-for="item in totales_transportistas" :key="item.transportistaId">
              <v-row dense class="py-0">
                <v-col cols="12" md="4"  class=" py-0 text-left text-caption">
                  <span class="text-capitalize"> {{ item.nombre }}</span>
                </v-col>

                <v-col cols="12" md="2" class=" py-0 text-right">
                  <span> {{ convertToMoney(item.precio_base) }}</span>
                </v-col>

                <v-col cols="12" md="2" class=" py-0 text-right">
                  <span class="red--text"> {{ convertToMoney(item.descuento) }}</span>
                </v-col>

                <v-col cols="12" md="2" class=" py-0 text-right">
                  <span> {{ convertToMoney(item.iva) }}</span>
                </v-col>

                <v-col cols="12" md="2" class=" py-0 text-right">
                  <span> {{ convertToMoney(item.total) }}</span>
                </v-col>
              </v-row>
            </div>

          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="blue darken-1"
              text
              :disabled="disableBtn"
              :loading="isloadingAsignacion"
              @click="CrearAsignacionMany(false)"
            >
              Crear sin email
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :disabled="true"
              @click="CrearAsignacionMany(true)"
            >
              Crear envio email
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="isloadingAsignacion"
              @click="previewProgramacion = false"
            >
              Cerrar
            </v-btn>
            
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>



  </v-row>
</template>

<script>
import { axiosC } from "../api/axiosbd"
import { paginationMethods } from "../mixins/pagination";

export default {
  name: 'AsignacionBase',
  mixins: [paginationMethods],
  props:{
    AsignacionGlobal:{
      type: Boolean,
      required: false,
      default: false
    },
    tipoModulo:{
      type: String,
      required: true,
    }
  },
  data() {
    return {
      isloading: false,
      isloadingAsignacion: false,
      formulario: true, //esto es necesario para el reinicio del form y tome los valores por defecto
      disableForm: false,
      disableCardContent: true,
      menuPicker: false,
      menu: false,
      botonContinuar: false,
      transportistas: [],
      estado_asignaciones: [],
      estado_guias: [],
      estado: true,
      guias: [],
      guiasUpdate: [],
      ajustes: [],
      ajustesUpdate:[],

      registros: [],
      registrosAsignar: [],
      busquedaRegistros: [],
      sumatorias:{
        totalBase: [],
        totalDescuento: [],
        totalIva: [],
        subtotal: [],
        total: [],
      },

      fechaActual: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      createForm: {
        fecha: null,
        estado_asignacion: null,
        transportista: null,
      },
      createFormRegistrosAsignar: { records: []},
      updateStateGuias: {
        guias: [],
        ajustes: [],
      },
      recentCabecera: null,
      completeProcess: true,
      reglasCreateForm: [(v) => !!v || "Este Campo es requerido"],
      codigoEstadoGuiaEnviada: "",
      allOption: false,
      previewProgramacion: false,
    }
  },

  methods: {
    haveEmail(value){
      let email = this.transportistas.filter((item)=>item.id == value)
      if (!email[0].email){
        alert(`Favor actualizar email si desea el envio automatico de esta asignacion para el transportista: ${email[0].nombre.toUpperCase()}`)
      }
    },
    convertToMoney(x) {
      return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    calculoDiasFecha(fechaComparacion){
      // Calculo de cuantos dias tiene una fecha de otra dada
      // En este caso siempre la comparacion es con la fecha actual
      // las fechas deben enviarse en formato yyyy-mm-dd
      let fechaActual = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0,10)
      let diferencia = Math.abs(new Date(fechaActual)-new Date(fechaComparacion))
      let days = diferencia/(1000 * 3600 * 24)
      return days
    },
    async saveCabecera() {
      this.isloading = false
      if (this.$refs.createForm.validate()) {
        if (this.tipoModulo == 'AG') {
          await this.getDataGuiasAjustes(this.createForm.transportista)
  
          //comprobar si el transportista tiene registros
          // para el guia seleccionado y desabilitar el cuadro para la asignacion de todas
          if (!this.AsignacionGlobal){
            if (this.registros.length > 0) {
              //hace sumit de cabecera
              await this.$store.dispatch("AuthModule/verifyToken")
  
              this.createForm.company = 1;
              this.createForm.created_by = this.$store.state.AuthModule.refu
  
              axiosC({
                method: "POST",
                url: `transjuma/AsignacionCabecera/`,
                data: this.createForm,
                headers: { Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}` },
              }).then((res)=>{
                this.recentCabecera = res.data
                // console.log(this.recentCabecera)
                //despues del 200 hacer esto
                this.disableCardContent = false
                this.botonContinuar = true
                this.disableForm = true
                this.completeProcess = false
                this.isloading = false
              }).catch((err)=>{
                this.registros = []
                this.isloading = false
                alert(err.response.data.message)
  
              })
  
            } else {
              alert('No se puede salvar registro, transportista no tiene guias ni ajustes')
              this.isloading = false
            }
          } else {
              this.disableCardContent = false
              this.botonContinuar = true
              this.disableForm = true
              this.completeProcess = false
              this.isloading = false
          }
        } else {
          // ES MODULO PROFORMAS
          await this.getDataProformasGuiasAjustes()
          this.disableCardContent = false
          this.botonContinuar = true
          this.disableForm = true
          this.completeProcess = false
          this.isloading = false
        }

      } else {
        this.isloading = false
      }
    },
    async saveAsignacion() {
      console.log(this.createFormRegistrosAsignar)
      this.isloadingAsignacion = true
      if (this.createFormRegistrosAsignar.records.length > 0) {
        //hace sumit de cabecera
        await this.$store.dispatch("AuthModule/verifyToken")
        console.info('hice click para salvar la vaina')
        axiosC({
            method: "POST",
            url: `transjuma/AsignacionDetalle/`,
            data: this.createFormRegistrosAsignar.records,
            headers: { Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}` },
          })
        .then((res)=>{
          this.$refs.createForm.reset()
          this.$refs.createForm.resetValidation()
          this.disableCardContent = true
          this.isloadingAsignacion = false
          this.formulario = false

          this.formulario = true
          this.isloadingAsignacion = false
          this.botonContinuar = false
          this.disableForm = false

          this.$nextTick(() => {
            this.iniciarForm()
          })

          this.completeProcess = true
        })
        .catch((err)=>{
          this.isloadingAsignacion = false
          if (err.response?.data?.error && Array.isArray(err.response.data.error)) {
            const errores = this.extractErrors(err.response.data.error);
            alert(errores);
          }
          // console.log(err.response)
          // // this.createFormRegistrosAsignar = []
          // alert(err.response.data.message)
        })

      } else {
        alert('No se puede salvar registro, no ha seleccionado guias y ajustes a asignar')
        this.isloadingAsignacion = false
      }

    },
    extractErrors(json) {
      let result = '';

      function extract(obj) {
          if (Array.isArray(obj)) {
              obj.forEach(item => extract(item));
          } else if (typeof obj === 'object') {
              for (let key in obj) {
                  if (key === 'non_field_errors') {
                      if (Array.isArray(obj[key])) {
                          result += obj[key].join(', ') + ', ';
                      } else {
                          result += obj[key] + ', ';
                      }
                  } else {
                      extract(obj[key]);
                  }
              }
          }
      }

      extract(json);
      // Remove trailing comma and space
      result = result.replace(/, $/, '');
      return result;
    },
    iniciarForm() {
      this.createForm = {
        fecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substring(0, 10),
        codigo: "",
        estado: true,
        estado_asignacion: 1,
        transportista: null,
      }
      this.sumatorias = {
        totalBase: [],
        totalDescuento: [],
        totalIva: [],
        subtotal: [],
        total: [],
      }
      this.registros = []
      this.registrosAsignar = []
      this.busquedaRegistros = []
      this.createFormRegistrosAsignar = { records: []}
      this.updateStateGuias = {
        guias: [],
        ajustes: [],
      }
      this.previewProgramacion = false
    },
    handleChange(value, direction, movedKeys) {
      let idEstado = 1
      let transportistasAsignacion = []

      if (!this.AsignacionGlobal || this.tipoModulo == 'PF'){
        if (direction == 'right') {
          for (let reg of movedKeys){
            this.busquedaRegistros.push(this.registros.find((item) =>item.key == reg))
          }
          this.sumatorias = {
              totalBase: [],
              totalDescuento: [],
              totalIva: [],
              subtotal: [],
              total: [],
            }
          this.createFormRegistrosAsignar = { records: []}
          this.updateStateGuias = {
            guias: [],
            ajustes: [],
          }

          for (let item of this.busquedaRegistros){
            this.sumatorias.totalBase.push(parseFloat(item.precioBase))
            this.sumatorias.totalDescuento.push(parseFloat(item.descuento))
            this.sumatorias.totalIva.push(parseFloat(item.iva))
            this.sumatorias.subtotal.push(parseFloat(item.subtotal))
            this.sumatorias.total.push(parseFloat(item.total))

            let obj = {
              company: 1,
              created_by: this.$store.state.AuthModule.refu,
              asignacion_cabecera: !this.AsignacionGlobal ? this.recentCabecera.id : null
            }
            if (item.tipo == 'A'){
              obj.ajuste_detalle = item.id
            } else {
              obj.guia = item.id
            }
            this.createFormRegistrosAsignar.records.push(obj)
            obj = {}

          }
          console.log(this.createFormRegistrosAsignar)
        } else {
          // //console.log('quitar elemento')
          for (let reg of movedKeys){
            this.busquedaRegistros = this.busquedaRegistros.filter((item) =>item.key != reg)
          }
          if (this.busquedaRegistros.length == 0) {
            this.sumatorias = {
              totalBase: [],
              totalDescuento: [],
              totalIva: [],
              subtotal: [],
              total: [],
            }
            this.createFormRegistrosAsignar = {records:[]}
            this.updateStateGuias = {
              guias: [],
              ajustes: [],
            }

          } else {
            this.sumatorias = {
              totalBase: [],
              totalDescuento: [],
              totalIva: [],
              subtotal: [],
              total: [],
            }
            this.createFormRegistrosAsignar = {records:[]}
            this.updateStateGuias = {
              guias: [],
              ajustes: [],
            }

            for (let item of this.busquedaRegistros){
              this.sumatorias.totalBase.push(parseFloat(item.precioBase))
              this.sumatorias.totalDescuento.push(parseFloat(item.descuento))
              this.sumatorias.totalIva.push(parseFloat(item.iva))
              this.sumatorias.subtotal.push(parseFloat(item.subtotal))
              this.sumatorias.total.push(parseFloat(item.total))

              let obj = {
                estado: true,
                asignacion_cabecera: !this.AsignacionGlobal ? this.recentCabecera.id : null
              }
              if (item.tipo == 'A'){
                obj.ajuste_detalle_asignacion = item.id
              } else {
                obj.guia_asignacion = item.id
              }
              this.createFormRegistrosAsignar.records.push(obj)
              obj = {}
              
              //crear las guias y ajustes a update
              if (item.tipo == 'A'){
                let ajuste = this.ajustesUpdate.filter((regAjuste) => regAjuste.id == item.id)[0]
                ajuste.estado_guia_ajuste = idEstado
                this.updateStateGuias.ajustes.push(ajuste)
              }else {
                //con el id busca la guia completa
                let guia = this.guiasUpdate.filter((regGuia) =>regGuia.id == item.id)[0]
                //modifica solo el campo de la guia seleccionada
                guia.estado_guia = idEstado
                this.updateStateGuias.guias.push(guia)
              }
              obj = {}
              
            }
          }
        }
      } else if (this.AsignacionGlobal && this.tipoModulo == 'AG') {
        // OPTION GLOBAL
        if (direction == 'right') {
          for (let reg of movedKeys){
            this.busquedaRegistros.push(this.registros.find((item) =>item.key == reg))
          }
          transportistasAsignacion = []
          this.sumatorias = {
              totalBase: [],
              totalDescuento: [],
              totalIva: [],
              subtotal: [],
              total: [],
            }
          this.createFormRegistrosAsignar = { records: [], info: []}
          this.updateStateGuias = {
            guias: [],
            ajustes: [],
          }
          
          this.asignacionesFinales = []
          this.totales_transportistas = []
        
          for (let item of this.busquedaRegistros) {
            // console.log('PRINT', item)
            let exist = false
            
            for (let reg of this.totales_transportistas) {
              // console.log(reg)
              if (reg.transportistaId == item.transportista.id) {
                exist = true

                reg.precio_base += item.precioBase,
                reg.descuento += item.descuento,
                reg.iva += item.iva,
                reg.total += item.total

                break
              }
            }

            if (!exist) {
              this.totales_transportistas.push({
                transportistaId: item.transportista.id,
                nombre: item.transportista.nombre,
                precio_base: item.precioBase,
                descuento: item.descuento,
                iva: item.iva,
                total: item.total,

              })
            }
          
            this.totales_transportistas = this.totales_transportistas.sort(function (x, y) {
                                              let a = x.nombre.toUpperCase(),
                                                  b = y.nombre.toUpperCase();
                                              return a == b ? 0 : a > b ? 1 : -1;
                                          });
                    
            
            transportistasAsignacion.push(item.transportista.id)

            this.sumatorias.totalBase.push(parseFloat(item.precioBase))
            this.sumatorias.totalDescuento.push(parseFloat(item.descuento))
            this.sumatorias.totalIva.push(parseFloat(item.iva))
            this.sumatorias.subtotal.push(parseFloat(item.subtotal))
            this.sumatorias.total.push(parseFloat(item.total))

            //se filtran  las guias y ajustes a update desde this.ajustesUpdate o this.guiasUpdate creados en el getdata
            // se modifica el estado y se obtiene la final con todos sus detalles
            let objRecord = {
              estado: true,
              asignacion_cabecera: null 
            }
            let obj = {

            }

            if (item.tipo == 'A'){
              objRecord.ajuste_detalle = item.id
              objRecord.transportistaId = item.transportista.id

              obj.codigo = null
              obj.estado_asignacion = item.estado_asignacion
              obj.transportistaId = item.transportista.id
              obj.transportista_asignacion = item.transportista
              obj.email = item.transportista.email

              let ajuste = this.ajustes.find((regAjuste) => regAjuste.id == item.id)
              ajuste.estado_guia_ajuste = idEstado
              // this.updateStateGuias.ajustes.push(ajuste)
            } else {
              objRecord.guia = item.id
              objRecord.transportistaId = item.transportista.id

              obj.codigo = null
              obj.estado_asignacion = item.estado_asignacion
              obj.transportistaId = item.transportista.id
              obj.transportista_asignacion = item.transportista
              obj.email = item.transportista.email

              let guia = this.guias.find((regGuia) =>regGuia.id == item.id)
              guia.estado_guia = idEstado
              // this.updateStateGuias.guias.push(guia)
            }
            this.createFormRegistrosAsignar.records.push(objRecord)
            this.createFormRegistrosAsignar.info.push(obj)
            obj = {}
            objRecord = {}
            // transportistasAsignacion = transportistasAsignacion.filter((item, index)=>{
            //   return transportistasAsignacion.indexOf(item) === index
            // })
            
            
            
          }
          // this.createFormRegistrosAsignar.info = this.createFormRegistrosAsignar.info.filter((item, index)=>{
          //   return this.createFormRegistrosAsignar.info.indexOf(item) == index 
          // })
          this.createFormRegistrosAsignar.info = this.createFormRegistrosAsignar.info.reduce((unique, o) => {
              if(!unique.some(obj => obj.transportistaId === o.transportistaId && obj.email === o.email)) {
                unique.push(o);
              }
              return unique;
          },[])

          // console.log(this.createFormRegistrosAsignar.info)
          for (let transportista of this.createFormRegistrosAsignar.info) {
            let obj = {id: transportista.transportistaId, guias: [], ajustes: [], records: [], email: {...transportista}}
            this.asignacionesFinales.push(obj)
          }
          //clasifico las info de guias y transportistas para los correos y adjuntos
          for (let guia of this.updateStateGuias.guias) {
            for (let [index, transportista] of this.asignacionesFinales.entries()){
              if (guia.transportista_guia == transportista.id){
                this.asignacionesFinales[index].guias.push(guia)
              }
            }
          }
          for (let ajuste of this.updateStateGuias.ajustes) {
            for (let [index, transportista] of this.asignacionesFinales.entries()){
              if (ajuste.transportista_ajuste == transportista.id){
                this.asignacionesFinales[index].ajustes.push(ajuste)
              }
            }
          }

          // formo los records necesarios para la creacion de las asignaciones con sus cabeceras
          for (let reg of this.createFormRegistrosAsignar.records){
            for (let [index, transportista] of this.asignacionesFinales.entries()){
              if (reg.transportistaId == transportista.id){
                //construir los objetos que se necesitan en records
                this.asignacionesFinales[index].records.push(reg)

                //contruir lo que va para el envio de mail
                
              }
            }
          }
          console.log('ASIGNACIONES FINALES',this.asignacionesFinales.length, this.asignacionesFinales)
        } else {
          // //console.log('quitar elemento')
          for (let reg of movedKeys){
            this.busquedaRegistros = this.busquedaRegistros.filter((item) =>item.key != reg)
          }
          if (this.busquedaRegistros.length == 0) {
            this.sumatorias = {
              totalBase: [],
              totalDescuento: [],
              totalIva: [],
              subtotal: [],
              total: [],
            }
            this.createFormRegistrosAsignar = {records:[], info: []}
            this.updateStateGuias = {
              guias: [],
              ajustes: [],
            }
            this.asignacionesFinales = []
          } else {
            transportistasAsignacion = []
            this.sumatorias = {
              totalBase: [],
              totalDescuento: [],
              totalIva: [],
              subtotal: [],
              total: [],
            }
            this.createFormRegistrosAsignar = {records:[], info:[]}
            this.updateStateGuias = {
              guias: [],
              ajustes: [],
            }

            for (let item of this.busquedaRegistros){
              this.sumatorias.totalBase.push(parseFloat(item.precioBase))
              this.sumatorias.totalDescuento.push(parseFloat(item.descuento))
              this.sumatorias.totalIva.push(parseFloat(item.iva))
              this.sumatorias.subtotal.push(parseFloat(item.subtotal))
              this.sumatorias.total.push(parseFloat(item.total))

              let obj = {
                estado: true,
                // asignacion_cabecera: this.recentCabecera.id,
              }
              if (item.tipo == 'A'){
                obj.ajuste_detalle_asignacion = item.id
                transportistasAsignacion.push(item.transportista.id)
              } else {
                obj.guia_asignacion = item.id
                transportistasAsignacion.push(item.transportista.id)
              }
              this.createFormRegistrosAsignar.records.push(obj)
              obj = {}
              
              //se filtran  las guias y ajustes a update desde this.ajustesUpdate o this.guiasUpdate creados en el getdata
              // se modifica el estado y se obtiene la final con todos sus detalles
              if (item.tipo == 'A'){
                let ajuste = this.ajustesUpdate.filter((regAjuste) => regAjuste.id == item.id)[0]
                ajuste.estado_guia_ajuste = idEstado
                this.updateStateGuias.ajustes.push(ajuste)
              }else {
                let guia = this.guiasUpdate.filter((regGuia) =>regGuia.id == item.id)[0]
                guia.estado_guia = idEstado
                this.updateStateGuias.guias.push(guia)
              }
              obj = {}
              
            }
          }

          transportistasAsignacion = transportistasAsignacion.filter((item, index)=>{
            return transportistasAsignacion.indexOf(item) === index
          })

          for (let transportista of transportistasAsignacion) {
            let obj = {id: transportista, guias: [], ajustes: []}
            this.asignacionesFinales.push(obj)
          }


        }
      }


      this.sumatorias.totalBase = this.convertToMoney(this.sumatorias.totalBase.reduce((a, b) => a + b, 0))
      this.sumatorias.totalDescuento = this.convertToMoney(this.sumatorias.totalDescuento.reduce((a, b) => a + b, 0))
      this.sumatorias.totalIva = this.convertToMoney(this.sumatorias.totalIva.reduce((a, b) => a + b, 0))
      this.sumatorias.subtotal = this.convertToMoney(this.sumatorias.subtotal.reduce((a, b) => a + b, 0))
      this.sumatorias.total = this.convertToMoney(this.sumatorias.total.reduce((a, b) => a + b, 0))


    },
    getItemTextTransportista(item) {
      return `${item.numero_identificacion} - ${item.nombre}`.toUpperCase()
    },
    getItemTextAsignaciones(item) {
      return `${item.codigo} - ${item.nombre}`.toUpperCase()
    },

    async getDataGuiasAjustes(transportista) {
      this.isloading = true
      await this.$store.dispatch("AuthModule/verifyToken")

      let registrosAjuste = []
      let registrosGuias = []


      const AxiosParamas =  {
        transportista_id : !this.AsignacionGlobal ? transportista : undefined,
        estado_guia_id: 1,
        fecha: this.AsignacionGlobal ? !this.allOption ?  this.createForm.fecha : undefined : undefined,
        // deep: false
      }
      const resAjuste =  this.getDataGeneral(
        "GET",
        `transjuma/AjusteDetalle/`,
        this.nameItemStore,
        "loading",
        {},
        false, //paginacion
        true, // opcion para que devuelva algo
        true, // modo normal
        null, //parametros query
        false, // si devuelve uno solo
        AxiosParamas // parametros para axios
      )


      const AxiosParamas2 =  {
        transportista_id : !this.AsignacionGlobal ? transportista : undefined,
        estado_guia_id: 1,
        fecha: this.AsignacionGlobal ? !this.allOption ?  this.createForm.fecha : undefined : undefined,
        pagination: false,
        deep: false
      }
      const resGuias =  this.getDataGeneral(
        "GET",
        `transjuma/Guia/`,
        this.nameItemStore,
        "loading",
        {},
        false, //paginacion
        true, // opcion para que devuelva algo
        true, // modo normal
        null, //parametros query
        false, // si devuelve uno solo
        AxiosParamas2 // parametros para axios
      )


      await Promise.all([resAjuste, resGuias])
      .then((resultados) => {
        if (resultados[0]){
          this.ajustes = [...resultados[0]]
          registrosAjuste =  this.ajustes.map((item)=>{

            // const transportistaA = this.transportistas.find(t => t.id == item.transportista)

            let obj = {
              tipo : "A",
              key : `A${item.id}`,
              id : item.id,
              id_cabecera: item.ajuste_cabecera,
              transportista: item.transportista,
              fecha: item.fecha,
              guia: item.guia.numero,
              destino: '',
              descripcion: item.tipo_ajuste.nombre,
              precioBase: parseFloat(item.valor_adicional),
              descuento: parseFloat(item.descuento),
              iva: parseFloat(item.iva),
              subtotal : parseFloat(item.valor_adicional) + parseFloat(item.descuento),
              total : parseFloat(item.valor_adicional) + parseFloat(item.descuento) + parseFloat(item.iva),
              label: `${item.fecha.substr(0, 10)} -- ${this.calculoDiasFecha(item.fecha.substr(0, 10))}Dias ${item.tipo_ajuste.nombre} ${item.transportista.nombre}
                ₡${this.convertToMoney(parseFloat(item.valor_adicional))}  ₡${this.convertToMoney(parseFloat(item.descuento))}  ₡${this.convertToMoney(parseFloat(item.iva))} ${item.guia.numero}`            
            }
            return obj  
          })
        }

        if (resultados[1]){
          this.guias = [...resultados[1]]
          registrosGuias =this.guias.map((item)=>{
            const transportista = this.transportistas.find(t => t.id == item.transportista)

            let obj =   {
              tipo : "G",
              key : `G${item.id}`,
              id : item.id,
              id_cabecera: '',
              transportista: transportista,
              fecha: item.fecha,
              guia: item.numero,
              destino: `${item.destino_tonelada.numero} -${item.destino_tonelada.destino}`,
              descripcion: item.comentario,
              precioBase: parseFloat(item.precio_base),
              descuento: parseFloat(item.descuento),
              iva: parseFloat(item.iva),
              subtotal : parseFloat(item.precio_base) - parseFloat(item.descuento),
              total : parseFloat(parseFloat(item.precio_base) - parseFloat(item.descuento)) + parseFloat(item.iva),
              label: `${item.fecha.substr(0, 10)} -- ${this.calculoDiasFecha(item.fecha.substr(0, 10))}Dias ${item.destino_tonelada.numero} - ${item.destino_tonelada.destino} ${item.transportista.nombre}
                ₡${this.convertToMoney(parseFloat(item.precio_base))}  ₡${this.convertToMoney(parseFloat(item.descuento))}  ₡${this.convertToMoney(parseFloat(item.iva))} ${item.numero}`
            }
            return obj
          })
        }


        this.registros = registrosGuias.concat(registrosAjuste)
        this.registros = this.registros.sort(function (x, y) {
                                              let a = x.guia.toUpperCase(),
                                                  b = y.guia.toUpperCase()
                                              return a == b ? 0 : a > b ? 1 : -1
                                          })

      })
      this.isloading = false
      console.log(this.registros)
    },

    async getDataProformasGuiasAjustes(transportista) {
      this.isloading = true
      await this.$store.dispatch("AuthModule/verifyToken")

      let registrosAjuste = []
      let registrosGuias = []


      const AxiosParamas =  {
        estado_guia_id: 2,
        // fecha: this.AsignacionGlobal ? !this.allOption ?  this.createForm.fecha : undefined : undefined,
        // deep: false
      }
      const resAjuste =  this.getDataGeneral(
        "GET",
        `transjuma/AjusteDetalle/`,
        this.nameItemStore,
        "loading",
        {},
        false, //paginacion
        true, // opcion para que devuelva algo
        true, // modo normal
        null, //parametros query
        false, // si devuelve uno solo
        AxiosParamas // parametros para axios
      )


      const AxiosParamas2 =  {
        estado_guia_id: 2,
        // fecha: this.AsignacionGlobal ? !this.allOption ?  this.createForm.fecha : undefined : undefined,
        pagination: false,
        deep: false
      }
      const resGuias =  this.getDataGeneral(
        "GET",
        `transjuma/Guia/`,
        this.nameItemStore,
        "loading",
        {},
        false, //paginacion
        true, // opcion para que devuelva algo
        true, // modo normal
        null, //parametros query
        false, // si devuelve uno solo
        AxiosParamas2 // parametros para axios
      )


      await Promise.all([resAjuste, resGuias])
      .then((resultados) => {
        if (resultados[0]){
          this.ajustes = [...resultados[0]]
          registrosAjuste =  this.ajustes.map((item)=>{

            // const transportistaA = this.transportistas.find(t => t.id == item.transportista)

            let obj = {
              tipo : "A",
              key : `A${item.id}`,
              id : item.id,
              id_cabecera: item.ajuste_cabecera,
              transportista: item.transportista,
              fecha: item.fecha,
              guia: item.guia.numero,
              destino: '',
              descripcion: item.tipo_ajuste.nombre,
              precioBase: parseFloat(item.valor_adicional),
              descuento: parseFloat(item.descuento),
              iva: parseFloat(item.iva),
              subtotal : parseFloat(item.valor_adicional) + parseFloat(item.descuento),
              total : parseFloat(item.valor_adicional) + parseFloat(item.descuento) + parseFloat(item.iva),
              label: `${item.fecha.substr(0, 10)} -- ${this.calculoDiasFecha(item.fecha.substr(0, 10))}Dias ${item.tipo_ajuste.nombre} ${item.transportista.nombre}
                ₡${this.convertToMoney(parseFloat(item.valor_adicional))}  ₡${this.convertToMoney(parseFloat(item.descuento))}  ₡${this.convertToMoney(parseFloat(item.iva))} ${item.guia.numero}`            
            }
            return obj  
          })
        }

        if (resultados[1]){
          this.guias = [...resultados[1]]
          registrosGuias =this.guias.map((item)=>{
            const transportista = this.transportistas.find(t => t.id == item.transportista)

            let obj =   {
              tipo : "G",
              key : `G${item.id}`,
              id : item.id,
              id_cabecera: '',
              transportista: transportista,
              fecha: item.fecha,
              guia: item.numero,
              destino: `${item.destino_tonelada.numero} -${item.destino_tonelada.destino}`,
              descripcion: item.comentario,
              precioBase: parseFloat(item.precio_base),
              descuento: parseFloat(item.descuento),
              iva: parseFloat(item.iva),
              subtotal : parseFloat(item.precio_base) - parseFloat(item.descuento),
              total : parseFloat(parseFloat(item.precio_base) - parseFloat(item.descuento)) + parseFloat(item.iva),
              label: `${item.fecha.substr(0, 10)} -- ${this.calculoDiasFecha(item.fecha.substr(0, 10))}Dias ${item.destino_tonelada.numero} - ${item.destino_tonelada.destino} ${item.transportista.nombre}
                ₡${this.convertToMoney(parseFloat(item.precio_base))}  ₡${this.convertToMoney(parseFloat(item.descuento))}  ₡${this.convertToMoney(parseFloat(item.iva))} ${item.numero}`
            }
            return obj
          })
        }


        this.registros = registrosGuias.concat(registrosAjuste)
        this.registros = this.registros.sort(function (x, y) {
                                              let a = x.guia.toUpperCase(),
                                                  b = y.guia.toUpperCase()
                                              return a == b ? 0 : a > b ? 1 : -1
                                          })

      })
      this.isloading = false
      console.log(this.registros)
    },
    setCodigoEstadoGuiaEnviada() {
      this.createForm.estado_asignacion = 1
    },
    previewAsignacion(){
      this.previewProgramacion = true
    },
    async CrearAsignacionMany(email){
      await this.$store.dispatch("AuthModule/verifyToken")

      this.isloadingAsignacion = true

      let registrosFinales = []
      for (let y of this.createFormRegistrosAsignar.records){
        delete y.asignacion_cabecera
        y.proforma_cabecera = null
        registrosFinales.push(y)
      }

      const body ={
        company : 1,
        created_by : this.$store.state.AuthModule.refu,
        updated_by : null,
        ref : this.$store.state.AuthModule.r2,
        data: this.tipoModulo == 'PF' ? registrosFinales : this.asignacionesFinales,
        dataCabecera: this.createForm
      }
      console.log(body)
      axiosC({
        method: "POST",
        url:  this.tipoModulo == 'PF' ? `transjuma/ProformaCabecera/bulk-create/` :`transjuma/AsignacionCabecera/bulk-create/`,
        data: body,
        headers: { Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}` },
      })
      .then((result) => {
        console.log(result)
        this.$refs.createForm.reset()
        this.$refs.createForm.resetValidation()
        this.disableCardContent = true
        this.isloadingAsignacion = false
        this.formulario = false

        this.formulario = true
        this.botonContinuar = false
        this.disableForm = false

        this.$nextTick(() => {
          this.iniciarForm()
        })

        this.completeProcess = true
      }).catch((err) => {
        console.log(err)
        this.isloadingAsignacion = false

      });
    },

    accionCancelar(){
      if (this.tipoModulo == 'AG'){
        this.$router.push({name: 'Asignaciones'})
      } else {
        this.$router.push({name: 'Proformas'})
      }
    }

  },

  async beforeRouteLeave(to, from, next) {
    if (this.completeProcess){
      next()
    } else{
      
      const answer =  window.confirm('Realmente deseas continuar? Tienes cambios sin guardar!')
  
      if (answer && !this.AsignacionGlobal) {
        this.isloading = true
        this.disableCardContent = true
        await this.$store.dispatch("AuthModule/verifyToken")
        axiosC({
          method: "DELETE",
          url: `transjuma/AsignacionCabecera/${this.recentCabecera.id}/`,
          headers: { Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}` },
        })
        .then(()=>{
          this.recentCabecera = null
          this.completeProcess = false
          this.isloading = false
          this.disableCardContent = false
          next()
  
        })
        .catch((err)=>{
          this.isloading = false
          this.disableCardContent = false
          alert(err)
        })
      }else {
        next(false)
      }
    }
    this.completeProcess = false
   // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
  },

  created() {
    this.getDataGeneral(
      "GET",
      `transjuma/EstadoGuia/`,
      'estado_guias',
      "",
      {},
      false, //paginacion
      false, // opcion para que devuelva algo
      true //modo normal activado, vienen todos los resultados
    );
    this.getDataGeneral(
      "GET",
      `transjuma/TransportistaList`,
      'transportistas',
      "",
      {},
      false, //paginacion
      false, // opcion para que devuelva algo
      true //modo normal activado, vienen todos los resultados
    );
    this.getDataGeneral(
      "GET",
      `transjuma/EstadoAsignacion/`,
      'estado_asignaciones',
      "",
      {},
      false, //paginacion
      false, // opcion para que devuelva algo
      true //modo normal activado, vienen todos los resultados
    );
  },
}
</script>

<style>
.card-principal.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0;
}
.card-content.v-card.v-card--disabled.v-sheet.theme--light.elevation-0 {
  opacity: 40%;
}
.el-transfer {
  display: contents !important;
}
.el-transfer-panel {
  width: 47.5%;
  height: 72vh;
}
.el-transfer-panel__list {
  height: 72vh;
}
.el-transfer-panel__list.is-filterable {
  height: 72vh;
}
.el-transfer-panel .el-transfer-panel__footer {
  height: 42px;
}

.fotter-row {
  padding: 0;
}

/* los botones */
.el-transfer__buttons {
  padding: 0 6px;
  color: #2e335b;
}
.el-button--primary,
.el-button--primary.is-active,
.el-button--primary:active {
  color: #2e335b;
  background-color: #2e335b;
}

.el-icon-arrow-left,
.el-icon-arrow-right {
  color: white;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #2e335b;
  border-color: #2e335b;
}

.el-checkbox__input.is-checked + .el-checkbox__label,
.el-transfer-panel__item:hover {
  color: #2e335a;
}

.texto-footer {
  font-size: 13px;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 675px) {
  .el-transfer-panel {
    width: 100%;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 676px) and (max-width: 856px) {
  .el-transfer-panel {
    width: 46%;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 857px) and (max-width: 992px) {
  .el-transfer-panel {
    width: 47%;
  }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 993px) and (max-width: 1200px) {
  .el-transfer-panel {
    width: 47.5%;
  }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1201px) and (min-width: 1400px) {
  .el-transfer-panel {
    width: 47.8%;
  }
}
</style>
