<template>
  <v-form ref="editedItem">
      <v-dialog v-model="dialog" width="60%" persistent>
        <v-card
          class="rounded-xl"
          height="auto"
          :loading="loadingForm"
          :disabled="loadingForm"
        >
          <v-card-title class="d-flex justify-space-between py-1 pl-5 pr-4">
            <h5 class="mb-0">{{ title }}</h5>
            <v-btn icon @click="closeBaseWindow">
              <v-icon color="red"> mdi-close-circle </v-icon>
            </v-btn>
          </v-card-title>

          <!-- Encabezado Principal -->
          <v-form v-model="validCabecera" ref="createFormCabecera" lazy-validation>

            <v-divider></v-divider>

            <!-- Encabezado Titulo -->
            <v-card-title class="py-0 pt-1 pl-5 pr-4">
              <v-row dense align="center">
                <v-col cols="12" md="12" class="pt-0">
                  <h6>Encabezado</h6>
                </v-col>
              </v-row>
            </v-card-title>

            <!-- Body Encabezadp -->
            <v-card-text class="pb-0 pl-5 pr-4">
              <!-- Campos encabezado -->
              <v-row dense>
                <v-col cols="12"
                  v-for="(header, i) in headersFilter"
                  :key="i"
                  :md="header.colmd"
                  class="d-flex align-center justify-center"
                  >
                  <v-btn
                    v-if="header.vicon"
                    small
                    icon
                    color="primary"
                    @click="metodoIcono(header.textComponent)"
                  >
                    <v-icon >{{header.text}}</v-icon>
                  </v-btn>
                  <v-textarea
                    v-if="header.vtextarea"
                    v-model="createFormCabecera[header.value]"
                    :label="header.text"
                    dense
                    outlined
                    class="rounded-lg"
                    hide-details
                    rows="6"
                  ></v-textarea>
                  <v-text-field
                    v-if="header.vtext"
                    v-model="createFormCabecera[header.value]"
                    :label="header.text"
                    dense
                    outlined
                    class="rounded-lg"
                    hide-details
                    :rules="header.require ? [v => !!v || 'Campo es requerido'] : []"
                  ></v-text-field>
                  <v-autocomplete
                    v-if="header.vauto"
                    v-model="createFormCabecera[header.value]"
                    :label="header.text"
                    :items="header.vautoName"
                    item-value="id"
                    :item-text="header.itemText"
                    dense
                    outlined
                    clearable
                    class="rounded-lg"
                    hide-details
                    :rules="header.require ? [v => !!v || 'Campo es requerido'] : []"
                    @input="inputAuto(header.inputauto, createFormCabecera[header.value], header.vautoName, header.formfield, header.vfunctname)"
                  ></v-autocomplete>
                  <v-combobox
                    v-if="header.vcombo"
                    v-model="createFormCabecera[header.value]"
                    :label="header.text"
                    :items="header.hasgetter ? $store.getters[header.vcomboName] :$store.state[header.vcomboName]"
                    dense
                    outlined
                    class="rounded-lg"
                    hide-details
                    :rules="header.require ? [v => !!v || 'Campo es requerido'] : []"
                    multiple
                    chips
                  ></v-combobox>
                  <v-menu
                    v-if="header.vdate"
                    v-model="fecha[header.value]"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="text-body-2 custom-form-text-fields rounded-lg"
                        dense
                        v-model="createFormCabecera[header.value]"
                        :label="header.text"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="createFormCabecera[header.value]"
                      @input="fecha[header.value] = false"
                    ></v-date-picker>
                  </v-menu>

                  <v-menu
                    v-if="header.vtime"
                    ref="menu"
                    v-model="tiempo[header.value]"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    class="pt-1"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="createFormCabecera[header.value]"
                        :label="header.text"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        class="pt-1 mb-2"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="tiempo[header.value]"
                      v-model="createFormCabecera[header.value]"
                      full-width
                      @click:minute="tiempo[header.value] = false"
                    ></v-time-picker>
                  </v-menu>
                </v-col>

                <v-col width="100%">
                    <div class="d-flex justify-end py-0 float-right">
                      <v-tooltip top v-if="tipoModulo=='FC'">
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :disabled="!validCabecera"
                          v-bind="attrs"
                          v-on="on"
                          @click="openNote"
                        >
                          <v-icon color="blue" >
                            mdi-note
                          </v-icon>
                        </v-btn>
                        </template>
                        <span>Datos Adicionales</span>
                      </v-tooltip>

                      <!-- gaurdar cabecera proforma -->
                      <v-tooltip top v-if="tipoModulo=='PF'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            :disabled="!validCabecera"
                            v-bind="attrs"
                            v-on="on"
                            @click="openNote"
                          >
                            <v-icon color="blue" >
                              mdi-note
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Notas</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :disabled="!validCabecera"
                          @click="editModeEncabezado ? submitInfo('PUT', 'Encabezado') :   submitInfo('POST', 'Encabezado')"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon :color="editModeEncabezado ? 'warning' : 'green' " >
                            mdi-content-save
                          </v-icon>
                        </v-btn>
                        </template>
                        <span>{{editModeEncabezado ? 'Modificar Cabecera' : 'Agregar Cabecera' }}</span>
                      </v-tooltip>

                    </div>
                  </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>

          </v-form>

          <!-- Body -->
          <v-form v-model="validLine" ref="createFormBodyLine" lazy-validation>
            <v-card-text class="py-1 pl-5 pr-4">
              <v-tabs v-model="tab">
                <v-tab v-for="item in itemsTab" :key="item.tab">
                  {{ item.tab }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in itemsTab" :key="item.tab">
                  <v-card flat :disabled="!editModeEncabezado">
                    <!-- PRIMER TAB -->
                    <v-card-text v-if="item.content == 1" class="px-0 pt-3 pb-0">
                      <!-- Primeria linea Tab 1 -->
                      <v-row dense align="center" class="mb-1">
                        <v-col cols="12"
                          v-for="(header, i) in headersFilterDetalles"
                          :key="i"
                          :md="header.colmd"
                          class="d-flex align-center justify-center"
                          >
                          <v-btn
                            v-if="header.vicon"
                            small
                            icon
                            color="primary"
                            @click="metodoIcono(header.textComponent)"
                          >
                            <v-icon >{{header.text}}</v-icon>
                          </v-btn>
                          <v-textarea
                            v-if="header.vtextarea"
                            v-model="createFormBodyLine[header.value]"
                            :label="header.text"
                            dense
                            outlined
                            class="rounded-lg"
                            hide-details
                            rows="6"
                          ></v-textarea>
                          <v-text-field
                            v-if="header.vtext"
                            v-model="createFormBodyLine[header.value]"
                            :label="header.text"
                            dense
                            outlined
                            class="rounded-lg"
                            hide-details
                            :rules="header.require ? [v => !!v || 'Campo es requerido'] : []"
                          ></v-text-field>
                          <v-autocomplete
                            v-if="header.vauto"
                            v-model="createFormBodyLine[header.value]"
                            :label="header.text"
                            :items="header.vautoName"
                            item-value="id"
                            :item-text="header.itemText"
                            dense
                            outlined
                            clearable
                            class="rounded-lg"
                            hide-details
                            :rules="header.require ? [v => !!v || 'Campo es requerido'] : []"
                            @input="inputAuto(header.inputauto, createFormBodyLine[header.value], header.vautoName, header.formfield, header.vfunctname)"
                          ></v-autocomplete>
                          <v-combobox
                            v-if="header.vcombo"
                            v-model="createFormBodyLine[header.value]"
                            :label="header.text"
                            :items="header.hasgetter ? $store.getters[header.vcomboName] :$store.state[header.vcomboName]"
                            dense
                            outlined
                            class="rounded-lg"
                            hide-details
                            :rules="header.require ? [v => !!v || 'Campo es requerido'] : []"
                            multiple
                            chips
                          ></v-combobox>
                          <v-menu
                            v-if="header.vdate"
                            v-model="fechaDetalle[header.value]"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                class="text-body-2 custom-form-text-fields rounded-lg"
                                dense
                                v-model="createFormBodyLine[header.value]"
                                :label="header.text"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="createFormBodyLine[header.value]"
                              @input="fechaDetalle[header.value] = false"
                            ></v-date-picker>
                          </v-menu>

                          <v-menu
                            v-if="header.vtime"
                            ref="menu"
                            v-model="tiempo[header.value]"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            class="pt-1"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="createFormBodyLine[header.value]"
                                :label="header.text"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                                class="pt-1 mb-2"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="tiempo[header.value]"
                              v-model="createFormBodyLine[header.value]"
                              full-width
                              @click:minute="tiempo[header.value] = false"
                            ></v-time-picker>
                          </v-menu>

                          <!-- campo especial autocompletado -->
                          <BaseAutoComp
                            v-if="header.specialAuto"
                            :textoBusqueda="header.itemSpecialAuto.textoBusqueda"
                            :label="header.itemSpecialAuto.label"
                            :itemText="header.itemSpecialAuto.itemText"
                            itemValue="id"
                            :advanceSearchFields="header.itemSpecialAuto.advanceSearchFields"
                            :rutaItemAuto="header.itemSpecialAuto.rutaItemAuto"
                            :procesoCompleto="procesoCompleto"
                            :modoEdicion="modoEdicionAuto"
                            :itemEditar="selectedItemEditar"
                            v-on:change-auto="selectedSpecialAuto"
                            
                            :fieldName="header.value"
                            :outline="true"
                            :alternativeRutaItemAuto="header.itemSpecialAuto.rutaItemAutoEdit"
                          >
                          </BaseAutoComp>
                        </v-col>

                        <!-- ACCIONES DE LA LINEA -->
                        <v-col cols="12" md="2">
                          <div v-if="!editLine" class="d-flex justify-center">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  @click="submitInfo('POST', 'Detalle')"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon color="blue"> mdi-plus-box </v-icon>
                                </v-btn>
                              </template>
                              <span>Agregar Linea</span>
                            </v-tooltip>

                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="cancelAction"
                                >
                                  <v-icon color="red"> mdi-cancel </v-icon>
                                </v-btn>
                              </template>
                              <span>Cancelar Accion</span>
                            </v-tooltip>

                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  @click="vistaPrevia"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon color="purple">
                                    mdi-printer-search
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Vista Preliminar</span>
                            </v-tooltip>
                            <v-tooltip
                              top
                              v-if="tipoModulo == 'AS' || tipoModulo == 'PF'"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  :disabled="totales.TOTAL == 0"
                                  icon
                                  @click="enviar"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon color="success">
                                    mdi-receipt-text-arrow-right-outline
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Enviar Factura</span>
                            </v-tooltip>

                            <v-tooltip top v-if="tipoModulo == 'PF'">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  @click="enviar"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon color="success">
                                    mdi-email-fast-outline
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Enviar Correo</span>
                            </v-tooltip>
                          </div>

                          <div v-else class="d-flex justify-center">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  @click="submitInfo('PUT', 'Detalle')"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon color="warning">
                                    mdi-content-save
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Modificar Linea</span>
                            </v-tooltip>

                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  @click="submitInfo('DELETE', 'Detalle')"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon color="red"> mdi-minus-box </v-icon>
                                </v-btn>
                              </template>
                              <span>Eliminar Linea</span>
                            </v-tooltip>

                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="cancelAction"
                                >
                                  <v-icon color="red"> mdi-cancel </v-icon>
                                </v-btn>
                              </template>
                              <span>Cancelar Accion</span>
                            </v-tooltip>
                          </div>
                        </v-col>

                      </v-row>

                      <!-- DETALLE DE FACTURA -->
                      <v-row dense align="baseline" no-gutters>
                        <v-col cols="12" md="9">
                          <!-- data table detalle factura -->
                          <v-data-table
                            dense
                            :headers="headersFilterMiniDetalle"
                            :items="itemsDetalle"
                            disable-sort
                            :loading="isLoadingLines"
                            :items-per-page="5"
                            @dblclick:row="editLineClick"
                          >
                            <!-- pass through scoped slots -->
                            <template
                              v-for="(_, scopedSlotName) in $scopedSlots"
                              v-slot:[scopedSlotName]="slotData"
                            >
                              <slot :name="scopedSlotName" v-bind="slotData" />
                            </template>


                          </v-data-table>
                        </v-col>

                        <v-col cols="12" md="3" class="px-2 pt-2 pb-0">
                          <!-- SUMATORIAS -->
                          <v-row
                            align="center"
                            justify="end"
                            v-if="tipoModulo == 'PF'"
                          >
                            <v-col cols="12" md="3">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    @click="enviar"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="success">
                                      mdi-receipt-text-arrow-right-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Enviar Factura</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>

                          <!-- FACTURA SUMATORIAS -->
                          <BaseSumatorias
                            :itemsSumatorias="itemsSumatorias"
                            :tipoModulo="tipoModulo"
                            :totales="totales"
                          ></BaseSumatorias>
                        
                        </v-col>
                      </v-row>
                    </v-card-text>

                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-form>
        </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { axiosC } from "../api/axiosbd";
import BaseAutoComp from '../components/BaseAutoComplet.vue'
import BaseSumatorias from '../components/BaseSumatorias.vue'
import { paginationMethods } from "../mixins/pagination";

export default {
  components:{
    BaseAutoComp, BaseSumatorias
  },
  props:{
    //PROPIEDADES DE SPECIAL AUTO
    specialAuto:{
      type: Boolean,
      required: false,
      default: false
    },
    itemSpecialAuto:{
      type: Object,
      required: false,
      default: function() {
        return {
          textoBusqueda: '',
          label: '',
          itemText: [],
          advanceSearchFields: {},
          rutaItemAuto: '',
        }
      }
    },
    modoEdicion:{
      type: Boolean,
      required: false,
      default: false
    },

    ///
    title: {
      type: String,
      required: true
    },
    tipoModulo:{
      type: String,
      required: true
    },
    modulo:{
      type: String,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    headersDetalle: {
      type: Array,
      required: true
    },
    urlFormEncabezado:{
      type: String,
      required: true
    },
    urlFormDetalle:{
      type: String,
      required: true
    },
    fieldCabeceraName:{
      type: String,
      required: true
    },
    cabeceraDocumento:{
      type: Object,
      required: false,
    },
    customValidate:{
      type: Function,
      required: false
    }

  },
  mixins: [paginationMethods],
  data() {
    return {
      dialog:true,
      fecha:{...this.headers.filter(item => item.vdate)},
      tiempo:{...this.headers.filter(item => item.vtime)},
      fechaDetalle:{...this.headersDetalle.filter(item => item.vdate)},
      tiempoDetalle:{...this.headersDetalle.filter(item => item.vtime)},
      createFormBodyLine: {},

      isLoading: false,

      validCabecera: true,
      createFormCabecera: {},
      loadingForm: false,
      editModeEncabezado: false,

      validLine: true,
      createFormBodyLine: {},
      isLoadingLines: false,
      editModeDetalle: false,
      editLine: false,

      tab: null,
      itemsTab: [
        { tab: "Detalle", content: 1 },
        // { tab: "Otros Cargos", content: 2 },
      ],
      
      itemsDetalle: [],
      itemsSumatorias: [
        { nombre: "Sub total", nombreForm: "SUBTOTAL", disable: [] },
        { nombre: "Descuento", nombreForm: "DESCUENTO", disable: [] },
        { nombre: "Impuesto", nombreForm: "IMPUESTO", disable: [] },
        { nombre: "Total", nombreForm: "TOTAL", disable: [] },
      ],
      keyTc: 0,
      facturaSelected: {},
      loadingHacienda: false,
      actividadKey: 0,
      enableCobroTerceroFields: false,
      keyAutoDestino: 0,
      document: null,
      editedItem: {},
      modoEdicionAuto: false,
      procesoCompleto: false,
      selectedItemEditar: null,
      keyAuto: 0
    };
  },
  computed:{
    headersFilter(){
      if (this.headers){
        return this.headers.filter(h=> h.form == true)
      } else {
        return []
      }
    },
    headersFilterDetalles(){
      if (this.headersDetalle){
        return this.headersDetalle.filter(h=> h.form == true)
      } else {
        return []
      }
    },
    headersFilterMiniDetalle(){
      if (this.headersDetalle){
        return this.headersDetalle.filter(h=> h.showInnerDetail == true)
      } else {
        return []
      }
    },
    totales(){
      console.log(this.itemsDetalle)
      const SUBTOTAL = this.itemsDetalle.map(item => parseFloat(item.valor_adicional)).reduce((a,b)=> a+b,0)
      const DESCUENTO = this.itemsDetalle.map(item => parseFloat(item.descuento)).reduce((a,b)=> a+b,0)
      const IMPUESTO = this.itemsDetalle.map(item => parseFloat(item.iva)).reduce((a,b)=> a+b,0)
      // const subtotal = this.itemsDetalle.map(item => item.ARTCAN*item.PREUNI).reduce((a,b)=> a+b,0)
      const TOTAL = SUBTOTAL + DESCUENTO + IMPUESTO
      return {
        SUBTOTAL,DESCUENTO, IMPUESTO, TOTAL
        }
    },
  },
  methods: {
    esEntero(numero) {
      return typeof numero === 'number' && numero % 1 === 0;
    },
    submitInfo(method, typeForm){
      
      let body = {}
      let url = ''
      let error = false
      if (typeForm == 'Encabezado'){
        this.loadingForm = true
        if (method == 'POST' && this.$refs.createFormCabecera.validate()){
          console.log(method, typeForm, this.createFormCabecera)
          body.company = 1;
          body.created_by = this.$store.state.AuthModule.refu
          body.updated_by = null
          body.ref = this.$store.state.AuthModule.r2

          body = {...body, ...this.createFormCabecera}

          url = this.urlFormEncabezado

          console.log(body)
        } else {
          error = true
        }
      }

      if (typeForm == 'Detalle'){
        this.loadingForm = true
        if (method == 'POST' && this.$refs.createFormBodyLine.validate()){
          body = {...this.createFormBodyLine}
          body.company = 1;
          body.created_by =  this.$store.state.AuthModule.refu
          body.updated_by =  null
          body.ref = this.$store.state.AuthModule.r2

          if (!body.hasOwnProperty('ajuste_cabecera')){
            body.ajuste_cabecera = this.cabeceraDocumento.ajuste_cabecera
          }
          
          if (this.tipoModulo == 'AD' ) body.estado_guia = 1
          
          url = this.urlFormDetalle
        } else if (method == 'DELETE'){
          body = {...this.createFormBodyLine}
          url = this.urlFormDetalle
        } else if (method == 'PUT'){
            body = {...this.createFormBodyLine}
            // console.log(method,body)
            body.updated_by =   this.$store.state.AuthModule.refu
          if (this.tipoModulo == 'AD') {
            body.estado_guia =  this.esEntero(body.estado_guia) ? body.estado_guia : body.estado_guia.id
            body.guia = this.esEntero(body.guia) ? body.guia : body.guia.id
            body.transportista = this.esEntero(body.transportista) ? body.transportista : body.transportista.id
            body.tipo_ajuste = this.esEntero(body.tipo_ajuste) ? body.tipo_ajuste : body.tipo_ajuste.id
            url = this.urlFormDetalle
          }
        }
        else {
          error = true
        }
        console.log(body)
      }
      if (!error){
        axiosC({
          method: method,
          url: `${url}${method == "PUT" ||  method == "DELETE" ? body.id + "/" : ""}`,
          data: method != "DELETE" ? body : {},
          headers: {
            Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
          },
        })
          .then((response) => {
            if (typeForm == 'Encabezado'){
              this.editModeEncabezado = true
              if (method == 'POST') {
                console.log(response)
                this.editedItem = response.data
                this.createFormBodyLine.ajuste_cabecera = response.data.id
                this.createFormBodyLine.fecha = response.data.fecha.substring(0,10)
              }
              this.loadingForm = false
            }

            if (typeForm == 'Detalle'){

              this.editModeDetalle = true
              // this.close();
              this.resultLines()
              this.resultDataDocument()
              this.cancelAction()
              this.loadingForm = false
              this.$emit('update-List')
            }
          })
          .catch((err) =>  {
            this.loading = false
            alert(err)
          })
      } else {
        this.loadingForm = false
        console.log('hay error')
      }
    },
    close() {
    },
    closeBaseWindow(){
      this.mode = "POST";
      this.editedItem = {}
      this.search =  null
      this.editModeEncabezado = false
      this.procesoCompleto = true
      this.modoEdicionAuto = false
      this.selectedItemEditar = null
      this.$emit('cierre-form')
    },
    vistaPrevia(){

    },
    cancelAction(){
      this.editModeDetalle = true
      this.editLine = false
      // this.resultLines()
      this.createFormBodyLine = {}
      this.$refs.createFormBodyLine.resetValidation()

      this.modoEdicionAuto = false
      this.selectedItemEditar = null
      this.procesoCompleto = true

    },
    editLineClick(event, {item}){

      if (this.customValidate(item)){
        console.warn('MODO EDITAR ACTIVADO')
        console.log(item)
        this.keyAuto ++
        this.editLine = true
        this.createFormBodyLine = {...item}

        this.createFormBodyLine.fecha = this.createFormBodyLine.fecha.substring(0,10)
        this.createFormBodyLine.fecha_pago = this.createFormBodyLine.fecha_pago ? this.createFormBodyLine.fecha_pago.substring(0,10) : null
        // this.modoEdicionAuto = true
        this.$nextTick(()=>{
          this.keyAuto ++
          this.modoEdicionAuto = true
          this.procesoCompleto = false
          this.selectedItemEditar =  item.guia //se envia al componente auto
        })
      } else {
        this.$message({
          message: 'Info, El ajuste seleccionado debe estar en estado flete para ser modificado.',
          type: 'warning'
        });
      }

    },
    inputAuto(activar, selected, items, formField, vfunctname){
      if (vfunctname){
        this[vfunctname](selected, items)
      }
      if (activar) {
        const select = items.find(k => k.id == selected)
        if (select) {
          this.createFormCabecera[formField] =`${select.texto_mensaje}`
          console.log(this.createFormCabecera)
        }
      }
    },
    updatePrecio(selected, items){
      console.log(selected, items)
      const precio = items.find( x => x.id == selected)

      if (precio) {
        // this.createFormBodyLine.valor_adicional = this.$convertToMoney(parseFloat(precio.valor_adicional))
        // this.createFormBodyLine.descuento = this.$convertToMoney(parseFloat(precio.valor_adicional) * 0.1)
        // this.createFormBodyLine.iva = this.$convertToMoney(parseFloat(precio.valor_adicional) * 0.13)

        let valor_adicional = parseFloat(precio.valor_adicional)
        let descuento = parseFloat(precio.valor_adicional) * -0.1
        let iva = (valor_adicional + descuento) * 0.13

        this.createFormBodyLine.valor_adicional = valor_adicional.toFixed(2)
        this.createFormBodyLine.descuento = descuento.toFixed(2)
        this.createFormBodyLine.iva = iva.toFixed(2)

        console.log(this.createFormBodyLine)

      }
    },
    getDataDocumento(url, fields, itemsName, loading, isquery=true, one=false, normal=true){
      const query = new URLSearchParams(fields)
        // Lazily load input items
        this.getDataGeneral(
          "GET",
          url,
          itemsName,
          loading,
          {},
          false, //paginacion
          false, // opcion para que devuelva algo
          normal ? true : false, //modo normal activado, vienen todos los resultados
          isquery ? query : false, // parametros para busqueda avanzada
          one ? true : false
        )
    },
    async resultLines(){
      this.isLoading = true;
      this.loadingFormDetalle = true
      let fields = {}
      // fields[this.fieldCabeceraName] = this.editedItem.id
      // console.log('RESULT LINES', this.urlFormDetalle, fields)
      // this.getDataDocumento(this.urlFormDetalle, fields, 'itemsDetalle', "isLoading")
    },

    resultDataDocument(){
      // CUANDO VIENE DEL MODO EDITAR
      this.loadingForm = true
      if (this.modoEdicion){
        this.editModeEncabezado = true
        // this.modoEdicionAuto = true
        //ENCABEZADO
        this.createFormCabecera = {}
        this.isLoading = true;
        let fields = {}
        // fields['id'] = this.cabeceraDocumento.id
        
        this.getDataDocumento(this.urlFormEncabezado + `${this.cabeceraDocumento.ajuste_cabecera}/`, null, 'createFormCabecera', "loadingForm", false, true, false)
        //====================================
        
        //DETALLE
        this.isLoading = true;
        let fieldsDetalle = {}
        fieldsDetalle[this.fieldCabeceraName] = this.cabeceraDocumento.ajuste_cabecera
        
        console.log('EDICION BUSCAR DOC', this.cabeceraDocumento, fieldsDetalle)
        this.getDataDocumento(this.urlFormDetalle, fieldsDetalle, 'itemsDetalle', "isLoadingLines")
      } else {
        this.loadingForm = false
        console.log('EDICION NO HABILITADA',  this.editedItem)
        if (this.editedItem.hasOwnProperty('id')){
          this.loadingForm = true
          // this.isLoading = true;
          // this.loadingFormDetalle = true
          let fields = {}
          fields[this.fieldCabeceraName] = this.editedItem.id
          this.getDataDocumento(this.urlFormDetalle, fields, 'itemsDetalle', "isLoadingLines")
        }
      }
    },

    //FUNCIONES DE SPECIAL AUTO
    selectedSpecialAuto(item){
      console.log(item)
      this.createFormBodyLine[item.label] = item.itemSelected ? item.itemSelected.id : null
    },

  },
  created(){
    console.log('FORM CREADO')
    this.resultDataDocument()
  }
}
</script>

<style>


</style>
