<template>
  <div>
    <BaseWindowList
      :headers="headers"
      :itemsBaseWindowList="desserts"
      title="Gestion de Ajustes"
      titleAddMethod="Agregar Nuevo Ajuste"
      :activarEdicion="true"
      :activarBorrar="true"
      :currentPage="currentPage"
      :totalPages="totalPages"
      :loading="loading"
      v-on:editar-row="editItem"
      v-on:open-agregar-listado="openForm"
      v-on:click-gotopage="siguientePagina"
      v-on:eliminar-row="deleteItem"
      itemStore="searchAjustes"
      :actBtnExcel="activeAdvanceSearch"
      v-on:reset-info="resetInfo"
      v-on:info-excel="infoExcel"
    >
      <template v-slot:item.fecha="{ item }">
        <tr class="d-flex justify-end">
          <td>
            {{ item.fecha.substring(0, 10) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.valor_adicional="{ item }">
        <tr class="d-flex justify-end">
          <td class="text-center text-caption blue-grey--text text--darken-1">
            {{ convertToMoney(item.valor_adicional) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.descuento="{ item }">
        <tr class="d-flex justify-end">
          <td class="text-center text-caption blue-grey--text text--darken-1">
            {{ convertToMoney(item.descuento) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.iva="{ item }">
        <tr class="d-flex justify-end">
          <td class="text-center text-caption blue-grey--text text--darken-1">
            {{ convertToMoney(item.iva) }}
          </td>
        </tr>
      </template>

      <!-- <template v-slot:item.estado="{ item }">
        <tr class="d-flex justify-end">
          <td class="text-center text-caption blue-grey--text text--darken-1">
            {{ item.estado }}
          </td>
        </tr>
      </template>

      <template v-slot:item.transportista="{ item }">
        <tr class="d-flex justify-center">
          <td class="text-center text-caption blue-grey--text text--darken-1">
            {{ item.transportista }}
          </td>
        </tr>
      </template> -->

      <!-- <template v-slot:item.tipo_ajuste="{ item }">
        <tr class="d-flex justify-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <td
                v-bind="attrs"
                v-on="on"
                class="text-center text-caption blue-grey--text text--darken-1">
                {{ item.tipo_ajuste }}
              </td>
            </template>
            <span>{{ item.descripcion_detallada }}</span>
          </v-tooltip>
        </tr>
      </template> -->
    </BaseWindowList>

    <!-- MAIN DIALOG -->
    <FormDocumentoVue
      v-if="dialog"
      title="Crear Ajuste"
      :companyTitle="`Transportes Juma`"
      :headers="headers"
      :headersDetalle="headersDetalles"
      tipoModulo="AD"
      modulo="AjusteCabecera"
      fieldCabeceraName="ajuste_cabecera_id"
      urlFormEncabezado="transjuma/AjusteCabecera/"
      urlFormDetalle="transjuma/AjusteDetalle/"
      :modoEdicion="themodeEdicion"
      :cabeceraDocumento="editedItem"
      v-on:update-List="resetList"
      v-on:cierre-form="close"

      :customValidate="validarEstado"
    >

      <template v-slot:item.guia="{ item }">
        <tr class="d-flex justify-end">
          <td>
            {{ item.guia.numero }}
          </td>
        </tr>
      </template>

      <template v-slot:item.transportista="{ item }">
        <tr class="d-flex justify-center">
          <td>
            {{ item.transportista.nombre }}
          </td>
        </tr>
      </template>

      <template v-slot:item.tipo_ajuste="{ item }">
        <tr class="d-flex justify-center">
          <td>
            {{ item.tipo_ajuste.nombre }}
          </td>
        </tr>
      </template>

      <template v-slot:item.valor_adicional="{ item }">
        <tr class="d-flex justify-end pr-4">
          <td>
            {{ $convertToMoney(item.valor_adicional) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.descuento="{ item }">
        <tr class="d-flex justify-end pr-4 red--text">
          <td>
            {{ $convertToMoney(item.descuento) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.iva="{ item }">
        <tr class="d-flex justify-end pr-4">
          <td>
            {{ $convertToMoney(item.iva) }}
          </td>
        </tr>
      </template>

    </FormDocumentoVue>

    <!-- DIALOG DELETE -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >¿Está seguro de borrar este destino y tonelada?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete"
            >Cancel</v-btn
          >
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CUADRO DIALOGO BUSQUEDA AVANZADA -->
    <BusquedaAvanzada
      nameItemStore="searchAjustes"
      v-on:advance-search="onAdvanceSearch"
    ></BusquedaAvanzada>
  </div>
</template>

<script>
import { axiosC } from "../api/axiosbd";
import BaseWindowList from "@/components/BaseWindowList";
import { paginationMethods } from "../mixins/pagination";
import BaseAutoComp from "../components/BaseAutoComplet.vue";
import FormDocumentoVue from "../components/FormDocumento.vue";
import BusquedaAvanzada from "../components/BusquedaAvanzada.vue"


export default {
  components: {
    BaseWindowList,
    BaseAutoComp,
    FormDocumentoVue,
    BusquedaAvanzada
  },
  mixins: [paginationMethods],
  data() {
    return {
      loading: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "CC", value: "cc" },
        { text: "Guia", value: "numero_guia" },
        { text: "Estado", value: "estado", align: "center" },
        {
          text: "Fecha",
          value: "fecha",
          form: true,
          vdate: true,
          require: true,
          colmd: 3,
        },
        // { text: 'Sede', value: 'sede' },
        { text: "Cod. Asign", value: "ca", align: "center" },
        { text: "Tipo Ajuste", value: "tipo_ajuste", align: "center" },
        // { text: 'Destino', value: 'tipo_ajuste', align: 'center', width: '140' },
        {
          text: "Transportista",
          value: "transportista",
          width: "260",
          align: "center",
        },
        { text: "Precio", value: "valor_adicional", align: "end" },
        { text: "Descuento", value: "descuento", align: "end" },
        { text: "IVA", value: "iva", align: "end" },
        // { text: 'Fecha proforma', value: 'fecha_proforma' },
        { text: "Lt proforma", value: "lote_proforma", align: "center" },
        // { text: 'Comentario', value: 'comentario' },
        // { text: 'Fecha de factura', value: 'fecha_factura' },
        // { text: 'No. de factura', value: 'no_factura' },
        // { text: 'Referencia de pago', value: 'ref_pago' },
        // { text: 'No. de orden de compra', value: 'no_orden_compra' },
        // { text: 'Fecha de pago', value: 'fecha_pago' },
        // { text: 'Fecha de pago recibido', value: 'fecha_pago_recibido' },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headersDetalles: [
        {
          text: "Fecha ajuste",
          value: "fecha",
          form: true,
          vdate: true,
          require: true,
          colmd: 2,
          showInnerDetail: false,
        },
        {
          text: "Guia",
          value: "guia",
          form: true,
          specialAuto: true,
          require: true,
          colmd: 5,
          align: 'center',
          itemSpecialAuto: {
            textoBusqueda: "Guias ...",
            label: "Guia",
            itemText: ["id", "numero", "destino"],
            advanceSearchFields: {
              "a.numero": null,
              "b.destino": null,
            },
            rutaItemAuto: `transjuma/GuiaSelect`,
            rutaItemAutoEdit: `transjuma/Guia/`, //opcion cuando la ruta es cambiada originalemte del viewset normal
          },
          showInnerDetail: true,
          innerTextName: 'numero'
        },
        {
          text: "Transportista",
          value: "transportista",
          form: true,
          vauto: true,
          require: true,
          colmd: 3,
          vautoName: [],
          itemText: "nombre",
          vfunctname: "",
          showInnerDetail: true,
          innerTextName: 'nombre'
        },
        {
          text: "Tipo Ajuste",
          value: "tipo_ajuste",
          form: true,
          vauto: true,
          require: true,
          colmd: 3,
          align: 'center',
          autoName: [],
          itemText: "nombre",
          vfunctname: "updatePrecio",
          showInnerDetail: true,
          innerTextName: 'nombre'
        },
        {
          text: "Precio",
          value: "valor_adicional",
          form: true,
          vtext: true,
          require: true,
          colmd: 3,
          showInnerDetail: true,
        },
        {
          text: "Descuento",
          value: "descuento",
          form: true,
          vtext: true,
          require: true,
          colmd: 3,
          showInnerDetail: true,
        },
        {
          text: "iva",
          value: "iva",
          form: true,
          vtext: true,
          require: true,
          colmd: 3,
          showInnerDetail: true,
          align: 'center'
        },
        {
          text: "Descripción detallada",
          value: "descripcion_detallada",
          form: true,
          vtext: true,
          require: true,
          colmd: 3,
        },
        {
          text: "Referencia de Pago",
          value: "ref_pago",
          form: true,
          vtext: true,
          require: false,
          colmd: 3,
        },
        {
          text: "No. Orden de Compra",
          value: "no_orden_compra",
          form: true,
          vtext: true,
          require: false,
          colmd: 3,
        },
        {
          text: "Fecha de pago",
          value: "fecha_pago",
          form: true,
          vdate: true,
          require: true,
          colmd: 3,
        },
      ],
      desserts: [],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {},
      deletedItem: {},
      defaultItem: {},
      tipos: [
        { id: "F", text: "FIJO" },
        { id: "V", text: "VARIABLE" },
      ],
      mode: "POST",

      //
      menuPickers: {
        fecha: false,
        fecha_proforma: false,
        fecha_fcatura: false,
        fecha_pago: false,
        fecha_pago_recibido: false,
      },
      keyPrecio: 0,
      keyDescuento: 0,
      keyIva: 0,
      sedes: [{ id: 1, nombre: "Pavas" }],
      transportistas: [],
      estados: [],
      destinos: [],
      tipoAjustes: [],
      procesoCompleto: false,
      themodeEdicion: false,
      activeAdvanceSearch: false,
      queryAdvanceSearch: {}
    };
  },
  methods: {
    infoExcel(item){
      console.log(item)
      //debe ir al back y traer todas las guias de la opcion filtrada, con mejor detalle
      // ahora mismo solo esta trayendo 20
      // mejor hacer el excel en el back y que venga listo
      const fileName = `Info Ajustes filtrada.xlsx`
      var ws = utils.json_to_sheet(item)
      var wb = utils.book_new()
      utils.book_append_sheet(wb, ws, 'test')
      writeFile(wb, fileName)
    },
    resetInfo(){
      this.resetList()
    },
    onAdvanceSearch(item){
      console.log(item)
      const page = 1;
      const size = 20;
      this.nameItemStore = "desserts"
      this.queryAdvanceSearch = {}
      this.queryAdvanceSearch.list = true
      this.queryAdvanceSearch.onAdvanceSearch = true

      let valid = true

      if (item.fecha1 && item.fecha2 && item.rango){
        this.queryAdvanceSearch.fecha_bw = [item.fecha1, item.fecha2]
      }
      if (item.fecha1 && !item.rango) {
        this.queryAdvanceSearch.fecha_e = item.fecha1
      }
      if (item.fecha1 && !item.fecha2 && item.rango ) {
        this.queryAdvanceSearch.fecha_gt = item.fecha1
      }
      if (!item.fecha1 && item.fecha2 && item.rango ) {
        this.queryAdvanceSearch.fecha_lt = item.fecha2
      }
      if (item.transportista && item.transportista.length > 0){
        this.queryAdvanceSearch.transportista_id = item.transportista
      }
      if (item.id) {
        this.queryAdvanceSearch.guia_id = item.id
      }
      this.activeAdvanceSearch = true
      this.currentPage = 1
      console.log(this.queryAdvanceSearch)
        this.getDataGeneral(
          "GET",
          `transjuma/AjusteDetalle/`,
          'desserts',
          "loading",
          {},
          true, //paginacion
          false, // opcion para que devuelva algo
          false, //modo normal activado, vienen todos los resultados
          null, // parametros query
          false, //unico registro
          this.queryAdvanceSearch
        )

    },
    itemTextTransportista(item) {
      if (item) {
        const text = this.transportistas.find((y) => y.id == item);
        return text ? text.nombre : "";
      }
    },
    itemTextEstado(item) {
      if (item) {
        const text = this.estados.find((y) => y.id == item);
        return text ? text.nombre : "";
      }
    },
    itemTextTipoAjuste(item) {
      if (item) {
        const text = this.tipoAjustes.find((y) => y.id == item.id);

        return text ? `${text.nombre}` : "";
      }
    },
    siguientePagina(item) {
      // this.gotoPage(item, this.$route.meta.backRoute);

      let query = {}
      if (this.activeAdvanceSearch){
        query = {...this.queryAdvanceSearch}
      } else {
        query =  {list:true}
      }

      this.gotoPage(item, this.$route.meta.backRoute, query)
    },
    openForm() {
      console.log("ABRIENDO");
      this.updateAutoCompletesDetalle
      this.dialog = true;
    },

    convertToMoney(x) {
      // Verificar si el valor es nulo, indefinido o no es un número
      if (x === undefined || x === null || isNaN(Number(x))) {
        return "0.00";
      }
      // Convertir a número y luego aplicar toFixed(2)
      x = Number(x).toFixed(2);

      // Aplicar formato de separador de miles
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    clearSelects() {
      for (let field in this.defaultItem) {
        if (this.defaultItem[field].value) this.defaultItem[field].value = null;
        if (this.defaultItem[field].values) this.defaultItem[field].values = [];
      }
    },
    close() {
      console.log('CERRANDO FORM')
      this.dialog = false;
      this.mode = "POST";
      this.editedItem = {};
      this.themodeEdicion =  false
    },
    closeDelete() {
      this.dialogDelete = false;

      this.$nextTick(() => {
        this.clearSelects();
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      console.log(item);
      this.deletedItem = { ...item };
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.loading = true;
      await this.$store.dispatch("AuthModule/verifyToken");

      axiosC({
        method: "DELETE",
        url: `transjuma/AjusteDetalle/${this.deletedItem.id}/`,
        headers: {
          Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
        },
      })
        .then(() => {
          this.getDataGeneral(
            "GET",
            `transjuma/AjusteDetalle/`,
            this.nameItemStore,
            "loading",
            {},
            true,
            true
          );
          this.closeDelete();
        })
        .catch((err) => {
          this.loading = false;
          alert(err.response.data.message);
        });
    },
    editItem(item) {
      console.log('EDITAR',item);
      this.dialog = true;
      this.mode = "PUT";
      this.editedItem = { ...item }
      this.themodeEdicion = true
      this.updateAutoCompletesDetalle
      

      // this.$store.state.transportistas = this.transportistas
      // this.$store.state.tipoAjustes = this.tipoAjustes
    },
    validarEstado(item){
      if (item.estado_guia.nombre == 'Flete') {
        return true
      } else {
        return false
      }
    }, 
    updateList() {
      this.getDataGeneral(
        "GET",
        `transjuma/TransportistaList`, //cuando son rutas de listas completas sin el /
        "transportistas",
        "",
        {},
        false, //paginacion
        false, // opcion para que devuelva algo
        true //modo normal activado, vienen todos los resultados
      );
     
      this.getDataGeneral(
        "GET",
        `transjuma/TipoAjuste/`,
        "tipoAjustes",
        "",
        {},
        false, //paginacion
        false, // opcion para que devuelva algo
        true //modo normal activado, vienen todos los resultados
      );
    },
    resetList(){
      this.currentPage = 1
      this.activeAdvanceSearch = false
      this.queryAdvanceSearch = {}
      this.getDataGeneral(
        "GET",
        `transjuma/AjusteDetalle/`,
        this.nameItemStore,
        "loading",
        {},
        true, //paginacion
        false, // opcion para que devuelva algo
        false, //modo normal activado, vienen todos los resultados
        null, // parametros query
        false, //unico registro
        {list:true}
      );
    }
  },
  computed: {
    checkMode() {
      return this.mode == "PUT" ? true : false;
    },
    formTitle() {
      if (this.editedIndex === -1) {
        return `Nuevo destino y tonelada`;
      } else {
        return `Edición de destino y tonelada`;
      }
    },
    updateAutoCompletesDetalle() {
      let headerDetalleTransportista = this.headersDetalles.find(
        (item) => item.value == "transportista"
      );
      if (headerDetalleTransportista) {
        headerDetalleTransportista.vautoName = [...this.transportistas];
      }

      let headerDetalletipoAjustes = this.headersDetalles.find(
        (item) => item.value == "tipo_ajuste"
      );
      if (headerDetalletipoAjustes) {
        headerDetalletipoAjustes.vautoName = [...this.tipoAjustes]
      }

      // console.log(this.headersDetalles);
    },
  },
  created() {
    const page = 1;
    const size = 20;
    this.nameItemStore = "desserts";
    this.resetList()
    this.updateList()
  },
};
</script>
