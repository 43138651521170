<template>
  <div>
    <v-data-table
      dense
      disable-sort
      :headers="headers"
      :items="records"
      :search="search"
      class="elevation-1 rounded-xl px-2"
      :loading="loading"
      :items-per-page="20"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat>
          <div class="d-flex flex-column mt-5">
            <v-toolbar-title>{{ `Lista de Guias Historico` }}</v-toolbar-title>
            <div class="d-flex text-caption flex-column mb-5">
              <p class="ma-0 pa-0">CA:Codigo Asignacion, OC: Orden de Compra</p>
              <p class="ma-0 pa-0">DT:Destino Tonelada</p>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn small rounded color="info" @click="openSearch">Filtrado</v-btn>
          <v-btn small rounded color="success" v-if="activeAdvanceSearch" @click="infoExcel" >excel</v-btn>
          <v-btn small rounded color="error"  v-if="activeAdvanceSearch" @click="resetInfo">reset</v-btn>

        </v-toolbar>
      </template>
    </v-data-table>
    <v-pagination
        v-model="currentPage"
        :length="totalPages"
        total-visible="8"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        circle
        @input="siguientePagina"
      ></v-pagination>
    
    <!-- CUADRO DIALOGO BUSQUEDA AVANZADA -->
    <BusquedaAvanzada
      nameItemStore="searchGuias"
      v-on:advance-search="onAdvanceSearch"
    ></BusquedaAvanzada>
  </div>
</template>

<script>
import { paginationMethods } from "../mixins/pagination";
import BusquedaAvanzada from "../components/BusquedaAvanzada.vue"
export default {
  components: {BusquedaAvanzada},
  mixins:[paginationMethods],
  data() {
    return {
      search: "",
      headers: [
        { text: 'Número', value: 'numero'},
        { text: 'Fecha', value: 'fecha' },
        // { text: 'Sede', value: 'sede' },
        { text: 'CA', value: 'ca' },
        { text: 'DT', value: 'destino_tonelada'},
        { text: 'Transportista', value: 'transportista', width: 250},
        { text: 'Precio', value: 'precio' },
        { text: 'Descuento', value: 'descuento' },
        { text: 'IVA', value: 'iva' },
        { text: 'Fec.proforma', value: 'fecha_proforma' },
        { text: 'Lote proforma', value: 'lote_proforma' },
        { text: 'Estado de guía', value: 'estado_guia', width: 150 },
        { text: 'Comentario', value: 'comentario' },
        { text: 'Fec.factura', value: 'fecha_factura' },
        { text: 'No.factura', value: 'no_factura' },
        { text: 'Ref pago', value: 'ref_pago', width: 220 },
        { text: 'OC', value: 'no_orden_compra' },
        { text: 'Fec.pago', value: 'fecha_pago' },
        { text: 'Fec.pago recibido', value: 'fecha_pago_recibido' },
      ],
      records: [],
      loading: false,
      nameItemStore: 'records',
      activeAdvanceSearch: false,
      queryAdvanceSearch: {}
    }
  },
  methods: {
    openSearch(){
      this.$store.state.searchGuias= true
    },
    infoExcel(item){
      console.log(item)
    },
    resetInfo(){
      this.getDataGuias()
    },
    onAdvanceSearch(item){
      console.log(item)
      this.queryAdvanceSearch = {}
      this.queryAdvanceSearch.list = true
      this.queryAdvanceSearch.onAdvanceSearch = true

      let valid = true

      if (item.fecha1 && item.fecha2 && item.rango){
        this.queryAdvanceSearch.fecha_bw = [item.fecha1, item.fecha2]
      }
      if (item.fecha1 && !item.rango) {
        this.queryAdvanceSearch.fecha_e = item.fecha1
      }
      if (item.fecha1 && !item.fecha2 && item.rango ) {
        this.queryAdvanceSearch.fecha_gt = item.fecha1
      }
      if (!item.fecha1 && item.fecha2 && item.rango ) {
        this.queryAdvanceSearch.fecha_lt = item.fecha2
      }
      if (item.transportista && item.transportista.length > 0){
        this.queryAdvanceSearch.transportista_id = item.transportista
      }
      if (item.id) {
        this.queryAdvanceSearch.id = item.id
      }
      this.activeAdvanceSearch = true
      this.currentPage = 1

      this.getDataGeneral(
        "GET",
        `transjuma/Guia/`,
        'records',
        "loading",
        {},
        true, //paginacion
        false, // opcion para que devuelva algo
        false, //modo normal activado, vienen todos los resultados
        null, // parametros query
        false, //unico registro
        this.queryAdvanceSearch
      )
    },
    siguientePagina(item) {
      let query = {}
      if (this.activeAdvanceSearch){
        query = {...this.queryAdvanceSearch}
      } else {
        query =  {
          list: true
        }
      }
      this.gotoPage(item, `transjuma/Guia/`, query);
    },
    getDataGuias(){
      this.currentPage = 1
      this.activeAdvanceSearch = false
      this.queryAdvanceSearch = {}
      let query ={}
      query.list = true

      this.getDataGeneral(
        "GET",
        `transjuma/Guia/`,
        'records',
        "loading",
        {},
        true, //paginacion
        false, // opcion para que devuelva algo
        false, //modo normal activado, vienen todos los resultados
        null, // parametros query
        false, //unico registro
        query
      )

    }
  },
  computed: {
  },
  created() {
    this.getDataGuias()

  }
}
</script>