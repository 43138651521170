<template>
  <div class="window-card">
    <v-row dense align="center">
      <v-col cols="12" md="12">
        <v-dialog v-model="dialog" :max-width="customWidth" :persistent="persistentDialog"
          @click:outside="clickOutsideDialog">
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl">
            <slot name="title"></slot>
            <slot name="subTitle"></slot>
            <slot name="cardText"></slot>
            <slot name="cardAction"></slot>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    customWidth: {
      type: String,
      require: false,
      default: '700px'
    },
    persistentDialog: {
      type: Boolean,
      require: false,
      default: true
    },
    isLoading: {
      type: Boolean,
      require: true,
      default: false
    }
  },
  data() {
    return {
      dialog: true,
    }
  },
  methods: {
    clickOutsideDialog() {
      console.log('sali')
      this.dialog = true
      this.$emit('sali', false)
    }
  }

}
</script>

<style></style>