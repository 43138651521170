import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {Transfer, Popover, Message, MessageBox }  from 'element-ui';
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

locale.use(lang)
Vue.config.productionTip = false
Vue.use(Transfer);
Vue.use(Popover);
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;


Vue.prototype.$getTokenPy = async function () {
  try {
    const response = await axios.post(
      "https://connect.webdgroup.com/api/token/",
      {
        username: "wafront",
        password: "bmpK@!2#Iob%w*0zd$zU7pDvdJYR5W9Q$JeUOsboxtJNMYmX%w",
      }
    );
    return response.data.access
  } catch (error) {
    console.error(error);
  }
}

// Vue.prototype.$convertToMoney = function (x) {
//   // Verificar si el valor es nulo, indefinido o no es un número
//   if (x === undefined || x === null || isNaN(Number(x))) {
//     return "0.00";
//   }
//   // Convertir a número y luego aplicar toFixed(2)
//   x = Number(x).toFixed(2);

//   // Aplicar formato de separador de miles
//   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }

Vue.prototype.$convertToMoney = function(x, abbreviate = false, fix=2) {
  // Verificar si el valor es nulo, indefinido o no es un número
  if (x === undefined || x === null || isNaN(Number(x))) {
    return "0";
  }

  // Convertir a número
  x = Number(x);

  // Abreviar el número si se requiere
  if (abbreviate) {
    const abbreviations = ["", "k", "m", "b", "t"];
    const tier = Math.floor(Math.log10(Math.abs(x)) / 3);

    if (tier > 0 && tier < abbreviations.length) {
      const divisor = Math.pow(10, tier * 3);
      const abbreviated = (x / divisor).toFixed(Math.max(0, tier - 1));
      x = `${abbreviated}${abbreviations[tier]}`;
    } else {
      x = x.toFixed(0);
    }
  } else {
    // Aplicar formato de separador de miles y dos decimales
    x = x.toFixed(fix);
    x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return x;
};

Vue.prototype.$dayLabels = function(year, month) {
  let monthIndex = month - 1 // 0..11 instead of 1..12
  let names = [ 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat' ]
  let date = new Date(year, monthIndex, 1)
  let result = []
  while (date.getMonth() == monthIndex) {
    // result.push(date.getDate() + '-' + names[date.getDay()]);
    result.push(date.getDate())
    date.setDate(date.getDate() + 1)
  }
  return result
}

Vue.prototype.$capitalize = function (x) {
  if (x){
    return x.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
  } 
  else {
    return ''
  } 
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
