<template>
    <div class="base-window">
      <!-- Cuadro de busqueda -->
      <v-row dense align="center" justify="center">
        <v-col cols="12" md="12">
          <v-dialog  v-model="dialogBase" :max-width="customWidth ? customWidth : '700px'" persistent>
            <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
              <v-card-title class="d-flex justify-space-between">
                <p class="mb-0">{{title}}</p>
                <v-btn
                  icon
                  @click="closeWindow(closeBaseWindow)"
                >
                  <v-icon color="red" >
                    mdi-close-circle
                  </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-subtitle class="pb-1">
                {{company}}
              </v-card-subtitle>
  
              <v-card-text>
                <v-container fluid class="px-0 pt-1">
                  <!-- linea del filtrado -->
                  <v-row dense align="start">
                    <v-col cols="12" md="4">
                      <div>
                        <v-btn
                          icon
                          @click="openAgregar"
                        >
                          <v-icon color="primary" samll class="mr-2">
                            mdi-plus
                          </v-icon>
                        </v-btn>
                        <span>{{titleAddMethod}}</span>
                      </div>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-text-field
                        dense
                        class=""
                        v-model="searchDocument"
                        append-icon="mdi-magnify"
                        label="Filtrado"
                        hide-details
                      ></v-text-field>
                    </v-col>
  
                  </v-row>
  
                  <v-row dense>
                    <v-col cols="12" md="12">
                      <v-data-table
                        dense
                        :headers="headers.filter(item => item.require)"
                        :items="itemsBaseWindowList"
                        :search="searchDocument"
                        disable-sort
                        :loading="loading"
                        @dblclick:row="dobleClick"
                      >
                      <!-- pass through scoped slots -->
                      <template
                        v-for="(_, scopedSlotName) in $scopedSlots"
                        v-slot:[scopedSlotName]="slotData"
                      >
                        <slot :name="scopedSlotName" v-bind="slotData" />
                      </template>

                      <!-- eslint-disable-next-line -->
                      <template v-slot:item.actions="{ item }">
                        <!-- <v-btn
                          icon
                          @click="openAgregarListado('addFactura')"
                        >
                          <v-icon color="primary" samll class="mr-2">
                            mdi-plus
                          </v-icon>
                        </v-btn> -->
  
                        <v-btn
                          small
                          v-if="activarEdicion"
                          icon
                          @click="openEditar(item)"
                        >
                          <v-icon color="warning" small class="mr-2" >
                            mdi-pencil
                          </v-icon>
                        </v-btn>
  
                        <v-btn
                          small
                          v-if="activarBorrar"
                          icon
                          @click="openEliminar(item)"
                        >
                          <v-icon color="red" small>
                            mdi-{{'delete'}}
                          </v-icon>
                        </v-btn>
  
                        <v-tooltip top  v-if="activarAdicional1">
                          <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              small
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="activarAdicional1 ? callMethod(`${adicional1['methodo']}`, item): False"
                            >
                              <v-icon :color="activarAdicional1 ? adicional1['color'] : 'primary'" small>
                                mdi-{{activarAdicional1 ? `${adicional1['nombreIcono']}`: 'home'}}
                              </v-icon>
                            </v-btn>
                          </template>
                        <span>{{activarAdicional1 ? adicional1.nombre : ''}}</span>
                      </v-tooltip>
  
                      </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
  
      <!-- FORM -->
      <v-row dense align="center" justify="center">
        <v-col cols="12" md="12">
          <v-dialog  v-model="dialogForm" :max-width="customWidthForm ? customWidthForm : '500px'" persistent v-if="activarInternalForm">
            <v-card class="rounded-xl" :loading="isLoading" :disabled="isLoading">
                <v-container fluid>
                  <v-form
                    ref="multiForm"
                    lazy-validation
                  >
                    <!-- TITULO FORM Y BOTON CERRAR -->
                    <v-card-subtitle class="d-flex justify-space-between pb-1 align-center">
                      <strong>{{actionEdit ? `${formProperties.edicion}` : `${formProperties.creacion}`}}</strong>
                      <v-btn
                        icon
                        @click="closeForm"
                      >
                        <v-icon color="red" >
                          mdi-close-circle
                        </v-icon>
                      </v-btn>
                    </v-card-subtitle>
                    <div class="slot-titulo-form pl-4 mb-0 text--accent-1">
                      <slot name="header-subtitutle"></slot>
                    </div>
                    <!-- <p style="font-size: 12px;" class="pl-4 mb-0 text--accent-1" v-if="contratoSelected">{{contratoSelected.descripcion}}</p>
                    <p style="font-size: 12px;" class="pl-4 mb-0 text--caption" v-if="contratoSelected">
                      {{`Fecha: ${contratoSelected && contratoSelected.fecha ? contratoSelected.fecha.substring(0,10) : ''} // Termina en: ${calculoPlazo(contratoSelected)}`}}</p>
                    <p style="font-size: 12px;" class="pl-4 mb-0 text--caption" v-if="contratoSelected">{{`Fecha de pagos: ${contratoSelected.fecha_pago.substring(0,10)}, ${parseInt(contratoSelected.fecha_pago.substring(8,10))} de cada mes.`}}</p> -->
  
                    <!-- INPUTS DEL FORMULARIO -->
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12"
                          v-for="(header, i) in headersFilter"
                          :key="i"
                          :md="header.colmd"
                          class="d-flex align-center justify-center"
                          >
                          <v-btn
                            v-if="header.vicon"
                            small
                            icon
                            color="primary"
                            @click="metodoIcono(header.textComponent)"
                          >
                            <v-icon >{{header.text}}</v-icon>
                          </v-btn>
                          <v-textarea
                            v-if="header.vtextarea"
                            v-model="multiForm[header.value]"
                            :label="header.text"
                            dense
                            outlined
                            class="rounded-lg"
                            hide-details
                            rows="6"
                          ></v-textarea>
                          <v-text-field
                            v-if="header.vtext"
                            v-model="multiForm[header.value]"
                            :label="header.text"
                            dense
                            outlined
                            class="rounded-lg"
                            hide-details
                            :rules="header.require ? [v => !!v || 'Campo es requerido'] : []"
                            :type="header.type ? header.type : 'text'"
                          ></v-text-field>
                          <v-autocomplete
                            v-if="header.vauto"
                            v-model="multiForm[header.value]"
                            :label="header.text"
                            :items="$store.state[header.vautoName]"
                            item-value="id"
                            item-text="descripcion"
                            dense
                            outlined
                            clearable
                            class="rounded-lg"
                            hide-details
                            :rules="header.require ? [v => !!v || 'Campo es requerido'] : []"
                            @input="inputAuto(header.inputauto, multiForm[header.value], $store.state[header.vautoName], header.formfield)"
                          ></v-autocomplete>
                          <v-combobox
                            v-if="header.vcombo"
                            v-model="multiForm[header.value]"
                            :label="header.text"
                            :items="header.hasgetter ? $store.getters[header.vcomboName] :$store.state[header.vcomboName]"
                            dense
                            outlined
                            class="rounded-lg"
                            hide-details
                            :rules="header.require ? [v => !!v || 'Campo es requerido'] : []"
                            multiple
                            chips
                          ></v-combobox>
                          <v-menu
                            v-if="header.vdate"
                            v-model="fecha[header.value]"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                class="text-body-2 custom-form-text-fields rounded-lg"
                                dense
                                v-model="multiForm[header.value]"
                                :label="header.text"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="multiForm[header.value]"
                              @input="fecha[header.value] = false"
                            ></v-date-picker>
                          </v-menu>
  
                          <v-menu
                            v-if="header.vtime"
                            ref="menu"
                            v-model="tiempo[header.value]"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            class="pt-1"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="multiForm[header.value]"
                                :label="header.text"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                                class="pt-1 mb-2"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="tiempo[header.value]"
                              v-model="multiForm[header.value]"
                              full-width
                              @click:minute="tiempo[header.value] = false"
                            ></v-time-picker>
                          </v-menu>
                        </v-col>
  
                        <v-col cols="12" md="12" v-if="specialPost">
                          <v-container fluid class="d-flex justify-start align-center px-0">
                            <v-file-input
                              dense
                              v-model="files"
                              :loading="isLoading"
                              :disabled="isLoading"
                              placeholder="Subir archivo"
                              label="Archivo"
                              prepend-icon="mdi-paperclip"
                              counter
                              show-size
                            >
                              <template v-slot:selection="{ text }">
                                <v-chip small label color="primary" >
                                  {{ text }}
                                </v-chip>
                              </template>
                            </v-file-input>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  small
                                  rounded
                                  class="ma-2"
                                  color="primary"
                                  fab
                                  :loading="isLoading"
                                  :disabled="isLoading"
                                  @click="uploadFile('isLoading')"
                                  v-on="on"
                                  v-bind="attrs"
                                >
                                  <v-icon dark> mdi-cloud-upload </v-icon>
              
                                </v-btn>
                              </template>
                              <span>{{titleAddMethodBoton}}</span>
                            </v-tooltip>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-card-text>
  
                    <!-- ACCIONES EDITAR Y BORRAR -->
                    <v-card-actions class="px-4">
                      <v-row dense align="center" justify="end" class="">
                        <v-col cols="12" md="3" class="d-flex justify-end">
                          <v-btn 
                            v-if="actionEdit"
                            small 
                            class="rounded-xl" 
                            color="warning"
                            @click="editar"
                          >
                            Modificar
                          </v-btn>
                          <v-btn
                            v-if="!actionEdit && !titleAddMethodBoton"
                            small 
                            class="rounded-xl"
                            color="primary"
                            @click="agregar"
                          >
                            Crear
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-form>
                </v-container>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
  
      <!-- INSERTAR -->
      <slot name="other"></slot>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex'
  import {mainUrl } from '@/api/axiosbd'
  import { paginationMethods } from '@/mixins/pagination'
  import { saveAs } from 'file-saver';
  export default {
    name: 'BaseWindowListGeneral',
    mixins: [],
    props:{
      customWidth: {
        type: Number,
        required: false
      },
      customWidthForm: {
        type: Number,
        required: false
      },
      headers: {
        type: Array,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      titleAddMethodBoton: {
        type: String,
        required: false
      },
      formProperties: {
        type: Object,
        required: false,
        default() {
          return {
            creacion: 'Creacion de ...',
            edicion: 'Editar ...',
            urlPostAdjunto: '',
            board: ''
          }
        }
      },
      company:{
        type: String,
        required: true
      },
      addMethod:{
        type: String,
        required: true
      },
      addAuto:{
        type: Boolean,
        required: false,
        default: false
      },
      addAutoEditar:{
        type: Boolean,
        required: false,
        default: false
      },
      titleAddMethod:{
        type: String,
        required: true
      },
      itemStoreName:{
        type: String,
        required: true
      },
      closeBaseWindow:{
        type: String,
        required: true
      },
      activarFiltrado:{
        type: Boolean,
        required: false,
        default: false
      },
      activarEdicion:{
        type: Boolean,
        required: false
      },
      activarBorrar:{
        type: Boolean,
        required: false
      },
      activarAdicional1:{
        type: Boolean,
        required: false
      },
      adicional1:{
        type: Object,
        required: false
      },
      loading:{
        type: Boolean,
        required: false,
        default: false
      },
      activarInternalForm: {
        type: Boolean,
        required: false,
        default: false
      },
      specialPost:{
        type: Boolean,
        required: false,
        default: false
      },
      contratoSelected:{
        type: Object,
        require: false,
      }
  
  
    },
    
    data() {
      return {
        multiForm: {},
        fecha:{...this.headers.filter(item => item.vdate)},
        tiempo:{...this.headers.filter(item => item.vtime)},
        files: null,
        loader: null,
        validFormBase: false,
        isLoading: false,
        dialogBase: true,
        dialogForm: false,
        searchDocument: '',
        loadingitems: [],
        count: 0,
        loadingTable: false,
        actionEdit: false,
      }
    },
    computed:{
      // ...mapState([
      //   'itemsUsuarios'
      // ]),
      headersFilter(){
        if (this.headers){
          return this.headers.filter(h=> h.form == true)
        } else {
          return []
        }
      },
  
      headersAlerts(){
        if (this.headers){
          return this.headers.filter(h=> h.alert == true)
        } else {
          return []
        }
      },
      itemsBaseWindowList(){
        return this.$store.state[this.itemStoreName]
      },
    },
    methods:{
      metodoIcono(item){
        this.$store.state[item] = true
        this.$emit('metodo-icono', item)
      },
      // itemTextUsuarios(item){
      //   const user = this.itemsUsuarios.find(user => user.id == item)
      //   return `${user.username}`
      // },
  
      inputAuto(activar, selected, items, formField){
        if (activar) {
          const select = items.find(k => k.id == selected)
          if (select) {
            this.multiForm[formField] =`${select.texto_mensaje}`
            console.log(this.multiForm)
          }
        }
      },
      // calculoPlazo(item){
      //   this.fecha = false
      //   if (item.plazo_meses && item.fecha){
      //     let fecha = DateTime.fromISO(item.fecha).setZone('America/Costa_Rica')
      //     fecha= fecha.plus({months: item.plazo_meses}).toISODate()
      //     console.log(fecha)
      //     // console.log('calcular fecha final', fecha.plus({months: this.plazo}))
      //     return fecha
      //   } else {
      //     console.log('faltan valores')
      //     return ''
      //   }
      // },
      closeWindow(text){
        this.$store.state[text] = false
        this.$emit('close-window', false)
      },
      openAgregarListado(text){
        this.$store.state[text] = true
      },
      openEditar(item){
        this.actionEdit = true
        this.$emit('edit-row', item)
        this.dialogForm = true
  
        this.selectedEditItem = {...item}
        const keysToCopy = this.headers.filter(item => item.form == true).map(item => item.value)
  
        this.multiForm = Object.assign({}, ...keysToCopy.map(key => ({ [key]: item[key] })))
  
      },
      editar(){
        const url = `${this.formProperties.url}${this.selectedEditItem.id}/`
        const body = {
          ...this.multiForm,
          updated_by: this.$store.state.AuthModule.refu,
          created_by: this.$store.state.AuthModule.refu,
          company: 1,
        }
        this.getData(url, 'PUT', body )
  
      },
      openAgregar(){
        this.dialogForm = true
        this.actionEdit = false
        this.$emit('agregar-base-window')
      },
      agregar(){
        if(this.specialPost){
          console.log('soy especial')
          this.uploadFile('')
        } else {
          if(this.$refs.multiForm.validate()){
            const url = `${this.formProperties.url}`

            const typeFilter = this.headers.filter(reg => reg.type)
            console.log(typeFilter)

            if (typeFilter){
              for (let item of typeFilter){
                if (item.type == 'number') this.multiForm[item.value] = parseInt(this.multiForm[item.value])
              }

            }

            const body = {
              ...this.multiForm,
              updated_by: this.$store.state.AuthModule.refu,
              created_by: this.$store.state.AuthModule.refu,
              company: 1,
            }
            console.log('BODY AGREGAR',body)
            this.getData(url, 'POST', body )
          }
        }
      },
      async uploadFile(loader) {
        console.log(this.contratoSelected)
        if (this.$refs.multiForm.validate()) {
          if (this.files) {
  
            this.loader = loader
            this.loaderEfect('A')
            const body = {
              enlace_id : this.contratoSelected.id,
              adjuntar: 'adjuntar',
              nombre: this.files.name,
              extension: Object.keys(mimeTypes).find(key => mimeTypes[key] === this.files.type),
              mimeType: this.files.type,
              tamano: this.files.size,
              ...this.multiForm
            }
  
            let data = await new FormData(); // creates a new FormData object
            data.append("file", this.files); // add your file to form data
            data.append('form',JSON.stringify(body))
  
            let access =  await this.$store.dispatch('AuthModule/verifyToken')
  
            if (access) {
              fetch(`${mainUrl}${this.formProperties.urlPostAdjunto}${body.nombre}`, {
                method: 'POST',
                body: data,
                headers: {
                  Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
                },
              })
              .then(res => {
                this.loaderEfect('D')
                if(!res.ok) {
                  return res.json().then(text => { throw new Error(text.message) })
                } else {
                  this.showAlert('success', 'El archivo ha sido adjuntado correctamente.')
                  this.$emit('success-upload')
                  this.closeForm()
                }
              })
              .catch(err => {
                console.log(err.response)
                this.showAlert('error', err)
              })
            } else {
              this.showAlert('error', 'Se ha terminado al sesión, ingrese nuevamente')
            }
  
          } else {
            this.showAlert('error', 'No ha seleccionado ningun archivo')
          }
        } else {
          this.showAlert('error', 'Llenar los campos seleccionados en rojo')
        }
      },
      async downloadFile(item){
        console.log('activar download', item)
  
        await this.$store.dispatch('AuthModule/verifyToken')
        this.isLoading = true
        fetch(`${mainUrl}${this.formProperties.urlDownloadAdjunto}${item.id}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
            'Content-Type': 'application/json',
          }
        })
        .then(res => {
          if(!res.ok) {
            return res.json().then(text => { throw new Error(text.message) })
          } else {
            console.log(res)
            if (item.extension.toLocaleLowerCase() == 'PDF'.toLocaleLowerCase()) {
              res.blob().then(file => {
                this.isLoading = false
                const fileURL = URL.createObjectURL(file)
                window.open(fileURL)
              })
            } else {
              res.blob().then(file => {
                saveAs(file, `${item.nombre}`)
                this.isLoading = false
              })
            }
          }
        })
        .catch(err => {
          this.isLoading = false
          console.log(err.response)
        })
  
      },
      loaderEfect (param) {
        const l = this.loader
        if (param == "A"){
          this[l] = !this[l]
        } else {
          this[l] = false
          this.loader = null
        }
      },
      openEliminar(item){
        this.$confirm('Deseas Borrar esta registro?', 'Warning', {
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          type: 'warning',
          confirmButtonClass: 'v-btn v-btn--has-bg  v-size--small primary'
        }).then(() => {
          const url = `${this.formProperties.url}${item.id}/`
            const res = this.getData(url, 'DELETE')
            if (res){
              this.$message({
                type: 'success',
                message: 'El registro seleccionado a sido borrado.'
              })
            } else {
              this.$message({
                type: 'error',
                message: 'Al parecer hubo problemas al realizar la operacion, de igual manera vertifique si el registro ha sido eliminado.'
              })
            }

          console.log(this.dataTableConciliacion)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'e ha cancelado la operación :)'
          })
        });


      },
      eliminar(){
  
      },
      async getData(url, method, body, action, nombre, params={}){
        let res = false
        await this.$store.dispatch('AuthModule/verifyToken')
        const obj = {
          token : this.$store.state.AuthModule.accessToken,
          method: method,
          url: url,
          body: body
        }
        this.isLoading = true
        this.$store.dispatch('fechData', obj)
          .then((result) => {
            console.log(result)
            res = true
            // this.$store.dispatch(action,{data: result.data, nombre: nombre})
            // this.totalPages = result.totalPages-1
            this.isLoading = false
            this.closeForm()
            this.$emit('updated', result)
          })
          .catch((err) => {
            this.isLoading = false
            const regex = /["[{\]}]/g
            const mensaje = JSON.stringify(err.response.data).replaceAll(regex,'') ?? ''
            this.showAlert('error', mensaje)
            res = false
            if (err.response.status == 401){
              alert(
                `token no valido o vencido:  ${err.response.data.detail ?? ''}`
              )
              this.$router.push({name: 'LoginView'})
            }
          })
        return res
      },
      closeForm(){
        this.dialogForm=false
        this.multiForm = {}
        this.actionEdit = false
        this.files = null
      },
      modificar(item){
        console.log(item)
      },
      dobleClick (event, {item}){
        this.$emit('selected-row', item)
      },
      callMethod(methodName, item) {
        // check if the method exists and call it
        if (typeof this[methodName] === 'function') {
          this[methodName](item)
        } else {
          console.error(`Method ${methodName} does not exist`)
        }
      }
    },
    // watch: {
    //   apiResult(newVal, oldVal) {
    //     // Do something with the new value of apiResult
    //     console.log('API result not changed:', oldVal);
    //     console.log('API result changed:', newVal);
    //   },
    // },
    created(){
      console.log('BASE WIN LIST CREATED')
    },
    mounted(){
      if (this.addAuto) this.openAgregar()
      const item = this.itemsBaseWindowList.find(reg => reg.id == this.$store.state.selectedTipoContrato)
      if (this.addAutoEditar) this.openEditar(item)
    }
  
  }
  </script>
  
  <style>
  
  </style>