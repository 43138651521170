<template>
  <div>
    
    <v-data-table
    :headers="headers"
    :items="records"
    :search="search"
    class="elevation-1 rounded-xl px-2"
    :loading="loading"
    :items-per-page="20"
    hide-default-footer
    disable-sort
    >
      <template v-slot:top>
        <v-toolbar flat>
          <div class="d-flex flex-column mt-5">
            <v-toolbar-title>{{ `Lista de detalle de ajustes` }}</v-toolbar-title>
            <div class="d-flex text-caption flex-column mb-5">
              <p class="ma-0 pa-0">CC:Codigo Cabecera. CA:Codigo Asignacion. CA:Codigo Detalle</p>
              <p class="ma-0 pa-0">TA:Tipo de Ajuste</p>
            </div>
          </div>

          <v-spacer></v-spacer>

          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
    </v-data-table>
    <v-pagination
        v-model="currentPage"
        :length="totalPages"
        total-visible="8"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        circle
        @input="siguientePagina"
      ></v-pagination>
  </div>
</template>

<script>
import { paginationMethods } from "../mixins/pagination";
export default {
  mixins:[paginationMethods],
  data() {
    return {
      search: "",
      headers: [
        { text: 'CC', value: 'cc' },
        { text: 'CA', value: 'ca' },
        { text: 'CD', value: 'cd' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Guía', value: 'numero_guia' },
        { text: 'Transportista', value: 'transportista', width: '220' },
        { text: 'Valor', value: 'valor_adicional', align: 'end'},
        { text: 'Descuento', value: 'descuento', align: 'end'},
        { text: 'IVA', value: 'iva', align: 'end'},
        { text: 'Estado', value: 'estado' },
        { text: 'TA', value: 'tipo_ajuste' },
        { text: 'Info', value: 'descripcion_detallada', width: '250'},
        { text: 'Fecha PR', value: 'fecha_proforma' },
        { text: 'Lote PR', value: 'lote_proforma' },
        // { text: 'Criterio de estado', value: 'criterio_estado' },
        { text: 'Fecha Fact', value: 'fecha_factura' },
        { text: 'No. Fact', value: 'no_factura' },
        { text: 'Ref. pago', value: 'ref_pago' },
        { text: 'No. OC', value: 'no_orden_compra' },
        { text: 'Fecha pago', value: 'fecha_pago' },
        // { text: 'Fecha de pago recibido', value: 'fecha_pago_recibido' }
      ],
      records: [],
      urlParams: '?createdAt[order]=desc',
      loading: false,
      nameItemStore: 'records'
    }
  },
  methods: {
    siguientePagina(item) {
      const query2 =  new URLSearchParams()
      query2.append('list', true)
      this.gotoPage(item, `transjuma/AjusteDetalle/`, query2);
    },
  },
  computed: {
  },
  created() {
    const query =  new URLSearchParams()
    query.append('list', true)

    this.getDataGeneral(
      "GET",
      `transjuma/AjusteDetalle/`,
      'records',
      "loading",
      {},
      true, //paginacion
      false, // opcion para que devuelva algo
      false,
      query //modo normal activado, vienen todos los resultados
    )
  }
}
</script>