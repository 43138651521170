<template>

  <v-stepper v-model="paso1" v-if="componentKey" rounded="xl">
    <v-stepper-header >
      <v-stepper-step
        :complete="paso1 > 1"
        step="1"
      >
        Paso 1: Se detallan los pagos a programar
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="paso1 > 2"
        step="2"
      >
        Name of step 2
      </v-stepper-step>

    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-row justify="center" class=" ml-1">
          <v-col cols="12" class="py-1">
            <v-card class="card-principal" flat rounded="xl">
              <v-card-subtitle class="pa-1"
                ><strong>Programacion de pagos</strong>
              </v-card-subtitle>
              <v-form class="px-1 py-1" ref="createForm" v-if="formulario" :disabled="disableForm">

                <v-row dense class="mb-2">
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="createForm.numeros_factura"
                      label="Escoger las Facturas a Programar"
                      chips
                      clearable
                      dense
                      outlined
                      multiple
                      small-chips
                      deletable-chips
                      :items="no_facturas"
                      :item-text="itemTextSelectFactura"
                      item-value="no_factura"
                      class="rounded-lg"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row dense align="center">
                  <v-col cols="12" md="2">
                    <v-menu
                      v-model="menuPicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="pa-0 rounded-lg"
                          v-model="createForm.fecha_programacion"
                          label="Fecha"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          required
                          :rules="reglasCreateForm"
                          hide-details
                          dense
                          outlined
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="createForm.fecha_programacion"
                        @input="menuPicker = false"
                        dense
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="1" class="d-flex justify-center">
                    <v-tooltip top color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          color="primary"
                          rounded
                          dark
                          @click="openDialoginfoAdditional"
                          class="mx-2"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="visiblePlusInfo"
                          >
                          <v-icon dark small>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Agregar mas Guias o Aajustes</span>
                    </v-tooltip>

                    <v-tooltip top color="deep-orange" >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          color="deep-orange"
                          rounded
                          dark
                          @click="openDialogDeducciones"
                          class="px-1"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="visiblePlusInfo"
                          >
                          <v-icon dark small>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Agregar las deducciones</span>
                    </v-tooltip>

                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      class="pa-0 rounded-lg"
                      dense
                      v-model="createForm.descripcion"
                      label="Descripcion Programacion"
                      placeholder="Este campo es Opcional"
                      hide-details
                      outlined
                    ></v-text-field>
                  </v-col>
                  
                  <v-col cols="12" md="5" class="d-flex justify-end">
                    <v-btn
                      rounded
                      color="primary"
                      small
                      @click="saveCabecera(createForm)"
                      :disabled="botonContinuar"
                      >Continuar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>

          <v-col cols="12" class="pt-0 pb-0">
            <v-card elevation="0" :disabled="disableCardContent" class="card-content">
              <el-transfer

                style="text-align: left display: inline-block"
                v-model="registrosAsignar"
                filterable
                :props="{
                  key: 'key',
                  guia: 'guia',
                  fecha: 'fecha',
                  destino: 'codDestino',
                  descripcion: 'descripcion',
                  precioBase: 'precioBase',
                  descuento: 'descuento',
                  iva: 'iva_con_descuento',
                  tipo: 'tipo'
                }"
                :titles="['Escoger opciones', 'Listado Programacion']"
                :format="{
                  noChecked: '${total}',
                  hasChecked: '${checked}/${total}',
                }"
                @change="handleChange"
                :data="registros"
              >
                <!-- <div 
                  slot-scope="{ option }" 
                >
                  <el-popover 
                    placement="left-end"
                    width="340"
                    trigger="hover"
                    >
                      <v-list-item three-line dense class="pa-0 ma-0">
                        <v-list-item-content>
                          <div class="text-overline mb-4">
                            {{option.codDestino}}
                          </div>
                          <v-list-item-title class="text-h6 mb-1">
                            {{option.descripcionDestino}}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <div>Base: {{convertToMoney(option.precioBase)}}</div>
                            <div >
                              <span style="color:red;">
                                Descuento: {{convertToMoney(option.descuento)}}
                              </span>
                            </div>
                            <div>Iva: {{convertToMoney(option.iva_con_descuento)}}</div>
                            <div> <strong>Total: {{convertToMoney(option.total)}}</strong> </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar
                          tile
                          size="80"
                          color="grey"
                        ></v-list-item-avatar>
                      </v-list-item>

                    <span slot="reference">
                      {{ `${option.key}--${option.label}` }}
                    </span>
                  </el-popover>


                </div> -->

                <!-- <el-button class="transfer-footer" slot="left-footer" size="small"
                    >Operation</el-button
                  > -->
                <!-- <div class="transfer-footer" slot="right-footer">

                </div> -->
              </el-transfer>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense class="d-felx justify-space-between my-4">
          <v-col cols="4" class="d-flex"> 
            <v-btn
              v-if="!checkNegativos"
              rounded
              small
              :disabled="direction"
              color="primary"
              @click="continuarAlPaso2"
              class="mr-3"

            >
              Continuar Paso 2
            </v-btn>
            <v-btn
              small
              rounded
              color="error"
              @click="cancelarPaso1">
              Cancelar
            </v-btn>
          </v-col>

          <v-col cols="6">
            <v-row class="align-center">
              <v-col class="d-flex flex-column pr-1 pl-4">
                <div class="d-flex justify-space-between px-2">
                  <span class="texto-footer"> Ttl Base: </span>
                  <span class="texto-footer">{{
                    `¢${sumatorias.totalBase ? sumatorias.totalBase: 0.00}`
                  }}</span>
                </div>

                <div class="d-flex justify-space-between red--text px-2">
                  <span class="texto-footer"> Ttl Desc: </span>
                  <span class="texto-footer">{{
                    `¢${sumatorias.totalDescuento ? sumatorias.totalDescuento: 0.00}`
                  }}</span>
                </div>
              </v-col>

              <v-col class="d-flex flex-column px-1">
                <div class="d-flex justify-space-between">
                  <span class="texto-footer"> Ttl Iva: </span>
                  <span class="texto-footer">{{
                    `¢${sumatorias.totalIva ? sumatorias.totalIva: 0.00}`
                  }}</span>
                </div>
                <div class="d-flex justify-space-between">
                  <span class="texto-footer">
                    <strong>Programacion:</strong>
                  </span>
                  <span class="texto-footer"
                    ><strong> {{ `¢${sumatorias.total ? sumatorias.total: 0.00}` }}</strong>
                  </span>
                </div>
              </v-col>

              <v-col cols="2" class="d-flex justify-center">
                <v-btn fab color="primary" x-small @click="previewPagos"
                  ><v-icon light>mdi-magnify-plus-outline</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-stepper-content>

      <!-- ACCIONES DE LA PROGRAMACION -->
      <v-stepper-content step="2" rounded="xl">
        <v-card rounded="xl" :loading="loading" :disabled="loading">
          <v-card-title>
            <h5>Programacion de pagos</h5>
          </v-card-title>
          <v-card-text>
            En el siguiente apartado usted puede elegir si ir al proceso de pago de la programacion o simplemente gaurdarla y hacer una diferente para el mismo dia.
            Los registros que aun no han sido pagados en la fecha que usted indico, estaran disponibles y por lo cual apareceran en la programacion de pagos.
            En los 2 primeros botones de abajo puede visualizarla el resumen de pago de cada transportista, ya sea en excel o pdf.
            El boton rojo es para pasar al pago en donde usted llenara la informacion pertinente a cada pago realizado.
          </v-card-text>
          <div class="d-flex justify-center align-center" :disabled="loading">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  text
                  large
                  v-bind="attrs"
                  v-on="on"
                  disabled
                >
                  <v-icon>picture_as_pdf</v-icon>
                </v-btn>
              </template>
              <span>Ver Pdf</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="green"
                  icon
                  text
                  large
                  @click="reporteProgramacionXsl"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Ver Excel</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  text
                  large
                  @click="saveProgramacion(true)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="red" large>payments</v-icon>
                </v-btn>
              </template>
              <span>Grabar y colocar detalles de pagos</span>
            </v-tooltip>

          </div>
        </v-card>

        <v-btn
          class="my-3 mr-2"
          small
          rounded
          :disabled="direction"
          color="primary"
          @click="saveProgramacion(false)"
          :loading="loading"
        >
          Guardar Programacion
        </v-btn>

        <v-btn
          small
          rounded
          color="warning"
          @click="paso1 = 1"
          :disabled="loading">
          Atras
        </v-btn>
      </v-stepper-content>

    </v-stepper-items>

    <!-- PREVIOSUALIZACION DE LOS PAGOS -->
    <v-row dense justify="center" v-if="previewProgramacion">
      <v-dialog
        v-model="previewProgramacion"
        scrollable
        max-width="700px"
        max-heigth="600px"
      >

        <v-card rounded="xl">
          <v-card-title>
            <v-row dense align="center">
              <v-col cols="12">
                <span>Previsualizacion de Pagos</span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-subtitle class="pb-1">
              <v-row dense align="center" no-gutters>
                <v-col cols="6">
                  <span>Nombre</span>
                </v-col>
                <v-col cols="3" class="d-flex justify-end">
                  <span>Total Facturacion</span>
                </v-col>
                <v-col cols="3" class="d-flex justify-end">
                  <span>Total a Pagar</span>
                </v-col>
              </v-row>
            </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text style="height: 300px;" class="py-3">

            <v-row dense align="center" v-for="item in totales_transportistas" :key="item.transportistaId">
              <v-col cols="6" class="pt-1 pb-0">
                <span> {{ item.nombre }}</span>
              </v-col>
              <v-col cols="3" class="d-flex justify-end py-0">
                <span>{{ convertToMoney(item.totalFactura) }}</span>
              </v-col>
              <v-col cols="3" class="d-flex justify-end py-0">
                <span :class="{'red--text': item.total < 0}"> {{ convertToMoney(item.total) }}</span>
              </v-col>

            </v-row>

          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row dense align="center" class="ma-0 pa-0">
              <v-col :cols="negativos ? 12 : 6" class="pa-0">
                <v-btn
                  rounded
                  color="blue darken-1"
                  text
                  @click="previewProgramacion = false"
                >
                  Cerrar
                </v-btn>
                <span class="red--text" v-if="negativos"> ***Existen valores negativos a pagar, todos deben ser positivos.</span>
              </v-col>
              <v-col cols="3" class="d-flex justify-end pa-1" v-if="!negativos">
                <span><strong> {{ `¢${sumTotalFactura ? sumTotalFactura : 0.00}` }}</strong></span>
              </v-col>
              <v-col cols="3" class="d-flex justify-end pa-1" v-if="!negativos">
                <span><strong> {{ `¢${sumatorias.total ? sumatorias.total: 0.00}` }}</strong></span>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- CUADRO DE BUSQUEDA ADICIONAL DE REGISTROS -->
    <v-row dense align="center">
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="850"
        content-class="border-dialog"
        v-model="dialogAdditional"
      >
        <template v-slot:default="dialog">
          <v-card
            rounded="xl"
          >

            <v-card-title color="primary" class="d-flex justify-space-between" :style="{'background-color': lookdeducciones ? '#FF5722' : '#2e335b'}">
              <div style="color: white;">{{ lookdeducciones ? 'Busqueda adicional de deducciones' : 'Busqueda adicional de registros' }}</div>
              <div style="color: white;">
                <span>Total de registros actuales:</span>
                <span>{{ totalRegistrosProgramacion }}</span>
              </div>
            </v-card-title>

            <v-card-text>

              <!-- cabecera busqueda informacion adicional -->
              <v-container>
                <v-row dense align="center">
                  <!-- numero de guia -->
                  <v-col cols="12" sm="6" md="4"  v-if="!lookdeducciones">
                    <BaseAutoComp
                      textoBusqueda="Digite numero de guia"
                      label="Buscar Guia..."
                      :itemText="['id', 'numero', 'destino']"
                      itemValue="id"
                      :advanceSearchFields="{
                        'a.numero': null,
                        'b.destino': null,
                      }"
                      rutaItemAuto="transjuma/GuiaSelect"
                      :procesoCompleto="procesoCompleto"
                      v-on:change-auto="selected"
                      fieldName="Numero de Guia"
                      :modoEdicion="false"
                    >

                    </BaseAutoComp>
                  </v-col>

                  <v-col cols="12" md="3" v-if="!lookdeducciones">
                    <v-autocomplete
                      dense
                      outlined
                      v-model="createSearchRegister.factura"
                      label="Factura"
                      clearable
                      :loading="loadingInfoAdd"
                      :items="facturasSelect"
                      :item-text="itemTextSelectFactura"
                      item-value="no_factura"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4"  v-if="lookdeducciones">
                    <v-text-field
                      v-model="createSearchRegister.guida_d"
                      dense
                      outlined
                      clearable
                      label="Guia"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4"  v-if="lookdeducciones">
                    <v-text-field
                      v-model="createSearchRegister.descripcion_detallada"
                      dense
                      outlined
                      clearable
                      label="Descripcion detallada"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3" v-if="lookdeducciones">
                    <v-menu
                      v-model="menuPickeLookdeducciones"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="pa-0 rounded-lg"
                          v-model="createSearchRegister.fecha_transporte"
                          label="Fecha transp"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                          dense
                          outlined
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="createSearchRegister.fecha_transporte"
                        @input="menuPickeLookdeducciones = false"
                        dense
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" :md="lookdeducciones ? 5 : 3">
                    <v-autocomplete
                      dense
                      outlined
                      v-model="createSearchRegister.transportista"
                      label="Transportista"
                      clearable
                      :loading="loadingInfoAdd"
                      :items="transportistas"
                      item-value="id"
                      item-text="nombre"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" :md="lookdeducciones ? 12 : 2" class="d-flex align-content-center justify-end">
                    <v-btn
                      x-small
                      color="success"
                      class="mr-3"
                      :loading="loadingInfoAdd"
                      @click="searchInfoAdditional(createSearchRegister)"
                      >Buscar</v-btn>
                    <v-tooltip top color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          rounded
                          color="success"
                          v-bind="attrs"
                          v-on="on"
                          @click="addManyRegisters"
                          :disabled="visibleAddSelectedRows"
                        >
                          <v-icon x-mall rounded>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Agregar Registros</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-container>

              <!-- tabla contenido busqueda informacion adicional -->
              <v-container>
                <v-row align="center">
                  <v-col cols="12" md="12">
                    <v-card
                      :loading="loadingTableInfoAdd"
                      class="py-2"
                      elevation="0"
                      outlined>
                      <v-text-field
                        dense
                        class="my-1 py-1"
                        v-model="searchRegistros"
                        append-icon="mdi-magnify"
                        label="Filtrado"
                        hide-details
                      ></v-text-field>
                        <v-data-table
                          v-model="selectedAddInfoRows"
                          dense
                          :headers="headers"
                          :items="newRegisters"
                          item-key="id"
                          class="elevation-0"
                          disable-sort
                          show-select
                          :items-per-page="20"
                          :search="searchRegistros"
                        >
                          <template v-slot:body="{ items }">
                            <tbody>
                              <tr v-for="item in items" :key="item.id">
                                <td class="text-center">
                                    <v-checkbox class="pa-0 ma-0" v-model="selectedAddInfoRows" :value="item"
                                        hide-details />
                                </td>
                                <td class="text-center" >{{ item.guia }}</td>
                                <td class="text-left text-caption" >{{ item.transportista }}</td>
                                <td class="text-center" >{{ item.no_factura }}</td>
                                <td class="text-center" >{{ item.tipo }}</td>
                                <td class="text-end" >{{ convertToMoney(parseFloat(item.precioBase ? item.precioBase: 0)) }}</td>
                                <td class="text-end" >{{ convertToMoney(parseFloat(item.iva_con_descuento ? item.iva_con_descuento: 0)) }}</td>
                                <td class="text-end" >{{ convertToMoney(parseFloat(item.total_select ? item.total_select: 0)) }}</td>
                                <td class="text-center d-flex justify-center align-center" >
                                  <v-btn
                                    max-height="20px"
                                    max-width="30px"
                                    fab
                                    dark
                                    samll
                                    icon
                                    color="red"
                                    @click="deleteLineRegisterDetail(item)"
                                  >
                                    <v-icon dark>mdi-minus</v-icon>
                                  </v-btn>
                                  <v-btn
                                    max-height="20px"
                                    max-width="30px"
                                    fab
                                    dark
                                    samll
                                    icon
                                    color="primary"
                                    @click="pushLineRegisterDetail(item)"
                                  >
                                    <v-icon dark>mdi-plus</v-icon>
                                  </v-btn>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                small
                rounded
                text
                @click="cerrarAdicionalGuias"
              >Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-row>
  </v-stepper>
</template>


<script>
import { axiosC } from "../api/axiosbd"
import { paginationMethods } from "../mixins/pagination";
import { saveAs } from 'file-saver';
import BaseAutoComp from "../components/BaseAutoComplet.vue";

export default {
  mixins: [paginationMethods],
  components:{BaseAutoComp},
  data() {
    return {
      headers: [
        {text: 'Guia', value: 'guia'},
        {text: 'Transportista', value: 'transportista', align: 'center'},
        {text: 'Factura', value: 'no_factura'},
        {text: 'Tipo', value: 'tipo'},
        {text: 'Precio', value: 'precioBase'},
        {text: 'Iva', value: 'iva'},
        {text: 'Total', value: 'total'},
        {text: 'Acciones', value: 'actions'},
      ],
      selectedAddInfoRows: [],
      searchRegistros: null,
      //campos para busqueda adicional
      createSearchRegister: {},
      loadingInfoAdd: false,
      loadingTableInfoAdd: false,
      newRegisters: [],
      visiblePlusInfo: true,
      dialogAdditional: false,

      negativos : false,
      componentKey: true,
      totales_transportistas: [],
      previewProgramacion: false,
      dialogm1: '',
      paso1: 1,
      direction: true,
      formulario: true, //esto es necesario para el reinicio del form y tome los valores por defecto
      disableForm: false,
      disableCardContent: true,
      menuPicker: false,
      menuPickeLookdeducciones: false,
      menu: false,
      botonContinuar: false,

      estado: true,
      guias: [],
      guiasUpdate: [],
      ajustes: [],
      ajustesUpdate:[],
      deducciones: [],
      registrosDeducciones: [],
      deduccionesUpdate: [],
      preRegistros: [],
      registros: [],
      registrosAsignar: [],
      busquedaRegistros: [],
      numeros_factura: [],
      no_facturas: [],
      transportistas:[],
      sumatorias:{
        totalBase: [],
        totalDescuento: [],
        totalIva: [],
        subtotal: [],
        total: [],
      },
      sumTotalFactura: 0,
      dataReporteXls: [],

      fechaActual: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      createForm: {
        fecha_programacion: null,
        descripcion: '',
        estado_pago: 1,
      },
      createFormRegistros: { records: []},
      createFormProgramacion: { records: []},
      updateStateGuias: {
        guias: [],
        ajustes: [],
      },
      recentCabecera: null,
      completeProcess: true,
      reglasCreateForm: [(v) => !!v || "Este Campo es requerido"],
      codigoEstadoGuiaEnviada: "",
      procesoCompleto: false,
      loading: false,
      lookdeducciones: false
    }
  },

  methods: {
    async saveCabecera() {
      if (this.$refs.createForm.validate()) {
        //console.log(this.createForm)
        //console.log(`(${this.createForm.numeros_factura.map(item=> `'${item}'`).join()})`)

        const body = {
          descripcion: this.createForm.descripcion,
          estado_pago: this.createForm.estado_pago,
          fecha_programacion: this.createForm.fecha_programacion,
          numeros_factura:`(${this.createForm.numeros_factura.map(item=> `'${item}'`).join()})`
        }

        await this.$store.dispatch("AuthModule/verifyToken")
        axiosC({
          method: 'GET',
          url: `transjuma/PagoProveedoresCabecera/InvoiceListDetails`,
          params: body,
          headers: { Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}` }
        })
        .then((result) => {
          // //console.log(result.data)

          this.registros = result.data

          this.disableCardContent = false
          this.botonContinuar = true
          this.disableForm = true
          this.completeProcess = false
          this.visiblePlusInfo = false
        }).catch((err) => {
          //console.log(err.response ?? err)
          alert(err.response ?? err)
        });

      }
    },
    selected(item){
      //console.log(item)
      this.createSearchRegister.guia = item.itemSelected ? item.itemSelected.numero : null
    },
    convertToMoney(x) {
      if (x) {
        return x
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else {
        return '0.00'
      }
    },
    async reporteProgramacionXsl() {

        await this.$store.dispatch("AuthModule/verifyToken")

        axiosC({
          method: "POST",
          url: `transjuma/jumaReportePagos`,
          headers: {
            Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
            'Content-Type': 'aplication/json',
          },
          data: await this.dataReporteXls,
          responseType: 'blob',
        })
        .then((response) => {

            saveAs(response.data, response.headers['Content-Filename'])
          })
        .catch((err)=>{
          //console.log(err.response)
        })

    },

    infoOptionTransfer(option){

    },
    cancelarPaso1(){
      this.$router.push({name: 'Pago_proveedores'})
    },
    previewPagos (){
      this.revisarNegativos
      this.previewProgramacion = true
    },
    continuarAlPaso2(){
      this.revisarNegativos
      if (this.negativos == false) {
        this.paso1 = 2
      }
      else {
        this.paso1 = 1
        alert('***Existen valores negativos a pagar, todos deben ser positivos.')
      }
    },
    async saveProgramacion(seguirPago) {
      //console.log('GUARDAR PROGRAMACION')
      this.loading = true
      if (this.createFormRegistros.records.length > 0) {
        let cabecera = null
        let error = false

        const body ={
          company : 1,
          created_by : this.$store.state.AuthModule.refu,
          updated_by : null,
          ref : this.$store.state.AuthModule.r2,
          cabecera: this.createForm,
          detalles: this.createFormRegistros.records
        }

        //console.log(body)
        await this.$store.dispatch("AuthModule/verifyToken")
        axiosC({
          method: 'POST',
          url: `transjuma/PagoProveedoresCabecera/complete-create/`,
          data: body,
          headers: { Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}` }
        })
        .then((result)=>{
          console.info('complete el proceso satisfactoriamente', result.data)
          this.completeProcess = true
          if (seguirPago) {
            this.$store.dispatch('cabeceraProgramacion',result.data.cabecera)
            this.$router.push({name: 'ProcesoDePago'})
          } else {
            this.$router.push({name: 'ProvProgramaciones'})
          }
          this.loading = false
        })
        .catch((err)=>{
          //console.log(err)
          this.loading = false
        })

      } else {
        alert('No se puede salvar registro, no ha seleccionado guias y ajustes a asignar')
        this.loading = false
      }
      
    },
    postCreationDetalles(body){

    },
    iniciarForm() {
      this.createForm = {
        fecha_programacion: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        descripcion: "",
        estado_pago: 1,
      }
      this.sumatorias = {
        totalBase: [],
        totalDescuento: [],
        totalIva: [],
        subtotal: [],
        total: [],
      }
      this.registros = []
      this.registrosAsignar = []
      this.busquedaRegistros = []
      this.createFormRegistros = { records: []}
      this.updateStateGuias = {
        guias: [],
        ajustes: [],
      }
    },

    handleChange(value, direction, movedKeys) {

      if (direction == 'right') {
        console.log('adicionar')
        this.direction = false
        for (let reg of movedKeys){
          this.busquedaRegistros.push(this.registros.find((item) =>item.key == reg))
        }

        this.sumatorias = {
            totalBase: [],
            totalDescuento: [],
            totalIva: [],
            subtotal: [],
            total: [],
          }
        this.createFormRegistros = { records: []}
        this.createFormProgramacion = {records:[]}
        this.updateStateGuias = {
          guias: [],
          ajustes: [],
        }

        for (let item of this.busquedaRegistros){
          this.sumatorias.totalBase.push(parseFloat(item.precioBase))
          this.sumatorias.totalDescuento.push(parseFloat(item.descuento))
          this.sumatorias.totalIva.push(parseFloat(item.iva_con_descuento))
          this.sumatorias.subtotal.push(parseFloat(item.subtotal))
          this.sumatorias.total.push(parseFloat(item.total_select))

          this.createFormRegistros.records.push(item)

        }
      } else {
        console.log('quitar elemento')
        for (let reg of movedKeys){
          this.busquedaRegistros = this.busquedaRegistros.filter((item) =>item.key != reg)
        }
        if (this.busquedaRegistros.length == 0) {
          this.sumatorias = {
            totalBase: [],
            totalDescuento: [],
            totalIva: [],
            subtotal: [],
            total: [],
          }
          this.createFormRegistros = {records:[]}
          this.createFormProgramacion = {records:[]}
          this.updateStateGuias = {
            guias: [],
            ajustes: [],
          }

        } else {
          this.sumatorias = {
            totalBase: [],
            totalDescuento: [],
            totalIva: [],
            subtotal: [],
            total: [],
          }
          this.createFormRegistros = {records:[]}
          this.createFormProgramacion = {records:[]}
          this.updateStateGuias = {
            guias: [],
            ajustes: [],
          }

          for (let item of this.busquedaRegistros){
            this.sumatorias.totalBase.push(parseFloat(item.precioBase))
            this.sumatorias.totalDescuento.push(parseFloat(item.descuento))
            this.sumatorias.totalIva.push(parseFloat(item.iva_con_descuento))
            this.sumatorias.subtotal.push(parseFloat(item.subtotal))
            this.sumatorias.total.push(parseFloat(item.total_select))

            this.createFormRegistros.records.push(item)

          }
        }
      }
      this.sumatorias.totalBase = this.convertToMoney(this.sumatorias.totalBase.reduce((a, b) => a + b, 0))
      this.sumatorias.totalDescuento = this.convertToMoney(this.sumatorias.totalDescuento.reduce((a, b) => a + b, 0))
      this.sumatorias.totalIva = this.convertToMoney(this.sumatorias.totalIva.reduce((a, b) => a + b, 0))
      this.sumatorias.subtotal = this.convertToMoney(this.sumatorias.subtotal.reduce((a, b) => a + b, 0))
      this.sumatorias.total = this.convertToMoney(this.sumatorias.total.reduce((a, b) => a + b, 0))

      if (this.createFormRegistros.records.length > 0) {
        this.direction = false
        // //console.log('en el if y doy true')
        // //console.log(this.createFormRegistros.records)
        //aqui leo hace la magia
        this.totales_transportistas = []
        this.dataReporteXls = []
        for (let registro of this.createFormRegistros.records) {
          //formar el registro de el borrador del excel
          let xls = {
            index: registro.tipo == 'G'? 1 : 
              registro.tipo == 'A' ? 2: 
              registro.tipo == 'D' ? 3 : 0,

            transportista: registro.transportista,
            
            guia: registro.guia,
            
            fecha: registro.fecha,
            
            tn: registro.tn,
            
            codDestino: registro.codDestino,
            
            descripcionDestino: registro.tipo == 'G'? registro.descripcionDestino : 
              registro.tipo == 'A' ? registro.destino: 
              registro.tipo == 'D' ? registro.descripcion : '',
            
            precio_base: registro.precioBase,
            
            descuento: registro.descuento,
            
            iva: registro.iva_con_descuento,
            
            netoPagar: 0,
            
            origen: registro.tipo == 'G'? "Guia" : 
              registro.tipo == 'A' ? "Ajuste": 
              registro.tipo == 'D' ? "Deduccion" : '',

            transportistaId: registro.transportistaId,
            email: registro.email,
            // id: registro.id,

            // no_transaccion_general: item.no_transaccion_general ? item.no_transaccion_general : '',

            // no_transaccion_detallado: item.no_transaccion_detallado ? item.no_transaccion_detallado : '',

            // banco: item.bancoId ? item.bancoId.nombre: '',

            // metodo_pago: item.metodo_pagoId ? item.metodo_pagoId.nombre : ''
          }
          xls.netoPagar = xls.precio_base + xls.descuento + xls.iva

          xls.fecha = xls.fecha.substr(0,10)
          this.dataReporteXls.push(xls) 

          let exist = false
          if (registro.tipo == 'D'){
            let obj = {
              fecha_pago: registro.fecha,
              deduccion_pago_proveedores: registro.id,
              ajuste_detalle_pago_proveedores: null,
              guia_pago_proveedores: null,
              pago_proveedores_cabecera: null,
              metodo_pago: 1
            }

            this.createFormProgramacion.records.push(obj)
          } else if (registro.tipo == 'A'){
            let obj = {
              fecha_pago: registro.fecha,
              deduccion_pago_proveedores: null,
              ajuste_detalle_pago_proveedores: registro.id,
              guia_pago_proveedores: null,
              pago_proveedores_cabecera: null,
              metodo_pago: 1
            }
            this.createFormProgramacion.records.push(obj)
          } else if (registro.tipo == 'G') {
            let obj = {
              fecha_pago: registro.fecha,
              deduccion_pago_proveedores: null,
              ajuste_detalle_pago_proveedores: null,
              guia_pago_proveedores: registro.id,
              pago_proveedores_cabecera: null,
              metodo_pago: 1
            }
            this.createFormProgramacion.records.push(obj)
          }
          
          for (let item of this.totales_transportistas) {
            if (item.transportistaId == registro.transportistaId) {
              exist = true

              item.precio_base += registro.precioBase,
              item.descuento += registro.descuento,
              item.iva_con_descuento += registro.iva_con_descuento,
              item.total += registro.total_select
              item.totalFactura += registro.total_select
              
              if (registro.tipo == 'D' && registro.iva_con_descuento == 0) {
                // //console.log(item.total, item.totalFactura)
                // //console.log(`sin iva no sumar: ${registro.iva_con_descuento}`)
                item.totalFactura += registro.total_select*-1
                // //console.log(item.total, item.totalFactura)
              }

              // break
            }
          }

          if (!exist) {
            let objt = {
              transportistaId: registro.transportistaId,
              nombre: registro.transportista,
              precio_base: registro.precioBase,
              descuento: registro.descuento,
              iva: registro.iva_con_descuento,
              total: registro.total_select,
              totalFactura: registro.total_select
            }
            this.totales_transportistas.push(objt)
          }
        }
        this.totales_transportistas = this.totales_transportistas.sort(function (x, y) {
                                          let a = x.nombre.toUpperCase(),
                                              b = y.nombre.toUpperCase();
                                          return a == b ? 0 : a > b ? 1 : -1;
                                      });

        let sumTotal = []
        for (let item of this.totales_transportistas) {
          sumTotal.push(item.totalFactura)
        }
        this.sumTotalFactura = this.convertToMoney(sumTotal.reduce((a, b) => a + b, 0))

      } else {
        this.direction = true
      }

    },

    getItemTextTransportista(item) {
      return `${item.numero_identificacion} - ${item.nombre}`.toUpperCase()
    },
    getItemTextAsignaciones(item) {
      return `${item.codigo} - ${item.nombre}`.toUpperCase()
    },

    async getDataGuiasAjustes(params) {

      await this.$store.dispatch("AuthModule/verifyToken")
      let registrosAjuste = []
      let registrosGuias = []
      let registrosConfirmados = {
        guias: [],
        ajustes: [],
        deducciones: [],
      }
      const resAjuste = await axiosC({
        method: "GET",
        url: `ajuste_detalles/ordenCompra`,
        headers: { Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}` },
      })
      registrosConfirmados.ajustes = resAjuste.data.data

      //console.log(registrosConfirmados.ajustes)
      await this.$store.dispatch("AuthModule/verifyToken")
      const resGuias = await axiosC({
        method: "GET",
        url: `guias/ordenCompra`,
        headers: { Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}` },
      })
      registrosConfirmados.guias = resGuias.data.data

      await this.$store.dispatch("AuthModule/verifyToken")
      const resDeducciones = await axiosC({
        method: "GET",
        url: `deducciones/ordenCompra`,
        headers: { Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}` },
      })
      registrosConfirmados.deducciones = resDeducciones.data.data
      //console.log(resDeducciones.data.data)

      if (registrosConfirmados.deducciones.length) {
        this.registrosDeducciones =  registrosConfirmados.deducciones.map((item)=>{
          let obj = {
            tipo : "D",
            key : `D${item.id}`,
            id : item.id,
            id_cabecera: '',
            fecha: item.fecha,
            fecha_pago: item.fecha_pago ? item.fecha_pago.substr(0, 10) : null,
            guia: item.guia,
            tn: item.codigo,
            no_factura: '',
            codDestino: item.tipo_deduccionId.nombre,
            destino: item.tipo_deduccionId.nombre,
            descripcion: item.descripcion_detallada,
            precioBase: parseFloat(item.monto)*-1,
            descuento: 0,
            iva: parseFloat(item.iva_con_descuento)*-1,
            subtotal : parseFloat(item.monto)*-1,
            total : parseFloat(item.monto)*-1 + parseFloat(item.iva_con_descuento)*-1,
            transportistaId: item.transportista_deduccionId.id,
            transportista: item.transportista_deduccionId.nombre,
            transportistaEmail: item.transportista_deduccionId.email,
            label: `${item.fecha.substr(0, 10)} -- ${item.guia} ${item.transportista_deduccionId.nombre} ${item.descripcion_detallada}`,
            info: `₡${this.convertToMoney(parseFloat(item.monto))} ₡${this.convertToMoney(parseFloat(item.iva_con_descuento))}`

          }
          return obj

        })

      }

      if (registrosConfirmados.ajustes.length) {
        registrosAjuste =  registrosConfirmados.ajustes.map((item)=>{
          // if (!item.guia_ajusteId) //console.log(item)
          let obj = {
            tipo : "A",
            key : `A${item.id}`,
            id : item.id,
            id_cabecera: item.ajuste_cabeceraId.id,
            fecha: item.fecha,
            guia: item.guia_ajusteId.numero,
            tn: item.tipo_ajusteId.id,
            codDestino: item.tipo_ajusteId.nombre,
            no_factura: item.no_factura,
            destino: item.tipo_ajusteId.descripcion_detallada,
            descripcion: item.tipo_ajusteId.nombre,
            precioBase: parseFloat(item.valor_adicional),
            descuento: parseFloat(item.descuento),
            iva: parseFloat(item.iva_con_descuento),
            subtotal : parseFloat(item.valor_adicional) + parseFloat(item.descuento),
            total : parseFloat(item.valor_adicional) + parseFloat(item.descuento) + parseFloat(item.iva_con_descuento),
            transportistaId: item.transportista_ajusteId.id,
            transportista: item.transportista_ajusteId.nombre,
            transportistaEmail: item.transportista_ajusteId.email,
            label: `${item.fecha.substr(0, 10)} -- ${item.guia_ajusteId.numero} ${item.transportista_ajusteId.nombre}
            ${item.no_orden_compra} ${item.no_factura} ${item.lote_proforma} ${item.tipo_ajusteId.descripcion_detallada} ${item.tipo_ajusteId.nombre} ${item.transportista_ajusteId.numero_identificacion}
            `,
            info: `₡${this.convertToMoney(parseFloat(item.valor_adicional))}  ₡${this.convertToMoney(parseFloat(item.descuento))}  ₡${this.convertToMoney(parseFloat(item.iva_con_descuento))}`
            }
          return obj
        })

      }

      if (registrosConfirmados.guias.length){
        registrosGuias = registrosConfirmados.guias.map((item)=>{
          let obj =   {
            tipo : "G",
            key : `G${item.id}`,
            id : item.id,
            id_cabecera: '',
            fecha: item.fecha,
            guia: item.numero,
            no_factura: item.no_factura,
            tn: item.destino_toneladaId.tonelada,
            codDestino: item.destino_toneladaId.codigo_destino,
            descripcionDestino: item.destino_toneladaId.destino,
            destino: `${item.destino_toneladaId.numero} -${item.destino_toneladaId.destino}`,
            descripcion: item.comentario,
            precioBase: parseFloat(item.precio_base),
            descuento: parseFloat(item.descuento),
            iva: parseFloat(item.iva_con_descuento),
            subtotal : parseFloat(item.precio_base) + parseFloat(item.descuento),
            total : parseFloat(parseFloat(item.precio_base) + parseFloat(item.descuento)) + parseFloat(item.iva_con_descuento),
            transportistaId: item.transportista_guiaId.id,
            transportista: item.transportista_guiaId.nombre,
            transportistaEmail: item.transportista_guiaId.email,
            label: `${item.fecha.substr(0, 10)} -- ${item.numero} ${item.transportista_guiaId.nombre}
            ${item.no_orden_compra} ${item.no_factura} ${item.lote_proforma} ${item.transportista_guiaId.numero_identificacion} ${item.destino_toneladaId.numero} -${item.destino_toneladaId.destino}
            `,
            info: `${item.destino_toneladaId.numero} -
                    ${item.destino_toneladaId.destino.slice(0,20)> 20 ? item.destino_toneladaId.destino.slice(0,20) :item.destino_toneladaId.destino.slice(0,20) + '...' }
              ₡${this.convertToMoney(parseFloat(item.precio_base))}  ₡${this.convertToMoney(parseFloat(item.descuento))}  ₡${this.convertToMoney(parseFloat(item.iva_con_descuento))}`
          }
          return obj
        })

        // this.guiasUpdate = registrosConfirmados.guias.map((item)=>{
        //   let obj = {
        //     id: item.id,
        //     numero: item.numero,
        //     fecha: item.fecha, 
        //     precio_base: item.precio_base, 
        //     descuento: item.descuento, 
        //     iva: item.iva_con_descuento, 
        //     fecha_proforma: item.fecha, 
        //     lote_proforma: item.lote_proforma, 
        //     comentario: item.comentario, 
        //     estado: item.estado, 
        //     transportista_guia: item.transportista_guiaId.id, 
        //     sede: item.sedeId.id, 
        //     destino_tonelada: item.destino_toneladaId.id,
        //     estado_guia: item.estado_guiaId.id
        //   }
        //   return obj
        // })

      }

      this.preRegistros = registrosAjuste.concat(registrosGuias)

      //sacar los distinct de proformas
      for (let record of this.preRegistros) {
        if (this.numeros_factura.indexOf(record.no_factura) == -1) this.numeros_factura.push(record.no_factura)
      }
      // ordenamos para el select de las facturas
      this.numeros_factura.sort()
    },

    //metodos especiales para agregar mas guias a los registros seleccionados
    openDialoginfoAdditional(){
      this.newRegisters = []
      this.dialogAdditional = true
      this.lookdeducciones = false
    },
    openDialogDeducciones(){
      this.newRegisters = []
      this.lookdeducciones = true
      this.dialogAdditional = true
    },
    async searchInfoAdditional(item){
      //console.log(item)
      this.newRegisters = []
      try {
        this.loadingTableInfoAdd = true

        let body = {
          // descripcion: this.createForm.descripcion,
          // estado_pago: this.createForm.estado_pago,
          // fecha_programacion: this.createForm.fecha_programacion,
          guia: item.guia,
          transportista: item.transportista,
          numeros_factura: item.factura ? `('${item.factura}')` : null
        }
        if (this.lookdeducciones){
          body = {
            look_deducciones: true,
            transportista_d: item.transportista,
            guia_d: item.guia,
            descripcion_detallada: item.descripcion_detallada,
            fecha_transporte: item.fecha_transporte
          }
        }

        await this.$store.dispatch("AuthModule/verifyToken")
        axiosC({
          method: 'GET',
          url: `transjuma/PagoProveedoresCabecera/InvoiceListDetails`,
          params: body,
          headers: { Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}` }
        })
        .then((result) => {
          //console.log(result.data)
          // This will return the items that are present in list1 but not in list2
          this.newRegisters = result.data.filter(e => {
            return !this.registros.some(item => item.key === e.key)
          })

        }).catch((err) => {
          //console.log(err)
          //console.log(err.response ?? '')
          alert(err.response ?? '')
        })
        // //console.log(this.registros)
        this.loadingTableInfoAdd = false
        // //console.log(this.newRegisters)
      } catch (error) {
        this.loadingTableInfoAdd = false
        alert(error.response.data.message)
      }
    },
    addManyRegisters(){
      //console.log(this.selectedAddInfoRows)
      this.registros.push(...this.selectedAddInfoRows)
      this.selectedAddInfoRows.forEach(element => this.deleteLineRegisterDetail(element))
      this.selectedAddInfoRows = []
    },
    deleteLineRegisterDetail(item){
      const index =  this.newRegisters.findIndex((i) => {
        return i.key === item.key
      })
      this.newRegisters.splice(index, 1)
    },
    pushLineRegisterDetail(item){
      this.registros.push(item)
      this.deleteLineRegisterDetail(item)
    },
    itemTextSelectFactura(item){
      return `No.${item.no_factura} | ${item.fecha_factura} || $${this.$convertToMoney(item.total, false, 0)}`
    },
    cerrarAdicionalGuias(){
      this.dialogAdditional = false
      this.newRegisters = []
      this.createSearchRegister = []
    },
    //DEDUCCIONES
    

  },
  computed: {
    totalRegistrosProgramacion(){
      return this.registros.length
    },
    visibleInfobutton(){
      return this.visiblePlusInfo
    },
    visibleAddSelectedRows(){
      return this.selectedAddInfoRows.length > 0 ? false : true
    },
    facturasSelect(){
      //console.log(this.no_facturas, this.createForm.numeros_factura)
      const facturas = this.no_facturas.filter(item=> !this.createForm.numeros_factura.includes(item.no_factura))
      return facturas
    },
    tablaDerecha (){
      return this.direction
    },
    revisarNegativos() {
      this.negativos = false
      for (let item of this.totales_transportistas){
        if (item.total < 0) {
          //console.log('es negativo')
          this.negativos = true
        }
      }
    },
    checkNegativos(){
      let val = false
      for (let item of this.totales_transportistas){
        if (item.total < 0) {
          //console.log('es negativo')
          val = true
          break
        }
      }
      return val
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.completeProcess){
      next()
    } else{
      const answer =  window.confirm('Realmente deseas continuar? Tienes cambios sin guardar!')
      if (answer) {
        this.completeProcess = false
        next()
      }else {
        next(false)
      }
    }
    this.completeProcess = false
  },

  created() {
    this.getDataGeneral(
      "GET",
      `transjuma/PagoProveedoresCabecera/InvoiceList`, //cuando son rutas de listas completas sin el /
      'no_facturas',
      "",
      {},
      false, //paginacion
      false, // opcion para que devuelva algo
      true //modo normal activado, vienen todos los resultados
    );
    this.getDataGeneral(
      "GET",
      `transjuma/TransportistaList`, //cuando son rutas de listas completas sin el /
      'transportistas',
      "",
      {},
      false, //paginacion
      false, // opcion para que devuelva algo
      true //modo normal activado, vienen todos los resultados
    );

  },
}
</script>

<style>

.border-dialog{
  border-radius: 20px;
}


.v-application .mb-2 {
    margin-bottom: 0px !important;
}
.v-stepper__content{
  padding: 8px 8px 4px 8px !important;
}
.v-stepper__header{
  height: 45px !important;
}
.v-stepper__step {
  padding: 5px !important;
}
.card-principal.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0;
}
.card-content.v-card.v-card--disabled.v-sheet.theme--light.elevation-0 {
  opacity: 40%;
}
.el-transfer {
  display: contents !important;
}
.el-transfer-panel {
  width: 47.5%;
  height: 74vh;
}
.el-transfer-panel__list {
  height: 60vh;
}
.el-transfer-panel__list.is-filterable {
  height: 60vh;
}
.el-transfer-panel .el-transfer-panel__footer {
  height: 42px;
}

.fotter-row {
  padding: 0;
}

/* los botones */
.el-transfer__buttons {
  padding: 0 6px;
  color: #2e335b;
}
.el-button--primary,
.el-button--primary.is-active,
.el-button--primary:active {
  color: #2e335b;
  background-color: #2e335b;
}

.el-icon-arrow-left,
.el-icon-arrow-right {
  color: white;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #2e335b;
  border-color: #2e335b;
}

.el-checkbox__input.is-checked + .el-checkbox__label,
.el-transfer-panel__item:hover {
  color: #2e335a;
}

.texto-footer {
  font-size: 13px;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 0px) and (max-width: 675px) {
  .el-transfer-panel {
    width: 100%;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 676px) and (max-width: 856px) {
  .el-transfer-panel {
    width: 46%;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 857px) and (max-width: 992px) {
  .el-transfer-panel {
    width: 47%;
  }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 993px) and (max-width: 1200px) {
  .el-transfer-panel {
    width: 47.5%;
  }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1201px) and (min-width: 1400px) {
  .el-transfer-panel {
    width: 47.8%;
  }
}
</style>
