<template>
  <v-form ref="editedItem">
      <v-dialog v-model="dialog" width="60%" persistent>
        <v-card
          class="rounded-xl"
          height="auto"
          :loading="loadingForm"
          :disabled="loadingForm"
        >
          <v-card-title class="d-flex justify-space-between py-1 pl-5 pr-4">
            <h5 class="mb-0">{{ title }}</h5>
            <v-btn icon @click="closeBaseWindow">
              <v-icon color="red"> mdi-close-circle </v-icon>
            </v-btn>
          </v-card-title>

          <!-- Encabezado Principal -->
          <v-form v-model="validCabecera" ref="createFormCabecera" lazy-validation>

            <v-divider></v-divider>

            <!-- Encabezado Titulo -->
            <v-card-title class="py-0 pt-1 pl-5 pr-4">
              <v-row dense align="center">
                <v-col cols="12" md="12" class="pt-0">
                  <h6>Encabezado</h6>
                </v-col>
              </v-row>
            </v-card-title>

            <!-- Body Encabezadp -->
            <v-card-text class="pb-0 pl-5 pr-4">
              <!-- Campos encabezado -->
              <v-row dense>
                <v-col cols="12"
                  v-for="(header, i) in headersFilter"
                  :key="i"
                  :md="header.colmd"
                  class="d-flex align-center justify-center"
                  >
                  <v-btn
                    v-if="header.vicon"
                    small
                    icon
                    color="primary"
                    @click="metodoIcono(header.textComponent)"
                  >
                    <v-icon >{{header.text}}</v-icon>
                  </v-btn>
                  <v-textarea
                    v-if="header.vtextarea"
                    v-model="createFormCabecera[header.value]"
                    :label="header.text"
                    dense
                    outlined
                    class="rounded-lg"
                    hide-details
                    rows="6"
                  ></v-textarea>
                  <v-text-field
                    v-if="header.vtext"
                    v-model="createFormCabecera[header.value]"
                    :label="header.text"
                    dense
                    outlined
                    class="rounded-lg"
                    hide-details
                    :rules="header.require ? [v => !!v || 'Campo es requerido'] : []"
                  ></v-text-field>
                  <v-autocomplete
                    :ref="header.value"
                    v-if="header.vauto"
                    :disabled="autoEditDisableCabecera"
                    v-model="createFormCabecera[header.value]"
                    :label="header.text"
                    :items="header.vautoName"
                    item-value="id"
                    :item-text="header.itemText"
                    dense
                    outlined
                    clearable
                    class="rounded-lg"
                    hide-details
                    :rules="header.require ? [v => !!v || 'Campo es requerido'] : []"
                    @input="inputAuto(header.inputauto, createFormCabecera[header.value], header.vautoName, header.formfield, header.vfunctname)"
                  ></v-autocomplete>
                  <v-combobox
                    v-if="header.vcombo"
                    v-model="createFormCabecera[header.value]"
                    :label="header.text"
                    :items="header.hasgetter ? $store.getters[header.vcomboName] :$store.state[header.vcomboName]"
                    dense
                    outlined
                    class="rounded-lg"
                    hide-details
                    :rules="header.require ? [v => !!v || 'Campo es requerido'] : []"
                    multiple
                    chips
                  ></v-combobox>
                  <v-menu
                    v-if="header.vdate"
                    v-model="fecha[header.value]"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="text-body-2 custom-form-text-fields rounded-lg"
                        dense
                        v-model="createFormCabecera[header.value]"
                        :label="header.text"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="createFormCabecera[header.value]"
                      @input="fecha[header.value] = false"
                    ></v-date-picker>
                  </v-menu>

                  <v-menu
                    v-if="header.vtime"
                    ref="menu"
                    v-model="tiempo[header.value]"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    class="pt-1"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="createFormCabecera[header.value]"
                        :label="header.text"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        class="pt-1 mb-2"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="tiempo[header.value]"
                      v-model="createFormCabecera[header.value]"
                      full-width
                      @click:minute="tiempo[header.value] = false"
                    ></v-time-picker>
                  </v-menu>
                </v-col>

                <v-col width="100%">
                    <div class="d-flex justify-end py-0 float-right">
                      <!-- <v-tooltip top v-if="tipoModulo=='FC'">
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :disabled="!validCabecera"
                          v-bind="attrs"
                          v-on="on"
                          @click="openNote"
                        >
                          <v-icon color="blue" >
                            mdi-note
                          </v-icon>
                        </v-btn>
                        </template>
                        <span>Datos Adicionales</span>
                      </v-tooltip> -->

                      <!-- gaurdar cabecera proforma -->
                      <!-- <v-tooltip top v-if="tipoModulo=='PF'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            :disabled="!validCabecera"
                            v-bind="attrs"
                            v-on="on"
                            @click="openNote"
                          >
                            <v-icon color="blue" >
                              mdi-note
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Notas</span>
                      </v-tooltip> -->

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :disabled="!validCabecera"
                          @click="editModeEncabezado ? submitInfo('PUT', 'Encabezado') :   submitInfo('POST', 'Encabezado')"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon :color="editModeEncabezado ? 'warning' : 'green' " >
                            mdi-content-save
                          </v-icon>
                        </v-btn>
                        </template>
                        <span>{{editModeEncabezado ? 'Modificar Cabecera' : 'Agregar Cabecera' }}</span>
                      </v-tooltip>

                    </div>
                  </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>

          </v-form>

          <!-- Body -->
          <v-form v-model="validLine" ref="createFormBodyLine" lazy-validation>
            <v-card-text class="py-1 pl-5 pr-4">
              <v-tabs v-model="tab">
                <v-tab v-for="item in itemsTab" :key="item.tab">
                  {{ item.tab }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in itemsTab" :key="item.tab">
                  <v-card flat :disabled="!editModeEncabezado">
                    <!-- PRIMER TAB -->
                    <v-card-text v-if="item.content == 1" class="px-0 pt-3 pb-0">
                      <!-- Primeria linea Tab 1 -->
                      <v-row dense v-if="tipoModulo=='PF'">
                        <v-col cols="12" md="6" v-if="checkTotalGuia">
                          <span>Total de guias: {{guias.length}}, </span>
                          <span>La cantidad de guias supera parametro de alerta de 250, favor revisar el porque hay guias resagadas.</span>
                        </v-col>
                        <v-col cols="12" md="6" v-if="checkTotalAjuste">
                          <span>Total de ajustes: {{ajustes.length}}, </span>
                          <span>La cantidad de ajustes supera parametro de alerta de 150, favor revisar el porque hay ajustes resagadas.</span>
                        </v-col>
                      </v-row>
                      <v-row dense align="center" class="mb-1">
                        <!-- <v-col cols="6"
                          class="d-flex align-center justify-center">
                          <BaseAutoComp
                            :key="keyAutoDestino"
                            textoBusqueda="Seleccionar guia ..."
                            label="Buscar Guia..."
                            :itemText="['id', 'numero', 'destino']"
                            itemValue="id"
                            :advanceSearchFields="{
                              'a.numero': null,
                              'b.destino': null,
                            }"
                            rutaItemAuto="transjuma/GuiaSelect"
                            :rutaItemAutoParamAdicional="{
                              'a.transportista_id': transportistaCabecera
                            }"
                            :procesoCompleto="procesoCompleto"
                            :modoEdicion="themodeEdicion"
                            :itemEditar="selectedItemEditar"
                            v-on:change-auto="selectedDestino"
                            fieldName="guia"
                            :outline="true"
                            alternativeRutaItemAuto="transjuma/Guia/"
                          >

                          </BaseAutoComp>
                        </v-col> -->

                        <v-col cols="12"
                          v-for="(header, i) in headersFilterDetalles"
                          :key="i"
                          :md="header.colmd"
                          class="d-flex align-center justify-center"
                          >
                          <v-btn
                            v-if="header.vicon"
                            small
                            icon
                            color="primary"
                            @click="metodoIcono(header.textComponent)"
                          >
                            <v-icon >{{header.text}}</v-icon>
                          </v-btn>
                          <v-textarea
                            v-if="header.vtextarea"
                            v-model="createFormBodyLine[header.value]"
                            :label="header.text"
                            dense
                            outlined
                            class="rounded-lg"
                            hide-details
                            rows="6"
                          ></v-textarea>
                          <v-text-field
                            v-if="header.vtext"
                            v-model="createFormBodyLine[header.value]"
                            :label="header.text"
                            dense
                            outlined
                            class="rounded-lg"
                            hide-details
                            :rules="header.require ? [v => !!v || 'Campo es requerido'] : []"
                          ></v-text-field>
                          <v-autocomplete
                            v-if="header.vauto"
                            v-model="createFormBodyLine[header.value]"
                            :label="header.text"
                            :items="header.vautoName"
                            item-value="id"
                            :item-text="itemTextAuto"
                            :disabled="autoEditDisable"
                            dense
                            outlined
                            clearable
                            class="rounded-lg"
                            hide-details
                            :rules="header.require ? [v => !!v || 'Campo es requerido'] : []"
                            @input="inputAuto(header.inputauto, createFormBodyLine[header.value], header.vautoName, header.formfield, header.vfunctname)"
                          ></v-autocomplete>
                          <v-combobox
                            v-if="header.vcombo"
                            v-model="createFormBodyLine[header.value]"
                            :label="header.text"
                            :items="header.hasgetter ? $store.getters[header.vcomboName] :$store.state[header.vcomboName]"
                            dense
                            outlined
                            class="rounded-lg"
                            hide-details
                            :rules="header.require ? [v => !!v || 'Campo es requerido'] : []"
                            multiple
                            chips
                          ></v-combobox>
                          <v-menu
                            v-if="header.vdate"
                            v-model="fechaDetalle[header.value]"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                class="text-body-2 custom-form-text-fields rounded-lg"
                                dense
                                v-model="createFormBodyLine[header.value]"
                                :label="header.text"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="createFormBodyLine[header.value]"
                              @input="fechaDetalle[header.value] = false"
                            ></v-date-picker>
                          </v-menu>

                          <v-menu
                            v-if="header.vtime"
                            ref="menu"
                            v-model="tiempo[header.value]"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            class="pt-1"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="createFormBodyLine[header.value]"
                                :label="header.text"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                                class="pt-1 mb-2"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="tiempo[header.value]"
                              v-model="createFormBodyLine[header.value]"
                              full-width
                              @click:minute="tiempo[header.value] = false"
                            ></v-time-picker>
                          </v-menu>

                        </v-col>

                        <v-col cols="12" md="6">
                          <v-autocomplete
                            v-model="createFormBodyLine.guia"
                            label="Guia"
                            :items="guias"
                            item-value="id"
                            :item-text="itemTextAuto"
                            :disabled="autoEditDisable"
                            dense
                            outlined
                            clearable
                            class="rounded-lg"
                            hide-details
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-autocomplete
                            v-model="createFormBodyLine.ajuste_detalle"
                            label="Ajuste"
                            :items="ajustes"
                            item-value="id"
                            :item-text="itemTextAuto"
                            :disabled="autoEditDisable"
                            dense
                            outlined
                            clearable
                            class="rounded-lg"
                            hide-details
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="6" v-if="tipoModulo == 'AG'">
                          <v-autocomplete
                            v-model="createFormBodyLine.deduccion"
                            label="Deduccion"
                            :items="deducciones"
                            item-value="id"
                            :item-text="itemTextAuto"
                            :disabled="autoEditDisable"
                            dense
                            outlined
                            clearable
                            class="rounded-lg"
                            hide-details
                          ></v-autocomplete>
                        </v-col>



                        <!-- ACCIONES DE LA LINEA -->
                        <v-col cols="12" md="2">
                          <div v-if="!editLine" class="d-flex justify-center">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  @click="submitInfo('POST', 'Detalle')"
                                  v-bind="attrs"
                                  v-on="on"
                                  :disabled="checkTotalDetalles"
                                >
                                  <v-icon color="blue"> mdi-plus-box </v-icon>
                                </v-btn>
                              </template>
                              <span>Agregar Linea</span>
                            </v-tooltip>

                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="cancelAction"
                                >
                                  <v-icon color="red"> mdi-cancel </v-icon>
                                </v-btn>
                              </template>
                              <span>Cancelar Accion</span>
                            </v-tooltip>

                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  @click="vistaPrevia"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon color="purple">
                                    mdi-printer-search
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Vista Preliminar</span>
                            </v-tooltip>

                            <!-- <v-tooltip
                              top
                              v-if="tipoModulo == 'AS' || tipoModulo == 'PF'"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  :disabled="totales.TOTAL == 0"
                                  icon
                                  @click="enviar"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon color="success">
                                    mdi-receipt-text-arrow-right-outline
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Enviar Factura</span>
                            </v-tooltip>

                            <v-tooltip top v-if="tipoModulo == 'PF'">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  @click="enviar"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon color="success">
                                    mdi-email-fast-outline
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Enviar Correo</span>
                            </v-tooltip> -->
                          </div>

                          <div v-else class="d-flex justify-center">
                            <v-tooltip top >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  @click="submitInfo('PUT', 'Detalle')"
                                  v-bind="attrs"
                                  v-on="on"
                                  :disabled="autoEditDisable"
                                >
                                  <v-icon color="warning">
                                    mdi-content-save
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Modificar Linea</span>
                            </v-tooltip>

                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  @click="submitInfo('DELETE', 'Detalle')"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon color="red"> mdi-minus-box </v-icon>
                                </v-btn>
                              </template>
                              <span>Eliminar Linea</span>
                            </v-tooltip>

                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="cancelAction"
                                >
                                  <v-icon color="red"> mdi-cancel </v-icon>
                                </v-btn>
                              </template>
                              <span>Cancelar Accion</span>
                            </v-tooltip>
                          </div>
                        </v-col>

                      </v-row>

                      <!-- LINEAS DE DETALLE DEL DOCUMENTO -->
                      <v-row dense align="baseline" no-gutters>
                        <v-col cols="12" md="9">
                          <!-- data table detalle factura -->
                          <v-data-table
                            dense
                            :headers="headersFilterMiniDetalle"
                            :items="itemsDetalle"
                            disable-sort
                            :loading="isLoadingLines"
                            :items-per-page="5"
                            @dblclick:row="editLineClick"
                          >
                            <!-- pass through scoped slots -->
                            <template
                              v-for="(_, scopedSlotName) in $scopedSlots"
                              v-slot:[scopedSlotName]="slotData"
                            >
                              <slot :name="scopedSlotName" v-bind="slotData" />
                            </template>


                          </v-data-table>
                        </v-col>

                        <v-col cols="12" md="3" class="px-2 pt-2 pb-0">
                          <!-- SUMATORIAS -->
                          <!-- <v-row
                            align="center"
                            justify="end"
                            v-if="tipoModulo == 'PF'"
                          >
                            <v-col cols="12" md="3">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    @click="enviar"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="success">
                                      mdi-receipt-text-arrow-right-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Enviar Factura</span>
                              </v-tooltip>
                            </v-col>
                          </v-row> -->

                          <!-- FACTURA SUMATORIAS -->
                          <BaseSumatorias
                            :itemsSumatorias="itemsSumatorias"
                            :tipoModulo="tipoModulo"
                            :totales="totales"
                          ></BaseSumatorias>
                        
                        </v-col>
                      </v-row>
                    </v-card-text>

                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-form>
        </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { axiosC } from "../api/axiosbd";
import BaseAutoComp from '../components/BaseAutoComplet.vue'
import BaseSumatorias from '../components/BaseSumatorias.vue'
import { paginationMethods } from "../mixins/pagination";

export default {
  name: 'AsignacionForm',
  components:{
    BaseAutoComp, BaseSumatorias
  },
  props:{
    //PROPIEDADES DE SPECIAL AUTO
    specialAuto:{
      type: Boolean,
      required: false,
      default: false
    },
    itemSpecialAuto:{
      type: Object,
      required: false,
      default: function() {
        return {
          textoBusqueda: '',
          label: '',
          itemText: [],
          advanceSearchFields: {},
          rutaItemAuto: '',
        }
      }
    },
    modoEdicion:{
      type: Boolean,
      required: false,
      default: false
    },

    ///
    title: {
      type: String,
      required: true
    },
    tipoModulo:{
      type: String,
      required: true
    },
    modulo:{
      type: String,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    headersDetalle: {
      type: Array,
      required: true
    },
    urlFormEncabezado:{
      type: String,
      required: true
    },
    urlFormDetalle:{
      type: String,
      required: true
    },
    fieldCabeceraName:{
      type: String,
      required: true
    },
    cabeceraDocumento:{
      type: Object,
      required: false,
    },
    customValidate:{
      type: Function,
      required: false
    }

  },
  mixins: [paginationMethods],
  data() {
    return {
      dialog:true,
      fecha:{...this.headers.filter(item => item.vdate)},
      tiempo:{...this.headers.filter(item => item.vtime)},
      fechaDetalle:{...this.headersDetalle.filter(item => item.vdate)},
      tiempoDetalle:{...this.headersDetalle.filter(item => item.vtime)},
      createFormBodyLine: {},

      isLoading: false,

      validCabecera: true,
      createFormCabecera: {},
      loadingForm: false,
      editModeEncabezado: false,

      validLine: true,
      createFormBodyLine: {},
      isLoadingLines: false,
      editModeDetalle: false,
      editLine: false,

      tab: null,
      itemsTab: [
        { tab: "Detalle", content: 1 },
        // { tab: "Otros Cargos", content: 2 },
      ],
      
      itemsDetalle: [],
      itemsSumatorias: [
        { nombre: "Sub total", nombreForm: "SUBTOTAL", disable: [] },
        { nombre: "Descuento", nombreForm: "DESCUENTO", disable: [] },
        { nombre: "Impuesto", nombreForm: "IMPUESTO", disable: [] },
        { nombre: "Total", nombreForm: "TOTAL", disable: [] },
      ],
      keyTc: 0,
      facturaSelected: {},
      loadingHacienda: false,
      actividadKey: 0,
      enableCobroTerceroFields: false,
      keyAutoDestino: 0,
      document: null,
      editedItem: {},
      modoEdicionAuto: false,
      procesoCompleto: false,
      selectedItemEditar: null,
      keyAuto: 0,
      autoEditDisable: false,
      guias: [],
      ajustes: [],
      deducciones: [],
      autoEditDisableCabecera: false
    };
  },
  computed:{
    headersFilter(){
      if (this.headers){
        return this.headers.filter(h=> h.form == true)
      } else {
        return []
      }
    },
    headersFilterDetalles(){
      if (this.headersDetalle){
        return this.headersDetalle.filter(h=> h.form == true)
      } else {
        return []
      }
    },
    headersFilterMiniDetalle(){
      if (this.headersDetalle){
        return this.headersDetalle.filter(h=> h.showInnerDetail == true)
      } else {
        return []
      }
    },
    totales(){
      console.log(this.tipoModulo)
      if (this.tipoModulo == 'AG'){
        const SUBTOTAL = this.itemsDetalle.map(item => {
          if(item.guia){
            return parseFloat(item.guia.precio_base)
  
          } else if (item.ajuste_detalle){
            return parseFloat(item.ajuste_detalle.valor_adicional)
  
          } else if (item.deduccion){
            return parseFloat(item.deduccion.monto) *-1
  
          } else {
            return 0
          }
        }).reduce((a,b)=> a+b,0)
        const DESCUENTO = this.itemsDetalle.map(item => {
          if(item.guia){
            return parseFloat(item.guia.descuento)
  
          } else if (item.ajuste_detalle){
            return parseFloat(item.ajuste_detalle.descuento)
  
          } else {
            return 0
          }
        }).reduce((a,b)=> a+b,0)
        const IMPUESTO = this.itemsDetalle.map(item => {
          if(item.guia){
            return parseFloat(item.guia.iva)
  
          } else if (item.ajuste_detalle){
            return parseFloat(item.ajuste_detalle.iva)
  
          } else if (item.deduccion){
            return parseFloat(item.deduccion.iva) *-1
  
          } else {
            return 0
          }
        }).reduce((a,b)=> a+b,0)
        // const subtotal = this.itemsDetalle.map(item => item.ARTCAN*item.PREUNI).reduce((a,b)=> a+b,0)
        const TOTAL = SUBTOTAL + DESCUENTO + IMPUESTO
        return {
          SUBTOTAL,DESCUENTO, IMPUESTO, TOTAL
          }

      } else {
        const SUBTOTAL = this.itemsDetalle.map(item => {
          if(item.guia){
            return parseFloat(item.guia.precio_base)
  
          } else if (item.ajuste_detalle){
            return parseFloat(item.ajuste_detalle.valor_adicional)
  
          } else {
            return 0
          }
        }).reduce((a,b)=> a+b,0)

        const IMPUESTO = this.itemsDetalle.map(item => {
          if(item.guia){
            return parseFloat(item.guia.precio_base * 0.13)
  
          } else if (item.ajuste_detalle){
            return parseFloat(item.ajuste_detalle.valor_adicional * 0.13)
  
          } else {
            return 0
          }
        }).reduce((a,b)=> a+b,0)
        // const subtotal = this.itemsDetalle.map(item => item.ARTCAN*item.PREUNI).reduce((a,b)=> a+b,0)
        const TOTAL = SUBTOTAL + IMPUESTO
        return {
          SUBTOTAL, IMPUESTO, TOTAL
          }
      }
    },
    transportistaCabecera(){
      return this.createFormCabecera.transportista
    },
    checkTotalGuia(){
      if (this.tipoModulo == 'PF' && this.guias.length > 250){
        return true
      } else {
        return false
      }
    },
    checkTotalAjuste(){
      if (this.tipoModulo == 'PF' && this.ajustes.length > 150){
        return true
      } else {
        return false
      }
    },
    checkTotalDetalles(){
      if (this.tipoModulo == 'PF' && this.itemsDetalle.length < 20){
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    esEntero(numero) {
      return typeof numero === 'number' && numero % 1 === 0;
    },
    submitInfo(method, typeForm){

      let body = {}
      let url = ''
      let error = false
      let records = []

      if (typeForm == 'Encabezado'){
        this.loadingForm = true
        if (method == 'POST' && this.$refs.createFormCabecera.validate()){
          console.log(method, typeForm, this.createFormCabecera)
          body.company = 1;
          body.created_by = this.$store.state.AuthModule.refu
          body.updated_by = null
          body.ref = this.$store.state.AuthModule.r2

          body = {...body, ...this.createFormCabecera}

          url = this.urlFormEncabezado
          this.$refs.createFormCabecera.resetValidation()
          this.createFormCabecera = []

        } else if (method == 'PUT' && this.$refs.createFormCabecera.validate()) {
          body.company = 1;
          console.log(this.$store.state.AuthModule.refu,  this.createFormCabecera)
          if (this.createFormCabecera.hasOwnProperty('updated_by')){
            this.createFormCabecera.updated_by = this.$store.state.AuthModule.refu
          } else {
            body.updated_by =  this.$store.state.AuthModule.refu
          }
          body.ref = this.$store.state.AuthModule.r2

          body = {...body, ...this.createFormCabecera}

          url = this.urlFormEncabezado

          this.$refs.createFormCabecera.resetValidation()
          this.createFormCabecera = []

        } else {
          error = true
        }
      }

      if (typeForm == 'Detalle'){
        this.loadingForm = true
        const validateAtLeast = this.createFormBodyLine.guia || this.createFormBodyLine.ajuste_detalle
        if (method == 'POST' && validateAtLeast){
          body.company = 1;
          body.created_by =  this.$store.state.AuthModule.refu
          body.updated_by =  null
          body.ref = this.$store.state.AuthModule.r2

          if (this.tipoModulo == 'AG'){
            if (!body.hasOwnProperty('asignacion_cabecera')){
              body.asignacion_cabecera = this.createFormCabecera.id
            }
          } else {
            if (!body.hasOwnProperty('proforma_cabecera')){
              body.proforma_cabecera = this.createFormCabecera.id
            }
          }

          for (let key in  this.createFormBodyLine){
            if (key == 'guia'){
              records.push({...body, guia: this.createFormBodyLine.guia})

            }
            if (key == 'ajuste_detalle'){
              records.push({...body, ajuste_detalle: this.createFormBodyLine.ajuste_detalle})

            }
            if (key == 'deduccion'){
              records.push({...body, deduccion: this.createFormBodyLine.deduccion})

            }
          }

          //validar que no pase de 20 registros el documento
          if ((records.length + this.itemsDetalle.length) > 20 ) {
            error = true
          }


          url = this.urlFormDetalle
        } else if (method == 'DELETE'){
          body = {...this.createFormBodyLine}
          url = this.urlFormDetalle
        } else if (method == 'PUT'){
            body = {...this.createFormBodyLine}
            console.log(method,body)
            body.updated_by =   this.$store.state.AuthModule.refu
          
            // body.estado_guia =  this.esEntero(body.estado_guia) ? body.estado_guia : body.estado_guia.id
            // body.guia = this.esEntero(body.guia) ? body.guia : body.guia.id
            // body.transportista = this.esEntero(body.transportista) ? body.transportista : body.transportista.id
            // body.tipo_ajuste = this.esEntero(body.tipo_ajuste) ? body.tipo_ajuste : body.tipo_ajuste.id
            url = this.urlFormDetalle
        }
        else {
          error = true
        }
        console.log(body)
      }
      if (!error){

        axiosC({
          method: method,
          url: `${url}${method == "PUT" ||  method == "DELETE" ? body.id + "/" : ""}`,
          data: typeForm == 'Detalle' ? records : method != "DELETE" ? body : {},
          headers: {
            Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
          },
        })
          .then((response) => {
            if (typeForm == 'Encabezado'){
              this.editModeEncabezado = true
              if (method == 'POST') {
                this.createFormCabecera = {...response.data}
                this.createFormCabecera.fecha = this.createFormCabecera.fecha.substring(0,10)

                if (this.tipoModulo == 'AG'){
                  this.createFormBodyLine.asignacion_cabecera = this.createFormCabecera.id
                } else {
                  this.createFormBodyLine.proforma_cabecera = this.createFormCabecera.id
                }
                this.createFormBodyLine.fecha = this.createFormCabecera.fecha.substring(0,10)
              }
              if (method == 'PUT') {
                this.createFormCabecera = {...response.data}
                this.createFormCabecera.fecha = this.createFormCabecera.fecha.substring(0,10)
              }
              this.resultLines()
              this.loadingForm = false
              console.log(this.createFormCabecera)
            }

            if (typeForm == 'Detalle'){

              this.editModeDetalle = true
              // this.close();
              this.resultLines()
              this.resultDataDocument()
              this.cancelAction()
              this.loadingForm = false
              this.$emit('update-List')
              this.autoEditDisable = false
            }
          })
          .catch((err) =>  {
            this.loadingForm = false
            this.autoEditDisable = false
            alert(err)
          })
      } else {

        if (this.tipoModulo == 'PF'){
          if ((records.length + this.itemsDetalle.length) > 20 ){
            records = []
            this.createFormBodyLine = {}
            const total  = 20 -  this.itemsDetalle.length

            this.$message({
              message: 'la cantidad de regisaros a ingresar excede el maximo de 20 permitidos por Proforma, solo puede ingresar: ' +  total + ' registros.',
              type: 'warning',
              showClose: true,
              duration: 0
            })
            this.loadingForm = false
          }
        } else {
          this.loadingForm = false
          this.autoEditDisable = false
          this.$message({
            message: 'Info, Debe haber al menos una guia o un ajuste seleecionado.',
            type: 'warning'
          });
        }
      }
    },
    close() {
    },
    closeBaseWindow(){
      this.mode = "POST";
      this.editedItem = {}
      this.search =  null
      this.editModeEncabezado = false
      this.procesoCompleto = true
      this.modoEdicionAuto = false
      this.selectedItemEditar = null
      this.$emit('cierre-form')
      this.autoEditDisableCabecera = false
    },
    vistaPrevia(){

    },
    cancelAction(){
      this.editModeDetalle = true
      this.editLine = false
      this.createFormBodyLine = {}
      this.$refs.createFormBodyLine.resetValidation()

      this.modoEdicionAuto = false
      this.selectedItemEditar = null
      this.procesoCompleto = true
      this.autoEditDisable = false

    },
    editLineClick(event, {item}){

      if (this.customValidate(item)){
        this.autoEditDisable = true
        console.warn('MODO EDITAR ACTIVADO')
        console.log(item)
        this.keyAuto ++
        this.editLine = true
        this.createFormBodyLine = {...item}

        // if (item.guia) {
        //   this.createFormBodyLine.guia = item.guia.id
        // } else if (item.ajuste_detalle){
        //   this.createFormBodyLine.ajuste_detalle = item.ajuste_detalle.id
        // } else if (item.deduccion){
        //   this.createFormBodyLine.deduccion = item.deduccion.id
        // }


        // this.modoEdicionAuto = true
        this.$nextTick(()=>{
          this.keyAuto ++
          this.modoEdicionAuto = true
          this.procesoCompleto = false
          // this.selectedItemEditar =  item.guia //se envia al componente auto
        })
      } else {
        let mensaje = ''
        if (this.tipoModulo == 'AG'){
          mensaje = 'Info, El registro seleccionado debe estar en estado asignado y/o flete para ser modificado.'
        } else [
          mensaje = 'Info, El registro seleccionado debe estar en estado proforma y/o asigando para ser modificado.'
        ]
        this.$message({
          message: mensaje,
          type: 'warning'
        });
      }

    },
    inputAuto(activar, selected, items, formField, vfunctname){
      if (vfunctname){
        this[vfunctname](selected, items)
      }
      if (activar) {
        const select = items.find(k => k.id == selected)
        if (select) {
          this.createFormCabecera[formField] =`${select.texto_mensaje}`
          console.log(this.createFormCabecera)
        }
      }
    },
    updatePrecio(selected, items){
      console.log(selected, items)
      const precio = items.find( x => x.id == selected)

      if (precio) {
        // this.createFormBodyLine.valor_adicional = this.$convertToMoney(parseFloat(precio.valor_adicional))
        // this.createFormBodyLine.descuento = this.$convertToMoney(parseFloat(precio.valor_adicional) * 0.1)
        // this.createFormBodyLine.iva = this.$convertToMoney(parseFloat(precio.valor_adicional) * 0.13)

        let valor_adicional = parseFloat(precio.valor_adicional)
        let descuento = parseFloat(precio.valor_adicional) * -0.1
        let iva = (valor_adicional + descuento) * 0.13

        this.createFormBodyLine.valor_adicional = valor_adicional.toFixed(2)
        this.createFormBodyLine.descuento = descuento.toFixed(2)
        this.createFormBodyLine.iva = iva.toFixed(2)

        console.log(this.createFormBodyLine)

      }
    },
    async getDataDocumento(url, fields, itemsName, loading, isquery=true, one=false, normal=true){
      const query = new URLSearchParams(fields)
        // Lazily load input items
        await this.getDataGeneral(
          "GET",
          url,
          itemsName,
          loading,
          {},
          false, //paginacion
          false, // opcion para que devuelva algo
          normal ? true : false, //modo normal activado, vienen todos los resultados
          isquery ? query : false, // parametros para busqueda avanzada
          one ? true : false
        )
    },
    async resultLines(){
      this.isLoading = true;
      this.loadingFormDetalle = true
      // let fields = {}
      // fields[this.fieldCabeceraName] = this.createFormCabecera.id

      // this.getDataDocumento(this.urlFormDetalle, fields, 'itemsDetalle', "isLoading")
      let query = ''
      if (this.tipoModulo == 'AG'){
        query =  {
          estado_guia: 'Flete',
          auto: true,
          transportista_id: this.createFormCabecera.transportista
        }

        // DEDUCCION
        const queryD =  {
          auto: true,
          transportista_id: this.createFormCabecera.transportista,
          pago_proveedores_detalle_id: 'null',
          estado_deduccion: 'Flete'
        }

        this.getDataGeneral(
          "GET",
          `transjuma/DeduccionSelect`,
          'deducciones',
          "loading",
          {},
          false, //paginacion
          false, // opcion para que devuelva algo
          true, //modo normal activado, vienen todos los resultados
          null, // parametros query
          false, //unico registro
          queryD
        )

      } else {
        query =  {
        estado_guia: 'Asignada',
        estado_asignacion: 'Confirmada',
        auto: true,
        }
      }

      //GUIA
      this.getDataGeneral(
        "GET",
        `transjuma/GuiaSelect`,
        'guias',
        "loading",
        {},
        false, //paginacion
        false, // opcion para que devuelva algo
        true, //modo normal activado, vienen todos los resultados
        null, // parametros query
        false, //unico registro
        query
      )

      //AJUSTES
      this.getDataGeneral(
        "GET",
        `transjuma/AjusteSelect`,
        'ajustes',
        "loading",
        {},
        false, //paginacion
        false, // opcion para que devuelva algo
        true, //modo normal activado, vienen todos los resultados
        null, // parametros query
        false, //unico registro
        query
      )
     
    },

    resultDataDocument(){
      // CUANDO VIENE DEL MODO EDITAR

      this.loadingForm = true
      if (this.modoEdicion){
        console.log('EDICION BUSCAR DOC')
        this.editModeEncabezado = true
        // this.modoEdicionAuto = true
        //ENCABEZADO
        this.createFormCabecera = {...this.cabeceraDocumento}
        this.createFormCabecera.fecha = this.createFormCabecera.fecha.substring(0,10)
        this.isLoading = true;
        let fields = {}
        this.autoEditDisableCabecera = true
        // fields['id'] = this.createFormCabecera.id

        // this.getDataDocumento(this.urlFormEncabezado + `${this.createFormCabecera.id}/`, null, 'createFormCabecera', "loadingForm", false, true, false)
        
        console.log('CABECERA', this.createFormCabecera, this.createFormCabecera )
        this.loadingForm = false
        //====================================

        //DETALLE
        this.isLoading = true;
        // let fieldsDetalle = {}
        // fieldsDetalle[this.fieldCabeceraName] = this.createFormCabecera.id
        

        this.getDataDocumento(this.urlFormDetalle, this.tipoModulo == 'AG' ? {asignacion_cabecera_id: this.createFormCabecera.id} : {proforma_cabecera_id: this.createFormCabecera.id}, 'itemsDetalle', "isLoadingLines")
      } else if (this.editModeDetalle) {
        console.log('DESPUES DEL POST', this.createFormCabecera)
        this.autoEditDisableCabecera = true
        this.loadingForm = false
        this.getDataDocumento(this.urlFormDetalle, this.tipoModulo == 'AG' ? {asignacion_cabecera_id: this.createFormCabecera.id} : {proforma_cabecera_id: this.createFormCabecera.id}, 'itemsDetalle', "isLoadingLines")
      }
      else {
        this.loadingForm = false
        console.log('EDICION NO HABILITADA')
      }
    },

    itemTextAuto(item){
      // console.log(item)
      const numero = item.numero ? item.numero : item.numero_guia ? item.numero_guia : ''
      const detalle = item.destino_tonelada ? item.destino_tonelada : item.tipo_ajuste ? item.tipo_ajuste : ''
      const detalle2 = item.destino ? item.destino : item.descripcion_detallada ? item.descripcion_detallada : item.tipo_deduccion ? item.tipo_deduccion : ''
      return `${item.fecha.substring(0,10)}//-->${numero} == ${detalle} -- ${detalle2}`
    },

    //FUNCIONES DE SPECIAL AUTO
    selectedSpecialAuto(item){
      console.log(item)
      this.createFormBodyLine[item.label] = item.itemSelected ? item.itemSelected.id : null
    },

  },
  created(){
    console.log('FORM CREADO')
    this.resultLines()
    this.resultDataDocument()
  },

}
</script>

<style>


</style>
