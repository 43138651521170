<template>
  <v-container fluid>
    <v-card class="rounded-xl">
      <v-card-title>
        Modulo configuración
      </v-card-title>
      <v-card-subtitle>
        Darle click al icono desplegara el menu de creacion para cada tipo.
      </v-card-subtitle>
      <v-card-text class="pt-3">
        <v-row align="center">
          <v-col cols="12" md="1" class="d-flex flex-column justify-center align-center" v-for="(item, i) in items"
            :key="i">
            <v-btn icon color="primary" @click="openMenu(item)">
              <v-icon v-text="item.icon"></v-icon>
            </v-btn>
            <div class="mt-1 text-caption text-center ">
              <span>{{ item.text }}</span>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  <Sedes v-if="addSedes" />
  <Tipo_facturar v-if="addTipoFacturar" />
  <Tipo_identificaciones v-if="addTipoIdentificacion" />
  <Tipo_deducciones v-if="addTipoDeduccion" />
  <Estado_guias v-if="addEstadoGuia" />
  <Tipo_ajustes v-if="addTipoAjuste" />
  <Esatdo_asignaciones v-if="addEstadoAsignacion" />
  <Banco v-if="addBanco" />
  <Metodo_pagos v-if="addMetodoPago" />
  <Estado_pagos v-if="addEstadoPago" />

  </v-container>

</template>

<script>
import { mapState } from 'vuex'
import Sedes from './configuracion/Sedes.vue'
import Tipo_facturar from './configuracion/Tipo_facturar.vue'
import Tipo_identificaciones from './configuracion/Tipo_identificaciones.vue'
import Tipo_deducciones from './configuracion/Tipo_deducciones.vue'
import Estado_guias from './configuracion/Estado_guias.vue'
import Tipo_ajustes from './configuracion/Tipo_ajustes.vue'
import Esatdo_asignaciones from './configuracion/Esatdo_asignaciones.vue'
import Banco from './configuracion/Banco.vue'
import Metodo_pagos from './configuracion/Metodo_pagos.vue'
import Estado_pagos from './configuracion/Estado_pagos.vue'


export default {
  components: {Sedes, Tipo_facturar, Tipo_identificaciones, Tipo_deducciones, Estado_guias, Tipo_ajustes, Esatdo_asignaciones, Banco, Metodo_pagos, Estado_pagos},
  data() {
    return {
      items: [
        { icon: "mdi-clipboard-list-outline", text: "Sedes", route: { name: 'Sedes' }, action: 'addSedes' },
        { icon: "mdi-account-check-outline", text: "Tipos facturar", route: { name: 'Tipo_facturar' }, action: 'addTipoFacturar' },
        { icon: "mdi-bell-alert-outline", text: "Tipos de identificación", route: {name: 'Tipo_identificaciones'}, action: 'addTipoIdentificacion' },
        { icon: "mdi-phone-ring", text: "Tipos de deducción", route: { name: 'Tipo_deducciones' }, action: 'addTipoDeduccion' },
        { icon: "mdi-currency-usd", text: "Estados de guías", route: { name: 'Estado_guias' }, action: 'addEstadoGuia' },
        { icon: "mdi-account-details-outline", text: "Tipos de ajuste", route: { name: 'Tipo_ajustes' }, action: 'addTipoAjuste' },
        { icon: "mdi-note-multiple-outline", text: "Estados de asignación", route: { name: 'Esatdo_asignaciones' }, action: 'addEstadoAsignacion' },
        { icon: "mdi-account-group-outline", text: "Bancos", route: { name: 'Banco' }, action: 'addBanco' },
        { icon: "mdi-note-text", text: "Metodos de pago", route: { name: 'Metodo_pagos' }, action: 'addMetodoPago' },
        { icon: "mdi-note-text", text: "Estados de pago", route: { name: 'Esatdo_pagos' }, action: 'addEstadoPago' },
      ],

    }
  },
  methods: {
    openMenu(item) {
      console.log(item)
      this.$store.state[item.action] = true
    }
  },
  computed: {
    ...mapState([
      'addSedes', 'addTipoFacturar', 'addTipoIdentificacion', 'addTipoDeduccion', 'addEstadoGuia', 'addTipoAjuste', 'addEstadoAsignacion', 'addBanco', 'addMetodoPago', 'addEstadoPago'
    ])
  }
}
</script>

<style></style>