<template>
  <div>
    <AsignacionBase
      :asignacion-global="true"
      tipoModulo="PF"
    ></AsignacionBase>
  </div>
</template>

<script>
import AsignacionBase from '@/components/AsignacionBase.vue'

export default {
  name: 'Proforma',
  components:{
    AsignacionBase
  }
}
</script>

<style>

</style>