<template>
  <v-app id="inspire">
    <v-navigation-drawer v-if="checkLogin" v-model="drawer" app width="220">
      <div class="px-5" style="background: #2e335b">
        <v-list-item class="pa-0" color="#">
          <v-img height="50" src="./assets/logo-juma.jpg"> </v-img>
        </v-list-item>
      </div>

      <v-list dense rounded nav class="mt-6">
        <v-list-item-group color="#0D47A1">
          <div v-for="item in Object.keys(items)" :key="item">
            <v-list-group v-if="items[item].type == 'group'" no-action>
              <template v-slot:activator>
                <v-list-item-icon class="ml-1 mr-3">
                  <v-icon v-text="items[item].icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title color="#0D47A1">{{
                  items[item].title
                }}</v-list-item-title>
              </template>
              <template v-slot:appendIcon>
                <v-icon>mdi-menu-down</v-icon>
              </template>

              <v-list-item
                color="#0D47A1"
                v-for="option in items[item].options"
                :key="option.title"
                :to="option.path"
                @click="navigation(option.path)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    option.title.charAt(0).toUpperCase() + option.title.slice(1)
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-item
              color="#0D47A1"
              v-if="items[item].type == 'single'"
              :key="items[item].title"
              :to="items[item].path"
              @click="navigation(items[item].path)"
            >
              <v-list-item-icon class="ml-1 mr-3" v-if="items[item].icon">
                <v-icon>{{ items[item].icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{
                  items[item].title.charAt(0).toUpperCase() +
                  items[item].title.slice(1)
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
       <v-container>
         <div class="px-2">
          <v-btn color="#2e335b" class="white--text" @click="logout" block rounded>
            Logout
          </v-btn>
        </div>
       </v-container>
      </template>
    </v-navigation-drawer>

    <v-app-bar height="50" v-if="checkLogin" app color="#2e335b">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        color="white"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="white--text">{{menuOption}}</v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- Boton destinos -->
      <v-tooltip bottom>
        <template v-slot:activator="{on, attrs}">
          <v-btn
            dark
            v-bind="attrs"
            v-on="on"
            icon
            @click="dialogVerDestinos = true"
            >
            <v-icon>sell</v-icon>
          </v-btn>
        </template>
        <span>Buscar Destino</span>
      </v-tooltip>

      <!-- boton cambio de precios -->
      <v-tooltip bottom v-if="userPriceChange">
        <template v-slot:activator="{on, attrs}">
          <v-btn
            dark
            v-bind="attrs"
            v-on="on"
            icon
            @click="dialog = true"
            >
            <v-icon>mdi-key-change</v-icon>
          </v-btn>
        </template>
        <span>Cambiar precios</span>
      </v-tooltip>
    </v-app-bar>


    <v-main>
      <!-- aceptacion de disclamer -->
      <v-row align="center" justify="center">
        <v-col cols="12">
          <div class="text-center">
            <v-dialog
              v-model="dialogVerDestinos"
              width="500"
            >
              <v-card :loading="isLoading" :disabled="isLoading" rounded="xl">
                <v-card-title 
                  class="text-h6 grey lighten-2">
                  Busqueda de precios Destinos Tonelada
                </v-card-title>

                <v-card-text>
                  <BaseAutoComp
                    fieldName="destino_tonelada"
                    textoBusqueda="Destino y/o codigo tonelada"
                    label="Buscar Destino..."
                    :itemText="['numero', 'tonelada', 'codigo_destino', 'destino', 'neto_pagar']"
                    itemValue="id"
                    :advanceSearchFields="{
                      numero: null,
                      codigo_destino: null,
                      destino: null,
                      destino: null,
                    }"
                    rutaItemAuto="transjuma/DestinoTonelada/"
                    :modoEdicion="false"
                  >

                  </BaseAutoComp>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <!-- <v-btn
                    color="primary"
                    text
                    @click="changePrice"
                  >
                    Acepto
                  </v-btn> -->
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-col>
      </v-row>

      <!-- aceptacion de disclamer -->
      <v-row align="center" justify="center" v-if="dialog">
        <v-col cols="12">
          <div class="text-center">
            <v-dialog
              v-model="dialog"
              width="500"
            >
              <v-card :loading="isLoading" :disabled="isLoading">
                <v-card-title class="text-h5 grey lighten-2">
                  Politica cambios de precios
                </v-card-title>

                <v-card-text>
                  <p class="text-justify">
                    La política de cambio de precios contiene 2 elementos en su base, el precio este compuesto de 2 partes la primera que es un 70% y la segunda que es un 30%, la parte que siempre toma en consideración para los aumentos o disminución por políticas internas del país es siempre aumentar en un porcentaje indicado el 30%, es decir si aumentamos un 10% el aumento correspondería a el 10% del 30% y al final se suma todo.
                    Recordar que esto es irreversible desde la interface y cualquier reversión de dicha política se debe brindar un ejemplo de como estaba anteriormente ya que el sistema no guarda los precios anteriores.
                  </p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="changePrice"
                  >
                    Acepto
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-col>
      </v-row>

      <!-- form con datos -->
      <v-row align="center" justify="center" v-if="dialogForm">
        <v-col cols="12">
          <div class="text-center">
            <v-dialog
              v-model="dialogForm"
              width="550"
            >
              <v-card :loading="isLoading" :disabled="isLoading" >
                <v-form 
                  ref="createForm"
                  v-model="valid"
                  lazy-validation
                >
                  <v-card-title class="text-h5 grey lighten-2">
                    Actualizacion de precios
                  </v-card-title>

                  <v-card-text>
                      <v-row align="center" class="px-2">
                        <v-col cols="12" class="mt-5">
                           <v-autocomplete
                            v-model="createForm.component"
                            chips
                            clearable
                            dense
                            label="Tipo de Accion"
                            :items="optionsValues"
                            item-text="value"
                            item-value="id"
                            :rules="rules"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row align="center" class="px-2">
                        <v-col cols="12" >
                           <v-autocomplete
                            v-model="createForm.tipo"
                            chips
                            clearable
                            dense
                            label="Tpos de Tarifa a cambiar"
                            :items="optionsType"
                            item-text="value"
                            item-value="id"
                            :rules="rules"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row align="center" class="px-2">
                        <v-col cols="12"  md="6">
                          <v-autocomplete
                            v-model="createForm.action"
                            chips
                            clearable
                            dense
                            label="Tipo de Accion"
                            :items="optionsActions"
                            item-text="value"
                            item-value="id"
                            :rules="rules"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12"  md="6">
                          <v-text-field
                          dense
                          type="number"
                          v-model.number="createForm.percent"
                          label="Porcentaje de cambio"
                          :rules="rules"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                  
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :disabled="isLoading"
                      color="warning"
                      text
                      @click="cerrar"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="isLoading"
                      color="primary"
                      text
                      @click="summit()"
                    >
                      Cambiar
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
              
            </v-dialog>
          </div>
        </v-col>
      </v-row>

      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { axiosC } from "./api/axiosbd"
import axios from 'axios'
import BaseAutoComp from '@/components/BaseAutoComplet.vue';

export default {
  components: {
    BaseAutoComp
  },
  data() {
    return {
      dialogVerDestinos: false,
      isLoading: false,
      drawer: null,
      items: [],
      menuOption: null,
      dialog: false,
      dialogForm: false,
      optionsActions: [
        {id: '+', value: 'Aumento'},
        {id: '-', value: 'Disminucion'}
      ],
      optionsValues: [
        {id: '70', value: '70% Comp Tarifa'},
        {id: '30', value: '30% Comp Combustible'}
      ],
      optionsType: [
        {id: 'F', value: 'Fija'},
        {id: 'V', value: 'Variable'}
      ],
      createForm: {},
      valid: true,
      rules: [
        v => !!v || 'Name is required',
      ]
    };
  },
  computed:{
    userPriceChange(){
      console.log(this.$store.state.AuthModule.refu)
      // return true
      if (this.$store.state.AuthModule.refu == '1' || this.$store.state.AuthModule.refu == '2' ){
        return true
      } else {
        return false
      }
    },
    checkLogin(){
      return this.$store.state.menuOption
    }
  },
  methods: {
    changePrice(){
      this.dialog = false
      this.dialogForm = true
    },
    async summit(){
      this.isLoading = true
      this.createForm.user = this.$store.state.AuthModule.refu

      if (this.$refs.createForm.validate()) {
        let token = await this.getToken()
        axiosC({
          method: "POST",
          data: this.createForm,
          baseURL: `https://connect.webdgroup.com/extractor/api/jumaCambioPrecios`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          alert('cambio en precios realizado')
          this.isLoading = false
          this.dialogForm = false
          this.$refs.createForm.reset()
          // console.log(res)
          // alert(`${res.status? res.status: res} El correo o correos se han enviado, revisar en la bandeja de salida del correo para confirmar`)
        })
        .catch(err => {
          this.isLoading = false
          console.log(err)
          // alert(`Hubo un problema con el envio del correo, Error: ${err}`)
        })

      } else {
        alert('completar los campos indicadfos')
        this.isLoading = false
      }
    },

    cerrar(){
      this.dialogForm = false
      this.$refs.createForm.reset()
    },

    async getToken() {
      try {
        const response = await axios.post(
          "https://connect.webdgroup.com/api/token/",
          {
            username: "david",
            password: "And11305@!",
          }
        );
        return response.data.access;
      } catch (error) {
        console.error(error);
      }
    },

    navigation(option) {
      this.menuOption = option;
    },
    getCurrentOption() {
      // this.$store.dispatch('getTitle')
      // this.menuOption = true
      this.items = this.$store.state.allOptions;
    },
    logout() {
      this.$store.dispatch("AuthModule/logoutUser")
      .then(() => this.$router.push({name: 'Login'}))
      
    },
  },
  beforeMount() {
    // this.$store.dispatch('getAllData', { all: true })
    this.getCurrentOption();
  },
  updated() {
    this.getCurrentOption();
  },
};
</script>

<style >
a {
  text-decoration: none;
}

.link-name {
  font-size: 15px;
  color: black;
}

.custom-loader {
    animation: loader 1s infinite;
    display: flex;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 11px !important;
}
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 24px !important;
  padding: 0 10px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 24px !important;
  padding: 0 10px !important;
}
.texto-auto-cuentas{
  font-size: 12px !important;
  
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
    min-height: 0 !important; 
}

.v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 48px !important;
}



.v-text-field--outlined.v-input--dense .v-label {
	top: 8px;
}

.v-input__icon {
	height: 19px !important;
	min-width: 19px !important;
	width: 19px !important;
}

.v-text-field--outlined.v-input--dense .v-label {
	top: 8px !important;
}

.v-input {
	font-size: 13px !important;
}

.v-list-item--dense, .v-list--dense .v-list-item {
	min-height: 16px !important ;
}


/* INPUTS DE SOLO NUMEROS CON FORMATO NUMERICO */
.input-totals .v-input__control .v-text-field__details{
  display: none !important;
}
.input-totals .v-input__control .v-input__slot .v-text-field__slot{
  font-size: 12px !important;
}
.input-totals .v-input__control .v-input__slot{
  padding: 0 0.5rem 0 0.5rem !important;
	min-height: 25px !important;
}
.input-totals .v-input__control .v-input__slot input {
  text-align: end !important;
  max-height: 15px !important;
}

.v-text-field--filled.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot {
	min-height: 30px !important;
}

/* de los select */
.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
	margin-right: 10px !important;
}
.v-list-item__action {
	margin: 6px 0 !important;
}

.v-input--selection-controls__input {
	
	height: 16px !important;
	
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

</style>

