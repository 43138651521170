<template>
  <div>
    <v-text-field
      v-model="cmpValue"
      v-bind:label="label"
      v-bind:placeholder="placeholder"
      v-bind:readonly="readonly"
      v-bind:disabled="disabled"
      v-bind:outlined="outlined"
      v-bind:dense="dense"
      v-bind:hide-details="hideDetails"
      v-bind:error="error"
      v-bind:rules="rules"
      v-bind:clearable="clearable"
      v-bind:backgroundColor="backgroundColor"
      v-bind:prefix="options.prefix"
      v-bind:suffix="options.suffix"
      v-on:keypress="keyPress"
      class="input-totals ma-0 px-0"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  model: { prop: "value", event: "input" },
  props: {
    customClass:  {
      type: String,
      default: ""
    },
    value: {
      // type: String,
      type: [String, Number],
      default: "0"
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: [Boolean, String],
      default: false
    },
    rules: {
      type: [Array, String],
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: "white"
    },
    valueWhenIsEmpty: {
      type: String,
      default: ""  // "0" or "" or null
    },
    options: {
      type: Object,
      default: function() {
        return {
          locale: "pt-BR",
          prefix: "",
          suffix: "",
          length: 11,
          precision: 2
        };
      },
    },
  },
  data: () => ({}),
  /*
  v-model="cmpValue": De esta manera, a medida que escribe, el valor se actualiza automáticamente en el componente principal.
  El valor escrito entra en el valor nuevo del conjunto y se emite al componente principal.
  the-vue-mask no funciona correctamente al incluir valores existentes en el componente padre.

  This is a Vue.js single-file component (SFC) that defines a custom text field input. 
  The component is composed of a template and a script section.

  The template section contains the markup for the custom text field input. 
  It consists of a v-text-field element that is bound to various attributes, such as v-model, 
  v-bind:label, v-bind:placeholder, v-bind:readonly, v-bind:disabled, v-bind:outlined, 
  v-bind:dense, v-bind:hide-details, v-bind:error, v-bind:rules, v-bind:clearable, 
  v-bind:backgroundColor, v-bind:prefix, v-bind:suffix, and v-on:keypress. 

  The v-model directive is used to create a two-way binding between the component's value prop 
  and the input field's value.

  The script section contains the component's logic. 
  It defines the component's props, which are the various attributes that can be passed into 
  the component from the parent component. It also defines the data, computed, and methods properties.

  The computed property defines a computed property called cmpValue, 
  which is used to format the input field's value using the humanFormat method when the input field 
  is displayed and the machineFormat method when the input field's value is changed.

  The methods property contains various methods that are used to format and clean the input 
  field's value, such as the humanFormat, machineFormat, keyPress, and cleanNumber methods. 
  These methods are used to format the input field's value in a way that is consistent 
  with the component's options, such as the options.precision property.
  */

  computed: {
    cmpValue: {
      //cuando es mostrado
      get: function() {
        return this.value !== null && this.value !== ""
          ? this.humanFormat(this.value.toString())
          : this.valueWhenIsEmpty;
      },
      //cuando es cambiado
      set: function(newValue) {
        this.$emit("input", this.machineFormat(newValue));
      },
    },
  },
  methods: {
    humanFormat: function(number) {
      // console.log(number)
      if (isNaN(number)) {
        number = "";
      } else {
        // number = Number(number).toLocaleString(this.options.locale, {maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'BRL'});
        number = Number(number).toLocaleString(this.options.locale, {
          maximumFractionDigits: this.options.precision,
          minimumFractionDigits: this.options.precision,
        });
      }
      return number;
    },
    machineFormat(number) {
      if (number) {
        number = this.cleanNumber(number);
        // Ajustar quantidade de zeros à esquerda
        number = number.padStart(parseInt(this.options.precision) + 1, "0");
        // Incluir ponto na casa correta, conforme a precisão configurada
        number =
          number.substring(
            0,
            number.length - parseInt(this.options.precision)
          ) +
          "." +
          number.substring(
            number.length - parseInt(this.options.precision),
            number.length
          );
        if (isNaN(number)) {
          number = this.valueWhenIsEmpty;
        }
      } else {
        number = this.valueWhenIsEmpty;
      }
      if (this.options.precision === 0) {
        number = this.cleanNumber(number);
      }
      return number;
    },
    keyPress($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      // if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
      if (this.targetLength()) {
        $event.preventDefault();
      }
    },
    // Retira todos os caracteres não numéricos e zeros à esquerda
    cleanNumber: function(value) {
      let result = "";
      if (value) {
        let flag = false;
        let arrayValue = value.toString().split("");
        for (var i = 0; i < arrayValue.length; i++) {
          if (this.isInteger(arrayValue[i])) {
            if (!flag) {
              // Retirar zeros à esquerda
              if (arrayValue[i] !== "0") {
                result = result + arrayValue[i];
                flag = true;
              }
            } else {
              result = result + arrayValue[i];
            }
          }
        }
      }
      return result;
    },
    isInteger(value) {
      let result = false;
      if (Number.isInteger(parseInt(value))) {
        result = true;
      }
      return result;
    },
    targetLength() {
      if (
        Number(this.cleanNumber(this.value).length) >=
        Number(this.options.length)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style>

</style>

