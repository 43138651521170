<template>
    <v-card class="table-middle-info border-radius-xl py-2 card-shadow rounded-xl">
      <v-simple-table dense class="pa-1">
      <template v-slot:default>
        <thead class="cabecera">
          <tr class="tr-cabecera">
            <th class="text-left">
              Tipo de Servicio
            </th>
            <th class="text-center">
              Dia
            </th>
            <th class="text-center">
              <span>Mes Hasta hoy</span>
              &nbsp;
              <div class="custom-grid-column">
                <span class="text-right pr-4">A Hoy</span>
                <span class="text-right pr-4">Meta</span>
                <span class="text-right pr-4">%</span>
              </div>
            </th>
            <th class="text-center">
              <span> Mes Completo</span>
              &nbsp;
              <div class="custom-grid-column">
                <span class="text-right pr-4">Acum.</span>
                <span class="text-right pr-4">Meta</span>
                <span class="text-right pr-4">%</span>
              </div>
            </th>
            <th class="text-center">
              <span>Acumulado Hoy</span>
               &nbsp;
              <div class="custom-grid-column">
                <span class="text-right pr-4">A Hoy</span>
                <span class="text-right pr-4">Meta</span>
                <span class="text-right pr-4">%</span>
              </div>
            </th>
            <th class="text-center">
              <span>Acumulado Año</span>
               &nbsp;
              <div class="custom-grid-column">
                <span class="text-right pr-4">Anual</span>
                <span class="text-right pr-4">Meta</span>
                <span class="text-right pr-4">%</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in dataRecords"
            :key="item.name"
                class="font-weight-light text-grey-body--text tr-body"
            >
            <td class="text-caption">{{ item.order }}</td>
            <td class="text-caption text-right">{{ $convertToMoney(item.dia,0) }}</td>
            <td class="text-caption text-right">
              <div class="custom-grid-column">
                <span class="">{{ $convertToMoney(item.mes_hasta_la_fecha,0) }}</span>
                <span :class="item.mes_hasta_la_fecha-item.budget_mes_hasta_la_fecha >= 0 ? 'success--text' : 'error--text'" class="budget-size font-weight-bold ">{{ $convertToMoney(item.budget_mes_hasta_la_fecha,0) }}</span>
                <span :class="item.mes_hasta_la_fecha-item.budget_mes_hasta_la_fecha >= 0 ? 'info--text' : 'error--text'" class="budget-size font-weight-ligth ">{{ `${$convertToMoney(item.mes_hasta_la_fecha/item.budget_mes_hasta_la_fecha*100,0)}%` }}</span>
              </div>
            </td>
            <td class="text-caption text-right">
              <div class="custom-grid-column">
                <span class="">{{ $convertToMoney(item.mes_completo,0) }}</span>
                <span :class="item.mes_completo-item.budget_mes_completo >= 0 ? 'success--text' : 'error--text'" class="budget-size font-weight-bold ">{{ $convertToMoney(item.budget_mes_completo,0) }}</span>
                <span :class="item.mes_completo-item.budget_mes_completo >= 0 ? 'info--text' : 'error--text'" class="budget-size font-weight-ligth ">{{ `${$convertToMoney(item.mes_completo/item.budget_mes_completo*100,0)}%` }}</span>
              </div>
            </td>
            <td class="text-caption text-right">
              <div class="custom-grid-column">
                <span>{{ $convertToMoney(item.new_acum_subfamily,0) }}</span>
                <span :class="item.new_acum_subfamily-item.budget_acum_hasta_fecha >= 0 ? 'success--text' : 'error--text'" class="budget-size font-weight-bold ">{{ $convertToMoney(item.budget_acum_hasta_fecha,0) }}</span>
                <span :class="item.new_acum_subfamily-item.budget_acum_hasta_fecha >= 0 ? 'info--text' : 'error--text'" class="budget-size font-weight-ligth ">{{ `${$convertToMoney(item.new_acum_subfamily/item.budget_acum_hasta_fecha*100,0)}%` }}</span>
              </div>
            </td>
            <td class="text-caption text-right">
              <div class="custom-grid-column">
                <span>{{ $convertToMoney(item.acumulado,0) }}</span>
                <span :class="item.acumulado-item.budget_year >= 0 ? 'success--text' : 'error--text'" class="budget-size font-weight-bold ">{{ $convertToMoney(item.budget_year,0) }}</span>
                <span :class="item.acumulado-item.budget_year >= 0 ? 'info--text' : 'error--text'" class="budget-size font-weight-ligth ">{{ `${$convertToMoney(item.acumulado/item.budget_year*100,0)}%` }}</span>
              </div>
            </td>
            <!-- <td class="text-caption">{{ item.avgGuest }}</td> -->
            <!-- <td class="text-caption text-right">{{ item.checks }}</td> -->
            <!-- <td class="text-caption text-right">{{$convertToMoney(item.avgCheck,0) }}</td> -->
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    </v-card>
  </template>
  
  <script>
  export default {
    name: 'TableMiddle',
    props:{
      dataRecords:{
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        
      }
    },
  }
  </script>
  
  <style scoped>
  .tr-cabecera th, .tr-body td{
    padding: 0 0.45rem 0 0.45rem !important;
  }

  .custom-grid-column{
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.34%;
  }
  </style>