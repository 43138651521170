<template>
  <div>
    <!-- PRIMERA FILA -->
    <v-row align="center" class="mt-1">
      <v-col cols="12" md="2" sm="6">
        <CardSimple
          class="mb-2"
          :items="items"
          cardTitle="Dia"
          :CardTotalValue="dia"
          prependSing=" ₡ "
        ></CardSimple>
      </v-col>

      <v-col cols="12" md="2">
        <card-totals
          :items="items"
          cardTitle="Acumulado Mes"
          compareText="Año anterior"
          :CardTotalValue="mes"
          prependSing="₡"
          :BudgetFields="true"
          :BudgetData="budgetMesHastaHoy"
        ></card-totals>
      </v-col>

      <v-col cols="12" md="2" sm="6">
        <card-totals
          :items="items"
          cardTitle="Acumulado año"
          compareText="Mes Año anterior"
          :CardTotalValue="acumulado"
          prependSing="₡"
          :BudgetFields="true"
          :BudgetData="budgetMesHastaHoy"
        ></card-totals>
      </v-col>

      <v-col cols="12" md="6">
        <SimpleTable 
          :headers="headersIncome"
          :dataRecords="dataIncome"
          />
      </v-col>

    </v-row>

    <!-- SEGUNDA FILA -->
    <v-row>
      <!--  -->
      <!-- <v-col cols="12" md="3">
        <Pie
          pieTitle="Segmentos"
          :chartData="chartDataPie"
          :chartOptions="chartOptionsPie"
        ></Pie>
      </v-col> -->
      <v-col cols="12" md="6">
        <v-card rounded="xl" class=" pa-3" >
          <BarChart
            :chartData="chartDataBarsVxdia"
            :chartOptions="chartOptionsBars"
          ></BarChart>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card rounded="xl" class=" pa-3" >
          <BarChart
            :chartData="chartDataBarsYearbyMonth"
            :chartOptions="chartOptionsBars"
          ></BarChart>
        </v-card>
      </v-col>

      <!-- <table-middle :dataRecords="itemsDataTable"></table-middle> -->
      <!-- tabla de pax -->
      <!-- <v-col cols="12" md="2">
        <pax-table :dataRecords="itemsPaxTable"></pax-table>
      </v-col> -->

      <!-- tabla de rubros de ingresos -->
      <!-- <v-col cols="12" md="10">
        <v-card rounded="xl" elevation="2" class="mt-2 pa-2 pl-3">
          <v-row dense>
            <v-col cols="12" md="6">
              <p class="text-caption my-0">
                <span class="mr-1 font-weight-medium">Avg. Venta x dia:</span>
                <span class="mr-3">{{ `$${$convertToMoney(promVtaDia)}` }}</span>
              </p>
              <p class="text-caption my-0">
                <span class="">Meta:</span>
                <span :class="promVtaDia >= 0 ? 'success--text' : 'error--text'" class="mr-2">{{ `$${$convertToMoney(budgetPromVtaDia)}` }}</span>
                <span :class="promVtaDia >= 0 ? 'success--text font-weight-medium' : 'error--text'">{{ `${promVtaDia > 0 ? '+': promVtaDia == 0 ? '' :'-'}${budgetPromVtaDiaPercent ? `${budgetPromVtaDiaPercent}%` : ''}`}}</span>
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <p class="text-caption my-0">
                <span class="mr-1 font-weight-medium">Avg. Venta x Mes:</span>
                <span class="mr-3">{{ `$${$convertToMoney(promVtaMes)}` }}</span>
              </p>
              <p class="text-caption my-0">
                <span class="mr-1 font-weight-medium">Meta:</span>
                <span :class="promVtaMes >= 0 ? 'success--text' : 'error--text'" class="mr-2">{{ `$${$convertToMoney(budgetPromVtaMes)}` }}</span>
                <span :class="promVtaMes >= 0 ? 'success--text font-weight-medium' : 'error--text'">{{ `${promVtaMes > 0 ? '+': promVtaMes == 0 ? '' :'-'}${budgetPromVtaMesPercent ? `${budgetPromVtaMesPercent}%` : ''}`}}</span>
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col> -->
      <!--  -->
      <!-- <v-col cols="12" md="4">
          <SalesCardSummary></SalesCardSummary>
      </v-col> -->
      <!--  -->
      <!-- <v-col cols="12" md="4">
          <SalesCardSummary></SalesCardSummary>
      </v-col> -->
    </v-row>

    <!-- <v-row>
      <v-col cols="12" md="6">
        <SimpleTable
          :headers="headersProceso"
          :dataRecords="dataProceso"
          />
      </v-col>
      <v-col cols="12" md="6">
        <SimpleTable
          :headers="headersAntiguedad"
          :dataRecords="dataAntiguedad"
          />
      </v-col>
    </v-row> -->

    <!-- cuadro de dialogo -->
    <!-- <v-row>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="dialogDescargar"
      >
        <template v-slot:default="dialog">
          <v-card :loading="loading">
            <v-toolbar color="primary" dark
              >Favor seleccione el mes a descargar</v-toolbar
            >
            <v-card-text :loading="loading">
              <div class="text-h2 pa-12">
                <v-chip-group active-class="primary--text" column>
                  <v-chip v-for="mes in meses" :key="Object.keys(mes)[0]">
                    <div @click="descargarExcel(mes)">
                      {{ Object.keys(mes)[0] }}
                    </div>
                  </v-chip>
                </v-chip-group>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-row> -->
  </div>
</template>

<script>
import BarChart from "@/components/dashboard/Bars.vue"
import TableMiddle from "@/components/dashboard/TableMiddle.vue"
import Pie from "@/components/dashboard/Pie.vue"
import CardTotals from "@/components/dashboard/CardTotals.vue"
import CardSimple from "@/components/dashboard/CardSimple.vue"
import SimpleTable from "@/components/dashboard/SimpleTable.vue"
import { paginationMethods } from "@/mixins/pagination";

const pieColors = [
  "#29B6F6",
  "#4DD0E1",
  "#81C784",
  "#90A4AE",
  "#F06292",
  "#9575CD",
  "#3F51B5",
]
const monthColors = [
  "#FF7043",
  "#9CCC65",
  "#26C6DA",
  "#29B6F6",
  "#5C6BC0",
  "#42A5F5",
  "#26A69A",
  "#C62828",
  "#EEFF41",
  "#0D47A1",
]

export default {
  name: "MainDashboard",
  components: {
    BarChart,
    CardTotals,
    Pie,
    TableMiddle,
    SimpleTable,
    CardSimple
  },
  props: {
    yearData: {
      type: Number,
      required: true,
    },
    monthData: {
      type: Number,
      required: true,
    },
    dayData: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dataYears: [],
      dialogDescargar: false,
      loading: false,
      itemsDataTable: [],
      itemsPaxTable: [],
      headersIncome: [
        {title: 'Descripcion'},
        {title: 'Dia', class: 'text-right'},
        {title: 'Mes', class: 'text-right'},
        {title: 'MesLy', class: 'text-right'},
        {title: 'Year', class: 'text-right'},
        {title: 'YearLY', class: 'text-right'},
      ],
      dataIncome: [],
      

      
      headersProceso: [
        {title: 'Estado'},
        {title: '30', class: 'text-right'},
        {title: '60', class: 'text-right'},
        {title: '90', class: 'text-right'},
        {title: '120+', class: 'text-right'},
        {title: '150+', class: 'text-right'},
      ],
      dataProceso: [
        {name:'Flete', order: 1, values: ['Flete', 1500000, 200000, 0,300000, 0]},
        {name:'Asignacion', order: 1, values: ['Asignacion',  700000, 14500000, 0, 14500000, 0]},
        {name:'proforma', order: 1, values: ['proforma',  700000, 14500000, 0, 14500000, 0]},
        {name:'Orden', order: 1, values: ['Orden',  700000, 14500000, 0, 14500000, 0]},
        {name:'Factura', order: 1, values: ['Factura',  700000, 14500000, 0, 14500000, 0]},
      ],

      headersAntiguedad: [
        {title: 'Detalle'},
        {title: 'Tipo', class: 'text-right'},
        {title: '30', class: 'text-right'},
        {title: '60', class: 'text-right'},
        {title: '90', class: 'text-right'},
        {title: '120+', class: 'text-right'},
        // {title: 'Acumulado', class: 'text-right'},
      ],
      dataAntiguedad: [
        {name:'Demasa', order: 1, values: ['Demasa', 'cxc',1500000, 200000, 0,300000]},
        {name:'Transportistas', order: 1, values: ['Transportistas', 'cxp', 700000, 14500000, 0, 14500000]},
        {name:'Deducciones', order: 1, values: ['Deducciones', 'cxc', 700000, 14500000, 0, 14500000]},
      ],


      items: [
        { title: "Hoy" },
        { title: "Ayer" },
        { title: "Semana" },
        { title: "Mes" },
        { title: "Año" },
      ],
      textLines: {
        text: {
          color1: "primary--text",
          line1: "267,433 Sc",
          color2: "warning--text font-weight-bold",
          line2: "255,386 Tax",
        },
      },
      chartDataBarsVxdia: {
        labels: [],
        datasets: [],
      },
      chartDataPie: {
        labels: [],
        datasets: [],
      },
      chartDataBarsYearbyMonth: {
        labels: [],
        datasets: [],
      },
      chartOptionsBars: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
      chartOptionsPie: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      meses: [
        { Enero: 1 },
        { Febrero: 2 },
        { Marzo: 3 },
        { Abril: 4 },
        { Mayo: 5 },
        { Junio: 6 },
        { Julio: 7 },
        { Agosto: 8 },
        { Septiembre: 9 },
        { Octubre: 10 },
        { Noviembre: 11 },
        { Diciembre: 12 },
      ],
      acumulado: 0,
      acumuladoHoy: 0,
      mes: 0,
      mesHastaLaFecha: 0,
      dia: 0,
      promPaxDia: 0,
      promPaxMes: 0,
      promVtaDia: 0,
      promVtaMes: 0,
      acumuladoPax: 0,
      acumuladoHoypax: 0,
      budgetMesHastaHoy: {},
      budgetMescompleto: {},
      budgetAcumuladoHoy: {},
      budgetAcumuladoYear: {},
      budgetPromVtaMes: 0,
      budgetPromVtaDiaPercent: 0,
      budgetPromVtaDia: 0,
      budgetPromVtaMesPercent: 0,
    }
  },
  mixins:[paginationMethods],
  methods: {
    openDescargar() {
      this.dialogDescargar = true
    },
    // async descargarExcel(item) {
    //   this.loading = true
    //   console.log(item)
    //   await this.$store.dispatch("verifyTokenPy")
    //   const response = await axiosB({
    //     method: "GET",
    //     url: `extractor/api/506transfers/resultBymonth?year=${this.yearData}&mes=${
    //       Object.values(item)[0]
    //     }`,
    //     headers: {
    //       Authorization: `Bearer ${this.$store.state.accessTokenPy}`,
    //     },
    //     responseType: "blob",
    //   })
    //   console.log(response.headers)
    //   // fetch(`extractor/api/506transfers/resultBymonth?mes=${Object.values(item)[0]}`, {
    //   //   method: 'GET',
    //   //   headers: {
    //   //     Authorization: `Bearer ${this.$store.state.accessTokenPy}`,
    //   //     'Content-Type': 'application/json',
    //   //   }
    //   // })
    //   // .then(res => {
    //   //   if(!res.ok) {
    //   //     return res.json().then(text => { throw new Error(text.message) })
    //   //   } else {
    //   //     console.log(res)
    //   saveAs(response.data, response.headers["content-filename"])
    //   // response.blob().then(file => {
    //   //   saveAs(file, `${item.nombre}`)
    //   //   this.isLoading = false
    //   // })
    //   //   }
    //   // })
    //   // .catch(err => {
    //   //   this.loading = false
    //   //   console.log(err.response)
    //   // })
    // },
    calculateDays(date_1, date_2) {
      let difference = date_1.getTime() - date_2.getTime()
      let TotalDays = Math.ceil(difference / (1000 * 3600 * 24))
      return TotalDays
    },
    async getData(){
      this.chartDataBarsVxdia.labels = this.$dayLabels(this.yearData, this.monthData)
      const AxiosParamas = {
        year: this.yearData,
        month: this.monthData,
        day: this.dayData,
      }
      const data = await this.getDataGeneral(
        "GET",
        `transjuma/QueryDashboard/`,
        '',
        "loading",
        {},
        false, //paginacion
        true, // opcion para que devuelva algo
        true, // modo normal
        null, //parametros query
        true, // si devuelve uno solo
        AxiosParamas // parametros para axios
      )
      if (data.data){
        console.log(data.data, this.chartDataBarsVxdia)

        this.dia = data.data.dia.length ? data.data.dia.reduce((total, obj) => total + obj.descuento, 0)*-1 : 0
        this.mes =  data.data.mes.length ?  data.data.mes.reduce((total, obj) => total + obj.descuento, 0)*-1 : 0
        this.acumulado = data.data.anio.reduce((total, obj) => total + obj.descuento, 0)*-1

        this.dataIncome = [
          {name:'Guias', order: 1, values: [
            'Guias',
            data.data.dia.length > 1 ? data.data.dia[1].descuento : 0,
            data.data.mes.length > 1 ? data.data.mes[1].descuento : 0,
            0,
            data.data.anio[1].descuento,
            0,
          ]},
          {name:'Ajustes', order: 1, values: [
            'Ajustes',
            data.data.dia.length ? data.data.dia[0].descuento : 0,
            data.data.mes.length ? data.data.mes[0].descuento : 0,
            0,
            data.data.anio[0].descuento,
            0,
          ]},
        ]

        this.chartDataBarsVxdia.datasets.push({
          label: "Ventas x dia del mes, en miles de ₡",
          backgroundColor: "rgba(255, 99, 132, 0.7)",
          borderColor: "rgba(255, 99, 132)",
          data:  data.data.vxdia.map(a=> a.descuento*-1 > 0 ? (a.descuento*-1)/1000 : 0),
        })

        let datosBar2 = {
          backgroundColor: [],
          data: [],
        }
        for (let [index, item] of  data.data.vxmes.entries()) {
          this.chartDataBarsYearbyMonth.labels.push(item.mes)
          datosBar2.backgroundColor.push(monthColors[index])
          datosBar2.data.push(item.descuento*-1 > 0  ? (item.descuento*-1)/1000 : 0)
        }
        datosBar2.label = "Ventas totales x Mes, en miles de ₡"
        this.chartDataBarsYearbyMonth.datasets.push(datosBar2)
      } else [
        alert('Al parecer hay un error, si persiste consultar a soporte tecnico')
      ]


    }
  },
  created() {
    this.getData()
  },
}
</script>

<style>
</style>