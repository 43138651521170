<template>
  <div>
    <BaseWindowList
      :show-select="false"
      :headers="headers"
      :itemsBaseWindowList="desserts"
      title="Gestion de Proforma"
      titleAddMethod="Agregar Nueva Proforma"
      :activarEdicion="true"
      :activarBorrar="true"
      :activarPDF="true"
      :activarXLS="true"
      :currentPage="currentPage"
      :totalPages="totalPages"
      :loading="loading"
      v-on:editar-row="editItem"
      v-on:open-agregar-listado="openForm"
      v-on:click-gotopage="siguientePagina"
      v-on:eliminar-row="deleteItem"
      v-on:get-pdf="reporteProforma"
      v-on:get-xls="reporteProformaXsl"
      externalSearch
      @external-search="openSearch"
      @reset-info="refreshData"
      >
      <template v-slot:item.fecha="{ item }">
        <tr class="d-flex justify-center">
          <td>
            {{ item.fecha.substring(0, 10) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.estado_asignacion="{ item }">
        <tr class="d-flex justify-center">
          <td class="text-center text-caption blue-grey--text text--darken-1">
            {{ itemTextEstado(item.estado_asignacion) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.transportista="{ item }">
        <tr class="d-flex justify-center">
          <td class="text-center text-caption blue-grey--text text--darken-1">
            {{ itemTextTransportista(item.transportista) }}
          </td>
        </tr>
      </template>

    </BaseWindowList>

    <!-- MAIN DIALOG -->
    <AsignacionesForm
      v-if="dialog"
      title="Crear Proforma"
      :companyTitle="`Transportes Juma`"
      :headers="headers"
      :headersDetalle="headersDetalles"
      tipoModulo="PF"
      modulo="ProformaCabecera"
      fieldCabeceraName="asignacion_cabecera_id"
      urlFormEncabezado="transjuma/ProformaCabecera/"
      urlFormDetalle="transjuma/ProformaDetalle/"
      :modoEdicion="themodeEdicion"
      :cabeceraDocumento="editedItem"
      v-on:update-List="updateList"
      v-on:cierre-form="close"

      :customValidate="validarEstado"
    >

      <template v-slot:item.guia="{ item }">
        <tr class="d-flex justify-center">
          <td>
            {{ item.guia ? item.guia.numero : '' }}
          </td>
        </tr>
      </template>

      <template v-slot:item.ajuste_detalle="{ item }">
        <tr class="d-flex justify-center">
          <td>
            {{ item.ajuste_detalle ? `${item.ajuste_detalle.id}=${item.ajuste_detalle.guia.numero}` : '' }}
          </td>
        </tr>
      </template>

      <template v-slot:item.deduccion="{ item }">
        <tr class="d-flex justify-end">
          <td>
            {{ item.deduccion ? item.deduccion.id : '' }}
          </td>
        </tr>
      </template>


      <template v-slot:item.precio="{ item }">
        <tr class="d-flex justify-end pr-9">
          <td>
            {{ itemTextPrecio(item) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.descuento="{ item }">
        <tr class="d-flex justify-end pr-4 red--text">
          <td>
            {{ itemTextDescuento(item) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.iva="{ item }">
        <tr class="d-flex justify-end pr-9">
          <td>
            {{ itemTextIva(item) }}
          </td>
        </tr>
      </template>

    </AsignacionesForm>

    <!-- DIALOG DELETE -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card rounded="xl" height="120px" :loading="loading" :disabled="loading">
        <v-card-title class="text-h6"
          >¿Está seguro de borrar este registro?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete"
            >Cancel</v-btn
          >
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- UPDATE MANY DIALOG -->
    <v-form ref="updateManyForm">
      <v-dialog v-model="manyRowsDialog" max-width="600px" persistent>
        <v-card rounded="xl" :loading="loading" :disabled="loading">
          <v-card-title>
            <span class="text-h6">Edición de varias asignaciones</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-menu
                    v-model="manyUpdateItem.fecha_many"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="updateManyForm.fecha_many"
                        label="Fecha"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="updateManyForm.fecha_many"
                      @input="manyUpdateItem.fecha_many = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="8">
                  <v-autocomplete
                    v-model="updateManyForm.estado_asignacion"
                    :items="estados"
                    item-text="nombre"
                    item-value="id"
                    label="Estado de asignación"
                    clearable
                    dense
                    :rules="[
                        v => !!v || 'Campo no debe estar en blanco'
                      ]"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small :loading="loading" color="blue darken-1" text @click="closeManyRowsDialog">Cancelar</v-btn>
            <v-btn small :loading="loading" color="blue darken-1" text @click="saveManyRows">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <!-- CUADRO DIALOGO BUSQUEDA AVANZADA -->
    <BusquedaAvanzada
      nameItemStore="searchProformas"
      v-on:advance-search="onAdvanceSearch"
      :activateGuiaTransportista="false"
      @reset-refresh="refreshData"
    ></BusquedaAvanzada>
  </div>
</template>

<script>
import { axiosC } from "../api/axiosbd";
import BaseWindowList from "@/components/BaseWindowList";
import { paginationMethods } from "../mixins/pagination";
import BaseAutoComp from "../components/BaseAutoComplet.vue";
import AsignacionesForm from "../components/AsignacionesForm.vue";
import BusquedaAvanzada from "../components/BusquedaAvanzada.vue";


export default {
  components: {
    BaseWindowList,
    BaseAutoComp,
    AsignacionesForm, BusquedaAvanzada
  },
  mixins: [paginationMethods],
  data() {
    return {
      loading: false,
      headers: [
        { text: "ID - Lote Proforma", value: "id" },
        {
          text: "Fecha",
          value: "fecha",
          form: true,
          vdate: true,
          require: true,
          colmd: 3,
          align: 'center'
        },
        {
          text: "Codigo Ext.",
          value: "nombre",
          form: true,
          vtext: true,
          require: true,
          colmd: 3,
          align: 'center'
        },
        // {
        //   text: "Transportista",
        //   value: "transportista",
        //   disable: false,
        //   form: true,
        //   vauto: true,
        //   require: true,
        //   colmd: 5,
        //   vautoName: [],
        //   itemText: "nombre",
        //   vfunctname: "",
        //   showInnerDetail: true,
        //   innerTextName: 'nombre'
        // },
        // {
        //   text: "Estado Proforma",
        //   value: "estado_asignacion",
        //   disable: false,
        //   form: true,
        //   vauto: true,
        //   require: true,
        //   colmd: 3,
        //   vautoName: [],
        //   itemText: "nombre",
        //   vfunctname: "",
        //   showInnerDetail: true,
        //   innerTextName: 'nombre'
        // },

        { text: "Actions", value: "actions", sortable: false, align: 'center'},
      ],
      headersDetalles: [
        {
          text: "ID",
          value: "id",
          form: false,
          vtext: true,
          require: false,
          colmd: 2,
          showInnerDetail: false,
        },
        {
          text: "Fecha ajuste",
          value: "fecha",
          form: false,
          vdate: true,
          require: true,
          colmd: 2,
          showInnerDetail: false,
        },

        {
          text: "Guia",
          value: "guia",
          form: false,
          vauto: false,
          require: false,
          disable: true,
          colmd: 6,
          align: 'center',
          autoName: [],
          itemText:  ["id", "numero", "destino"],
          vfunctname: "",
          showInnerDetail: true,
          innerTextName: 'nombre'
        },
        {
          text: "Ajuste",
          value: "ajuste_detalle",
          form: false,
          vauto: false,
          require: false,
          disable: true,
          colmd: 6,
          align: 'center',
          autoName: [],
          itemText: "nombre",
          vfunctname: "",
          showInnerDetail: true,
          innerTextName: 'nombre'
        },
        // {
        //   text: "deduccion",
        //   value: "deduccion",
        //   form: false,
        //   vauto: false,
        //   require: false,
        //   disable: false,
        //   colmd: 6,
        //   align: 'center',
        //   autoName: [],
        //   itemText: "nombre",
        //   vfunctname: "",
        //   showInnerDetail: true,
        //   innerTextName: 'nombre'
        // },
        {
          text: "Precio",
          value: "precio",
          align: 'center',
          form: false,
          vtext: true,
          require: true,
          colmd: 3,
          showInnerDetail: true,
        },
        // {
        //   text: "Descuento",
        //   value: "descuento",
        //   form: false,
        //   vtext: true,
        //   require: true,
        //   colmd: 3,
        //   showInnerDetail: true,
        // },
        {
          text: "iva",
          value: "iva",
          align: 'center',
          form: false,
          vtext: true,
          require: true,
          colmd: 3,
          showInnerDetail: true,
          align: 'center'
        },
      ],
      desserts: [],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {},
      deletedItem: {},
      defaultItem: {},
      tipos: [
        { id: "F", text: "FIJO" },
        { id: "V", text: "VARIABLE" },
      ],
      mode: "POST",

      //
      menuPickers: {
        fecha: false,
        fecha_proforma: false,
        fecha_fcatura: false,
        fecha_pago: false,
        fecha_pago_recibido: false,
      },
      keyPrecio: 0,
      keyDescuento: 0,
      keyIva: 0,
      sedes: [{ id: 1, nombre: "Pavas" }],
      transportistas: [],
      estados: [],
      destinos: [],
      tipoAjustes: [],
      guias: [],
      ajustes: [],
      deducciones: [],
      procesoCompleto: false,
      themodeEdicion: false,
      baseSelected: null,
      searchDocument: null,
      manyUpdateItem: {
        fecha_many: null
      },
      manyRowsDialog: false, // MANY ROWS DIALOG
      updateManyForm: {}

    };
  },
  methods: {
    itemTextTransportista(item) {
      if (item) {
        const text = this.transportistas.find((y) => y.id == item);
        return text ? text.nombre : "";
      }
    },
    itemTextEstado(item) {
      if (item) {
        const text = this.estados.find((y) => y.id == item);
        return text ? text.nombre : "";
      }
    },
    itemTextTipoAjuste(item) {
      if (item) {
        const text = this.tipoAjustes.find((y) => y.id == item);

        return text ? `${text.nombre}` : "";
      }
    },
    itemTextPrecio(item) {
      if (item.guia) {
        return this.$convertToMoney(item.guia.precio_base)
      } else if (item.ajuste_detalle){
        return this.$convertToMoney(item.ajuste_detalle.valor_adicional)
      } else if (item.deduccion){
        return this.$convertToMoney(parseFloat(item.deduccion.monto) * -1)
      } else {
        return "0.00"
      }
    },

    itemTextDescuento(item) {
      if (item.guia) {
        return this.$convertToMoney(item.guia.descuento)
      } else if (item.ajuste_detalle){
        return this.$convertToMoney(item.ajuste_detalle.descuento)
      } else {
        return "0.00"
      }
    },

    itemTextIva(item) {
      if (item.guia) {
        return this.$convertToMoney(item.guia.iva)
      } else if (item.ajuste_detalle){
        return this.$convertToMoney(item.ajuste_detalle.iva)
      } else if (item.deduccion){
        return this.$convertToMoney(parseFloat(item.deduccion.iva) * -1)
      } else {
        return "0.00"
      }
    },

    siguientePagina(item) {
      let axiosParamas = {}
      axiosParamas = {...this.queryAdvanceSearch}

      this.gotoPage(item, `transjuma/ProformaCabecera/`, axiosParamas, 'loading');
    },
    openForm() {
      console.log("ABRIENDO");
      this.updateAutoCompletesCabecera
      this.dialog = true;
    },

    convertToMoney(x) {
      // Verificar si el valor es nulo, indefinido o no es un número
      if (x === undefined || x === null || isNaN(Number(x))) {
        return "0.00";
      }
      // Convertir a número y luego aplicar toFixed(2)
      x = Number(x).toFixed(2);

      // Aplicar formato de separador de miles
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    clearSelects() {
      for (let field in this.defaultItem) {
        if (this.defaultItem[field].value) this.defaultItem[field].value = null;
        if (this.defaultItem[field].values) this.defaultItem[field].values = [];
      }
    },
    close() {
      console.log('CERRANDO FORM')
      this.dialog = false;
      this.mode = "POST";
      this.editedItem = {};
      this.themodeEdicion =  false
      this.updateList()
    },
    closeDelete() {
      this.dialogDelete = false;

      this.$nextTick(() => {
        this.clearSelects();
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async deleteItem(item) {
      console.log(item)
      this.loading = true

      await this.$store.dispatch("AuthModule/verifyToken")
      let validate = true
      let erroresLinea = []

      const itemsCheck = await this.getDataGeneral(
          "GET",
          `transjuma/ProformaDetalle/`,
          '',
          'loading',
          {},
          false, //paginacion
          true, // opcion para que devuelva algo
          true, //modo normal activado, vienen todos los resultados
          null, // parametros para busqueda avanzada
          false,
          {proforma_cabecera_id: item.id}
        )

      for (let a of itemsCheck){
        if (a.guia){
          if (a.guia.estado_guia ==3){
            erroresLinea.push(true)
          } else {
            erroresLinea.push(false )
          }
        }

        if (a.ajuste_detalle){
          if (a.ajuste_detalle.estado_guia ==3){
            erroresLinea.push(true)
          } else {
            erroresLinea.push(false )
          }
        }
      }

      if (erroresLinea.includes(false)){
        validate = false
      }
      //validar que la cabecera este en estado asignacion flete, ademas
      //validar que sus item sean estado 2 o menos

      if (validate){
        this.deletedItem = { ...item }
        this.dialogDelete = true
      } else {
        let msg = 'Todas las guias y ajustes del documento deben estar en estado Proforma, para que pueda continuar con la eliminacion de todo el documento y sus detalles.'
        this.$message({
          message: msg,
          type: 'warning',
          showClose: true,
          duration: 0
        });
      }

    },
    async deleteItemConfirm() {
      this.loading = true;
      await this.$store.dispatch("AuthModule/verifyToken");

      axiosC({
        method: "DELETE",
        url: `transjuma/ProformaCabecera/${this.deletedItem.id}/`,
        headers: {
          Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
        },
      })
        .then(() => {
          this.getDataGeneral(
            "GET",
            `transjuma/ProformaCabecera/`,
            this.nameItemStore,
            "loading",
            {},
            true,
            true
          );
          this.closeDelete();
        })
        .catch((err) => {
          this.loading = false;
          alert(err.response.data.message);
        });
    },
    async editItem(item) {
      this.editedItem = { ...item }
      this.updateAutoCompletesCabecera
      await this.updateAutoCompletesDetalle
      console.log('EDITAR',item);
      this.dialog = true;
      this.mode = "PUT";
      this.themodeEdicion = true

      // this.$store.state.transportistas = this.transportistas
      // this.$store.state.tipoAjustes = this.tipoAjustes
    },
    validarEstado(item){
      console.log(item)

      if (item.guia) {
        if (item.guia.estado_guia <= 3) {
          return true
        } else {
          return false
        }
      } else if (item.ajuste_detalle){
        if (item.ajuste_detalle.estado_guia <= 3) {
          return true
        } else {
          return false
        }
      } else if (item.deduccion) {
        return true
      }else {
        return false
      }
    },
    updateList() {
      this.getDataGeneral(
        "GET",
        `transjuma/ProformaCabecera/`,
        this.nameItemStore,
        "loading",
        {},
        true, //paginacion
        false, // opcion para que devuelva algo
        false //modo normal activado, vienen todos los resultados
      );
      this.getDataGeneral(
        "GET",
        `transjuma/TransportistaList`, //cuando son rutas de listas completas sin el /
        "transportistas",
        "",
        {},
        false, //paginacion
        false, // opcion para que devuelva algo
        true //modo normal activado, vienen todos los resultados
      );
      this.getDataGeneral(
        "GET",
        `transjuma/EstadoAsignacion/`,
        "estados",
        "",
        {},
        false, //paginacion
        false, // opcion para que devuelva algo
        true //modo normal activado, vienen todos los resultados
      );
      // this.getDataGeneral(
      //   "GET",
      //   `transjuma/TipoAjuste/`,
      //   "tipoAjustes",
      //   "",
      //   {},
      //   false, //paginacion
      //   false, // opcion para que devuelva algo
      //   true //modo normal activado, vienen todos los resultados
      // );
    },

    //REPORTES
    async reporteProforma(item) {
      console.log(item)
      this.loading = true
      await this.$store.dispatch("AuthModule/verifyToken")

        axiosC({
          url: `transjuma/ReporteProforma/${item.id}/pdf`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
            'Content-Type': 'application/pdf',
          },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          // const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          saveAs(new Blob([response.data]), `reporte_proforma_${item.id}.pdf`)
          this.loading = false
        })
        .catch((err)=>{
          this.loading = false
          console.log(err)
        })
    },
    async reporteProformaXsl(item) {
      console.log(item)
      this.loading = true
      await this.$store.dispatch("AuthModule/verifyToken")

      axiosC({
        url: `transjuma/ReporteProforma/${item.id}/xls`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'arraybuffer',
      })
      .then((response) => {
        // const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        saveAs(new Blob([response.data]), `reporte_proforma_${item.id}.xlsx`)
        this.loading = false
      })
      .catch((err)=>{
        this.loading = false
        console.log(err)
      })
    },
    async sendManyEmails(item) {
      this.baseSelected = [...item]
      this.loading = true

      for (let tr of this.baseSelected){
        let transportista_asignacion = this.transportistas.find(x => x.id == tr.transportista)
        tr.transportista_asignacion = transportista_asignacion
      }

      const body = { records: this.baseSelected }

      if (body.records.length){
        await this.$store.dispatch("AuthModule/verifyToken")
        axiosC({
          method: "POST",
          data: body,
          url: `transjuma/jumaEnvioMails`,
          headers: {
            Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
          },
        })
        .then(res => {
          this.loading = false
          alert(`${res.status? res.status: res} El correo o correos se han enviado, revisar en la bandeja de salida del correo para confirmar`)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          alert(`Hubo un problema con el envio del correo, Error: ${err}`)
        })
      } else {
        this.loading = false
        this.$message({
          message: 'Debe tener seleccionado uno o mas registros de esta pagina para activar esta funcion.',
          type: 'warning'
        });
      }

    },
    openUpdateMany(item){
      this.baseSelected = [...item]
      console.log(this.baseSelected)
      if (this.baseSelected.length){
        this.manyRowsDialog = true

      } else {
        this.loading = false
        this.$message({
          message: 'Debe tener seleccionado uno o mas registros de esta pagina para activar esta funcion.',
          type: 'warning'
        })
      }
    },
    closeManyRowsDialog() {
      this.manyRowsDialog = false
      this.manyUpdateItem = {
        fecha_many: null
      }
      this.$refs.updateManyForm.reset()
      this.updateManyForm = {}
      this.loading = false
    },
    async saveManyRows() {
      await this.$store.dispatch("AuthModule/verifyToken")

      if (this.$refs.updateManyForm.validate()){
        this.loading = true

        const body = { records: this.baseSelected.map(item => {
            let obj ={
              estado_asignacion: this.updateManyForm.estado_asignacion ?? undefined,
              fecha: this.updateManyForm.fecha_many ?? undefined,
              id: item.id,
              company : 1,
              updated_by : this.$store.state.AuthModule.refu,
              ref : this.$store.state.AuthModule.r2,
            }
            return obj
          }),
        }

        console.log(body)
        axiosC({
          method: 'PUT',
          url: `transjuma/AsignacionCabecera/bulk-update/`,
          data: body.records,
          headers: {
            Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
          },
        })
        .then((result) => {
          console.log(result)
          this.closeManyRowsDialog()
          this.updateList()

        }).catch((err) => {
          console.log(err.response)
        })

        
      }
    },

    //BUSQUEDA Y FILTRO
    openSearch(){
      console.log('aquiiiiiiiiiiiiii')
      this.$store.state.searchProformas = true
    },
    getDataRecords (){
      this.getDataGeneral(
        "GET",
        `transjuma/ProformaCabecera/`,
        this.nameItemStore,
        "loading",
        {},
        true, //paginacion
        false, // opcion para que devuelva algo
        false //modo normal activado, vienen todos los resultados
      );
    },
    onAdvanceSearch(item){
      //console.log(item, this.pendientes)
      const page = 1;
      const size = 20;
      // this.nameItemStore = "proformas"
      this.queryAdvanceSearch = {}
      // this.queryAdvanceSearch.list = true
      this.queryAdvanceSearch.onAdvanceSearch = true

      let valid = true

      if (item.fecha1 && item.fecha2 && item.rango){
        this.queryAdvanceSearch.fecha_bw = [item.fecha1, item.fecha2]
      }
      if (item.fecha1 && !item.rango) {
        this.queryAdvanceSearch.fecha_e = item.fecha1
      }
      if (item.fecha1 && !item.fecha2 && item.rango ) {
        this.queryAdvanceSearch.fecha_gt = item.fecha1
      }
      if (!item.fecha1 && item.fecha2 && item.rango ) {
        this.queryAdvanceSearch.fecha_lt = item.fecha2
      }
      // if (item.transportista && item.transportista.length > 0){
      //   this.queryAdvanceSearch.transportista_id = item.transportista
      // }
      // if (item.numero_guia) {
      //   this.queryAdvanceSearch.guia = item.numero_guia
      // }

      if (item.nombre) {
        this.queryAdvanceSearch.nombre = item.nombre
      }

      if (item.id){
        this.queryAdvanceSearch.id = item.id
      }

      // if (this.pendientes == 'pendientes'){
      //   this.queryAdvanceSearch.id_estado_int = 4
      // } else if (this.pendientes == 'facturadas') {
      //   this.queryAdvanceSearch.id_estado_int = 5
      // } else {
      //   this.queryAdvanceSearch.id_estado_int = null
      // }

      this.activeAdvanceSearch = true
      this.currentPage = 1

      this.getDataGeneral(
        "GET",
        `transjuma/ProformaCabecera`,
        this.nameItemStore,
        "loading",
        {},
        true, false,false, null, false, this.queryAdvanceSearch
      );

    },
    refreshData(){
      this.getDataRecords()
    }

  },
  computed: {
    checkMode() {
      return this.mode == "PUT" ? true : false;
    },
    formTitle() {
      if (this.editedIndex === -1) {
        return `Nuevo destino y tonelada`;
      } else {
        return `Edición de destino y tonelada`;
      }
    },
    async updateAutoCompletesDetalle() {

      // let headerDetalletipoAjustes = this.headersDetalles.find(
      //   (item) => item.value == "tipo_ajuste"
      // );
      // if (headerDetalletipoAjustes) {
      //   headerDetalletipoAjustes.vautoName = [...this.tipoAjustes]
      // }

    },
    updateAutoCompletesCabecera() {
      let headerCabeceraTransportista = this.headers.find(
        (item) => item.value == "transportista"
      );
      if (headerCabeceraTransportista) {
        headerCabeceraTransportista.vautoName = [...this.transportistas];
      }

      let headerCabeceraEstadoAsignacion = this.headers.find(
        (item) => item.value == "estado_asignacion"
      );

      if (headerCabeceraEstadoAsignacion) {
        headerCabeceraEstadoAsignacion.vautoName = [...this.estados]
      }

      console.log(this.headers);
    },
  },

  created() {
    const page = 1;
    const size = 20;
    this.nameItemStore = "desserts";
    this.updateList();
  },
};
</script>
