<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card class="ml-1" elevation="0" rounded="xl">
          <v-card-title> Modulo Migracion Guias </v-card-title>
          <v-row justify="space-around">
            <v-col cols="12">
              <v-card class="ma-2" rounded="xl">
                <h3 class="text-center pt-3">
                  Indicaciones de Uso Herramienta de Migracion
                </h3>
                <v-card-text class="text-center">
                  La herramienta cuenta con un excel como template o borrador,
                  el cual le ayuda a la inserción en gran cantidad de guías.<br />
                  Dentro del excel está la forma correcta de colocar los datos
                  los cuales serán insertados, si son mal insertados se
                  informará con un mensaje el posible error.
                </v-card-text>
                <v-row>
                  <v-col> </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center justify-space-around">
            <v-col md="4" sm="6" class=" d-flex justify-center">
              <v-container>
                <v-btn
                  rounded
                  color="primary"
                  @click="downloadTemplate('loading')"
                  :loading="loading"
                  :disabled="loading"
                  >Template
                  <v-icon right dark> mdi-cloud-download </v-icon></v-btn
                >
              </v-container>
            </v-col>
            <v-col md="4">
              <v-container fluid class="d-flex justify-start align-center">
                <v-file-input
                  v-model="files"
                  :loading="loading4"
                  :disabled="loading4"
                  placeholder="Subir archivo de guias"
                  label="Archivo guia"
                  prepend-icon="mdi-paperclip"
                  counter
                  show-size
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
                <v-btn
                  small
                  rounded
                  class="ma-2"
                  color="primary"
                  fab
                  :loading="loading4"
                  :disabled="loading4"
                  @click="uploadFile('loading4')"
                >
                  <v-icon dark> mdi-cloud-upload </v-icon>
                  
                </v-btn>
              </v-container>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { saveAs } from 'file-saver'
import { axiosC } from "../api/axiosbd";
export default {
  data() {
    return {
      files: null,
      loader: null,
      loading: false,
      loading4: false
    };
  },
  methods: {
    async uploadFile(loader) {
      // console.log(this.files)
      await this.$store.dispatch('AuthModule/verifyToken')
      if (this.files) {

        this.loader = loader
        this.loaderEfect('A')

        let data = new FormData(); // creates a new FormData object
        data.append("file", this.files); // add your file to form data

        axiosC(`transjuma/upload/${this.files.name}`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
            "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          data,
        })
          .then((response) => {
            console.log(response)
            this.files= null

            let message = 'Info, El proceso ha finalizado con exito.'
            if (response.data && typeof response.data == 'string'){
              message = response.data
            }

            this.loaderEfect('D')
            this.$message({
              message: message,
              type: 'success'
            });
          })
          .catch((err) => {
            console.log(err.response)
            this.loaderEfect('D')
            this.$message({
              message: 'Recuerde revisar el excel, si el problema persiste consulte al servicio de soporte.',
              type: 'error'
            });

          })
      } else {
        console.error('No ha seleccionado ningun archivo')
      }
    },
    async downloadTemplate(loader) {
      this.loader = loader
      this.loaderEfect('A')
      await this.$store.dispatch('AuthModule/verifyToken')

      axiosC({
          method: 'GET',
          url: '/transjuma/templateJumaGuias',
          headers: {
              Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
          },
          responseType: 'blob',
      })
        .then((response) => {
          this.loaderEfect('D')
          console.log('HEADERS', response.headers['Content-Filename'])
          saveAs(response.data, response.headers['Content-Filename'])
        })
        .catch((err) => {
          this.loaderEfect('D')
          alert(`Error: ${err} Favor contactar al administrador`)
        })
    },
    loaderEfect (param) {
      const l = this.loader
      if (param == "A"){
        this[l] = !this[l]
      } else {
        this[l] = false
        this.loader = null
      }
    }
  },


};
</script>

<style>
  
</style>