<template>
    <div>
        <v-data-table        
        :headers="headers"
        :items="destinos"
        :search="search"
        class="elevation-1 rounded-xl px-2"
        :items-per-page="20"
        hide-default-footer
        >
          <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{ `Destinos Toneladas Historicos` }}</v-toolbar-title>
                <v-spacer></v-spacer>

                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-toolbar>
          </template>


          <template v-slot:item.neto_pagar="{ item }">
              <tr class="d-flex justify-end">
                <td class="text-center text-caption blue-grey--text text--darken-1">{{$convertToMoney(item.neto_pagar)}}</td>
              </tr>
          </template>

           <template v-slot:item.created="{ item }">
              <tr class="d-flex justify-center">
                <td class="text-center text-caption blue-grey--text text--darken-1">{{item.created.substring(0,10)}}</td>
              </tr>
          </template>
        </v-data-table>
        <v-pagination
          v-model="currentPage"
          :length="totalPages"
          total-visible="8"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="siguientePagina"
        ></v-pagination>

    </div>
</template>

<script>
import { paginationMethods } from '../mixins/pagination'

export default {
  mixins:[paginationMethods],
  data() {
    return {
      search: "",
      destinos: [],
      headers: [
        { text: 'Fecha', value: 'created', align: 'center'},
        { text: 'Número', value: 'numero' },
        { text: 'Tonelada', value: 'tonelada' },
        { text: 'Código de destino', value: 'codigo_destino' },
        { text: 'Destino', value: 'destino' },
        { text: 'Neto a pagar', value: 'neto_pagar', align:'center'},
        { text: 'Zona', value: 'zona', align:'center'},
        { text: 'Tipo', value: 'tipo' , align:'center'},
        // { text: "Actions", value: "accion", sortable: false , align:'center'}
      ],
    }
  },
  methods: {
    siguientePagina(item) {
      console.log(item)
      this.gotoPage(item, this.$route.meta.backRoute);
    },
  },
  created() {
    this.getDataGeneral(
      'GET',
      `transjuma/DestinoToneladaHistorico/`,
      'destinos',
      'loading',
      {},
      true,
      true
    )
  }
}
</script>