var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.itemsSumatorias),function(item){return _c('v-row',{key:item.nombre,staticClass:"my-0 py-0",attrs:{"dense":"","align":"center"}},[(!item.disable.includes(_vm.tipoModulo))?_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","md":"6"}},[_c('v-subheader',{staticClass:"px-1 pl-4"},[_vm._v(_vm._s(item.nombre)+":")])],1):_vm._e(),(!item.disable.includes(_vm.tipoModulo))?_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","md":"6"}},[_c('TextFieldNumber',{attrs:{"value":_vm.totales[`${item.nombreForm}`],"disabled":"","outlined":"","dense":"","options":{
                    locale: 'en-US',
                    prefix: '',
                    suffix: '',
                    length: 12,
                    precision: 2
                }}})],1):_vm._e()],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }