<template>
  <div>
    <AsignacionBase
      tipoModulo="AG"
      :asignacion-global="true"
    ></AsignacionBase>
  </div>
</template>

<script>
import AsignacionBase from '@/components/AsignacionBase.vue'

export default {
  name: 'Asignacion',
  components:{
    AsignacionBase
  }
}
</script>

<style>

</style>