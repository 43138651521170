<template>
  <div>
    <v-container fluid>
      <!-- 1ra fila de años -->
      <v-row align="center" dense>
        <v-col cols="12" md="12">

        <v-slide-group
          v-model="model"
          show-arrows
          active-class="success"
        >
          <v-slide-item
            v-for="n in dataYears"
            :key="n"
          >
            <v-btn
              small
              class="mx-1"
              :input-value="model === n"
              active-class="blue white--text"
              depressed
              rounded
              @click="() => { toggleYear(n)}"
            >
              Año {{ n }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
        </v-col>
      </v-row>

      <!-- 1ra fila de Meses -->
      <v-row align="center" dense>
        <v-col cols="12" md="12">

        <v-slide-group
          v-model="modelMonth"
          active-class="success"
          center-active
        >
          <v-slide-item
            v-for="n in monthItems"
            :key="n"
          >
            <v-btn
              small
              class=""
              :input-value="modelMonth === n"
              active-class="blue white--text"
              depressed
              rounded
              @click="() => { toggleMonth(n)}"
            >
              {{ n }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
        </v-col>
      </v-row>

    <data-dashboard
      :key="dashboarKey"
      :yearData="yearData"
      :monthData="monthData"
      :dayData="dayData"
      v-if="model== presentYearData"
    ></data-dashboard>
    </v-container>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
// import {axiosN506, axiosB} from '@/api/axiosbd'
import DataDashboard from './DataDashboard.vue'
import { paginationMethods } from "@/mixins/pagination";

export default {
  name: "MainDashboard",
  mixins:[paginationMethods],

  components:{
    DataDashboard
  },
  data() {
    return {
      dataYears: [],
      model: DateTime.now().setZone('America/Costa_Rica').year,
      modelMonth: `${DateTime.now().setZone('America/Costa_Rica').setLocale('es-419').toFormat('LLLL')}`,
      presentYearData: DateTime.now().setZone('America/Costa_Rica').year,
      presentDate: DateTime.now().setZone('America/Costa_Rica'),
      yearData: null,
      monthData: null,
      dayData: null,
      dashboarKey: 0
    }
  },
  methods:{
    // async getData(url){
    //   await this.$store.dispatch('verifyToken')
    //   axiosN506({
    //     method: 'GET',
    //     url: url,
    //     headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
    //   })
    //   .then((result) => {
    //     this.dataYears = result.data.data
    //   }).catch((err) => {
    //     console.log(err)
    //   });
    // },
    toggleYear(year) {
      // console.log(`Avtivo el toggle: ${year} modelo: ${this.model}`)
      this.model = this.model === year ? null : year;
      // console.log(`El modelo quedo como la opcion: ${this.model}`)
    
      // Your additional method logic here
      if (year === this.presentYearData) {
        // console.log(`Activating year: ${year}`)
        this.presentYearData = parseInt(year)
        this.yearData = parseInt(this.presentDate.year)
        this.monthData = this.presentDate.month
        this.dayData = this.presentDate.day
        console.log(this.dayData)
      } else {
        // console.log(`Activating year2: ${year == this.model}`)
        this.presentYearData = parseInt(year)
        this.yearData = parseInt(year)
        this.monthData = 12
        this.modelMonth = this.monthItems[this.monthData-1]
        const firstDayOfMonth = DateTime.fromObject({ year: this.yearData, month: this.monthData, day: 1 })
        this.dayData = firstDayOfMonth.plus({ months: 1, days: -1 }).day
        // console.log(this.dayData)
      }
      this.dashboarKey = year
    },
    toggleMonth(month){
      console.log(month)
      this.modelMonth = this.modelMonth === month ? null : month
      // Your additional method logic here
      console.log(this.monthItems.indexOf(month))
      this.dashboarKey = this.monthItems.indexOf(month)+1
      this.monthData = this.monthItems.indexOf(month)+1
      const firstDayOfMonth = DateTime.fromObject({ year: this.yearData, month: this.monthData, day: 1 })
      if (this.presentDate.year == this.presentYearData && this.presentDate.month == this.monthData){
        this.dayData = this.presentDate.day
      } else {
        this.dayData = firstDayOfMonth.plus({ months: 1, days: -1 }).day
      }
      console.log(this.dayData)

    }
  },
  computed:{
    monthItems(){
      // Create a DateTime object with any date in the year to get the month names
      const date = DateTime.local(2023, 1, 1);

      // Create an array of month names
      const monthNames = Array.from({ length: 12 }, (_, i) =>
        date.set({ month: i + 1 }).setLocale('es-419').toFormat('LLLL')
      )
      return monthNames
    }
  },
  created(){
    this.yearData= DateTime.now().setZone('America/Costa_Rica').year,
    this.monthData= DateTime.now().setZone('America/Costa_Rica').month,
    this.dayData= DateTime.now().setZone('America/Costa_Rica').day,
    console.log('me he creado', `${DateTime.now().setZone('America/Costa_Rica').year}`)
    // this.getData('/invoice_detail/usedYeras') //debe buscar los anos de movimiento de la base de datos
    this.getDataGeneral(
      "GET",
      `transjuma/usedYeras`,
      'dataYears',
      "loading",
      {},
      false,
      false, //retorna
      true, //normal
    )
  }
}
</script>

<style>

</style>