<template>
    <div>
      <div class="text-center" v-if="!showTable">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>

      <v-card rounded="xl" :disabled="loadingGeneral" >
        <v-card-title class="pt-1">
          <v-row dense width="100%" class="d-flex align-center justify-space-between mb-3">
            <v-col>
              <h5 class="ml-2">{{ `Lista de programación de pagos` }}</h5>
            </v-col>
            <v-col>
              <v-text-field
                  class="mx-2"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
              ></v-text-field>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn rounded x-small color="primary" class="mr-2" @click="$router.push({name: 'ProgramacionPagos'})">Nueva Programacion</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :loading="loadingGeneral"
            :headers="headers"
            :items="records"
            :search="search"
            class="elevation-0 rounded-xl px-2"
            v-if="showTable"
            dense
            disable-sort
            :items-per-page="20"
            :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-left-circle-outline',
                lastIcon: 'mdi-arrow-right-circle-outline',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                showCurrentPage: true
              }"
            >
              <template v-slot:item.actions="{ item }">
                  <!-- <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon> -->
                  <div class="d-flex">
                    <v-icon small class="pr-1" @click="openDelete(item)">mdi-delete</v-icon>
                    <v-icon small color="green"  class="pr-1" @click="reporteProgramacionXsl(item)">mdi-file-excel</v-icon>
                    <v-icon small v-if="item.estado_pago == 1" color="red" class="pr-1" @click="procesoDePago(item)" >payments</v-icon>
                    <v-icon small v-else color="blue darken-1" class="pr-1" @click="procesoDePago(item)" >payments</v-icon>
                    <!-- <v-icon small class="pr-1" v-if="validateButton(item.estado_pago)" @click="pagoProveedoresReporte(item)">mdi-email-send</v-icon> -->
                  </div>
              </template>

              <template v-slot:item.fecha_programacion="{item}">
                <td class="text-center">{{ item.fecha_programacion.substring(0,10) }}</td>
              </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <!-- DIALOG DELETE -->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">¿Está seguro de borrar esta programacion?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn small color="blue darken-1" text @click="deleteItem(false)">Cancel</v-btn>
            <v-btn small color="blue darken-1" text @click="deleteItem(true)">OK</v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialogo de vista de programacion pagada -->
      <v-row justify="center" v-if="previewProgramacion">
        <v-dialog
          v-model="previewProgramacion"
          scrollable
          max-width="800px"
          max-heigth="600px"
        >
          
          <v-card :loading="loadingGeneral">
            <v-card-title>
              <h5 v-if="programacionPorPagar">Programación por pagar</h5>
              <h5 v-else>Programación realizada</h5>
            </v-card-title>
            <v-card-subtitle class="pb-1">
              <v-row align="center" no-gutters>
                <v-col cols="5">
                  <span>Nombre</span>
                </v-col>
                <v-col cols="3" class="d-flex justify-end">
                  <span>Total Facturacion</span>
                </v-col>
                <v-col cols="2" class="d-flex justify-end">
                  <span>Total a Pagar</span>
                </v-col>
                <v-col cols="2" class="d-flex justify-end">
                  <span>Acciones</span>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text style="height: 300px;" class="py-3">

              <v-row align="center" v-for="item in totales_transportistas" :key="item.transportistaId">
                <v-col cols="5" class="pt-1 pb-0">
                  <span> {{ item.nombre }}</span>
                </v-col>
                <v-col cols="3" class="d-flex justify-end py-0">
                  <span>{{ convertToMoney(item.totalFactura) }}</span>
                </v-col>
                <v-col cols="2" class="d-flex justify-end py-0">
                  <span :class="{'red--text': item.total < 0}"> {{ convertToMoney(item.total) }}</span>
                </v-col>
                <v-col cols="2" class="d-flex justify-end py-0">
                  <v-btn icon :disabled="loadingGeneral" class="pt-1 pb-0">
                    <v-icon  color="red"  @click="reportePagoPDF(item)">mdi-file-pdf-box</v-icon>
                  </v-btn>
                  <v-btn icon :disabled="loadingGeneral" class="pt-1 pb-0" v-if="!programacionPorPagar">
                    <v-icon  color="primary"  @click="reportePagoEMAIL(item)">mdi-email-fast-outline</v-icon>
                  </v-btn>
                </v-col>
                
              </v-row>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-row align="center" class="ma-0 pa-0">
                <v-col cols="6" class="pa-0">
                  <v-btn
                    v-if="!programacionPorPagar"
                    color="blue darken-1"
                    text
                    @click="previewProgramacion = false"
                    :loading="loadingGeneral"
                    class="mr-4"
                  >
                    Cerrar
                  </v-btn>
                  <v-btn
                    v-else
                    rounded
                    small
                    color="warning"
                    @click="continuarProcesoPago"
                    :loading="loadingGeneral"
                    class="mr-4"
                  >
                    Pagar
                  </v-btn>
                  <v-tooltip top v-if="!programacionPorPagar">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn 
                        icon
                        :disabled="loadingGeneral"
                        v-bind="attrs"
                        v-on="on"
                        > 
                        <v-icon  color="teal darken-2" class="pr-1" @click="reportePagoGlobalEMAIL">mdi-email-fast-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Envio General de Email</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn 
                        icon
                        :disabled="loadingGeneral"
                        v-bind="attrs"
                        v-on="on"
                        > 
                        <v-icon  color="red" class="pr-1" @click="reportePDFGeneral">mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Reporte PDF</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="3" class="d-flex justify-end pa-1">
                  <span><strong> {{ `¢${sumTotalFactura ? sumTotalFactura : 0.00}` }}</strong></span>
                </v-col>
                <v-col cols="3" class="d-flex justify-end pa-1">
                  <span><strong> {{ `¢${sumTotalNeto ? sumTotalNeto: 0.00}` }}</strong></span>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
</template>

<script>
import { axiosC, mainUrl } from "../api/axiosbd"
import { paginationMethods } from "../mixins/pagination"

export default {
  mixins:[paginationMethods],
  data() {
    return {
      dialogSend: false,
      loadingGeneral: false,
      search: "",
      showTable: true,
      headerId: null,
      headerSchema: null,
      headers: [
        { text: 'No.', value: 'id', align:'center', class: 'px-2'},
        { text: 'Fecha de programación', value: 'fecha_programacion', align:'center'},
        { text: 'Descripción', value: 'descripcion', class: 'px-2' },
        { text: 'Estado de pago', value: 'estado_pago',  align:'center', class: 'px-2' },
        { text: "Actions", value: "actions", sortable: false }
      ],
      records: [],
      dialog: false,
      dialogDelete: false,
      dialogConfirm: null,
      editedIndex: -1,
      totales_transportistas: [],
      sumatorias:{
        totalBase: [],
        totalDescuento: [],
        totalIva: [],
        subtotal: [],
        total: [],
      },
      previewProgramacion: false,
      sumTotalFactura: 0,
      sumTotalNeto: 0,
      programacionPorPagar: false,
    }
  },
  methods: {
    convertToMoney(x) {
      return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    openDelete(item){
      this.dialogDelete = true
      this.dialogConfirm = item.id
      
    },
    async deleteItem(dialogConfirm) {
      
      if (dialogConfirm) {
        await this.$store.dispatch("verifyToken")
          try {
            const responseDelete = await axiosC({
              method: "DELETE",
              url: `pago_proveedores_cabeceras/delete`,
              data: {
                "id": this.dialogConfirm
              },
              headers: { Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}` },
            })
            if (responseDelete.status == 200){
              this.dialogDelete = false
              const obj = {
                requestMethod: 'GET',
                model: 'pago_proveedores_cabeceras',
                urlMethod: 'list',
                urlParams: '?updatedAt[order]=desc',
                body: null
              }
              await this.$store.dispatch("verifyToken")
              this.$store.dispatch('serverRequest', obj)
              this.getData(true)

            } else {
              this.dialogDelete = false
              throw new Error('Opps problemas en borrar este documento')
            }
                  
          } catch (error) {
            alert(error)
          }
          this.dialogDelete = false
      } else {
        this.dialogDelete = false
      }
    },
    validateButton(estado_pago) {
      if (estado_pago == 'Pagado') return true
      return false
    },
    async getDetallesDePago(cabeceraId) {
      //console.log('DETALLE DEL PAGO', cabeceraId)
      this.loadingGeneral = true
      await this.$store.dispatch("AuthModule/verifyToken")
      this.totales_transportistas = []
      const dataPago = await axiosC({
        method: "GET",
        url: `transjuma/PagoProveedoresCabecera/${cabeceraId}`,
        headers: {
          Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
        },
      })
      // //console.log(dataPago)
      this.dataReporteXls = [...dataPago.data]

      if (!this.dataReporteXls.length) {
        throw new Error('Opps no hay detalles en la consulta')
      }

      for (let registro of this.dataReporteXls) {
        // //console.log(registro)
        let exist = false
        const neto_pagar = registro.precio_base + registro.descuento + registro.iva

        for (let item of this.totales_transportistas){
          if (item.transportistaId == registro.transportistaId) {
            exist = true

            item.precio_base += registro.precio_base,
            item.descuento += registro.descuento,
            item.iva += registro.iva,
            item.total += neto_pagar
            item.totalFactura += neto_pagar

            if (registro.origen == 'Deduccion' && registro.iva == 0) {
              // //console.log(item.total, item.totalFactura)
              // //console.log(`sin iva no sumar: ${registro.iva}`)
              item.totalFactura += neto_pagar*-1
              // //console.log(item.total, item.totalFactura)
            }

            break
          }
        }
        if (!exist) {
          this.totales_transportistas.push({
            transportistaId: registro.transportistaId,
            nombre: registro.transportista,
            precio_base: registro.precio_base,
            descuento: registro.descuento,
            iva: registro.iva,
            total: neto_pagar,
            email: registro.email,
            totalFactura: neto_pagar
          })
        }



      }
      this.totales_transportistas = this.totales_transportistas.sort(function (x, y) {
                                        let a = x.nombre.toUpperCase(),
                                            b = y.nombre.toUpperCase();
                                        return a == b ? 0 : a > b ? 1 : -1;
                                    });

      let sumTotal = []
      let sumNeto = []
      for (let item of this.totales_transportistas) {
        sumTotal.push(item.totalFactura)
        sumNeto.push(item.total)
      }
      this.sumTotalFactura = this.convertToMoney(sumTotal.reduce((a, b) => a + b, 0))
      this.sumTotalNeto = this.convertToMoney(sumNeto.reduce((a, b) => a + b, 0))
      this.loadingGeneral = false
      // return dataReporteXls
    },
    async reporteProgramacionXsl(item) {
      //console.log('REPORTE PROGRA XLSX', item)
      this.loadingGeneral = true
      await this.$store.dispatch("AuthModule/verifyToken")
      await this.getDetallesDePago(item.id)
      axiosC({
        method: "POST",
        url: `transjuma/PagoProveedoresCabecera/reporteProgramacionXsl/`,
        headers: {
          Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
        },
        data: this.dataReporteXls,
        responseType: 'arraybuffer',
      }).then((response) => {

          saveAs(new Blob([response.data]), `Reporte de Pago  No.${item.id} con fecha ${item.fecha_programacion.substring(0,10)}.xlsx`)
          this.loadingGeneral = false 
      })
      .catch(err =>{
        //console.log(err)
        this.loadingGeneral = false
        //colocar alert de error
      })

    },
    pagoProveedoresReporteIndividual(){
      this.pagoProveedoresReporte()
    },
    async pagoProveedoresReporte() {
      
      await this.$store.dispatch("AuthModuleverifyToken")

      const list = await this.getDetallesDePago( this.cabeceraProgramacion.id)

      let groups = list.reduce((r, a) => {
        r[a.transportistaId] = [...r[a.transportistaId] || [], a]
        return r
      }, {})
      
      const body = {
        id_cabecera:  this.cabeceraProgramacion.id,
        email: '',
        records: groups
      }

      fetch(`${mainUrl}pago_proveedores_detalles/report`, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
              Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
              'Content-Type': 'application/json'
          }
      })
      .then(res => {
          res.blob().then(file => {
              const fileURL = URL.createObjectURL(file)
              window.open(fileURL)
          })
      })
      .catch(err => alert(err))
    },

    async reportePDFGeneral(){
      this.loadingGeneral = true
      await this.$store.dispatch("AuthModule/verifyToken")

      try {
        let list = JSON.parse(JSON.stringify(this.totales_transportistas))
        const keys = ['descuento','iva','precio_base','total','totalFactura']

        for (let item of list) {
          for (let key in item) {
            if (keys.indexOf(key) > -1) {
              if (item[key]) {
                item[key] = this.convertToMoney(item[key])
              } else {
                item[key] = '0.00'
              }
            }
          }
        }

        const url = `https://cwr.webdgroup.com/api/report`
        let body = {
            template: { _id : "cUF45EWKn4YfTk34"  },
            data : {
              fecha: this.cabeceraProgramacion.fecha_programacion.substring(0,10),
              total_facturacion: this.sumTotalFactura,
              total_pagar: this.sumTotalNeto,
              list
            },
            options: { reports: { save: false } }
        }

        fetch(url, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic YW5kcmVzZDI3OkFuZDExMzA1QCE=`
          }
        })
        .then(res => {
          res.blob().then(file => {
            this.loadingGeneral = false
            const fileURL = URL.createObjectURL(file)
            window.open(fileURL)
          })
        })
      } catch (error) {
        this.loadingGeneral = false
        //console.log(error)
      }
    },

    async reportePagoPDF(item) {
      //console.log(item)
      try {
        this.loadingGeneral = true
        let groups = this.dataReporteXls.reduce((r, a) => {
          r[a.transportistaId] = [...r[a.transportistaId] || [], a]
          return r
        }, {})

        const group = groups[item.transportistaId]

        let body = {
          id_cabecera:  this.cabeceraProgramacion.id,
          email: item.email,
          nombre: item.nombre,
          fecha: this.cabeceraProgramacion.fecha_programacion,
          records: group
        }

        //console.log(body)
        await this.$store.dispatch("AuthModule/verifyToken")
        axiosC({
          method: "POST",
          url: `transjuma/PagoProveedoresCabecera/reportePagoPDF/`,
          headers: {
            Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
          },
          data: body,
          responseType: 'arraybuffer', 
        })
        .then((response) => {
          saveAs(new Blob([response.data]), `reporte_pago_${body.fecha.substring(0,10)}_${item.nombre}.pdf`)
          this.loadingGeneral = false 
        })
        .catch(err=>{
          this.loadingGeneral = false
        })

      } catch (error) {
        this.loadingGeneral = false
        //console.log(error)
        // collocar alerta del usuariao
      }
    },
    procesoDePago(item){
      //console.log(item)
      if (item.estado_pago != 2 && item.estado_pago != 4){
        this.programacionPorPagar = true
      } else {
        this.programacionPorPagar = false
      }
      this.cabeceraProgramacion = item
      this.getDetallesDePago(item.id)
      this.previewProgramacion = true
    },
    continuarProcesoPago(){
      //console.log('INICIAR PROCESO DE PAGO', this.cabeceraProgramacion)
      this.$store.dispatch('cabeceraProgramacion',this.cabeceraProgramacion.id)
      this.$router.push({name: 'ProcesoDePago'})

    },
    updateList(){
      this.getDataGeneral(
        "GET",
        `transjuma/PagoProveedoresCabecera/`,
        "records",
        "loadingGeneral",
        {},
        false, //paginacion
        false, // opcion para que devuelva algo
        true, // modo normal
        null, //parametros query
        false, // si devuelve uno solo
        {ids:1} // parametros para axios
      )
    },

    async reportePagoEMAIL(item){
      let  answer =  window.confirm('Realmente deseas enviar este correo?')
      if (answer){
        this.loadingGeneral = true
        let groups = this.dataReporteXls.reduce((r, a) => {
          r[a.transportistaId] = [...r[a.transportistaId] || [], a]
          return r
        }, {})

        const group = groups[item.transportistaId]

        let body = {
          id_cabecera:  this.cabeceraProgramacion.id,
          email: item.email,
          nombre: item.nombre,
          fecha: this.cabeceraProgramacion.fecha_programacion.substring(0,10),
          records: group
        }

        await this.$store.dispatch("AuthModule/verifyToken")
        axiosC({
          method: "POST",
          data: {one: true, data: body, group: []},
          url: `transjuma/PagoProveedoresCabecera/jumaReportePagosEmail/`,
          headers: {
            Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
          },
        })
        .then(res => {
          this.loadingGeneral = false
          alert(`${res.status? res.status: res} El correo o correos se han enviado, revisar en la bandeja de salida del correo para confirmar`)
        })
        .catch(err => {
          this.loadingGeneral = false
          alert(`Hubo un problema con el envio del correo, Error: ${err}`)
        })
      }

    },
    async reportePagoGlobalEMAIL(){
      let  answer =  window.confirm('Realmente deseas enviar todos los correos a la vez?')
      if (answer){
        this.loadingGeneral = true

        let groupEmail = []
        for (let item of this.totales_transportistas){
          // //console.log(item)
          let groups = this.dataReporteXls.reduce((r, a) => {
            r[a.transportistaId] = [...r[a.transportistaId] || [], a]
            return r
          }, {})

          let group = groups[item.transportistaId]

          let body = {
            id_cabecera:  this.cabeceraProgramacion.id,
            email: item.email,
            nombre: item.nombre,
            fecha: this.cabeceraProgramacion.fecha_programacion.substring(0,10),
            records: group
          }
          groupEmail.push(body)
          groups = null
          group = null
          body = {}

        }
        await this.$store.dispatch("AuthModule/verifyToken")
        axiosC({
          method: "POST",
          data: {one: false, data: {}, group: groupEmail},
          url: `transjuma/PagoProveedoresCabecera/jumaReportePagosEmail/`,
          headers: {
            Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
          },
        })
        .then(res => {
          this.loadingGeneral = false
          alert(`${res.status? res.status: res} El correo o correos se han enviado, revisar en la bandeja de salida del correo para confirmar`)
        })
        .catch(err => {
          this.loadingGeneral = false
          alert(`Hubo un problema con el envio del correo, Error: ${err}`)
        })
      }
    },
  },
  computed: {
  },
  created() {
    //console.log('PROGRAMACIONES')
    this.updateList()
  }
}
</script>