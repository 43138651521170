<template>
    <div>
      <BaseWindowList
        :headers="headers"
        :itemsBaseWindowList="desserts"
        title="Gestion Destinos y Toneladas"
        titleAddMethod="Agregar Nuevo Destino - Tonelada"
        :activarEdicion="true"
        :activarBorrar="true"
        v-on:editar-row="editItem"
        :currentPage="currentPage"
        :totalPages="totalPages"
        v-on:open-agregar-listado="openForm"
        v-on:click-gotopage="siguientePagina"
        :loading="loading"
        v-on:eliminar-row="deleteItem"

      >
        <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:item.neto_pagar="{ item }">
            <tr class="d-flex justify-end">
              <td class="text-center text-caption blue-grey--text text--darken-1 pr-4">{{convertToMoney(item.neto_pagar)}}</td>
            </tr>
        </template>
        <template v-slot:item.tonelada="{ item }">
            <tr class="d-flex justify-end">
              <td class="text-center text-caption blue-grey--text text--darken-1 pr-4">{{convertToMoney(item.tonelada)}}</td>
            </tr>
        </template>

      </BaseWindowList>
    
      <!-- MAIN DIALOG -->
      <v-form ref="editedItem">
        <v-dialog v-model="dialog" max-width="800px" persistent>
          <v-card rounded="xl" :loading="loading" :disabled="loading">
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field disabled v-model="editedItem.numero" label="Número"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field @change="buildNumber" type="number" v-model.number="editedItem.tonelada" label="Tonelada"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field @change="buildNumber" v-model="editedItem.codigo_destino" label="Código de destino"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.destino" label="Destino"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field type="number" v-model="editedItem.neto_pagar" label="Neto a pagar"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.zona" label="Zona"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      v-model="editedItem.tipo"
                      :items="tipos"
                      item-text="text"
                      item-value="id"
                      label="Tipo"
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn small color="blue darken-1" text @click="save(mode)">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>

      <!-- DIALOG DELETE -->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">¿Está seguro de borrar este destino y tonelada?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn small color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
            <v-btn small color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import { axiosC } from "../api/axiosbd"
import BaseWindowList from '@/components/BaseWindowList'
import {paginationMethods} from '../mixins/pagination'
export default {
  components:{
    BaseWindowList
  },
  mixins:[paginationMethods],
  data() {
    return {
      loading: false,
      search: "",
      headerId: null,
      destinos: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Número', value: 'numero' },
        { text: 'Tonelada', value: 'tonelada', align:'center' },
        { text: 'Código de destino', value: 'codigo_destino' },
        { text: 'Destino', value: 'destino' },
        { text: 'Neto a pagar', value: 'neto_pagar', align:'center'},
        { text: 'Zona', value: 'zona', align:'center'},
        { text: 'Tipo', value: 'tipo' , align:'center'},
        { text: "Actions", value: "actions", sortable: false , align:'center'}
      ],
      desserts: [],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        numero: '',
        tonelada: 0,
        codigo_destino: '',
        destino: '',
        neto_pagar: 0,
        zona: '',
        tipo: ''
      },
      deletedItem: {},
      defaultItem: {},
      tipos: [
        { id: 'F', text: 'FIJO' },
        { id: 'V', text: 'VARIABLE' }
      ],
      mode: 'POST'
    }
  },
  methods: {
    openForm(){
      console.log('ABRIENDO')
      this.dialog=true
    },
    siguientePagina(item) {
      this.gotoPage(item, this.$route.meta.backRoute);
    },
    convertToMoney(x) {
      // Verificar si el valor es nulo, indefinido o no es un número
      if (x === undefined || x === null || isNaN(Number(x))) {
        return "0.00";
      }
      // Convertir a número y luego aplicar toFixed(2)
      x = Number(x).toFixed(2);

      // Aplicar formato de separador de miles
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    buildNumber() {
      // console.log(this.editedItem.tonelada)
      let tonelada = this.editedItem.tonelada > 0 ? this.editedItem.tonelada : 0
      let codigo_destino = this.editedItem.codigo_destino.trim() != '' ? this.editedItem.codigo_destino : ''
      this.editedItem.numero = this.editedItem.tonelada > 0 ?  parseInt(tonelada) + codigo_destino : codigo_destino
    },
    clearSelects() {
      for (let field in this.defaultItem) {
        if (this.defaultItem[field].value) this.defaultItem[field].value = null
        if (this.defaultItem[field].values) this.defaultItem[field].values = []
      }
    },
    close() {
      this.dialog = false
      this.mode = 'POST'
      this.$nextTick(() => {
        this.clearSelects()
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    closeDelete() {
      this.dialogDelete = false

      this.$nextTick(() => {
        this.clearSelects()
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteItem(item) {
      console.log(item)
      this.deletedItem = {...item}
      this.dialogDelete = true
    },
    async deleteItemConfirm() {
      this.loading = true
      await this.$store.dispatch("AuthModule/verifyToken")

      axiosC({
        method: "DELETE",
        url: `transjuma/DestinoTonelada/${this.deletedItem.id}/`,
        headers: { Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}` },
      })
      .then(() => {
        this.getDataGeneral(
            'GET',
            `transjuma/DestinoTonelada/`,
            this.nameItemStore,
            'loading',
            {},
            true,
            true
          )
        this.closeDelete()
      })
      .catch((err) => {
        this.loading = false 
        alert(err.response.data.message)
      })
    },
    async save(method) {
      this.loading = true
      await this.$store.dispatch("AuthModule/verifyToken")

      let body = {}

      body = {...this.editedItem}

      body.company =1
      body.created_by = method == 'POST' ? this.$store.state.AuthModule.refu : this.editedItem.created_by
      body.updated_by =  method == 'PUT' ? this.$store.state.AuthModule.refu : null
      console.log(this.editedItem)
     
      

        axiosC({
          method: method,
          url: `transjuma/DestinoTonelada/${method=='PUT' ? body.id +'/' : ''}`,
          data: body,
          headers: { Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}` },
        })
        .then(() => {
          this.close()
          this.getDataGeneral(
            'GET',
            `transjuma/DestinoTonelada/`,
            this.nameItemStore,
            'loading',
            {},
            true,
            true
          )
        })
        .catch((err) => alert(err.response.data.message))
    },
    async editItem(item) {
      console.log(item)
      this.mode = 'PUT'
      this.editedItem = {...item}

      this.headerId = item.id
      this.dialog = true
    }
  },
  computed: {
    formTitle() {
      if (this.editedIndex === -1) {
        return `Nuevo destino y tonelada`;
      } else {
        return `Edición de destino y tonelada`;
      }
    }
  },
  async created() {
    const page = 1
    const size = 20
    this.nameItemStore = 'desserts'
    await this.getDataGeneral(
      'GET',
      `transjuma/DestinoTonelada/`,
      this.nameItemStore,
      'loading',
      {},
      true,
      true
    )
    // this.getData()
  }
}
</script>