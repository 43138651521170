<template>
  <div>
    <BaseWindowList :headers="headers" :itemsBaseWindowList="desserts" title="Gestion de Deduciones"
      titleAddMethod="Agregar Nueva Deduccion" :activarEdicion="false" :activarBorrar="false" :currentPage="currentPage"
      :totalPages="totalPages" :loading="loading" v-on:editar-row="editItem" v-on:open-agregar-listado="openForm"
      v-on:click-gotopage="siguientePagina" v-on:eliminar-row="deleteItem" itemStore="searchDeducciones" :showSelect="activarSelectMultiple"
      :activateUpdateMany="activarSelectMultiple" v-on:update-many="updateMany" :clearSelectMultiple="clearSelectMultiple">

      <template
        v-slot:header="{ props: { headers } }"
      >
        <thead>
          <tr>
            <th :colspan="headers.length">
              <div class="d-flex">
              <v-radio-group
                v-model="pendientes"
                row
                class="mt-0"
                dense
              >
                <v-radio
                  label="Pendientes"
                  value="pendientes"
                ></v-radio>
                <v-radio
                  label="Todas las deducciones"
                  value="todas"
                ></v-radio>
              </v-radio-group>
              <h3 class="mx-4 pt-1" v-if="totalRegistros">Total de regitros: {{ totalRegistros }}</h3>
              <v-checkbox
                class="mt-0"
                dense
                v-model="activarSelectMultiple"
                :label="`Activar Seleccion Multiple`"
                hide-details
              ></v-checkbox>
              </div>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item.fecha="{ item }">
        <tr class="d-flex justify-center">
          <td>
            {{ item.fecha ? item.fecha.substring(0, 10) : '' }}
          </td>
        </tr>
      </template>

      <template v-slot:item.fecha_pago="{ item }">
        <tr class="d-flex justify-center">
          <td>
            {{ item.fecha_pago ? item.fecha_pago.substring(0, 10) : '' }}
          </td>
        </tr>
      </template>

      <template v-slot:item.fecha_transporte="{ item }">
        <tr class="d-flex justify-center">
          <td>
            {{ item.fecha_transporte ? item.fecha_transporte.substring(0, 10) : '' }}
          </td>
        </tr>
      </template>

      <template v-slot:item.monto="{ item }">
        <tr class="d-flex justify-end">
          <td class="text-center text-caption blue-grey--text text--darken-1 ">
            {{ $convertToMoney(item.monto) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.iva="{ item }">
        <tr class="d-flex justify-end">
          <td class="text-center text-caption blue-grey--text text--darken-1 ">
            {{ $convertToMoney(item.iva) }}
          </td>
        </tr>
      </template>


      <template v-slot:item.transportista="{ item }">
        <tr class="d-flex justify-center">
          <td class="text-center text-caption blue-grey--text text--darken-1 ">
            {{ itemTextTransportista(item.transportista) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.tipo_facturar="{ item }">
        <tr class="d-flex justify-center">
          <td class="text-center text-caption blue-grey--text text--darken-1 ">
            {{ itemTextTiposFacturar(item.tipo_facturar) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.tipo_deduccion="{ item }">
        <tr class="d-flex justify-center">
          <td class="text-center text-caption blue-grey--text text--darken-1 ">
            {{ itemTextTiposDeduccion(item.tipo_deduccion) }}
          </td>
        </tr>
      </template>

      <template v-slot:item.other="{ item }">
        <tr>
          <td class="d-flex flex-row">
            <v-btn small icon class="mr-1" @click="editItem(item)">
              <v-icon small :color="item.estado_deduccion !== 'Flete' ? 'blue' : 'warning'">mdi-pencil</v-icon>
            </v-btn>

            <v-btn small icon @click="deleteItem(item)" :disabled="item.estado_deduccion !== 'Flete'">
              <v-icon small color="red">mdi-trash-can</v-icon>
            </v-btn>
          </td>
        </tr>

      </template>

    </BaseWindowList>

    <!-- MAIN DIALOG -->
    <v-form ref="editedItem">
      <v-dialog v-model="dialog" max-width="800px" persistent>
        <v-card rounded="xl" :loading="loading" :disabled="loading">
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <!-- <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    dense
                    v-model="editedItem.numero"
                    label="Número"
                  ></v-text-field>
                </v-col> -->

                <v-col cols="12" sm="6" md="4">
                  <v-menu v-model="menuPickers.fecha" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense v-model="editedItem.fecha" label="Fecha" prepend-icon="mdi-calendar" readonly  :disabled="disableEdit"
                        v-bind="attrs" v-on="on" clearable :rules="[
                          v => !!v || 'Campo requerido'
                        ]"></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.fecha" @input="menuPickers.fecha = false"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete v-model="editedItem.tipo_facturar" :items="TiposFacturar" item-text="nombre"  :disabled="disableEdit"
                    item-value="id" label="Tipo facturar" clearable dense :rules="[
                      v => !!v || 'Campo requerido'
                    ]">
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete v-model="editedItem.transportista" :items="transportistas" item-text="nombre"  :disabled="disableEdit"
                    item-value="id" label="Transportistas" clearable dense :rules="[
                      v => !!v || 'Campo requerido'
                    ]">
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete v-model="editedItem.tipo_deduccion" :items="TiposDeduccion" item-text="nombre"  :disabled="disableEdit"
                    item-value="id" label="Tipos de Deduccion" clearable dense :rules="[
                      v => !!v || 'Campo requerido'
                    ]">
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field dense v-model="editedItem.descripcion_detallada"  :disabled="disableEdit"
                    label="Descripcion Detallada"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field :key="keyPrecio" v-model="editedItem.monto"  :disabled="disableEdit" label="Monto" dense :rules="[
                    v => !!v || 'Campo requerido'
                  ]"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="editedItem.iva" label="IVA" dense  :disabled="disableEdit"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field dense v-model="editedItem.guia" label="Guia"  :disabled="disableEdit"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-menu v-model="menuPickers.fecha_transporte" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto" dense>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="editedItem.fecha_transporte" label="Fecha de Transporte"  :disabled="disableEdit"
                        prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" clearable dense :rules="[v => !!v || 'Campo requerido']"></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.fecha_transporte"
                      @input="menuPickers.fecha_transporte = false"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-menu v-model="menuPickers.fecha_pago" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto" dense >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="editedItem.fecha_pago" label="Fecha de Pago" prepend-icon="mdi-calendar"  :disabled="disableEdit"
                        readonly v-bind="attrs" v-on="on" clearable dense></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.fecha_pago"
                      @input="menuPickers.fecha_pago = false"></v-date-picker>
                  </v-menu>
                </v-col>


              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn small color="blue darken-1" text @click="save(mode)">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <!-- DIALOG DELETE -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">¿Está seguro de borrar este destino y tonelada?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CUADRO DIALOGO BUSQUEDA AVANZADA -->
    <BusquedaAvanzada
      nameItemStore="searchDeducciones"
      v-on:advance-search="onAdvanceSearch"
    ></BusquedaAvanzada>

    <!-- CUANDOR DE ACTUALIZACION DE FECHA -->
    <v-row justify="center">
      <v-dialog
        v-model="dialogUpdateFechaPago"
        max-width="350"
      >
        <v-card rounded>
          <v-card-title class="text-h5">
            Agrega fecha de pago
          </v-card-title>

          <v-card-text>
            <v-menu v-model="menuPickers.fecha_pago" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y min-width="auto" dense>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="editedItemUpdateMany.fecha_pago" label="Fecha de Pago"  :disabled="disableEdit"
                  prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" clearable dense :rules="[v => !!v || 'Campo requerido']"></v-text-field>
              </template>
              <v-date-picker v-model="editedItemUpdateMany.fecha_pago"
                @input="menuPickers.fecha_pago = false"></v-date-picker>
            </v-menu>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error"
              small
              rounded
              @click="cancelarUpdateMany"
            >
              Cancelar
            </v-btn>

            <v-btn
              color="success"
              small
              rounded
              @click="contunuarUpdateMany"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { axiosC } from "../api/axiosbd";
import BaseWindowList from "@/components/BaseWindowList";
import { paginationMethods } from "../mixins/pagination";
import BaseAutoComp from '../components/BaseAutoComplet.vue';
import BusquedaAvanzada from "../components/BusquedaAvanzada.vue";

export default {
  components: {
    BaseWindowList, BaseAutoComp, BusquedaAvanzada
  },
  mixins: [paginationMethods],
  data() {
    return {
      pendientes: 'pendientes',
      totalRegistros: 0,
      // allDeducciones: false,
      loading: false,
      search: "",
      headers: [
        { text: 'Código', value: 'id' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Transportista', value: 'transportista' },
        { text: 'Tipo de deducción', value: 'tipo_deduccion' },
        { text: 'Descripción detallada', value: 'descripcion_detallada' },
        { text: 'Monto', value: 'monto' },
        { text: 'IVA', value: 'iva' },
        { text: 'Guía', value: 'guia', aling: 'center' },
        { text: 'Fecha de transporte', value: 'fecha_transporte' },
        { text: 'Tipo facturar', value: 'tipo_facturar' },
        { text: 'Fecha de pago', value: 'fecha_pago' },
        // { text: "Actions", value: "actions", sortable: false }
        { text: "Actions", value: "other", sortable: false }
      ],
      desserts: [],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {},
      editedItemUpdateMany: {},
      deletedItem: {},
      tipos: [
        { id: "F", text: "FIJO" },
        { id: "V", text: "VARIABLE" },
      ],
      mode: "POST",

      //
      menuPickers: {
        fecha: false,
        fecha_proforma: false,
        fecha_fcatura: false,
        fecha_pago: false,
        fecha_pago_recibido: false
      },
      keyPrecio: 0,
      keyDescuento: 0,
      keyIva: 0,
      keyAutoDestino: 0,
      sedes: [
        { id: 1, nombre: 'Pavas' }
      ],
      transportistas: [],
      TiposFacturar: [],
      TiposDeduccion: [],
      procesoCompleto: false,
      selectedItemEditar: null,
      themodeEdicion: false,
      disableEdit: false,
      activeAdvanceSearch: false,
      queryAdvanceSearch: {},
      activarSelectMultiple: false,
      dialogUpdateFechaPago: false,
      clearSelectMultiple: false
    };
  },
  watch:{
    pendientes(val){
      //console.log(val)
      this.getDataRecords(val)
    }
  },
  methods: {
    itemTextTiposFacturar(item) {
      if (item) {
        const text = this.TiposFacturar.find(y => y.id == item)
        return text ? text.nombre : ''
      }
    },
    itemTextTransportista(item) {
      if (item) {
        const text = this.transportistas.find(y => y.id == item)
        return text ? text.nombre : ''
      }
    },
    itemTextTiposDeduccion(item) {
      if (item) {
        const text = this.TiposDeduccion.find(y => y.id == item)
        return text ? text.nombre : ''
      }
    },

    siguientePagina(item) {
      let axiosParamas = {estado_deduccion: 'Flete'}
      if (this.pendientes == 'todas'){
        axiosParamas = {estado_deduccion: null}
      }
      if(this.activeAdvanceSearch){
        axiosParamas = {...this.queryAdvanceSearch}
      }
      this.gotoPage(item, `transjuma/Deduccion`, axiosParamas);
    },
    openForm() {
      //console.log("ABRIENDO");
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.mode = "POST";
      this.editedItem = {}
      this.search = null
      this.procesoCompleto = true
    },
    closeDelete() {
      this.dialogDelete = false;

      this.$nextTick(() => {
        this.editedItem = {}
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      //console.log(item);
      this.deletedItem = { ...item };
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.loading = true;
      await this.$store.dispatch("AuthModule/verifyToken");

      axiosC({
        method: "DELETE",
        url: `transjuma/Deduccion/${this.deletedItem.id}/`,
        headers: {
          Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
        },
      })
        .then(() => {
          this.getDataGeneral(
            "GET",
            `transjuma/Deduccion/`,
            this.nameItemStore,
            "loading",
            {},
            true,
            true
          );
          this.closeDelete();
        })
        .catch((err) => {
          this.loading = false;
          alert(err.response.data.message);
        });
    },
    async save(method) {
      if (this.$refs.editedItem.validate()){

        //console.log(method)
        this.loading = true;
        this.procesoCompleto = false
        await this.$store.dispatch("AuthModule/verifyToken");
  
        let body = {};
  
        //console.log('EDITEDITEM', this.editedItem)
        body = { ...this.editedItem }
  
        if (!this.editedItem.fecha_pago) {
          body.fecha_pago = null
        }
  
        body.estado_deduccion = 'Flete'
        body.company = 1;
        body.created_by =
          method == "POST"
            ? this.$store.state.AuthModule.refu
            : this.editedItem.created_by;
        body.updated_by =
          method == "PUT" ? this.$store.state.AuthModule.refu : null
  
        //del propio componente
        if (method == 'POST') {
          body.monto = parseFloat(this.editedItem.monto)
          body.iva = parseFloat(this.editedItem.iva ?? 0)
        }
  
        if (method == 'PUT') {
          body.monto = parseFloat(this.editedItem.monto.replace(',', ''))
          body.iva = parseFloat(this.editedItem.iva.replace(',', ''))
        }
        //console.log(body);
  
        axiosC({
          method: method,
          url: `transjuma/Deduccion/${method == "PUT" ? body.id + "/" : ""}`,
          data: body,
          headers: {
            Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
          },
        })
          .then(() => {
            this.close();
            this.getDataRecords()
          })
          .catch((err) => {
            this.loading = false
            alert(err.response.data.message)
          })
      }
    },
    editItem(item) {
      this.keyAutoDestino++
      //console.log(item);
      this.disableEdit = false
      if (item.estado_deduccion !== 'Flete') {
        this.disableEdit = true
      }
      this.mode = "PUT";

      this.$nextTick(() => {
        this.themodeEdicion = true
        this.selectedItemEditar = item.destino_tonelada //se envia al componente auto
      })

      this.editedItem = { ...item };
      this.editedItem.fecha = this.editedItem.fecha.substring(0, 10)
      this.editedItem.fecha_transporte = this.editedItem.fecha_transporte ? this.editedItem.fecha_transporte.substring(0, 10) : ''
      this.editedItem.fecha_pago = this.editedItem.fecha_pago ? this.editedItem.fecha_pago.substring(0, 10) : ''
      this.editedItem.monto = this.$convertToMoney(this.editedItem.monto)
      this.editedItem.iva = this.$convertToMoney(this.editedItem.iva)

      this.procesoCompleto = false
      this.dialog = true;
      return item

    },
    getDataRecords(modo = 'pendientes') {
      //console.log('MODE', modo)
      const page = 1;
      const size = 20;
      this.nameItemStore = "desserts"

      let axiosParamas = {estado_deduccion: 'Flete'}
      if (modo == 'todas'){
        axiosParamas = {estado_deduccion: null}
      }

      if(this.activeAdvanceSearch){
        //console.log('ACTIVADO SEARH ADVANCE')
        if (modo=='todas')  this.queryAdvanceSearch.estado_deduccion_ad = modo
        if (modo=='pendientes')  this.queryAdvanceSearch.estado_deduccion_ad = 'Flete'
        axiosParamas = {...this.queryAdvanceSearch}
      }

      this.getDataGeneral(
        'GET',  `transjuma/Deduccion/`, "desserts", "loading", {},
        true, false,false, null, false, axiosParamas)
    },
    onAdvanceSearch(item){
      //console.log(item, this.pendientes)
      const page = 1;
      const size = 20;
      this.nameItemStore = "desserts"
      this.queryAdvanceSearch = {}
      // this.queryAdvanceSearch.list = true
      this.queryAdvanceSearch.onAdvanceSearch = true

      let valid = true

      if (item.fecha1 && item.fecha2 && item.rango){
        this.queryAdvanceSearch.fecha_bw = [item.fecha1, item.fecha2]
      }
      if (item.fecha1 && !item.rango) {
        this.queryAdvanceSearch.fecha_e = item.fecha1
      }
      if (item.fecha1 && !item.fecha2 && item.rango ) {
        this.queryAdvanceSearch.fecha_gt = item.fecha1
      }
      if (!item.fecha1 && item.fecha2 && item.rango ) {
        this.queryAdvanceSearch.fecha_lt = item.fecha2
      }
      if (item.transportista && item.transportista.length > 0){
        this.queryAdvanceSearch.transportista_id = item.transportista
      }
      if (item.numero_guia) {
        this.queryAdvanceSearch.guia = item.numero_guia
      }

      if (item.descripcion_detallada) {
        this.queryAdvanceSearch.descripcion_detallada = item.descripcion_detallada
      }

      if (this.pendientes == 'pendientes'){
        this.queryAdvanceSearch.estado_deduccion_ad = 'Flete'
      } else {
        this.queryAdvanceSearch.estado_deduccion_ad = null
      }

      this.activeAdvanceSearch = true
      this.currentPage = 1

      //console.log(this.queryAdvanceSearch)
      this.getDataGeneral(
        'GET',  `transjuma/Deduccion/`, "desserts", "loading", {},
        true, false,false, null, false, this.queryAdvanceSearch)

    },
    updateMany(item){
      if (item.length > 0){
        //console.log(item, this.editedItemUpdateMany)
        this.selectedUpdateMany = [...item]
        this.dialogUpdateFechaPago = true
      } else {
        alert('Debe seleccionar al menos un registro')
      }
    },
    contunuarUpdateMany(){
      //console.log( this.editedItemUpdateMany.fecha_pago)
      let body = []
      const valid =  this.editedItemUpdateMany.fecha_pago !== null &&
            this.editedItemUpdateMany.fecha_pago !== undefined &&
            this.editedItemUpdateMany.fecha_pago !== ''
      if (valid){
        for (let item of this.selectedUpdateMany){
          item.fecha_pago = this.editedItemUpdateMany.fecha_pago
          body.push(item)
        }
        this.loading = true

        axiosC({
          method: 'PUT',
          url: `transjuma/Deduccion/bulk-update/`,
          data: {
            company: 1,
            created_by: this.$store.state.AuthModule.refu,
            records: body
          },
          headers: {
            Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
          },
        })
          .then(() => {
            this.clearSelectMultiple = true
            this.loading = false
            this.cancelarUpdateMany()
            his.selectedUpdateMany = []
            this.getDataRecords()
            this.clearSelectMultiple = false
          })
          .catch((err) => {
            this.loading = false
            //console.log(err.response ?? 'Error del sistema')
          })

      } else {
        alert('Debe ingresar una fecha, o cancele la accion')
      }
      //console.log(body)
    },
    cancelarUpdateMany(){
      this.editedItemUpdateMany = {}
      this.dialogUpdateFechaPago = false
    }
  },
  computed: {
    checkMode() {
      return this.mode == 'PUT' ? true : false
    },
    formTitle() {
      if (this.editedIndex === -1) {
        return `Nueva Deduccion`;
      } else {
        return `Edición Deduccion`;
      }
    },
  },
  created() {
    this.getDataRecords()
    this.getDataGeneral(
      "GET",
      `transjuma/TransportistaList`, //cuando son rutas de listas completas sin el /
      'transportistas',
      "",
      {},
      false, //paginacion
      false, // opcion para que devuelva algo
      true //modo normal activado, vienen todos los resultados
    );
    this.getDataGeneral(
      "GET",
      `transjuma/TipoFacturar/`,
      'TiposFacturar',
      "",
      {},
      false, //paginacion
      false, // opcion para que devuelva algo
      true //modo normal activado, vienen todos los resultados
    );
    this.getDataGeneral(
      "GET",
      `transjuma/TipoDeduccion/`,
      'TiposDeduccion',
      "",
      {},
      false, //paginacion
      false, // opcion para que devuelva algo
      true //modo normal activado, vienen todos los resultados
    );
  },
};
</script>