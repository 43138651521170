<template>
    <v-card class="table-middle-info border-radius-xl py-2 card-shadow rounded-xl">
      <v-simple-table dense class="pa-1">
      <template v-slot:default>
        <thead class="cabecera">
          <tr class="tr-cabecera">
            <th
              v-for="item in headers"
              :key="item.title"
              :class="item.class"
            >
              {{ item.title }}
            </th>
            <!-- <th class="text-center">
              Total en Pax
            </th> -->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in dataRecords"
            :key="item.name"
                class="font-weight-light text-grey-body--text tr-body"
            >
            <td
              v-for="(reg, index) in item.values"
              :key="index"
              class="text-caption"
              :class="headers[index].class"
              >
              <span v-if="typeof reg === 'string'"> {{ reg }}</span>
              <span v-else> {{ $convertToMoney(reg, true) }}</span>
            </td>
            <!-- <td class="text-caption">{{ item.name }}</td>
            <td class="text-caption text-right">{{ item.value ? parseInt(item.value) : 0 }}</td>
            <td class="text-caption text-right">{{ item.value ? parseInt(item.value) : 0 }}</td>
            <td class="text-caption text-right">{{ item.value ? parseInt(item.value) : 0 }}</td>
            <td class="text-caption text-right">{{ item.value ? parseInt(item.value) : 0 }}</td> -->
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    </v-card>
  </template>
  
  <script>
  export default {
    name: 'TableMiddle',
    props:{
      dataRecords:{
        type: Array,
        default: () => []
      },
      headers:{
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        
      }
    },
  }
  </script>
  
  <style scoped>
  .tr-cabecera th, .tr-body td{
    padding: 0 0.45rem 0 0.45rem !important;
  }
  </style>