export const paginationMethods = {
    data() {
        return {
            currentPage: 1,
            totalPages: 0,
            switch1: false,
            textSwitch: '',
            activateSearch: false,
            totalRegistros: 0
        }
    },
    methods: {
        gotoPage(item, ruta, urlParams='', loading = 'loading'){
            console.log(item, this.nameItemStore)
            this.currentPage = item
            const page = item
            const size = 10
            // const validateInfo = this.activateSearch ? { tipo: this.switch1 ? 1 : 0, filtro1: this.textSwitch} : false

            let url = `${ruta}?page=${page}`

            this.getDataGeneral(
                'GET',
                url,
                this.nameItemStore,
                loading,
                {},
                true,
                true,
                false,
                null,
                false,
                urlParams
            )
        },
        customSearch(){
            this.totalPages = 0
            this.currentPage = 1
            let params = {}
            if (this.textSwitch){
                params = {
                    tipo: this.switch1 ? 1 : 0,
                    filtro1: this.textSwitch
                }
                this.activateSearch = true
                this.getDataGeneral(
                    'POST',
                    'factura/catalogo_cabys/?page=1&size=10',
                    'itemsCabys',
                    'isLoading',
                    {},
                    false,
                    params,
                    true
                )
            } else {
                this.showAlert('error', "Debe ingresar un texto para realizar su busqueda.")
            }
            console.log(this.textSwitch)
        },
        async getDataGeneral(
            method, url, nameItemStore, nameLoading, moreBody = {},
            pagination = false, returnSome = false, normal= false, urlParams, one=false, paramsAxios={}) {
            // console.log(moreBody, params)
            this[nameLoading] = true

            let returnResult = []
            await this.$store.dispatch('AuthModule/verifyToken')

            const obj = {
                token: this.$store.state.AuthModule.accessToken,
                method: method,
                url: urlParams ? url + '?' +  urlParams.toString() : url,
                body: { ...moreBody },
                params: paramsAxios ? paramsAxios : null
            }
            // this.loadingTable = true
            await this.$store.dispatch('fechData', obj)
                .then((result) => {

                    if (returnSome && !pagination) {
                        if(normal){
                            if (one){
                                returnResult =  {...result}
                            } else {
                                returnResult =  [...result]
                            }
                        } else {
                            returnResult = [...result.results]
                        }
                    }

                    if (pagination) {
                        this.totalPages = result.total_pages
                        this.totalRegistros = result.count
                        returnResult = [...result.results]

                    }

                    if (nameItemStore) {
                        this[nameLoading] = false
                        
                        if(normal){
                            // console.log('DEVOLVIENDO {}')
                            // console.log(result)
                            this[nameItemStore] =  [...result]
                        } else if (!normal && !one) {
                            this[nameItemStore] =  [...result.results]
                        } else if (one){
                            this[nameItemStore] = [{...result}]
                        }
                        // this.$store.dispatch('actItemsBaseWindowsList', { data: result.data, nombre: nameItemStore })
                    } else {
                        this[nameLoading] = false
                        return 200
                    }

                })
                .catch((err) => {
                    console.log(err)
                    this[nameLoading] = false
                    if (err.response.status == 401) {
                        this.showAlert('error',
                            `token no valido o vencido:  ${err.response.data.detail}`
                        )
                        this.$router.push({ name: 'LoginView' })
                    } else if (err.response.status == 404) {
                        this.showAlert('error', 'Pagina no encontrada')
                    } else if (err == "Error: Network Error") {
                        this.showAlert(
                            "error",
                            `Se encontro un problema: Error de conexion a internert, favor revisar su conexion a internet.`,
                        )
                    } else if (err == "Error: timeout of 1000ms exceeded") {
                        this.showAlert(
                            "error",
                            `Se encontro un problema: ${err + " Revisar Conexion DB"}`,
                        );
                    } else {
                        this.errorAlert(err)
                    }
                })
            return returnResult
        },
    },

}