<template>
  <v-autocomplete
    dense
    class="rounded-lg"
    :outlined="outline"
    ref="autoRef"
    v-model="model"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    clearable
    hide-details
    :item-text="itemTextAuto"
    :item-value="itemValue"
    :label="label"
    prepend-icon="mdi-database-search"
    :color="color"
    @change="handleChange"
    @click:clear="borradoContenido"
    :key="keyAuto"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          Buscar
          <strong>{{textoBusqueda}}</strong>
        </v-list-item-title>
      </v-list-item>
    </template>

    <template v-slot:append >
      <v-icon
        color="green"
        @keyup.enter="searchAdvance(model)"
        @click="searchAdvance(model)"
      > mdi-send-circle </v-icon>
    </template>

  </v-autocomplete>
</template>

<script>
import { paginationMethods } from "../mixins/pagination"; 
export default {
  mixins: [paginationMethods],
  props:{
    outline:{
      type: Boolean,
      required: false,
      default: false
    },
    textoBusqueda:{
      type: String,
      required: true
    },
    fieldName:{
      type: String,
      required: true
    },
    label:{
      type: String,
      required: true
    },
    color:{
      type: String,
      required: false,
      default: "green"
    },
    itemText:{
      type: Array,
      required: true
    },
    itemValue:{
      type: String,
      required: true
    },
    advanceSearchFields:{
      type: Object,
      required: false,
      default: {}
    },
    rutaItemAuto:{
      type: String,
      required: true
    },
    procesoCompleto:{
      type: Boolean,
      required: false,
      default: false
    },
    modoEdicion:{
      type: Boolean,
      required: true,
      default: false
    },
    itemEditar:{
      required: false
    },
    alternativeRutaItemAuto:{
      required: false
    }
  },
  data: () => ({
    isLoading: false,
    items: [],
    model: null,
    search: null,
    tab: null,
    itemSelected: false,
    keyAuto:0,
    procesoInterno: false,
    globalUrl:''
  }),
  watch: {
    modoEdicion(val){
      if (val){
        this.searchAdvance(true)
      }
    },
    procesoCompleto(val){
      this.keyAuto++
      if (val){
        this.isLoading = false
        this.items = []
        this.model = null
        this.search = null
        this.tab = null
        this.itemSelected = false
        this.procesoInterno = false
      }
    },
    search(val) {
      // console.log(val, this.itemSelected, this.procesoInterno)
      if(val && val.length > 4 && !this.itemSelected && !this.procesoInterno){
        this.items = []
        this.searchAdvance()
      } else if (val && this.itemSelected && !this.procesoInterno) {
        this.itemSelected = false
      }
    },
    alternativeRutaItemAuto(val){
      if (val){
        this.globalUrl = val + `${this.itemEditar.id}`
      }
    },
    model(val){
      console.log(val)
    }
  },
  methods:{
    borradoContenido(){
      this.itemSelected = false
      this.keyAuto++
      this.items = []
      this.model = null
      this.tab = null
      this.search = null
      this.globalUrl = this.rutaItemAuto + this.itemEditar ? this.itemEditar.id : ''
      
    },
    async searchAdvance(id=false){
      console.log('buscando valores')
      // console.log(this.search)
      if(!id){
        if (this.search && this.search.length > 2){
          this.isLoading = true;
    
          let fields = {...this.advanceSearchFields}
          for (let propiedad in fields) {
            if (fields.hasOwnProperty(propiedad)) {
                fields[propiedad] = this.search; // Aquí colocamos la busqueda del auto
            }
          }
          const query = new URLSearchParams(fields)
          // Lazily load input items
          await this.getDataGeneral(
            "GET",
            this.rutaItemAuto,
            'items',
            "isLoading",
            {},
            false, //paginacion
            false, // opcion para que devuelva algo
            true, //modo normal activado, vienen todos los resultados
            query // parametros para busqueda avanzada
          );
          // this.$refs.autoRef.focus()
  
        }
      } else if (id){

        if (!this.globalUrl && !this.alternativeRutaItemAuto){
          this.globalUrl = this.itemEditar ? this.itemEditar.id : ''
        } else if(this.alternativeRutaItemAuto){
          this.globalUrl = this.alternativeRutaItemAuto + `${this.itemEditar.id}`
        }


        let url = this.globalUrl
        let fields = {}
        fields.id = this.itemEditar
        const query = new URLSearchParams(fields)
          // Lazily load input items
          await this.getDataGeneral(
            "GET",
            url,
            'items',
            "isLoading",
            {},
            false, //paginacion
            false, // opcion para que devuelva algo
            false, //modo normal activado, vienen todos los resultados
            null, //parametros de ruta
            true, //busqueda un solo registro
          )
          this.procesoInterno = true
          this.keyAuto++

          if (this.items.length == 1){
            this.model = this.items[0].id
          }
      } else {
        console.error('NO SE QUE HACER')
      }
    },
    itemTextAuto(item){
      let resultado = '';
      for (const elemento of this.itemText) {
        
        if (elemento == 'neto_pagar') {
          let numero = this.$convertToMoney(item[elemento])
          resultado += `$${numero} `;
        } else if (elemento == 'tonelada'){
          let tonelada = parseInt(item[elemento])
          resultado += `${tonelada} `
        } else {
          resultado += `${item[elemento]} `;
        }
      }
      return resultado
    },
    handleChange(item){
      console.log('escogi redultado', this.items, item)
      this.itemSelected = true
      const itemSelected = this.items.find(destino => destino.id == item)
      // console.warn('change inside auto', itemSelected)
      this.$emit('change-auto', {itemSelected, label:this.fieldName})
    }
  },
  beforeDestroy() {
    // this.procesoCompleto = false
    this.isLoading = false
    this.items = []
    this.model = null
    this.search = null
    this.tab = null
    this.itemSelected = false
    this.procesoInterno = false
  },
};
</script>

<style></style>
