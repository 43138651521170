<template>
    <div class="busqueda-avanzada">
      <v-dialog v-model="checkDialog" :max-width="customWidth ? customWidth : '700px'" persistent>
        <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl">
          <v-card-title class="d-flex justify-space-between">
            <p class="mb-0">Busqueda Avanzada</p>
            <v-btn icon @click="closeDialogbu">
              <v-icon color="red">
                mdi-close-circle
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle class="pb-3">
            <span>**Si solo busca una guia, seleccione solo la guia.</span>
          </v-card-subtitle>

          <v-card-text>
              <v-row v-if="activateGuiaTransportista">
                <!-- numero de guia -->
                <v-col cols="12" sm="6" md="6" v-if="nameItemStore != 'searchAsignaciones'">
                  <BaseAutoComp
                    textoBusqueda="Digite numero de guia"
                    label="Buscar Guia..."
                    :itemText="['id', 'numero', 'destino']"
                    itemValue="id"
                    :advanceSearchFields="{
                      'a.numero': null,
                      'b.destino': null,
                    }"
                    rutaItemAuto="transjuma/GuiaSelect"
                    :procesoCompleto="procesoCompleto"
                    v-on:change-auto="selected"
                    fieldName="Numero de Guia"
                    :modoEdicion="false"
                  >

                  </BaseAutoComp>
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                  :key="keyAutoTranportista"
                  v-model="searchForm.transportista"
                  class="text-body-2 custom-form-text-fields rounded-lg"
                  item-value="id"
                  dense
                  chips
                  multiple
                  small-chips
                  searchable
                  clearable
                  :items="transportistas"
                  :item-text="getItemTextTransportista"
                  label="Transportistas"
                  outlined
                ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row dense v-if="nameItemStore == 'searchDeducciones'">
                <v-col col="12" md="12">
                  <v-text-field
                    v-model="searchForm.descripcion_detallada"
                    dense
                    outlined
                    label="Descripcion detallada"
                    clearable
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense v-if="nameItemStore == 'searchFacturas'">
                <v-col col="12" md="5">
                  <v-text-field
                    v-model="searchForm.no_factura"
                    dense
                    outlined
                    label="No. factura"
                    clearable
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense v-if="nameItemStore == 'searchOrdenes'">
                <v-col col="12" md="5">
                  <v-text-field
                    v-model="searchForm.no_orden_compra"
                    dense
                    outlined
                    label="No. Orden"
                    clearable
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense v-if="nameItemStore == 'searchProformas' || nameItemStore == 'searchAsignaciones'">
                <v-col col="12" md="5">
                  <v-text-field
                    v-model="searchForm.id"
                    dense
                    outlined
                    :label="nameItemStore == 'searchAsignaciones' ? 'No. Asignación' : 'No. proforma'"
                    clearable
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col col="12" md="5" v-if="nameItemStore != 'searchAsignaciones'">
                  <v-text-field
                    v-model="searchForm.nombre"
                    dense
                    outlined
                    label="Codigo Ext. o descripcion"
                    clearable
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col cols="12" md="4" class="d-flex align-center">
                  <v-switch
                    dense
                    v-model="switch1"
                    :label="switch1 ? 'Por solo Fecha' : 'Por Rango de fechas'"
                    class="mt-0"
                    hide-details
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu
                    v-model="fecha1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="text-body-2 custom-form-text-fields rounded-lg"
                        dense
                        v-model="searchForm.fecha1"
                        :label="switch1 ? 'Fecha Exacta' : 'Desde'"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="searchForm.fecha1"
                      @input="fecha1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="4">
                  <v-menu
                    v-model="fecha2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="text-body-2 custom-form-text-fields rounded-lg"
                        dense
                        v-model="searchForm.fecha2"
                        label="hasta"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        :disabled="switch1"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="searchForm.fecha2"
                      @input="fecha2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>


          </v-card-text>

          <v-card-actions class="d-flex justify-end">
            <v-btn small rounded color="error" @click="reset" >reset</v-btn>
            <v-btn small rounded color="primary" @click="advanceSearch">buscar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>
<script>
import BaseAutoComp from "../components/BaseAutoComplet.vue";
import { paginationMethods } from "../mixins/pagination";

export default {
  components:{
    BaseAutoComp
  },
  mixins: [paginationMethods],
	props:{
		customWidth: {
      type: Number,
      required: false,
    },
    nameItemStore: {
      type: String,
      required: false,
    },
    activateGuiaTransportista: {
      type: Boolean,
      require: false,
      default: true
    }
	},
	data() {
		return {
			isLoading: false,
			dialogBase: false,
      procesoCompleto: false,
      searchForm: {},
      fecha1: null,
      fecha2: null,
      switch1: false,
      transportistas: [],
      keyAutoTranportista: 0
		}
	},
  watch:{
    switch1(val){
      if (val){
        this.searchForm.fecha2 = null
      }
    }
  },
  computed:{
    checkDialog(){
      return this.$store.state[this.nameItemStore]
    }
  },
  methods:{
    openDialog(){
      this.$store.state[this.nameItemStore] = true
    },
    closeDialogbu(){
      this.$store.state[this.nameItemStore] = false
    },
    reset(){
      console.log('reset')
      this.searchForm = {}
      this.keyAutoTranportista ++
      this.$emit('reset-refresh')
    },
    selected(item){
      console.log(item)
      this.searchForm.transportista = null
      this.searchForm.fecha1 = null
      this.searchForm.fecha2 = null
      this.switch1 = false
      this.searchForm.id = item.itemSelected?.id ?? null
      this.searchForm.numero_guia =  item.itemSelected?.numero ?? null
      if (item !== null && item !== undefined){
      }
      console.log(this.searchForm)
      // this.procesoCompleto = true
      // this.infoForm.numero = item.itemSelected ? item.itemSelected.numero : null
    },
    getItemTextTransportista(item) {
      if (item){
        return `${item.numero_identificacion} - ${item.nombre}`.toUpperCase()
      } else{
        return ``
      }
    },
    advanceSearch(){
      console.log(this.searchForm, Object.values(this.searchForm))
      
      if (Object.values(this.searchForm).length) {
        const body = this.filterValidProperties(this.searchForm)
        body.rango = !this.switch1
        if (body) {
          this.$emit('advance-search', body)
        } else {
          alert('no hay campos validos')
        }
      } else {
        this.$emit('advance-search', {})
        alert('formulario vacio')
      }
    },
    filterValidProperties(obj) {

      const validProperties = {}

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          if (
            value !== null &&
            value !== undefined &&
            value !== '' &&
            value !== 0 &&
            (typeof value !== 'object' || Object.keys(value).length > 0)
          ) {
            validProperties[key] = value;
          }
        }
      }

      return validProperties
    },
  },
  created(){
    this.getDataGeneral(
      "GET",
      `transjuma/TransportistaList`,
      'transportistas',
      "",
      {},
      false, //paginacion
      false, // opcion para que devuelva algo
      true //modo normal activado, vienen todos los resultados
    );
  }
}
</script>