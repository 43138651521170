<template>
  <div class="model">
    <DataTable model="permissions"/>
  </div>
</template>

<script>
// @ is an alias to /src
import DataTable from '@/components/DataTable.vue'

export default {
  components: {
    DataTable
  }
}
</script>