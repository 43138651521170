import Vue from 'vue'
import Vuex from 'vuex'
import VueJwtDecode from 'vue-jwt-decode'
import Router from '../router/index'
import { axiosC } from '../api/axiosbd'
import AuthModule from './modules/authModule'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        ActivateSpecials: false,
        selectedRow: {},
        permissions: [],
        currentOptions: {},
        accessToken: localStorage.getItem('token') || '',
        refreshToken: localStorage.getItem('refreshToken') || '',
        tokenDif: localStorage.getItem('tokenDif') || '',
        transportistas: [],
        tipoAjustes: [],

        menuOption: null,
        cabeceraProgramacion: null,
        searchGuias: false,
        searchAjustes: false,
        searchDeducciones: false,
        searchFacturas: false,
        searchOrdenes: false,
        searchProformas: false,
        searchAsignaciones: false,

        //configuracion
        addSedes: false,
        itemsSedes: [],

        addTipoFacturar: false,
        itemsTipoFacturar: [],

        addTipoIdentificacion: false,
        itemsTipoIdentificacion: [],

        addTipoDeduccion: false,
        itemsTipoDeduccion: [],

        addEstadoGuia: false,
        itemsEstadoGuia: [],

        addTipoAjuste: false,
        itemsTipoAjuste: [],

        addEstadoAsignacion: false,
        itemsEstadoAsignacion: [],

        addBanco: false,
        itemsBanco: [],

        addEstadoPago: false,
        itemsEstadoPago: [],

        addMetodoPago: false,
        itemsMetodoPago: [],

        allOptions: {
            "home": {
                "type": "single",
                "title": "Home",
                "path": "home",
                "icon": "dashboard",
                "componentName": "Home"
            },
            "operaciones": {
                "type": "group",
                "title": "Operaciones",
                "icon": "local_shipping",
                "options": [
                    {
                        "title": "Destinos y toneladas",
                        "path": "destinos_toneladas",
                        "componentName": "Destinos_toneladas"
                    },
                    {
                        "title": "Historico Destinos",
                        "path": "DestinosToneladasHistorico",
                        "componentName": "DestinosToneladasHistorico"
                    },
                    {
                        "title": "Migración de Guías",
                        "path": "MigracionGuias",
                        "componentName": "MigracionGuias"
                    },
                    {
                        "title": "Guias",
                        "path": "guias",
                        "componentName": "Guias"
                    },
                    {
                        "title": "Histórico de guias",
                        "path": "historico_guias",
                        "componentName": "Historico_guias"
                    },
                    {
                        "title": "Ajustes",
                        "path": "ajuste_detalles",
                        "componentName": "Ajustes"
                    },
                    {
                        "title": "Migracion Ajustes",
                        "path": "MigracionAjustes",
                        "componentName": "MigracionAjustes"
                    },
                    // {
                    //     "title": "Historico de Ajustes",
                    //     "path": "Historico_ajustes",
                    //     "componentName": "Historico_ajustes"
                    // },
                    {
                        "title": "Reporte G.A.D.",
                        "path": "Reporte_guias",
                        "componentName": "Reporte_guias"
                    },
                    {
                        "title": "Info Guia",
                        "path": "InfoGuias",
                        "componentName": "InfoGuias"
                    }
                ]
            },
            "proveedores": {
                "type": "group",
                "title": "Proveedores",
                "icon": "groups",
                "options": [{
                    "title": "Transportistas",
                    "path": "transportistas",
                    "componentName": "Transportistas"
                },
                {
                    "title": "Deducciones",
                    "path": "deducciones",
                    "componentName": "Deducciones"
                },
                {
                    "title": "Migracion Deducciones",
                    "path": "MigracionDeducciones",
                    "componentName": "MigracionDeducciones"
                }
                ]
            },
            "revisiones": {
                "type": "group",
                "title": "Revisiones",
                "icon": "receipt",
                "options": [
                    {
                        "title": "Asignacion",
                        "path": "Asignacion",
                        "componentName": "Asignacion"
                    },
                    {
                        "title": "Asignacion x dia",
                        "path": "AsignacionGlobal",
                        "componentName": "AsignacionGlobal"
                    },
                    {
                        "title": "Asignaciones",
                        "path": "asignacion_detalles",
                        "componentName": "Asignaciones"
                    }
                ]
            },
            "liquidaciones": {
                "type": "group",
                "title": "Liquidaciones",
                "icon": "request_quote",
                "options": [
                    {
                        "title": "Proforma",
                        "path": "Proforma",
                        "componentName": "Proforma"
                    },
                    {
                        "title": "Proformas",
                        "path": "proforma_detalles",
                        "componentName": "Proformas"
                    },
                    {
                        "title": "Migracion OC",
                        "path": "OrdenesCompra",
                        "componentName": "OrdenesCompra"
                    },
                    {
                        "title": "Listado OC",
                        "path": "OrdenesCompraList",
                        "componentName": "OrdenesCompraList"
                    },
                    {
                        "title": "Migracion Fact",
                        "path": "Factura",
                        "componentName": "Factura"
                    },
                    {
                        "title": "Listado Fact",
                        "path": "FacturaList",
                        "componentName": "FacturaList"
                    }
                ]
            },
            "gestion": {
                "type": "group",
                "title": "Gestión Cuentas",
                "icon": "mdi-hand-coin",
                "options": [
                    {
                        "title": "Por Pagar",
                        "path": "PorPagar",
                        "componentName": "PorPagar"
                    },
                    {
                        "title": "Por Cobrar",
                        "path": "PorCobrar",
                        "componentName": "PorCobrar"
                    },
                ]
            },
            "pago_proveedores": {
                "type": "group",
                "title": "Pagos",
                "icon": "payments",
                "options": [
                    {
                        "title": "Programar Pagos",
                        "path": "ProgramacionPagos",
                        "componentName": "ProgramacionPagos"
                    },
                    {
                        "title": "Programaciónes",
                        "path": "prov_programaciones",
                        "componentName": "ProvProgramaciones"
                    },
                ]
            },
            "configuracion": {
                "type": "group",
                "title": "Configuracion",
                "icon": "settings",
                "options": [
                    {
                        "title": "Predeterminadas",
                        "path": "Configuracion",
                        "componentName": "Configuracion"
                    },
                // {
                //     "title": "Sedes",
                //     "path": "sedes",
                //     "componentName": "Sedes"
                // },
                // {
                //     "title": "Tipos facturar",
                //     "path": "tipo_facturar",
                //     "componentName": "Tipo_facturar"
                // },
                // {
                //     "title": "Tipos de identificación",
                //     "path": "tipo_identificaciones",
                //     "componentName": "Tipo_identificaciones"
                // },
                // {
                //     "title": "Tipos de deducción",
                //     "path": "tipo_deducciones",
                //     "componentName": "Tipo_deducciones"
                // },
                // {
                //     "title": "Estados de guías",
                //     "path": "estado_guias",
                //     "componentName": "Estado_guias"
                // },
                // {
                //     "title": "Tipos de ajuste",
                //     "path": "tipo_ajustes",
                //     "componentName": "Tipo_ajustes"
                // },
                // {
                //     "title": "Estados de asignación",
                //     "path": "estado_asignaciones",
                //     "componentName": "Esatdo_asignaciones"
                // },
                // {
                //     "title": "Bancos",
                //     "path": "banco",
                //     "componentName": "Banco"
                // },
                // {
                //     "title": "Metodos de pago",
                //     "path": "metodo_pagos",
                //     "componentName": "Metodo_pagos"
                // },
                // {
                //     "title": "Estados de pago",
                //     "path": "estado_pagos",
                //     "componentName": "Esatdo_pagos"
                // }
                ]
            },
            "user": {
                "type": "group",
                "title": "User",
                "componentName": "User",
                "icon": "manage_accounts",
                "options": [{
                    "title": "User",
                    "path": "user",
                    "componentName": "User"
                },
                {
                    "title": "Permissions",
                    "path": "permissions",
                    "componentName": "Permissions"
                }
                ]
            }
        },
    },
    mutations: {
        MUT_ITENMS_WL(state, data){
        state[data.nombre] = data.data
        },
        setCurrentOptions(state, options) {
            state.currentOptions = options
        },
        setMenuOption(state, title) {
            state.menuOption = title
        },
        setDataRequest(state, obj) {
            state[obj.model].schema = obj.data.schema
            if (obj.urlMethod != 'schema') state[obj.model].data = obj.data.data
        },
        mutCabeceraProgramacion(state, data) {
            state.cabeceraProgramacion = data
        }
    },
    actions: {
        cabeceraProgramacion({ commit }, dataCabecera) {
            commit('mutCabeceraProgramacion', dataCabecera)
        },
        fechData(_, obj) {
            return new Promise((resolve, reject) => {
                axiosC({
                    method: obj.method,
                    url: obj.url,
                    data: obj.body ? obj.body : {},
                    headers: {
                        Authorization: `Bearer ${obj.token}`,
                    },
                    params: obj.params ? obj.params : null
                })
                    .then((res) => {
                        resolve(res.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        actItemsBaseWindowsList({commit}, data){
            console.log(data)
            commit('MUT_ITENMS_WL', data)
        },

    },
    modules: {
        AuthModule
      }
})