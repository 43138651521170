<template>
    <div>
      <v-data-table
        dense
        disable-sort
        :headers="headers"
        :items="records"
        :search="search"
        class="elevation-1 rounded-xl px-2"
        :loading="loading"
        :items-per-page="20"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <div class="d-flex flex-column">
              <v-toolbar-title>{{ `Estado de Cuentas por Pagar` }}</v-toolbar-title>
              <div class="d-flex text-caption flex-column mb-5">
                <p class="ma-0 pa-0">Antiguedad Transportistas</p>
                <!-- <p class="ma-0 pa-0">DT:Destino Tonelada</p> -->
              </div>
            </div>

            <v-spacer></v-spacer>
            <!-- <v-btn small rounded color="info" @click="openSearch">Filtrado</v-btn>
            <v-btn small rounded color="success" v-if="activeAdvanceSearch" @click="infoExcel" >excel</v-btn>
            <v-btn small rounded color="error"  v-if="activeAdvanceSearch" @click="resetInfo">reset</v-btn> -->
          </v-toolbar>
        </template>

        <template v-slot:item.total="{item}">
          <td class="text-caption blue-grey--text text--darken-1 text-end px-2 mr-1" v-if="(item['30'] + item['60'] + item['90'] + item['120'] + item['150'])>= 0">
            {{ $convertToMoney(item['30'] + item['60'] + item['90'] + item['120'] + item['150']) }}
          </td>
          <td class="text-caption red--text text--darken-1 text-end px-2 mr-1" v-else>
            {{ $convertToMoney(item['30'] + item['60'] + item['90'] + item['120'] + item['150']) }}
          </td>
        </template>

        <template v-slot:item.30="{item}">
          <td class="text-caption blue-grey--text text--darken-1 text-end px-2 mr-1" v-if="item['30'] >= 0">
            {{ $convertToMoney(item['30']) }}
          </td>
          <td class="text-caption red--text text--darken-1 text-end px-2 mr-1" v-else>
            {{ $convertToMoney(item['30']) }}
          </td>
        </template>

        <template v-slot:item.60="{item}">
          <td class="text-caption blue-grey--text text--darken-1 text-end px-2 mr-1" v-if="item['60'] >= 0">
            {{ $convertToMoney(item['60']) }}
          </td>
          <td class="text-caption red--text text--darken-1 text-end px-2 mr-1" v-else>
            {{ $convertToMoney(item['60']) }}
          </td>
        </template>

        <template v-slot:item.90="{item}">
          <td class="text-caption blue-grey--text text--darken-1 text-end px-2 mr-1" v-if="item['90'] >= 0">
            {{ $convertToMoney(item['90']) }}
          </td>
          <td class="text-caption red--text text--darken-1 text-end px-2 mr-1" v-else>
            {{ $convertToMoney(item['90']) }}
          </td>
        </template>

        <template v-slot:item.120="{item}">
          <td class="text-caption blue-grey--text text--darken-1 text-end px-2 mr-1" v-if="item['120'] >= 0">
            {{ $convertToMoney(item['120']) }}
          </td>
          <td class="text-caption red--text text--darken-1 text-end px-2 mr-1" v-else>
            {{ $convertToMoney(item['120']) }}
          </td>
        </template>

        <template v-slot:item.150="{item}">
          <td class="text-caption blue-grey--text text--darken-1 text-end px-2 mr-1" v-if="item['150'] >= 0">
            {{ $convertToMoney(item['150']) }}
          </td>
          <td class="text-caption red--text text--darken-1 text-end px-2 mr-1" v-else>
            {{ $convertToMoney(item['150']) }}
          </td>
        </template>

        <template v-slot:item.actions="{item}">
          <v-btn
            small
            icon
            @click="openEstadoCuenta(item)"
          >
            <v-icon color="blue" small>
              mdi-eye
            </v-icon>
          </v-btn>

        </template>
      </v-data-table>



      <!-- ESTADO DE CUENTA -->
      <WindowCard
        v-if="EstadoCuenta"
        customWidth="900"
        :isLoading="loading"
      >
        <template v-slot:title>
          <v-card-title class="d-flex justify-space-between pb-0">
            {{`Detalle Estado de Cuenta`}}
            <v-btn
              icon
              @click="closeEstadoCuenta"
            >
              <v-icon color="red" >
                mdi-close-circle
              </v-icon>
            </v-btn>
          </v-card-title>
        </template>

        <template v-slot:subTitle>
          <v-card-subtitle class="pb-2">
            <v-row dense align="center">
              <v-col cols="12" md="5">
                {{ transportistaName }}
              </v-col>
              <v-col cols=12 md="3" class="d-flex justify-space-around">
                <!-- boton conciliacion guias -->
                <v-tooltip left color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="openConciliacion('Guias')"
                    >
                      <v-icon color="indigo" >
                        mdi-truck-alert
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Conciliar Guias</span>
                </v-tooltip>

                <!-- boton conciliacion ajustes-->
                <v-tooltip top color="teal">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="openConciliacion('Ajustes')"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="teal" >
                        mdi-badge-account-alert
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Conciliar Ajustes</span>
                </v-tooltip>

                <!-- boton ajustes deducciones -->
                <v-tooltip right color="deep-orange">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="openConciliacion('Deducciones')"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="deep-orange" >
                        mdi-invoice-edit
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Conciliar Deducciones</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-divider class="mt-2"></v-divider>
            <v-row dense>
              <v-col cols="12">
                <div>
                  <span><strong>Guias: </strong></span>
                  <span class="mr-3">{{ $convertToMoney(totalesEstado.guias)}}</span>
                  <span><strong>Ajustes: </strong></span>
                  <span class="mr-3">{{ $convertToMoney(totalesEstado.ajustes)}}</span>
                  <span><strong>Deducciones: </strong></span>
                  <span class="mr-3">{{ $convertToMoney(totalesEstado.deducciones)}}</span>
                  <span><strong>Neto x Pagar: </strong></span>
                  <span class="mr-3">{{ $convertToMoney(totalesEstado.neto)}}</span>
                </div>

              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card-subtitle>
        </template>

        <template v-slot:cardText>
          <v-card-text class="pb-1">
            <!-- GUIAS -->
            <v-row dense class="py-1">
              <v-col cols="12">
                <h5>Listado de Documentos</h5>
                <v-divider></v-divider>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th v-for="head in headersEstado"
                          key:head.text
                          :class="head.class"
                        >
                          {{ head.text }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in desserts.guia"
                        :key="`guia-${item.id}`"
                        class="indigo--text font-weight-bold"
                      >
                        <td class="text-left px-2">{{ 'Guia' }}</td>
                        <td class="text-left px-2">{{ item.fecha }}</td>
                        <td class="text-left px-2">{{ item.estado_guia }}</td>
                        <td class="text-left px-2">{{ item.numero }}</td>
                        <td class="text-left px-2">{{ `${item.tn_numero} - ${item.descripcionDestino}` }}</td>
                        <td class="text-right px-2">{{ $convertToMoney(item.precio) }}</td>
                        <td class="text-right px-2">{{ $convertToMoney(item.descuento) }}</td>
                        <td class="text-right px-2">{{ $convertToMoney(item.iva) }}</td>
                        <td class="text-right px-2">{{ $convertToMoney(item.precio + item.descuento + item.iva) }}</td>
                      </tr>

                      <tr
                        v-for="item in desserts.ajuste"
                        :key="`ajuste-${item.id}`"
                        class="teal--text font-weight-bold"
                      >
                        <td class="text-left px-2">{{ 'Ajuste' }}</td>
                        <td class="text-left px-2">{{ item.fecha }}</td>
                        <td class="text-left px-2">{{ item.estado }}</td>
                        <td class="text-left px-2">{{ item.numero_guia }}</td>
                        <td class="text-left px-2">{{ item.descripcion_detallada }}</td>
                        <td class="text-right px-2">{{ $convertToMoney(item.valor_adicional) }}</td>
                        <td class="text-right px-2">{{ $convertToMoney(item.descuento) }}</td>
                        <td class="text-right px-2">{{ $convertToMoney(item.iva) }}</td>
                        <td class="text-right px-2">{{ $convertToMoney(item.valor_adicional + item.descuento + item.iva) }}</td>
                      </tr>

                      <tr
                        v-for="item in desserts.deduccion"
                        :key="`deduccion-${item.id}`"
                        class="deep-orange--text font-weight-bold"
                      >
                        <td class="text-left px-2">{{ 'Deduccion' }}</td>
                        <td class="text-left px-2">{{ item.fecha.substring(0,10) }}</td>
                        <td class="text-left px-2">{{ item.estado_deduccion }}</td>
                        <td class="text-left px-2">{{ item.numero }}</td>
                        <td class="text-left px-2">{{ item.descripcion_detallada }}</td>
                        <td class="text-right px-2">{{ $convertToMoney(item.monto) }}</td>
                        <td class="text-right px-2">{{ $convertToMoney(0) }}</td>
                        <td class="text-right px-2">{{ $convertToMoney(item.iva) }}</td>
                        <td class="text-right px-2">{{ $convertToMoney(item.monto + item.iva) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <!-- AJUSTES -->
            <!-- <v-row dense clas="py-1">
              <v-col cols="12">
                <h5>Listado de Ajustes</h5>
                <v-divider></v-divider>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th v-for="head in headersEstado"
                          key:head.text
                          :class="head.class"
                        >
                          {{ head.text }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in desserts.ajuste"
                        :key="item.id"
                      >
                        <td>{{ item.fecha }}</td>
                        <td>{{ item.fecha }}</td>
                        <td>{{ item.estado }}</td>
                        <td>{{ item.numero_guia }}</td>
                        <td>{{ item.descripcion_detallada }}</td>
                        <td>{{ item.valor_adicional }}</td>
                        <td>{{ item.descuento }}</td>
                        <td>{{ item.iva }}</td>
                        <td>{{ item.valor_adicional + item.descuento + item.iva }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-divider></v-divider>
              </v-col>
            </v-row> -->

            <!-- DEDUCCIONES -->
            <!-- <v-row dense>
              <v-col cols="12">
                <h5>Listado de Deducciones</h5>
                <v-divider></v-divider>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th v-for="head in headersEstado"
                          key:head.text
                          :class="head.class"
                        >
                          {{ head.text }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in desserts.deduccion"
                        :key="item.id"
                      >
                        <td>{{ item.fecha.substring(0,10) }}</td>
                        <td>{{ item.estado_deduccion }}</td>
                        <td>{{ item.numero }}</td>
                        <td>{{ item.descripcion_detallada }}</td>
                        <td>{{ item.monto }}</td>
                        <td>{{ 0 }}</td>
                        <td>{{ item.iva }}</td>
                        <td>{{ item.monto + item.iva }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              <v-divider></v-divider>
              </v-col>
            </v-row> -->
          </v-card-text>
        </template>

      </WindowCard>

      <!-- CUADRO DE CONCILIACIONES (GUIAS, AJUSTES, DEDUCCIONES) -->
      <WindowCard
        v-if="dialogConciliacion"
        customWidth="800"
        :isLoading="loading"
      >
        <template v-slot:title>
          <v-card-title class="d-flex justify-space-between pb-0">
            {{`Conciliacion de ${titlleConciliacion}`}}
            <div>
              <v-btn
                color="primary"
                @click="showConciliacion"
                x-small
                rounded
              >
                Conciliar
              </v-btn>

              <v-btn
                color="warning"
                @click="showBorrado"
                x-small
                rounded
              >
                Borrar
              </v-btn>
            </div>

            <v-btn
              icon
              @click="closeConciliacion"
            >
              <v-icon color="red" >
                mdi-close-circle
              </v-icon>
            </v-btn>
          </v-card-title>
          
        </template>

        <template v-slot:subTitle>
          <v-card-subtitle class="pb-2 pt-4">
            <div>Conciliar y borrar aplican para documentos cuyo estado es inferior a Factura.</div>
            <div>Si desea eliminar algo facturado debe ir al modulo de factura y hacer el degrado del documento.</div>
          </v-card-subtitle>
        </template>

        <template v-slot:cardText>
          <v-card-text class="pb-1">
            <v-data-table
              dense
              v-model="dataTableConciliacion"
              :headers="headersConciliacion"
              :items="recordsConciliacion"
              :items-per-page="10"
              show-select
              class="custom=datatable"
              disable-sort
            ></v-data-table>
          </v-card-text>
        </template>

      </WindowCard>
    </div>
  </template>

  <script>
  import { paginationMethods } from "../mixins/pagination";
  import BusquedaAvanzada from "../components/BusquedaAvanzada.vue"
  import WindowCard from "@/components/WindowCard.vue"
  import { axiosC } from "../api/axiosbd";

  export default {
    components: {BusquedaAvanzada, WindowCard},
    mixins:[paginationMethods],
    data() {
      return {
        EstadoCuenta: false,
        search: "",
        headers: [
          { text: 'ID - Codigo', value: 'transportista_id'},
          { text: 'Transportista', value: 'nombre'},
          { text: 'Total', value: 'total', align: 'center', class: 'px-2'},
          { text: 'a 30 dias', value: '30', align: 'end', class: 'px-2'},
          { text: 'a 60 dias', value: '60', align: 'end', class: 'px-2'},
          { text: 'a 90 dias', value: '90', align: 'end', class: 'px-2'},
          { text: 'a 120 dias', value: '120', align: 'end', class: 'px-2'},
          { text: 'a 150 dias', value: '150', align: 'end', class: 'px-2'},
          { text: 'Acciones', value: 'actions'},
        ],
        records: [],
        loading: false,
        nameItemStore: 'records',
        activeAdvanceSearch: false,
        queryAdvanceSearch: {},
        headersEstado:[
          { text: 'Tipo', class: 'text-left px-2'},
          { text: 'Fecha', class: 'text-left px-2'},
          { text: 'Estado', class: 'text-left px-2'},
          { text: 'No. Guia', class: 'text-left px-2'},
          { text: 'Detalle', class: 'text-left px-2'},
          { text: 'Monto', class: 'text-right px-2'},
          { text: 'Descuento', class: 'text-right px-2'},
          { text: 'Iva', class: 'text-right px-2'},
          { text: 'Neto', class: 'text-right px-2'},
        ],
        desserts: [],
        transportistaName: '',
        totalesEstado: {},
        dialogConciliacion: false,
        dataTableConciliacion:[],
        titlleConciliacion: '',
        headersConciliacion:[
          { text: 'Fecha', class: 'text-left px-2', value: 'fecha'},
          { text: 'Estado', class: 'text-left px-2', value: 'estado'},
          { text: 'No. Guia', class: 'text-left px-2', value: 'numero_guia'},
          { text: 'Detalle', class: 'text-left px-2', value: 'detalle'},
          { text: 'Neto', class: 'text-right px-2', value: 'neto'},
        ],
        recordsConciliacion: []
      }
    },
    methods: {
      //conciliacion
      openConciliacion(modo){
        this.dialogConciliacion = true
        this.titlleConciliacion= modo
        if (modo == 'Guias'){
          this.recordsConciliacion = this.desserts.guia.filter(fil=> fil.id_estado < 5).map((item)=>{
            let obj = {
              id: item.id,
              fecha: item.fecha,
              estado: item.estado_guia,
              numero_guia: item.numero,
              detalle: `${item.tn_numero} - ${item.descripcionDestino}`,
              neto: item.precio + item.descuento + item.iva,
              item: item
            }
            return obj
          })
        }

        if (modo == 'Ajustes'){
          this.recordsConciliacion = this.desserts.ajuste.filter(fil=> fil.id_estado < 5).map((item)=>{
            let obj = {
              id: item.id,
              fecha: item.fecha,
              estado: item.estado,
              numero_guia: item.numero_guia,
              detalle: item.tipo_ajuste,
              neto: item.valor_adicional + item.descuento + item.iva,
              item: item
            }
            return obj
          })
        }

        if (modo == 'Deducciones'){
          this.recordsConciliacion = this.desserts.deduccion.filter(fil=> fil.estado_deduccion == 'Flete').map((item)=>{
            let obj = {
              id: item.id,
              fecha: item.fecha ? item.fecha.substring(0,10) : '',
              estado: item.estado_deduccion,
              numero_guia: item.numero,
              detalle: item.descripcion_detallada,
              neto: item.monto + item.iva,
              item: item
            }
            return obj
          })
        }
        // console.log(this.recordsConciliacion)
      },
      closeConciliacion(){
        this.dialogConciliacion = false
      },
      showConciliacion(){
        this.$confirm('Conciliar es pasar los valores como si se hubiesen pagado, pero aparecera en la informacion del documento la palabra conciliado. Continuar?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
          confirmButtonClass: 'v-btn v-btn--has-bg  v-size--small primary'
        }).then(() => {
          this.loading = true
          this.sendConciliacion(this.dataTableConciliacion)
          this.$message({
            type: 'success',
            message: 'Conciliacion completa'
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Conciliacion cancela'
          })
        });
      },

      showBorrado(){
        this.$confirm('Borrar, eliminara el documento del sistema y de todas las partes donde este es utilizado. Esta seguro que desea continuar?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
          confirmButtonClass: 'v-btn v-btn--has-bg  v-size--small primary'
        }).then(() => {
          this.loading = true
          console.log(this.dataTableConciliacion)
          this.sendBorrado(this.dataTableConciliacion)

        }).catch(() => {
          this.loading = false
          this.$message({
            type: 'info',
            message: 'Borrado cancelo'
          })
        });
      },

      async sendBorrado(item){
        await this.$store.dispatch("AuthModule/verifyToken");
        const body = {
          borrar: true,
          datos: item,
          company: 1
        }

        let url = ''
        if (this.titlleConciliacion == 'Guias'){
          url = `transjuma/PagoProveedoresDetalle/conciliacion_guia/`
        }

        if (this.titlleConciliacion == 'Ajustes'){
          url = `transjuma/PagoProveedoresDetalle/conciliacion_ajuste/`
        }

        if (this.titlleConciliacion == 'Deducciones'){
          url = `transjuma/PagoProveedoresDetalle/conciliacion_deduccion/`
        }

        axiosC({
          method: 'PUT',
          url: url,
          data: body,
          headers: {
            Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
          },
        })
          .then(async () => {
            this.dataTableConciliacion = []
            await this.getDataGuias()
            await this.openEstadoCuenta(this.selectedTransportista)
            this.openConciliacion(this.titlleConciliacion)
            this.$message({
              type: 'success',
              message: 'Borrado completo'
            })
          })
          .catch((err) =>  {
            this.loading = false
            this.$message({
              type: 'success',
              message: err.response.data.message ?? 'Error al intentar completar el proceso'
            })

          })
      },

      async sendConciliacion(item){
        console.log(item)
        let newBody = []

        let url = ''
        if (this.titlleConciliacion == 'Guias'){
          url = `transjuma/PagoProveedoresDetalle/conciliacion_guia/`
          for (let reg of item){
            let newT = {...reg.item}
            newBody.push(newT)
          }
        }

        if (this.titlleConciliacion == 'Ajustes'){
          url = `transjuma/PagoProveedoresDetalle/conciliacion_ajuste/`
          for (let reg of item){
            let newT = {...reg.item}
            newBody.push(newT)
          }
        }

        if (this.titlleConciliacion == 'Deducciones'){
          url = `transjuma/PagoProveedoresDetalle/conciliacion_deduccion/`
          for (let reg of item){
            let newT = {...reg.item}
            newT.fecha_programacion = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0,10)
            newT.estado_deduccion = 'Conciliado'
            newBody.push(newT)
          }
        }

        await this.$store.dispatch("AuthModule/verifyToken");
        const body = {
          borrar: false,
          datos: newBody,
          company: 1,
          transportista_nombre: this.selectedTransportista.nombre
        }

        axiosC({
          method: 'PUT',
          url: url,
          data: body,
          headers: {
            Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
          },
        })
          .then(async () => {
            this.dataTableConciliacion = []
            await this.getDataGuias()
            await this.openEstadoCuenta(this.selectedTransportista)
            this.openConciliacion(this.titlleConciliacion)
            this.$message({
              type: 'success',
              message: 'Conciliacion Completa'
            })
          })
          .catch((err) =>  {
            this.loading = false
            this.$message({
              type: 'success',
              message: err.response.data.message ?? 'Error al intentar completar el proceso'
            })

          })
      },
      //==========
      openSearch(){
        this.$store.state.searchGuias= true
      },
      async openEstadoCuenta(transportista_item){
        console.log(transportista_item)
        this.desserts = []
        this.transportistaName = ''
        this.totalesEstado= {}
        this.EstadoCuenta = true

        this.transportistaName = transportista_item.nombre
        this.selectedTransportista = transportista_item
        const AxiosParamas = {
          transportista: transportista_item.transportista_id
        }
        const data = await this.getDataGeneral(
          "GET",
          `transjuma/saldo_prov_indiv/`,
          '',
          "loading",
          {},
          false, //paginacion
          true, // opcion para que devuelva algo
          true, // modo normal
          null, //parametros query
          true, // si devuelve uno solo
          AxiosParamas // parametros para axios
        )
        this.desserts = data.data
        this.totalesEstado = {
          guias: data.totales_guia,
          ajustes: data.totales_ajuste,
          deducciones: data.totales_deduccion,
          neto: data.total_general,
        }
        console.log('DATA TRANSP', data.data)
  
      },
      closeEstadoCuenta(){
        this.EstadoCuenta = false
      },
      infoExcel(item){
        console.log(item)
      },
      resetInfo(){
        this.getDataGuias()
      },
      siguientePagina(item) {
        let query = {}
        if (this.activeAdvanceSearch){
          query = {...this.queryAdvanceSearch}
        } else {
          query =  {
            list: true
          }
        }
        this.gotoPage(item, `transjuma/Guia/`, query);
      },
      async getDataGuias(){
        this.currentPage = 1
        this.activeAdvanceSearch = false
        this.queryAdvanceSearch = {}
        let query ={}
        query.list = true
  
        this.getDataGeneral(
          "GET",
          `transjuma/saldo_prov/`,
          'records',
          "loading",
          {},
          false, //paginacion
          false, // opcion para que devuelva algo
          true, //modo normal activado, vienen todos los resultados
          null, // parametros query
          false, //unico registro
          query
        )

        
      },
    },
    computed: {
    },
    created() {
      this.getDataGuias()
  
    }
  }
  </script>

  <style>
  .v-simple-checkbox  > :parent{
    color: yellowgreen !important;
  }
  </style>