import axios from 'axios'

const mainUrl = 'https://jb.webdgroup.com/'
// const mainUrl = 'http://127.0.0.1:9000/'

const axiosC = axios.create({
  baseURL: `${mainUrl}`,
  timeout: 240000,
})


export {
    axiosC,
    mainUrl
  }