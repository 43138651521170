<template>
  <base-window-list
    :headers="headers"
    title="Listado de Estado de Guia"
    :company="comdes"
    addMethod="itemsEstadoGuia"
    closeBaseWindow='addEstadoGuia'
    titleAddMethod="Agregar Estado de Guia"
    itemStoreName="itemsEstadoGuia"
    :loading="loadingResult"
    :activarEdicion="true"
    :activarBorrar="true"
    :activarInternalForm="true"
    :formProperties="{
      creacion: 'Creacion de Estado de Guia',
      edicion: 'Editar Estado de Guia',
      url: `/transjuma/EstadoGuia/`
    }"
    v-on:selected-row="handleEvent"
    v-on:updated="updatedList"
  ></base-window-list>
</template>

<script>
import { mapState } from 'vuex'
import BaseWindowList from '@/components/BaseWindowListGeneral'
import { paginationMethods } from '@/mixins/pagination'
export default {
  components:{
    BaseWindowList
  },
  mixins: [paginationMethods],
  data() {
    return {
      headers: [
        {
          text: 'ID',
          align: 'center',
          value: 'id',
          colmd: 4,
          form: false,
          require: true,
          vtext: true,
        },
        {
          text: 'Nombre',
          align: 'center',
          value: 'nombre',
          colmd: 12,
          form: true,
          require: true,
          vtext: true,
        },
        {
          text: 'Codigo',
          align: 'center',
          value: 'codigo',
          colmd: 12,
          form: true,
          require: true,
          vtext: true,
        },
        {
          text: 'Acciones',
          align: 'center',
          value: 'actions',
          require: true,
        },
      ],
      loadingResult: true
    }
  },
  computed:{
    ...mapState([
      'addEstadoGuia',
    ]),
    company(){
      return this.$store.state.AuthModule.refu
    },
    comdes(){
      return 'Transjuma'
    }
  },
  methods: {
    handleEvent(item) {
      this.$emit('EstadoGuia-selected', item)
    },
    updatedList(){
      this.getData()
    },
    async getData(){
      await this.$store.dispatch('AuthModule/verifyToken')
      console.log('BUSCANDO LOS DATOS')
      const obj = {
        token : this.$store.state.AuthModule.accessToken,
        method: 'GET',
        url: 'transjuma/EstadoGuia/',
        // body: body
        params:{
          ids: 1
        }
      }
      // this.loadingTable = true
      this.$store.dispatch('fechData', obj)
        .then((result) => {
          this.$store.dispatch('actItemsBaseWindowsList',{data: result, nombre: 'itemsEstadoGuia'})
          this.loadingResult = false
        })
        .catch((err) => {
          const regex = /["[{\]}]/g
          const mensaje = JSON.stringify(err.response.data).replaceAll(regex,'') ?? ''
          this.showAlert('error', mensaje)
          this.loadingResult = false
          if (err.response.status == 401){
            alert(
              `token no valido o vencido:  ${err.response.data.detail ?? ''}`
            )
            this.$router.push({name: 'LoginView'})
          }
        })
    }
  },
  created() {
    this.$store.state.itemsEstadoGuia = []
    this.getData()
  }
}
</script>

<style>

</style>