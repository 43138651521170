<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div>
        <v-row
            class="my-0 py-0"
            dense
            align="center"
            v-for="item in itemsSumatorias" :key="item.nombre"
        >
            <v-col cols="12" md="6"  class="my-0 py-0" v-if="!item.disable.includes(tipoModulo)">
                <v-subheader class="px-1 pl-4 ">{{item.nombre}}:</v-subheader>
            </v-col>
            <v-col cols="12" md="6"  class="my-0 py-0" v-if="!item.disable.includes(tipoModulo)">
                <TextFieldNumber
                    :value="totales[`${item.nombreForm}`]"
                    disabled
                    outlined
                    dense
                    :options="{
                        locale: 'en-US',
                        prefix: '',
                        suffix: '',
                        length: 12,
                        precision: 2
                    }"
                ></TextFieldNumber>

            </v-col>
        </v-row>
    </div>
</template>

<script>
import TextFieldNumber from '@/components/TextFieldNumber'
export default {
    props:{
        itemsSumatorias:{
            type: Array,
            require: true
        },
        tipoModulo:{
            type: String,
            required: true
        },
        totales:{
            type: Object,
            require: true
        },
    },
    components:{
        TextFieldNumber,
    }
}
</script>

<style>

</style>