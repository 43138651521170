<template>
  <div>
    <v-data-table
    :loading="isLoading"
    :headers="headers"
    :items="dataRows"
    :search="search"
    class="elevation-1 rounded-xl px-2"
    @click:row="openInformation"
    :items-per-page="15"
    :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-left-circle-outline',
        lastIcon: 'mdi-arrow-right-circle-outline',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        showCurrentPage: true
      }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ `Listado guias-ajustes-deducciones x Guia` }}</v-toolbar-title>
          

          <v-spacer></v-spacer>

          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
    </v-data-table>
    
    <div class="text-center">
      <v-dialog
        v-model="dialog"
        width="700"
      >
        

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Detalles de ajustes y deducciones
          </v-card-title>

          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Fecha
                    </th>
                    <th class="text-left">
                      Origen
                    </th>
                    <th class="text-left">
                      Transportista
                    </th>
                    <th class="text-left">
                      Detalle
                    </th>
                    <th class="text-left">
                      Monto
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="ajuste in dataDetail.ajustes"
                    :key="ajuste.id"
                  >
                    <td>{{ ajuste.fecha }}</td>
                    <td>{{ `Ajuste` }}</td>
                    <td>{{ ajuste.transportista }}</td>
                    <td>{{ ajuste.tipo_ajuste }}</td>
                    <td>{{ ajuste.valor_adicional ? convertToMoney(ajuste.valor_adicional): 0 }}</td>
                  </tr>
                   <tr
                    v-for="dedu in dataDetail.deducciones"
                    :key="dedu.id"
                  >
                    <td>{{ dedu.fecha.substr(0,10) }}</td>
                    <td>{{ `Deduccion` }}</td>
                    <td>{{ dedu.transportista_deduccion }}</td>
                    <td>{{ dedu.tipo_deduccion }}</td>
                    <td>{{ dedu.monto ? convertToMoney(dedu.monto*-1) : 0 }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
   
  </div>
</template>

<script>
import { axiosC } from "../api/axiosbd"

export default {
  data() {
    return {
      search: "",
      dialog: false,
      isLoading: false,
      headers: [
        { text: 'Fecha', value: 'fecha' },
        { text: 'Estado', value: 'estado' },
        { text: 'Guia', value: 'guia' },
        { text: 'Destino ID', value: 'destinoId' },
        { text: 'Tonelada', value: 'tonelada' },
        { text: 'Destino', value: 'destino' },
        { text: 'Transportista', value: 'transportista' },
        { text: 'Total Guia', value: 'total_guia', align: 'right' },
        { text: 'Total Ajustes', value: 'total_ajustes', align: 'right' },
        { text: 'Total Deducciones', value: 'total_deduciones', align: 'right' },
        // { text: 'Fecha proforma', value: 'fecha_proforma' },
      ],
      dataRows: [],
      dataResponse : [],
      dataDetail: [],
      totalAjustes: [],
      totalDeducciones: [],
      urlParams: '?updatedAt[order]=desc'
    }
  },
  methods: {
    async getData() {
      this.showTable = false

      
    },
    openInformation(row) {
      this.dialog = true
      this.dataDetail = this.dataResponse.filter((item)=> item.guia.id == row.id)[0] 
      
    },

    async buildDesserts() {
      this.isLoading = true
      await this.$store.dispatch('AuthModule/verifyToken')
      try {
        let dataReporte = await axiosC({
          method: "GET",
          url: `transjuma/reporteGAD`,
          headers: {
            Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
          },
        })
        if (dataReporte.status >= 400) throw new Error('Opps error en la peticion')
        
        console.log(dataReporte)

        this.dataResponse = dataReporte.data
        this.dataRows = []
        this.totalAjustes = []
        this.totalDeducciones = []
        
        for (let item of dataReporte.data) {
          let sumAjuste = 0
          if (item.ajustes) {
            for (let ajuste of item.ajustes){
              this.totalAjustes.push(ajuste.valor_adicional)
            }
            sumAjuste = this.totalAjustes.reduce((a, b) => a + b, 0)
          }

          let sumDeduccion = 0
          if (item.deducciones) {
            for (let deduccion of item.deducciones){
              this.totalDeducciones.push(deduccion.monto*-1)
            }
            sumDeduccion = this.totalDeducciones.reduce((a, b) => a + b, 0)
          }
          
          this.dataRows.push({
            id: item.guia.id,
            fecha: item.guia.fecha ? item.guia.fecha.substr(0, 10) : null,
            guia: item.guia.numero,
            destinoId: item.guia.destino_tonelada,
            tonelada: item.guia.destino_tonelada,
            destino: item.guia.destino,
            estado: item.guia.estado_guia,
            transportista: item.guia.transportista,
            total_guia: this.convertToMoney(item.guia.precio),
            total_ajustes: this.convertToMoney(sumAjuste),
            total_deduciones: this.convertToMoney(sumDeduccion),
          })
          this.totalAjustes = []
          this.totalDeducciones = []
        }
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        alert(error)
      }
    },
    convertToMoney(x) {
    return x
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
  },
  computed: {
  },
  created() {
    this.buildDesserts()
  }
}
</script>