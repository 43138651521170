<template>
<v-container>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="10">
            <v-card :loading="loading" class="elevation-6 mt-10">
                <v-row no-gutters>
                    <v-col md="6" sm="12">
                        <v-img class="align-center subheading" height="500" gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)" src="../assets/wave.svg">
                            <v-card-text class="mt-6">
                                <h3 class="text-center white--text">
                                    Bienvenidos a Transportes Juma-WebApp
                                </h3>

                                <h5 class="text-center white--text">
                                    Desarrollado y mantenimiento por Programar S.A. - Itech S.A.
                                </h5>
                            </v-card-text>
                        </v-img>
                    </v-col>
                    <v-col sm="12" md="6">
                        <v-card-text class="mt-8">
                            <h2 class="text-center">
                                <strong> Login - Acceso </strong>
                            </h2>

                            <v-card-text class="mt-16 px-12">
                                <v-form v-model="valid">
                                    <v-text-field :rules="nameRules" prepend-icon="mdi-account" name="login" label="Usuario o E-Mail" type="text" v-model="username" required :loading="loading"></v-text-field>
                                    <v-text-field :rules="nameRules" id="password" prepend-icon="mdi-lock" name="password" label="Password" type="password" v-model="password" required :loading="loading" @keyup.enter="login"></v-text-field>
                                </v-form>
                            </v-card-text>
                            <v-card-actions class="px-12">
                                <v-spacer></v-spacer>
                                <v-btn rounded large elevation="5" color="primary" @click="login" :loading="loading" :disabled="loading">Login</v-btn>
                            </v-card-actions>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import {
    axiosC
} from "../api/axiosbd";

export default {
    name: "Login",
    data() {
        return {
            username: "",
            password: "",
            valid: false,
            nameRules: [(v) => !!v || "Este campos no debe dejarse en blanco"],
            loading: false,
        };
    },
    props: {
        source: String,
    },
    methods: {
        async login() {
            const credentials = {
                username: this.username,
                password: this.password
            };
            this.loading = true;

            await this.$store.dispatch("AuthModule/loginUser", credentials)
                .then(() => {
                    //obtenemos los ref
                    this.$router.push({name: 'Home'})
                    this.$store.state.menuOption = false
                    .then(() => {
                    })

                })
                .catch((err) => {
                    console.log('aqui', err.message)
                    this.loading = false
                    let mensaje = []

                    if (err.response){
                    console.log(err.response)
                    if (err.response.data && err.response.data.detail){
                        mensaje.push(err.response.data.detail)
                    }
        
                    if (err.response.data && err.response.data.username){
                        mensaje.push(" Usuario: ",...err.response.data.username)
                    }
        
                    if (err.response.data && err.response.data.password){
                        mensaje.push(" Password: ",...err.response.data.password)
                    }
        
                    mensaje = mensaje.reduce((a,b) => a +" "+b)
                    // console.log(mensaje)
                    }

                    if (err.message == "Network Error") {
                    console.log('error')
                    this.showAlert('error', 'error de red, verificar su conexion a internet')
                    } else if (err == "Error: timeout of 1000ms exceeded") {
                    this.showAlert('error', 'Se excedio el tiempo de espera, intentar nuevamente')
                    } else {
                    if (mensaje.length){
                        // console.log(mensaje)
                        this.showAlert('error', mensaje)
                    } 
                    // else {
                    //   this.showAlert('error', err.message)
                    // }
                    }
                })

        },
    },
};
</script>

<style scoped>
body {
    font-family: "Poppins", sans-serif;
    overflow: hidden;
}

.wave {
    position: fixed;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -99;
}
</style>
