<template>
  <div class="base-window">
    <!-- Cuadro de busqueda -->
    <v-row dense align="center" justify="center">
      <v-col cols="12" md="12">

          <v-card :loading="loading" :disabled="loading" class="rounded-xl" elevation="1">
            <v-card-title class="d-flex justify-space-between">
              <p class="mb-0">{{ title }}</p>
            </v-card-title>

            <v-card-subtitle class="d-flex flex-column pb-1">
              <v-row dense align="center" justify="start">
                {{ company }}
              </v-row>
              <v-row dense align="center">
                <v-col cols="12" md="4">
                  <div>
                    <v-btn rounded small color="primary" @click="openAgregarListado(addMethod)">
                      <v-icon >
                        mdi-plus
                      </v-icon>
                    </v-btn>
                    <span class="pl-3">{{ titleAddMethod }}</span>
                  </div>
                </v-col>

                <v-col cols="12" md="2">
                  <v-tooltip top v-if="activateSendManyEmail">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        dark
                        rounded
                        color="teal"
                        @click="sendManyEmail"
                        v-bind="attrs"
                        v-on="on"
                        >
                        <v-icon samll>
                          mdi-email
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Envio Masivo correo</span>
                  </v-tooltip>

                  <v-tooltip top v-if="activateUpdateMany">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon dark rounded color="red" @click="updateMany" v-bind="attrs"
                        v-on="on">
                        <v-icon samll>
                          mdi-update
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Actualizar en conjunto</span>
                  </v-tooltip>

                  <v-tooltip top v-if="activateDeleteMany">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon dark rounded color="red" @click="deleteMany"  v-bind="attrs"
                        v-on="on">
                        <v-icon samll>
                          mdi-trash-can
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Borrar en conjunto</span>
                  </v-tooltip>

                </v-col>

                <v-col cols="12" md="4" class="d-flex justify-end">
                  <v-tooltip top v-if="externalSearch">
                    <template v-slot:activator="{on, attrs}">
                      <v-btn
                        fab
                        v-bind="attrs"
                        v-on="on"
                        rounded
                        @click="$emit('reset-info')"
                        color="success"
                        x-small
                        class="mr-3"
                        >
                        <v-icon dark> mdi-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>Actualizar Datos</span>
                  </v-tooltip>
                  <v-btn small rounded color="info" @click="openSearch">Filtrado</v-btn>
                </v-col>

                <v-col cols="12" md="1" class="d-flex justify-center">
                  <v-btn small rounded color="success" v-if="actBtnExcel" @click="infoExcel" >excel</v-btn>
                </v-col>

                <v-col cols="12" md="1" v-if="actBtnExcel" class="d-flex justify-center">
                  <v-btn small rounded color="error" @click="resetInfo">reset</v-btn>
                </v-col>
              </v-row>
            </v-card-subtitle>

            <v-card-text>
              <v-container fluid class="px-0 pt-1">
                <!-- linea del filtrado -->
                <v-row dense align="start"> </v-row>

                <v-row dense>
                  <v-col cols="12" md="12">
                    <v-data-table
                      v-model="baseModelData"
                      :show-select="showSelect"
                      dense
                      :headers="headers"
                      :items="itemsBaseWindowList"
                      :search="searchDocument"
                      disable-sort
                      :loading="loading"
                      hide-default-footer
                      :items-per-page="itemsPerPage"
                      >
                        <!-- pass through scoped slots -->
                        <template
                          v-for="(_, scopedSlotName) in $scopedSlots"
                          v-slot:[scopedSlotName]="slotData"
                        >
                          <slot :name="scopedSlotName" v-bind="slotData" />
                        </template>
                        <!-- eslint-disable-next-line -->
                        <template v-slot:item.actions="{ item }">

                      <div class="d-flex flex-row">
                        <v-btn
                          small
                          v-if="activarEdicion"
                          icon
                          @click="specialEditFunction ? editarSpecial(item) : editar(item)"
                        >
                          <v-icon color="warning" small class="mr-2">
                            mdi-pencil
                          </v-icon>
                        </v-btn>

                        <v-btn
                          small
                          v-if="activarBorrar"
                          icon
                          @click="specialEliminarFunction ? eliminarSpecial(item) :eliminar(item)"
                        >
                          <v-icon color="red" small>
                            mdi-{{ "delete" }}
                          </v-icon>
                        </v-btn>

                        <v-btn
                          small
                          v-if="activarPDF"
                          icon
                          @click="getPdf(item)"
                        >
                          <v-icon color="red" small>
                            mdi-file-pdf-box
                          </v-icon>
                        </v-btn>

                        <v-btn
                          small
                          v-if="activarXLS"
                          icon
                          @click="getXls(item)"
                        >
                          <v-icon color="green" small>
                            mdi-file-excel
                          </v-icon>
                        </v-btn>

                        <v-tooltip top v-if="activarAdicional1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="
                                extraEdit
                                  ? editar(item)
                                  : activarAdicional1
                                  ? $store.dispatch(`${adicional1['metodo']}`)
                                  : false
                              "
                            >
                              <v-icon
                                :color="
                                  activarAdicional1
                                    ? adicional1['color']
                                    : 'primary'
                                "
                                small
                              >
                                mdi-{{
                                  activarAdicional1
                                    ? `${adicional1["nombreIcono"]}`
                                    : "home"
                                }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{
                            activarAdicional1 ? adicional1.nombre : ""
                          }}</span>
                        </v-tooltip>
                      </div>

                      </template>
                    </v-data-table>
                    <v-pagination
                        v-model="currentPage"
                        :length="totalPages"
                        total-visible="8"
                        prev-icon="mdi-menu-left"
                        next-icon="mdi-menu-right"
                        circle
                        @input="gotoPage"
                      ></v-pagination>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "BaseWindowList",
  props: {
    showSelect:{
      type: Boolean,
      required: false,
      default: false
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 20
    },
    customWidth: {
      type: Number,
      required: false,
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1
    },
    totalPages: {
      type: Number,
      required: false,
      default: 0
    },
    headers: {
      type: Array,
      required: true,
    },
    itemsBaseWindowList:{
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true,
    },
    company: {
      type: String,
      required: false,
    },
    addMethod: {
      type: String,
      required: false,
    },
    titleAddMethod: {
      type: String,
      required: false,
    },
    itemStoreName: {
      type: String,
      required: false,
    },
    closeBaseWindow: {
      type: String,
      required: false,
    },
    activarFiltrado: {
      type: Boolean,
      required: false,
      default: false,
    },
    activarEdicion: {
      type: Boolean,
      required: false,
    },
    activarBorrar: {
      type: Boolean,
      required: false,
    },
    activarPDF: {
      type: Boolean,
      required: false,
    },
    activarXLS: {
      type: Boolean,
      required: false,
    },
    activarAdicional1: {
      type: Boolean,
      required: false,
    },
    adicional1: {
      type: Object,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    sortBy: {
      type: String,
      required: false,
    },
    extraEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    activateSendManyEmail:{
      type: Boolean,
      required: false,
      default: false,
    },
    activateUpdateMany:{
      type: Boolean,
      required: false,
      default: false,
    },
    activateDeleteMany:{
      type: Boolean,
      required: false,
      default: false,
    },
    specialEditFunction:{
      type: Boolean,
      required: false,
      default: false,
    },
    editarSpecial:{
      type: Function,
      required: false
    },
    specialEliminarFunction:{
      type: Boolean,
      required: false,
      default: false,
    },
    eliminarSpecial:{
      type: Function,
      required: false
    },
    itemStore: {
      type: String,
      required: false,
    },
    actBtnExcel: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearSelectMultiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    externalSearch: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      formBase: {},
      validFormBase: false,
      isLoading: false,
      dialogBase: true,
      searchDocument: "",
      loadingitems: [],
      count: 0,
      loadingTable: false,
      baseModelData: []
    };
  },
  watch:{
    clearSelectMultiple(val){
      if (val){
        this.baseModelData = []
      }
    }
  },
  computed: {
    // ...mapState([
    //   `${this.itemStoreName}`,
    // ])
    // itemsBaseWindowList() {
    //   return this.$store.state[this.itemStoreName];
    // },
  },
  methods: {
    openSearch(){
      if (this.externalSearch) {
        this.$emit("external-search")
      } else {
        this.$store.state[this.itemStore] = true
      }
    },
    gotoPage(text){
      console.log(text)
      this.$emit("click-gotopage", text)
    },
    closeWindow(text) {
        console.log('close-window')
        console.log(text);
        this.$store.state[text] = false;
    },
    openAgregarListado(text) {
        console.log('open-agregar-listado')
        this.$emit("open-agregar-listado")
        this.$store.state[text] = true;
    },
    editar(item) {
        this.$store.state.selectedRow = {...item}
        console.log("editar-row");
        this.$emit("editar-row", item);
        console.log(this.baseModelData)
    },
    eliminar(item) {
      console.log('eliminar-row')
      this.$emit("eliminar-row", item);
    },
    dobleClick(event, { item }) {
        console.log("dbclick-row");
        this.$emit("dbclick-row", item);
    },
    getPdf(item){
      this.$emit("get-pdf", item);
    },
    getXls(item){
      this.$emit("get-xls", item);
    },
    sendManyEmail(){
      this.$emit("send-many-email", this.baseModelData);
    },
    updateMany(){
      this.$emit("update-many", this.baseModelData);
    },
    deleteMany(){
      this.$emit("delete-many", this.baseModelData);
    },
    resetInfo(){
      this.$emit("reset-info")
    },
    infoExcel(){
      this.$emit("info-excel", this.itemsBaseWindowList)
    }
  },

  created() {
    // console.log(this.itemStoreName);
  },
};
</script>

<style></style>
