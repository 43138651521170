<template>
  <!-- <h1>Welcome!!</h1> -->
  <MainDashboard v-if="userPriceChange"></MainDashboard>
</template>

<script>
import MainDashboard from "./dashboards/MainDashboard.vue"

export default {
  name: 'Home',
  components: {
    MainDashboard
  },
  computed:{
    userPriceChange(){
      console.log(this.$store.state.AuthModule.refu)
      // return true
      if (this.$store.state.AuthModule.refu == '1' || this.$store.state.AuthModule.refu == '2' ){
        return true
      } else {
        return false
      }
    },
  }
}
</script>