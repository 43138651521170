import VueJwtDecode from "vue-jwt-decode"
import {axiosC} from "@/api/axiosbd"

const state = {
  name: localStorage.getItem("name") || null,
  accessToken: localStorage.getItem("accessToken") || null,
  refreshToken: localStorage.getItem("refreshToken") || null,
  accessExp: localStorage.getItem("accessExp") || null,
  ref: localStorage.getItem("ref") || null,
  comdes: localStorage.getItem("comdes") || null,
  r2: localStorage.getItem("r2") || null,
  refu: localStorage.getItem("refu") || null,
  // rol: localStorage.getItem("rol") || null,
  // email: localStorage.getItem("email") || null,
  // idUser: localStorage.getItem("idUser") || null,
}

const getters = {
  getAccessToken(state) {
    return state.accessToken
  },

  loggedIn(state) {
    return state.accessToken != null
  },
  refresh(state) {
    return state.refreshToken
  },
}

const mutations = {
  updateTokens(state, authData) {
    //actualiza el local store y el state
    //despues de hacer log in en el sistema
    const tokenDecoded = VueJwtDecode.decode(String(authData.access))
    console.log(tokenDecoded)
    state.accessExp = tokenDecoded.exp * 1000
    state.accessToken = authData.access
    state.refreshToken = authData.refresh
    state.accessToken = authData.access
    state.name = tokenDecoded.name
    state.refu = tokenDecoded.user_id
    // state.rol = tokenDecoded.role
    // state.email = tokenDecoded.email
    // state.idUser = tokenDecoded.id
    localStorage.setItem("name", tokenDecoded.name)
    localStorage.setItem("refu", tokenDecoded.user_id)
    localStorage.setItem("refreshToken", authData.refresh)
    localStorage.setItem("accessToken", authData.access)
    localStorage.setItem("accessExp", tokenDecoded.exp * 1000)
    // localStorage.setItem("email", tokenDecoded.email)
    // localStorage.setItem("idUser", tokenDecoded.id)
  },
  destroyTokens(state) {
    //funcionalidad para borrar los datos
    //ya se por error de login o por hacer logout
    state.name = null
    state.accessToken = null
    state.refreshToken = null
    state.accessExp = null
    state.ref = null
    state.r2 = null
    state.refu = null
    localStorage.clear()
    // state.email = null
    // localStorage.removeItem("refreshToken")
    // localStorage.removeItem("accessToken")
    // localStorage.removeItem("accessExp")
    // localStorage.removeItem("email")
    // localStorage.removeItem("idUser")
  },
  updateAccess(state, authData) {
    //actualiza el local store y el state
    //despues de hacer log in en el sistema
    const tokenDecoded =  VueJwtDecode.decode(String(authData.access))
    state.accessExp = tokenDecoded.exp * 1000
    state.accessToken = authData.access
    state.name = tokenDecoded.name
    localStorage.setItem("name", tokenDecoded.name)
    localStorage.setItem("accessToken", authData.access)
    localStorage.setItem("accessExp", tokenDecoded.exp * 1000)
  },
  mutRef(state, data ){
    state.ref = data.comcod
    localStorage.setItem("ref", data.comcod)

    state.comdes = data.comdes
    localStorage.setItem("comdes", data.comdes)

    state.r2 = data.refdb__info_env
    localStorage.setItem("r2", data.refdb__info_env)
  }
}

const actions = {
  refreshToken(context) {
    return new Promise((resolve, reject) => {
      axiosC
        .post("/api/auth/refresh", {
          refreshToken: `Bearer ${context.state.refreshToken}`,
        }) // esto envia el refreshToken al backend API
        .then((response) => {
          // if API sends back new access and refresh token update the store
          context.commit("updateAccess", response.data)
          //con esto respondo con el accessToken si todo sale bien
          //cuando refreshToken es llamado desde cualquier parte del codigo
          resolve(response.data)
        })
        .catch((err) => {
          alert(err)
          reject(err) // error generating new access and refresh token because refresh token has expired
        })
    })
  },
  loginUser({commit}, credentials) {
    //metodo en el cual devolvemos una promesa
    //denotando si nos conectamos o no al server
    //ademas envia datos de error para ser mostrado
    //en la vista Login
    return new Promise((resolve, reject) => {
      axiosC
        .post("api/token/", {
          username: credentials.username,
          password: credentials.password,
        })
        .then((res) => {
          commit("updateTokens", res.data)
          resolve()
        })
        .catch((err) => {
          commit("destroyTokens")
          reject(err)
        })
    })
  },
  refUser({commit}){
    return new Promise((resolve, reject) =>{
      axiosC({
        method: 'GET',
        url: 'transjuma/consulta_ref/',
        headers: {
            Authorization: `Bearer ${state.accessToken}`,
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        commit("destroyTokens")
        reject(err)
      })
    })
  },
  logoutUser({commit}) {
    commit("destroyTokens")
    //Router.history.push('/')
  },
  async verifyToken({state, commit}) {
    let res = {
      state: true,
      message: "",
    }

    if (state.accessToken) {
      if (state.accessExp < new Date().getTime()) {
        try {
          const response = await axiosC.post("api/token/refresh/", {
            refresh: state.refreshToken,
          })
          commit("updateAccess", response.data)
        } catch (err) {
          if (err.response && err.response.status == 401) {
            console.log(err.response.data.details)
            res.state = false
            res.message = `Verify token: Unauthorized user, please login.`
            localStorage.clear()
          } else {
            localStorage.clear()
            res.state = false
            res.message = `Verify token: ${err}`
          }
        }
      } else {
        console.warn("Token aun valido")
      }
    } else {
      res.state = false
      res.message = `Verify token: Can't get access token.`
      localStorage.clear()
    }
    return res
  },
  actRef({commit}, data){
    commit('mutRef', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
