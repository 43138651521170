<template>
  <div>
    <BaseWindowList
      :headers="headers"
      :itemsBaseWindowList="desserts"
      title="Gestion de Transportistas"
      titleAddMethod="Agregar Nuevo Transportista"
      :activarEdicion="true"
      :activarBorrar="true"
      v-on:editar-row="editItem"
      :currentPage="currentPage"
      :totalPages="totalPages"
      v-on:open-agregar-listado="openForm"
      v-on:click-gotopage="siguientePagina"
      :loading="loading"
      v-on:eliminar-row="deleteItem"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
      </template>

      <template v-slot:item.tipo_identificacion="{ item }">
        <tr class="d-flex justify-end">
          <td
            class="text-center text-caption blue-grey--text text--darken-1 pr-4"
          >
            {{ itemTextTipoIdentifi(item.tipo_identificacion) }}
          </td>
        </tr>
      </template>
    </BaseWindowList>

    <!-- MAIN DIALOG -->
    <v-form ref="editedItem">
      <v-dialog v-model="dialog" max-width="800px" persistent>
        <v-card rounded="xl" :loading="loading" :disabled="loading">
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      v-model="editedItem.tipo_identificacion"
                      :items="tipos"
                      item-text="nombre"
                      item-value="id"
                      label="Tipo de identificación"
                      clearable
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.numero_identificacion"
                    label="Número de identificación"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    @change="buildNumber"
                    v-model.number="editedItem.nombre"
                    label="Nombre"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    @change="buildNumber"
                    v-model="editedItem.email"
                    label="Email"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.direccion"
                    label="Dirección"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.placa"
                    label="Placa"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.telefono_1"
                    label="Teléfono 1"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.telefono_2"
                    label="Teléfono 2"
                  ></v-text-field>
                </v-col>

                
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="blue darken-1" text @click="close"
              >Cancel</v-btn
            >
            <v-btn small color="blue darken-1" text @click="save(mode)"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <!-- DIALOG DELETE -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >¿Está seguro de borrar este destino y tonelada?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete"
            >Cancel</v-btn
          >
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { axiosC } from "../api/axiosbd";
import BaseWindowList from "@/components/BaseWindowList";
import { paginationMethods } from "../mixins/pagination";
export default {
  components: {
    BaseWindowList,
  },
  mixins: [paginationMethods],
  data() {
    return {
      loading: false,
      search: "",
      headerId: null,
      destinos: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Tipo de identificación", value: "tipo_identificacion" },
        {
          text: "Número de identificación",
          value: "numero_identificacion",
          align: "center",
        },
        { text: "Nombre", value: "nombre" },
        { text: "Email", value: "email" },
        { text: "Dirección", value: "direccion", align: "center" },
        { text: "Placa", value: "placa", align: "center" },
        { text: "Teléfono 1", value: "telefono_1", align: "center" },
        { text: "Teléfono 2", value: "telefono_2", align: "center" },
        { text: "Foto", value: "foto", align: "center" },
        { text: "Código", value: "codigo", align: "center" },
        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],
      desserts: [],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {},
      deletedItem: {},
      defaultItem: {},
      tipos: [
        { id: "F", text: "FIJO" },
        { id: "V", text: "VARIABLE" },
      ],
      mode: "POST",
    };
  },
  methods: {
    itemTextTipoIdentifi(item){
      if (item){
        console.log(item)
        const text = this.tipos.find(y => y.id == item)
        return text ? text.nombre : ''
      }
    },
    siguientePagina(item) {
      this.gotoPage(item, this.$route.meta.backRoute);
    },
    openForm() {
      console.log("ABRIENDO");
      this.dialog = true;
    },

    convertToMoney(x) {
      // Verificar si el valor es nulo, indefinido o no es un número
      if (x === undefined || x === null || isNaN(Number(x))) {
        return "0.00";
      }
      // Convertir a número y luego aplicar toFixed(2)
      x = Number(x).toFixed(2);

      // Aplicar formato de separador de miles
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    buildNumber() {
      // console.log(this.editedItem.tonelada)
      let tonelada =
        this.editedItem.tonelada > 0 ? this.editedItem.tonelada : 0;
      let codigo_destino =
        this.editedItem.codigo_destino.trim() != ""
          ? this.editedItem.codigo_destino
          : "";
      this.editedItem.numero =
        this.editedItem.tonelada > 0
          ? parseInt(tonelada) + codigo_destino
          : codigo_destino;
    },
    clearSelects() {
      for (let field in this.defaultItem) {
        if (this.defaultItem[field].value) this.defaultItem[field].value = null;
        if (this.defaultItem[field].values) this.defaultItem[field].values = [];
      }
    },
    close() {
      this.dialog = false;
      this.mode = "POST";
      this.$nextTick(() => {
        this.clearSelects();
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    closeDelete() {
      this.dialogDelete = false;

      this.$nextTick(() => {
        this.clearSelects();
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      console.log(item);
      this.deletedItem = { ...item };
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.loading = true;
      await this.$store.dispatch("AuthModule/verifyToken");

      axiosC({
        method: "DELETE",
        url: `transjuma/Transportista/${this.deletedItem.id}/`,
        headers: {
          Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
        },
      })
        .then(() => {
          this.getDataGeneral(
            "GET",
            `transjuma/Transportista/`,
            this.nameItemStore,
            "loading",
            {},
            true,
            true
          );
          this.closeDelete();
        })
        .catch((err) => {
          this.loading = false;
          alert(err.response.data.message);
        });
    },
    async save(method) {
      this.loading = true;
      await this.$store.dispatch("AuthModule/verifyToken");

      let body = {};

      body = { ...this.editedItem };

      body.company = 1;
      body.created_by =
        method == "POST"
          ? this.$store.state.AuthModule.refu
          : this.editedItem.created_by;
      body.updated_by =
        method == "PUT" ? this.$store.state.AuthModule.refu : null;
      console.log(this.editedItem);

      axiosC({
        method: method,
        url: `transjuma/Transportista/${method == "PUT" ? body.id + "/" : ""}`,
        data: body,
        headers: {
          Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
        },
      })
        .then(() => {
          this.close();
          this.getDataGeneral(
            "GET",
            `transjuma/Transportista/`,
            this.nameItemStore,
            "loading",
            {},
            true,
            true
          );
        })
        .catch((err) =>  {
          this.loading = false
          alert(err.response.data.message)
        })
    },
    async editItem(item) {
      console.log(item);
      this.mode = "PUT";
      this.editedItem = { ...item };

      this.headerId = item.id;
      this.dialog = true;
    },
  },
  computed: {
    formTitle() {
      if (this.editedIndex === -1) {
        return `Nuevo destino y tonelada`;
      } else {
        return `Edición de destino y tonelada`;
      }
    },
  },
  created() {
    const page = 1;
    const size = 20;
    this.nameItemStore = "desserts";
    this.getDataGeneral(
      "GET",
      `transjuma/Transportista/`,
      this.nameItemStore,
      "loading",
      {},
      true,
      false
    )
    this.getDataGeneral(
      "GET",
      `transjuma/TipoIdentificacion/`,
      'tipos',
      "",
      {},
      false, //paginacion
      false, // opcion para que devuelva algo
      true //modo normal activado, vienen todos los resultados
    );
  },
};
</script>