<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="ml-1" elevation="0">
          <v-card-title> Modulo Migracion Ordenes de Compra </v-card-title>
          <v-row justify="space-around">
            <v-col cols="12">
              <v-card class="ma-2">
                <h3 class="text-center pt-3">
                  Indicaciones de Uso Herramienta de Migracion de Ordenes
                </h3>
                <v-card-text class="text-center">
                  La herramienta cuenta con un excel como template o borrador,
                  el cual le ayuda a la inserción en gran cantidad de guías.<br />
                  El Excel le traerá todas las guías y ajustes que se encuentran
                  en estado proforma, por orden de numero de proforma y numero
                  de guía, para que usted solo complete el campo del numero de
                  la proforma que le es enviada.<br />
                  <strong
                    >Recuerde no modificar el archivo de ninguna manera posible,
                    solo rellene el campo a actualizar que es el de orden de
                    compra.</strong
                  >
                </v-card-text>
                <v-row>
                  <v-col> </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center justify-space-around">
            <v-col md="4" sm="6" class="d-flex justify-center">
              <v-btn
                @click="downloadTemplate('loading2')"
                small
                :loading="loading2"
                :disable="loading2"
                >Descarga de proformas
                <v-icon right dark> mdi-cloud-download </v-icon></v-btn
              >
            </v-col>
            <v-col md="4" sm="6">
              <div class="d-flex align-center">
                <v-file-input
                  v-model="files"
                  placeholder="Subir archivo de Ordenes"
                  label="Archivo de ordenes"
                  prepend-icon="mdi-paperclip"
                  counter
                  show-size
                  :loading="loading"
                  :disable="loading"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
                <v-btn
                  small
                  rounded
                  class="ma-2"
                  color="primary"
                  :loading="loading"
                  :disable="loading"
                  @click="uploadFile('loading')"
                >
                  <v-icon dark> mdi-cloud-upload </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { axiosC } from "../api/axiosbd";
export default {
  data() {
    return {
      files: null,
      loader: null,
      loading: false,
      loading2: false,
    };
  },
  methods: {
    async uploadFile(loader) {
      // console.log(this.files)
      await this.$store.dispatch('AuthModule/verifyToken')
      if (this.files) {
        await this.$store.dispatch('AuthModule/verifyToken')
        this.loader = loader
        this.loaderEfect('A')

        let data = new FormData(); // creates a new FormData object
        data.append("file", this.files); // add your file to form data

        axiosC(`transjuma/FileUploadOrdenDeCompa/${this.files.name}`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
            "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          data,
        })
          .then((response) => {
            console.log(response)
            this.files= null
            this.loaderEfect('D')
            this.$message({
              message: 'Info, El proceso ha finalizado con exito.',
              type: 'success'
            });
          })
          .catch((err) => {
            console.log(err)
            this.loaderEfect('D')
            this.$message({
              message: 'Recuerde revisar el excel, si el problema persiste consulte al servicio de soporte.',
              type: 'error'
            });

          })
      } else {
        console.error('No ha seleccionado ningun archivo')
      }
    },
    async downloadTemplate(loader) {
      this.loader = loader
      this.loaderEfect('A')
      await this.$store.dispatch('AuthModule/verifyToken')

      axiosC({
          method: 'GET',
          url: '/transjuma/templateOrdenDeCompra',
          headers: {
              Authorization: `Bearer ${this.$store.state.AuthModule.accessToken}`,
          },
          responseType: 'blob',
      })
        .then((response) => {
          this.loaderEfect('D')
          console.log('HEADERS', response.headers['Content-Filename'])
          saveAs(response.data, response.headers['Content-Filename'])
        })
        .catch((err) => {
          this.loaderEfect('D')
          alert(`Error: ${err} Favor contactar al administrador`)
        })
    },
    loaderEfect (param) {
      const l = this.loader
      if (param == "A"){
        this[l] = !this[l]
      } else {
        this[l] = false
        this.loader = null
      }
    }
  },
};
</script>

<style>
</style>